import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({ progress, text }: { progress: number; text?: string }) => {
  return (
    <CircularProgressbar
      value={progress}
      text={text}
      styles={buildStyles({
        pathColor: `rgba(66, 153, 225, ${String(progress / 100)})`,
      })}
    />
  );
};

export default CircularProgress;
