import {
  CanvasAnswer,
  CustomerJourneyMapAnswer,
  PrioritizationMatrixAnswer,
} from '@app/@types/redux/answer';
import { CanvasOptions } from '@app/@types/redux/pageElement';
import { DependeeValue } from '@app/@types/redux/project';
import { store } from '@state/redux/store';
import { getDependeeValues } from '@state/selectors/projects';
import { useEffect } from 'react';
import { PageElementProps } from '../..';
import CanvasView from './CanvasView';

export interface ExternalPageElementProps extends PageElementProps {
  answers?: CanvasAnswer | CustomerJourneyMapAnswer | PrioritizationMatrixAnswer;
  preview?: boolean;
}

const ModelCanvas: React.FC<PageElementProps> = (props) => {
  const {
    answer,
    pageElement: {
      attributes: { dependees },
    },
    pageElement,
    currentAnswer,
  } = props;
  const options = pageElement.attributes.options as CanvasOptions;

  const elements = currentAnswer as CanvasAnswer;

  useEffect(() => {
    const dependentValues = getDependeeValues(
      dependees,
      [],
      store.getState().projects
    ) as DependeeValue[];

    const elements = dependentValues.map((dependentValueItem) => {
      return {
        answer: dependentValueItem.answer,
        pageElement: dependentValueItem.dependeePageElement,
      };
    }) as CanvasAnswer;
    answer(elements);
  }, []);

  return <CanvasView options={options} elements={elements} />;
};

export default ModelCanvas;
