import { CSSProperties, ReactNode } from 'react';
import { Card } from 'reactstrap';

interface CCardProps {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  onClick?: () => void;
}

const CCard = ({
  className = '',
  style = {},
  children = null,
  onClick = undefined,
}: CCardProps) => {
  return (
    <Card onClick={onClick} style={style} className={className}>
      {children}
    </Card>
  );
};

export default CCard;
