import env from '@config/env';
import logger from '@libs/log';
import ReactGA from 'react-ga4';
import { router } from './routes';

ReactGA.initialize(env.GA_TRACKING_ID);

const redirectTo = (path: string) => {
  router
    .navigate(path)
    .then(() => {
      ReactGA.send({ hitType: 'pageview', page: path, title: document.title });
    })
    .catch(() => {
      logger.error('Failed to redirect to: ' + path);
    });
  return null;
};

export { redirectTo };
