import env from '@config/env';
import logger from '@libs/log';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import elTranslations from './languages/el';
import enTranslations from './languages/en';

export const isRTL = () => {
  return i18n.dir() === 'rtl';
};

const languageDetector = new LanguageDetector();

languageDetector.init({
  supportedLngs: ['el', 'en'],
  order: ['querystring', 'cookie', 'localStorage', 'path', 'subdomain'],
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
  caches: ['localStorage', 'cookie'],
  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
});

await i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      nsSeparator: ':',
      ns: ['translations'],
      defaultNS: 'translations',
      fallbackLng: 'en',
      saveMissing: env.DEV,
      debug: env.DEV,
      interpolation: {
        escapeValue: false,
        prefix: '{{{',
        suffix: '}}}',
      },
      resources: {
        en: {
          translations: enTranslations,
        },
        el: {
          translations: elTranslations,
        },
      },
    },
    (err) => {
      if (err) {
        logger.error(err);
        return;
      }
    }
  )
  .catch((e: unknown) => {
    logger.error(e);
  });

export function i18nCText(value: string) {
  if (value) {
    return i18n.t(value, { keySeparator: ':::', nsSeparator: '|||' });
  }
  return '';
}

export default i18n;
