import Api from '@state/utils/Api';
import { useState } from 'react';

/**
 * This hook is used to upload files to the server.
 * @param validations Optional: Object with the following properties:
 * - accept: Array of strings with the allowed file types.
 * - maxSize: Number with the maximum file size allowed.
 * @returns Object with the following properties:
 * - progress: Number with the upload progress.
 * - url: String with the file URL.
 * - upload: Function to upload the file.
 * @example
 * const { progress, url, upload } = useUpload({
 *  accept: ['image/jpeg', 'image/png'],
 *  maxSize: 1024 * 1024 * 2,
 * });
 */

export interface UseUploadValidations {
  accept: string[];
  maxSize: number;
}

const useUpload = (validations?: UseUploadValidations) => {
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState('');

  const upload = (file: File, handleFinish?: (data: string) => void) => {
    if (validations) {
      if (validations.accept.length && !validations.accept.includes(file.type)) {
        return;
      }
      if (validations.maxSize && file.size > validations.maxSize) {
        return;
      }
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      setProgress(0);
      const response = Api.upload(formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / Number(progressEvent.total)
          );
          setProgress(percentCompleted);
        },
      })
        .then((response) => {
          setProgress(100);
          return response;
        })
        .catch(() => {
          setProgress(0);
          return null;
        });
      response
        .then((res) => {
          if (res) {
            setUrl(res.data.url);
            if (handleFinish) {
              handleFinish(res.data.url);
            }
          }
        })
        .catch(() => {
          setProgress(0);
        });
    } catch (error) {
      setProgress(0);
    }
  };

  return { progress, url, upload };
};

export default useUpload;
