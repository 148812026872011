import { fetchUserEvaluation } from '@state/redux/slices/project.slice';
import { RootState, useAppDispatch, useAppSelector } from '@state/redux/store';
import { useEffect, useState } from 'react';
import { FiCircle, FiUserCheck, FiUserPlus } from 'react-icons/fi';
import Skeleton from 'react-loading-skeleton';
import { i18nCText } from '../../../libs/i18n/I18n';
import getMBTIData, { IMbtiData } from '../../../libs/mbti/mbti-data';
import { redirectTo } from '../../../routes/helpers';
import { updateLocation } from '../../../state/redux/slices/auth.slice';
import { translate } from '../../../state/utils/helper';

import { LocationData } from '@app/@types/redux/auth';
import logger from '@libs/log';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Badge, Col, H1, ListGroup, ListGroupItem, Row } from '../../UI/Html';

interface MBTIResultProps {
  userId: string;
}

function MBTIResult(props: MBTIResultProps) {
  const location = useLocation() as LocationData;
  const { userId } = props;
  const [isFetching, setIsFetching] = useState(true);
  const dispatch = useAppDispatch();
  useEffect(() => {
    let mounted = true;
    setIsFetching(true);
    dispatch(fetchUserEvaluation({ id: 'mbti' }))
      .then(() => {
        if (mounted) {
          setIsFetching(false);
        }
      })
      .catch(() => {
        logger.error('Error fetching user evaluation');
        if (mounted) {
          setIsFetching(false);
        }
      });
    return () => {
      mounted = false;
    };
  }, [userId, dispatch]);

  const userEvaluation = useAppSelector(
    (state: RootState) => state.projects.currentEvaluation.userEvaluation
  );

  const mbtiData: IMbtiData | undefined = getMBTIData(
    String(userEvaluation.attributes.results?.resultHuman)
  );
  if (!mbtiData) {
    return (
      <>
        {isFetching ? (
          <Skeleton count={3} height={20} />
        ) : (
          <Row>
            <Col
              xs={{ size: 12, order: 2 }}
              md={{ size: 6, order: 1 }}
              className="d-none d-md-block"
            >
              <ListGroup>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <Badge color="primary" pill>
                    1
                  </Badge>
                  <span className="empty-input" />
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <Badge color="primary" pill>
                    2
                  </Badge>
                  <span className="empty-input" />
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <Badge color="primary" pill>
                    3
                  </Badge>
                  <span className="empty-input" />
                </ListGroupItem>
              </ListGroup>
            </Col>
            <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }} className="mb-4">
              <Row>
                <Col>
                  <H1>{translate('report.mbtiReport.title')}</H1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>{translate('report.mbtiReport.description')}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className="btn-icon btn-3"
                    color="info"
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      dispatch(updateLocation(location));
                      redirectTo('/evaluations/mbti');
                    }}
                  >
                    <span className="btn-inner--icon">
                      <FiCircle size={20} />
                    </span>
                    <span className="btn-inner--text">{translate('report.mbtiReport.button')}</span>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </>
    );
  }

  const { title, id, long, keywords, keepDoing, couldDoBetter, footNote } = mbtiData;
  return (
    <>
      {isFetching ? (
        <Skeleton count={3} height={20} />
      ) : (
        <div className="mbti-report">
          <Row>
            <Col>
              <H1>{`${i18nCText(title)} (${id})`}</H1>
              <p>{i18nCText(long)}</p>
              <div className="tags mt-4">
                {keywords.map((keyword) => {
                  return (
                    <span key={keyword} className="badge-tags" color="warning">
                      {i18nCText(keyword)}
                    </span>
                  );
                })}
              </div>
            </Col>
          </Row>
          <Row className="mb-4 mt-4">
            <Col>
              <ListGroup className="report-table">
                <ListGroupItem className="d-flex align-items-center report-table-header">
                  <FiUserCheck />
                  <h3>{i18nCText('Keep doing this, you are great at it')}</h3>
                </ListGroupItem>
                {keepDoing.map((keyword, index) => {
                  return (
                    <ListGroupItem key={keyword} className="d-flex align-items-center">
                      <Badge color="primary" pill>
                        {index + 1}
                      </Badge>
                      <span className="ml-3">{i18nCText(keyword)}</span>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <ListGroup className="report-table">
                <ListGroupItem className="d-flex align-items-center report-table-header">
                  <FiUserPlus />
                  <h3>{i18nCText('Things you could do better')}</h3>
                </ListGroupItem>
                {couldDoBetter.map((keyword, index) => {
                  return (
                    <ListGroupItem key={keyword} className="d-flex align-items-center">
                      <Badge color="primary" pill>
                        {index + 1}
                      </Badge>
                      <span className="ml-3">{i18nCText(keyword)}</span>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>{i18nCText('Summary')}</h1>
              <p>{i18nCText(footNote)}</p>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default MBTIResult;
