import classnames from 'classnames';
import { useEffect, useState } from 'react';

import { Project } from '@app/@types/redux/project';
import env from '@config/env';
import { DefaultError } from '@libs/constants/errors';
import {
  fetchReviewStatus,
  fetchToReviewIdea,
  updateReviewingFrom,
} from '@state/redux/slices/project.slice';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import _ from 'lodash';
import gallery from '../../assets/images/icons/gallery.svg';
import like from '../../assets/images/icons/like.svg';
import { errorToast, slugify } from '../../libs/helper';
import { redirectTo } from '../../routes/helpers';
import { getCurrentPage, getWorkingStage } from '../../state/selectors/projects';
import { translate } from '../../state/utils/helper';
import { Alert, Button, Col, H1, ImagePlaceholder, Row } from '../UI/Html';
import './ProjectNotificationModal.scss';

interface ReviewStatusItem {
  pageElementId: string;
  reviewsDone: number;
  reviewsAvailable: number;
  reviewStage: string;
  selectedReviewTypes: string[];
}

function ProjectNotificationModal({
  project,
  redirect,
  showAllComplete = true,
  showDoneButIncomplete = true,
  workspaceId,
}: {
  project: Project;
  redirect?: (sectionId: string) => void;
  showAllComplete?: boolean;
  showDoneButIncomplete?: boolean;
  workspaceId: string;
}) {
  const dispatch = useAppDispatch();
  const [reviewStatusIsFetching, setReviewStatusIsFetching] = useState(true);
  const [ideaToReviewIsFetching, setIdeaToReviewIsFetching] = useState(true);
  const userProject = useAppSelector((state) => state.projects.currentProject.userProject);
  const reviewStatus = useAppSelector((state) => state.projects.currentProject.reviewStatus);
  const projectState = useAppSelector((state) => getWorkingStage(state.projects));
  const currentStageId = projectState?.id;
  const currentPageId = useAppSelector((state) => getCurrentPage(state.projects)?.id);
  const [alert, setAlert] = useState('');
  const [reviewStatusItem, setReviewStatusItem] = useState<ReviewStatusItem>({
    pageElementId: '',
    reviewsDone: 0,
    reviewsAvailable: 0,
    reviewStage: '',
    selectedReviewTypes: [],
  });
  const [toReviewElementId, setToReviewElementId] = useState('');
  useEffect(() => {
    dispatch(fetchReviewStatus({ projectId: project.id }))
      .then(() => {
        setReviewStatusIsFetching(false);
      })
      .catch(() => {
        errorToast(DefaultError);
      });
  }, [project.id, dispatch]);

  useEffect(() => {
    if (reviewStatusItem.pageElementId && project.id) {
      dispatch(
        fetchToReviewIdea({
          projectId: project.id,
          pageElementId: reviewStatusItem.pageElementId,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.data) {
            setToReviewElementId(res.data);
            setIdeaToReviewIsFetching(false);
          }
        })
        .catch(() => {
          setIdeaToReviewIsFetching(false);
        });
    } else {
      setIdeaToReviewIsFetching(false);
    }
  }, [reviewStatusItem.pageElementId, dispatch, project.id]);

  useEffect(() => {
    if (
      !reviewStatusIsFetching &&
      Object.keys(reviewStatus).length > 0 &&
      userProject.attributes.state === 'Approved'
    ) {
      if (
        Object.keys(reviewStatus)
          .map((rs) => {
            return (
              reviewStatus[rs].selectedReviewTypes.includes('peerReview') &&
              reviewStatus[rs].reviewsDone >= env.MINIMUM_GIVE_REVIEWS
            );
          })
          .every((v) => v)
      ) {
        setAlert('reviewComplete');
      } else {
        const incompleteReviews = Object.keys(reviewStatus)
          .filter((reviewElementId) => {
            return (
              reviewStatus[reviewElementId].selectedReviewTypes.includes('peerReview') &&
              reviewStatus[reviewElementId].reviewsDone < env.MINIMUM_GIVE_REVIEWS
            );
          })
          .map((reviewElementId) => {
            return {
              ...reviewStatus[reviewElementId],
              pageElementId: reviewElementId,
            };
          });
        const incompleteReviewWithAvailableIdeas = incompleteReviews.filter((incompleteReview) => {
          return (
            incompleteReview.reviewsDone < env.MINIMUM_GIVE_REVIEWS &&
            incompleteReview.reviewsAvailable > 0
          );
        });
        if (incompleteReviewWithAvailableIdeas.length > 0) {
          setReviewStatusItem(incompleteReviewWithAvailableIdeas[0]);
          setAlert('reviewIncomplete');
        } else if (incompleteReviews.length > 0) {
          setReviewStatusItem(incompleteReviews[0]);
          setAlert('reviewIncomplete');
        }
      }
    }
  }, [reviewStatus, userProject, reviewStatusIsFetching]);

  const link: Record<string, string> = {
    Ideate: 'ideas',
    Submit: 'submissions',
    Empathy: 'submissions',
    Do: 'submissions',
    Care: 'submissions',
    Dare: 'submissions',
  };
  const object: Record<string, string> = {
    Ideate: 'idea',
    Submit: 'submission',
    Empathy: 'submission',
    Do: 'submission',
    Care: 'submission',
    Dare: 'submission',
  };
  const { reviewsDone, reviewStage, reviewsAvailable } = reviewStatusItem;
  const isFetching = reviewStatusIsFetching || ideaToReviewIsFetching;
  return (
    <>
      {/* Reviews done but not to minium and ideas not available Alert */}
      <Alert
        isOpen={
          !isFetching &&
          alert === 'reviewIncomplete' &&
          reviewsAvailable === 0 &&
          showDoneButIncomplete
        }
        onRequestClose={() => {
          setAlert('');
        }}
        showCloseButton
        id="review-incomplete-no-idea-available"
      >
        <Row className="py-3">
          <Col>
            <ImagePlaceholder src={gallery} height="60" width="60" native />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <H1 className="font-weight-500 text-dark">
              {translate(`projectNotificationModal.alert1.${String(link[reviewStage])}.title`, {
                count: env.MINIMUM_GIVE_REVIEWS - reviewsDone,
              })}
            </H1>
          </Col>
        </Row>
        <Row className="pt-2 pb-3">
          <Col>
            <p className="px-3 font-weight-400 text-dark">
              {translate(`projectNotificationModal.alert1.${link[reviewStage]}.content`)}
              <br />
            </p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <div className="review-status-modal">
              <ul className="review-status-timeline">
                {_.range(0, Number(env.MINIMUM_GIVE_REVIEWS)).map((reviewStep, index) => {
                  return (
                    <li
                      className={classnames(['li', { complete: index + 1 <= reviewsDone }])}
                      key={reviewStep}
                    >
                      <div className="status">
                        <h4>
                          {translate('projectNotificationModal.reviewLabel', {
                            index: index + 1,
                          })}
                        </h4>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              color="info"
              onClick={() => {
                setAlert('');
                // Added 100 timeout for animation to finish and then move to next page so that body class is removed every-time
                setTimeout(() => {
                  redirectTo(
                    `/workspace/${workspaceId}/challenge/${project.id}/${String(
                      currentStageId
                    )}/${String(currentPageId)}/complete`
                  );
                }, 100);
              }}
            >
              {translate('projectNotificationModal.alert1.button')}
            </Button>
          </Col>
        </Row>
      </Alert>
      {/* Reviews done but not to minium and ideas are available Alert */}
      <Alert
        isOpen={!isFetching && alert === 'reviewIncomplete' && reviewsAvailable > 0}
        onRequestClose={() => {
          setAlert('');
        }}
        showCloseButton
        id="review-incomplete-idea-available"
      >
        <Row className="py-3">
          <Col>
            <ImagePlaceholder src={gallery} height="60" width="60" native />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <H1 className="font-weight-500 text-dark">
              {translate(`projectNotificationModal.alert2.${link[reviewStage]}.title`)}
            </H1>
          </Col>
        </Row>
        <Row className="pt-2 pb-3">
          <Col>
            <p className="px-3 font-weight-400 text-dark">
              {translate(`projectNotificationModal.alert2.${link[reviewStage]}.content`, {
                count: env.MINIMUM_GIVE_REVIEWS - reviewsDone,
              })}
              <br />
              {translate('projectNotificationModal.alert2.footNote')}
            </p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <div className="review-status-modal">
              <ul className="review-status-timeline">
                {_.range(0, Number(env.MINIMUM_GIVE_REVIEWS)).map((reviewStep, index) => {
                  return (
                    <li
                      className={classnames(['li', { complete: index + 1 <= reviewsDone }])}
                      key={reviewStep}
                    >
                      <div className="status">
                        <h4>
                          {translate('projectNotificationModal.reviewLabel', {
                            index: index + 1,
                          })}
                        </h4>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              color="info"
              onClick={() => {
                setAlert('');
                // Added 100 timeout for animation to finish and then move to next page so that body class is removed every-time
                setTimeout(() => {
                  if (toReviewElementId) {
                    dispatch(
                      updateReviewingFrom({
                        reviewingFrom: 'allotted',
                        reviewingUserAnswerId: toReviewElementId,
                      })
                    );
                    redirectTo(
                      `/workspace/${workspaceId}/challenge/${project.id}/idea/${toReviewElementId}`
                    );
                  } else if (redirect) {
                    redirect(link[reviewStage]);
                  } else {
                    redirectTo(
                      `/workspace/${workspaceId}/challenge/${project.id}/${slugify(
                        project.attributes.name
                      )}?section=${link[reviewStage] || ''}`
                    );
                  }
                }, 100);
              }}
            >
              {translate('projectNotificationModal.alert2.button', {
                key: object[reviewStage] || '',
              })}
            </Button>
          </Col>
        </Row>
      </Alert>
      {/* Reviews complete */}
      <Alert
        isOpen={!isFetching && alert === 'reviewComplete' && showAllComplete}
        onRequestClose={() => {
          setAlert('');
        }}
        showCloseButton
        id="review-complete"
      >
        <Row className="py-3">
          <Col>
            <ImagePlaceholder src={like} height="60" width="60" native />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <H1 className="font-weight-500 text-dark">
              {translate('projectNotificationModal.alert3.title')}
            </H1>
          </Col>
        </Row>
        <Row className="pt-2 pb-3">
          <Col>
            <p className="px-3 font-weight-400 text-dark">
              {translate('projectNotificationModal.alert3.content', {
                count: Number(env.MINIMUM_GIVE_REVIEWS),
              })}
            </p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <div className="review-status-modal">
              <ul className="review-status-timeline">
                {_.range(0, Number(env.MINIMUM_GIVE_REVIEWS)).map((reviewStep, index) => {
                  return (
                    <li
                      className={classnames([
                        'li',
                        {
                          complete: index + 1 <= env.MINIMUM_GIVE_REVIEWS,
                        },
                      ])}
                      key={reviewStep}
                    >
                      <div className="status">
                        <h4>
                          {translate('projectNotificationModal.reviewLabel', {
                            index: index + 1,
                          })}
                        </h4>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              color="info"
              onClick={() => {
                setAlert('');
                // Added 100 timeout for animation to finish and then move to next page so that body class is removed every-time
                setTimeout(() => {
                  redirectTo(
                    `/workspace/${workspaceId}/challenge/${project.id}/${String(
                      currentStageId
                    )}/${String(currentPageId)}/complete`
                  );
                }, 100);
              }}
            >
              {translate('projectNotificationModal.alert3.button')}
            </Button>
          </Col>
        </Row>
      </Alert>
    </>
  );
}

export default ProjectNotificationModal;
