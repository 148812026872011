import { nanoid } from '@reduxjs/toolkit';
import classnames from 'classnames';
import { FC, FocusEventHandler, InputHTMLAttributes, Ref } from 'react';
import { Input } from 'reactstrap';
import I18n from '../../../libs/i18n/I18n';

interface CInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  type?: string;
  placeholder?: string;
  onFocus?: () => void;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
  rows?: number;
  className?: string;
  name?: string;
  innerRef?: Ref<HTMLInputElement>;
  required?: boolean;
  autoComplete?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  dir?: string;
  lang?: string;
}

const CInput: FC<CInputProps> = ({
  id = nanoid(),
  type = 'text',
  placeholder = I18n.t('defaults.inputPlaceholder'),
  onFocus,
  rows = 0,
  className = '',
  required = false,
  autoComplete = nanoid(),
  disabled = false,
  onChange,
  dir = 'ltr',
  lang = 'en',
  onBlur,
  // register,
  ...props
}) => {
  return (
    <Input
      id={id}
      rows={rows}
      className={classnames(className)}
      placeholder={placeholder}
      type={type}
      onFocus={onFocus}
      onblur={(e: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
          onBlur(e);
        }
      }}
      name={name}
      required={required}
      autoComplete={autoComplete}
      disabled={disabled}
      onChange={onChange}
      dir={dir}
      lang={lang}
      {...props}
    />
  );
};

export default CInput;
