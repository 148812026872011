import MainProfile from '@components/Users/MainProfile';
import { Component } from 'react';

class ProfileContainer extends Component {
  override render() {
    return <MainProfile />;
  }
}

export default ProfileContainer;
