import '@libs/i18n/I18n';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/cartedo/index.scss';
import MainApp from './startup/MainApp';

Sentry.init({
  dsn: 'https://f0e8949f1a724fa2b724b9b1a3b7cdfe@o1027829.ingest.sentry.io/6004233',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const rootElement = document.getElementById('root');
if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <MainApp />
    </React.StrictMode>
  );
}
