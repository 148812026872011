import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cartedoLogo } from '../../../assets/images';

import * as actions from '../../../state/redux/slices/user.slice';
import { AppDispatch, RootState } from '../../../state/redux/store';
import { translate } from '../../../state/utils/helper';

import { isLoggedIn } from '@utils/helpers/auth';
import {
  Badge,
  DropdownToggle,
  ImagePlaceholder,
  Media,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from '../Html';

const mapStateToProps = (state: RootState) => ({
  currentUser: state.users.user,
  notifications: state.users.notifications,
  isSignedIn: isLoggedIn(),
  skills: state.users.skills,
});

interface Skill {
  attributes?: {
    points?: number;
  };
}

interface TedoPointsProps {
  skills: Skill[];
  fetchUserMySkills: () => void;
}

const TedoPoints: FC<TedoPointsProps> = ({ skills = [], fetchUserMySkills }) => {
  useEffect(() => {
    fetchUserMySkills();
  }, [fetchUserMySkills]);

  const totalAmount = skills
    .filter((x) => x.attributes)
    .map((skill) => skill.attributes?.points ?? 0)
    .reduce((prev, next) => prev + next, 0);

  return (
    <UncontrolledDropdown nav inNavbar className="my-auto tedo-points-nav">
      <DropdownToggle nav id="tedoPointsNavbarToolTip">
        <Media className="text-sm align-items-center font-weight-bold">
          <ImagePlaceholder
            native
            alt="TedoSkills"
            className="px-2"
            src={cartedoLogo.dark.icon.small}
            style={{ height: 24, width: 'auto', objectFit: 'contain' }}
          />
          <Badge className="badge-lg total-points-badge" color="secondary" pill>
            {Number(totalAmount).toLocaleString()}
          </Badge>
        </Media>
      </DropdownToggle>
      <UncontrolledTooltip delay={0} placement="bottom" target="tedoPointsNavbarToolTip">
        {translate('layout.navbar.toolTips.tedoPoints')}
      </UncontrolledTooltip>
    </UncontrolledDropdown>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      ...actions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TedoPoints);
