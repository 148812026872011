import { Note } from '@app/@types/redux/users';
import { RootState } from '@state/redux/store';
import { translate } from '@state/utils/helper';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { getFullName } from '../../../state/selectors/users';
import NoteSidePaneInner from './NoteSidePaneInner';

const mapStateToProps = (state: RootState) => ({
  fullName: getFullName(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface NoteSidePaneProps extends PropsFromRedux {
  onRequestClose: () => void;
  note?: Note;
  isOpen: boolean;
}

const NoteSidePane: React.FC<NoteSidePaneProps> = ({ isOpen, onRequestClose, note, fullName }) => {
  const currentUserFullName = `${fullName}'s ${translate('notes.note')}s`;
  if (!note) {
    return null;
  }
  return (
    <SlidingPane
      className="some-custom-class"
      overlayClassName="some-custom-overlay-class"
      isOpen={isOpen}
      subtitle={currentUserFullName}
      title={note.attributes.title}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
    >
      <NoteSidePaneInner noteId={note.id} />
    </SlidingPane>
  );
};

export default connector(NoteSidePane);
