import React from 'react';
import { ActivityIndicator } from 'react-native-web';

interface IndicatorProps {
  size?: string;
}

const Indicator: React.FC<IndicatorProps> = ({ size = 'large' }) => {
  return <ActivityIndicator animating size={size} />;
};

export default Indicator;
