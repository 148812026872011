import { CSSProperties, HTMLAttributes, ReactNode, memo } from 'react';
import { Text } from 'react-native-web';

interface CTextProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode | string;
  onClick?: () => void;
  style?: CSSProperties;
}
const CText = ({ children, onClick, style, ...rest }: CTextProps) => {
  return (
    <Text style={style} onClick={onClick} {...rest}>
      {children}
    </Text>
  );
};

export default memo(CText);
