import { ImageOptions } from '@app/@types/redux/pageElement';
import React from 'react';
import { PageElementProps } from '..';
import { Col, Row } from '../../../UI/Html';
import CZoom from '../../../UI/Html/Zoom';

const EImage: React.FC<PageElementProps> = (props) => {
  const { pageElement } = props;
  const { classes, imageUrl } = pageElement.attributes.options as ImageOptions;

  return (
    <Row>
      <Col className="text-center">
        <CZoom zoomAllowed>
          <img
            src={imageUrl}
            width="100%"
            style={{
              aspectRatio: '16 / 9',
              objectFit: 'contain',
              margin: '30px 0',
            }}
            className={classes || ''}
          />
        </CZoom>
      </Col>
    </Row>
  );
};

export default EImage;
