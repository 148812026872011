import classnames from 'classnames';

import { ImageRadioStyle2Answer } from '@app/@types/redux/answer';
import { store } from '@state/redux/store';
import { getPageElementDisabledState } from '@state/selectors/projects';
import Image from 'react-graceful-image';
import { UncontrolledTooltip } from 'reactstrap';
import feedbackFace1 from '../../../../../assets/images/feedback_faces/1.png';
import feedbackFace2 from '../../../../../assets/images/feedback_faces/2.png';
import feedbackFace3 from '../../../../../assets/images/feedback_faces/3.png';
import feedbackFace4 from '../../../../../assets/images/feedback_faces/4.png';
import feedbackFace5 from '../../../../../assets/images/feedback_faces/5.png';
import { i18nCText } from '../../../../../libs/i18n/I18n';
import './Style2.scss';

const feedbackFaces = [feedbackFace1, feedbackFace2, feedbackFace3, feedbackFace4, feedbackFace5];

interface Style2Props {
  answer: (value: ImageRadioStyle2Answer) => void;
  currentAnswer?: ImageRadioStyle2Answer;
  pageElementId: string;
  values: { id: string; title: string }[];
}
function Style2({ answer, currentAnswer, pageElementId, values }: Style2Props) {
  const state = store.getState().projects;
  const disabled = getPageElementDisabledState(state, pageElementId);
  return (
    <div className="feedback-faces ml-md-3 mt-5">
      {values.map((feedbackFace, index) => {
        const feedbackFaceId = `feedbackFaceTooltip${feedbackFace.id}`;
        return (
          <>
            <div
              key={feedbackFace.id}
              onClick={() => {
                if (!disabled) {
                  answer({
                    ...currentAnswer,
                    value: Number(feedbackFace.id),
                    label: feedbackFace.title,
                  });
                }
              }}
              role="button"
              tabIndex={0}
              data-placement="top"
              id={feedbackFaceId}
            >
              <Image
                src={feedbackFaces[index]}
                alt={feedbackFace.id}
                className={classnames({
                  imageRadioButtons__satisfactionSelected:
                    currentAnswer?.value === Number(feedbackFace.id),
                })}
              />
            </div>
            <UncontrolledTooltip delay={0} placement="top" target={feedbackFaceId}>
              {i18nCText(feedbackFace.title)}
            </UncontrolledTooltip>
          </>
        );
      })}
    </div>
  );
}

export default Style2;
