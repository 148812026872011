import logger from '@libs/log';

export const getCookie = <T>(key: string, parse?: boolean): T | null => {
  const allCookies = document.cookie;
  const cookie = allCookies
    .split(';')
    .find((kuke) => kuke.toLowerCase().includes(`${key.toLowerCase()}=`));
  const cookieValue = cookie?.substring(cookie.indexOf('=') + 1);
  if (!cookieValue) return null;
  if (!parse) {
    return cookieValue as T;
  }
  try {
    const parsedValue = JSON.parse(cookieValue) as T;
    return parsedValue;
  } catch {
    logger.error('Error parsing cookie value');
    return null;
  }
};

export const constructNewCookie = (
  key: string,
  value: string,
  expiry?: string,
  path?: string
): string => {
  if (!key) return '';

  let newCookie = `${key}=${value || ''};`;

  if (expiry) {
    newCookie = `${newCookie} Expires=${expiry};`;
  }
  if (path) {
    newCookie = `${newCookie}  Path=${path};`;
  }
  return newCookie;
};

export const setCookie = ({
  key,
  value,
  path = '',
}: {
  key: string;
  value: string | Record<string, unknown> | boolean | number;
  path?: string;
}) => {
  if (!key) return;
  const expiry = new Date();
  expiry.setDate(180);

  if (typeof value == 'string') {
    const newCookieEntry = constructNewCookie(key, value, expiry.toString(), path);
    document.cookie = newCookieEntry;
  } else if (['object', 'boolean', 'number'].includes(typeof value)) {
    const stringifiedValue = JSON.stringify(value);
    const newCookieEntry = constructNewCookie(key, stringifiedValue, path);
    document.cookie = newCookieEntry;
  }
};

/**
 * @EXPIRES_ON set to a past date to delete a cookie
 */
const EXPIRES_ON = 'Thu, 01 Jan 1970 00:00:01 GMT';

export const deleteCookie = (key: string) => {
  if (!key) return;
  document.cookie = constructNewCookie(key, '', EXPIRES_ON, '/');
};
