import { MessageOptions } from '@app/@types/redux/pageElement';
import { Page, UserAnswerAnswer } from '@app/@types/redux/project';
import { redirectTo } from '@app/routes/helpers';
import { PAGE_ELEMENT } from '@libs/constants/constants';
import logger from '@libs/log';
import { fetchEvaluation, fetchUserEvaluation } from '@state/redux/slices/project.slice';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import { getPageElementAnswerAndType } from '@state/selectors/projects';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { updateLocation } from '../../../../state/redux/slices/auth.slice';
import { Button, Card, CardBody, CardImg, CardTitle, Col, H2, Row } from '../../../UI/Html';
import MBTIResult from '../../Report/MBTIResult';
import Message from '../Elements/Message';

interface QuizProps {
  page: Page['attributes'];
  answerProject: (id: string, elementType: PAGE_ELEMENT, value: string) => void;
}

const Quiz: React.FC<QuizProps> = (props) => {
  const {
    page: {
      options: { evaluationId },
      pageElements = [],
    },
    answerProject,
  } = props;
  const state = useAppSelector((state) => state).projects;
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.users.user.id);
  useEffect(() => {
    evaluationId &&
      dispatch(fetchEvaluation({ id: evaluationId })).catch(() => {
        logger.error('Failed to fetch evaluation');
      });
    evaluationId &&
      userId &&
      dispatch(fetchUserEvaluation({ id: evaluationId })).catch(() => {
        logger.error('Failed to fetch user evaluation');
      });
  }, [dispatch, evaluationId, userId]);
  const evaluation = useAppSelector((state) => state.projects.currentEvaluation);
  const location = useLocation();

  const messagePE = pageElements.find((pe) => pe.attributes.elementType === PAGE_ELEMENT.MESSAGE);

  if (
    evaluation.userEvaluation.attributes.results?.resultHuman &&
    evaluation.evaluation.attributes.code === 'mbti'
  ) {
    return <MBTIResult userId={userId} />;
  }

  type GetAnswerType = (peId: string) => { answer: UserAnswerAnswer; type: string } | undefined;
  const getAnswerType: GetAnswerType = (peId: string) => getPageElementAnswerAndType(state, peId);

  return (
    <>
      {evaluation.userEvaluation.attributes.results?.result ? (
        <Row>
          <Col
            md={{
              offset: 0,
              size: 8,
            }}
            className="fadeInUp"
          >
            {messagePE ? (
              <Message
                position={messagePE.attributes.position}
                pageElement={{
                  ...messagePE,
                  attributes: {
                    ...messagePE.attributes,
                    options: {
                      ...messagePE.attributes.options,
                      value: 'Quiz Completed!',
                    },
                  },
                }}
                answer={() => {
                  answerProject(
                    messagePE.attributes.id,
                    messagePE.attributes.elementType,
                    'Quiz Completed!'
                  );
                }}
                getDependeeAnswerType={
                  getAnswerType as (peId: string) => {
                    answer: UserAnswerAnswer;
                    type: PAGE_ELEMENT;
                  }
                }
                state={state}
                currentAnswer={
                  (messagePE.attributes.options as MessageOptions).value as UserAnswerAnswer
                }
              />
            ) : (
              <H2 className="mb-4">Quiz Completed!</H2>
            )}
            <span
              dangerouslySetInnerHTML={{
                __html:
                  props.page.options.afterDescription ??
                  evaluation.evaluation.attributes.description,
              }}
            />
            <br />
            <br />
          </Col>
          <Col
            md={{
              offset: 0,
              size: 4,
            }}
            className="fadeInUp"
          >
            <Card>
              <CardImg
                className="inspiration-card-img"
                alt={evaluation.evaluation.attributes.name}
                src={evaluation.evaluation.attributes.image}
                top
              />
              <CardBody>
                <CardTitle>{evaluation.evaluation.attributes.name}</CardTitle>
                {/* <CardText>{`You scored ${evaluation?.userEvaluation?.results?.result * 10
                    }/${(evaluation?.pages || []).length * 10} points`}</CardText> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col
            md={{
              offset: 0,
              size: 8,
            }}
            className="fadeInUp"
          >
            {messagePE ? (
              <Message
                position={messagePE.attributes.position}
                pageElement={{
                  ...messagePE,
                  attributes: {
                    ...messagePE.attributes,
                    options: {
                      ...messagePE.attributes.options,
                      value: 'Take this quiz',
                    },
                  },
                }}
                answer={() => {
                  answerProject(
                    messagePE.attributes.id,
                    messagePE.attributes.elementType,
                    'Take this quiz'
                  );
                }}
                getDependeeAnswerType={
                  getAnswerType as (peId: string) => {
                    answer: UserAnswerAnswer;
                    type: PAGE_ELEMENT;
                  }
                }
                state={state}
                currentAnswer={
                  (messagePE.attributes.options as MessageOptions).value as UserAnswerAnswer
                }
              />
            ) : (
              <H2 className="mb-4">Take this quiz</H2>
            )}
            <span
              dangerouslySetInnerHTML={{
                __html:
                  props.page.options.beforeDescription ??
                  evaluation.evaluation.attributes.description,
              }}
            />
            <br />
            <br />
          </Col>
          <Col
            md={{
              offset: 0,
              size: 4,
            }}
            className="fadeInUp"
          >
            <Card>
              <CardImg
                className="inspiration-card-img"
                alt={evaluation.evaluation.attributes.name}
                src={evaluation.evaluation.attributes.image}
                top
              />
              <CardBody>
                <CardTitle>{evaluation.evaluation.attributes.name}</CardTitle>
                <Button
                  color="primary"
                  onClick={() => {
                    dispatch(updateLocation(location));
                    redirectTo(`/evaluations/${String(evaluation.evaluation.id)}`);
                  }}
                >
                  Take Quiz
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Quiz;
