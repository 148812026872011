import { MessageAnswer } from '@app/@types/redux/answer';
import { MessageReviewOptions } from '@app/@types/redux/pageElement';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import replace from 'react-string-replace';
import { PageElementProps } from '..';
import { i18nCText } from '../../../../libs/i18n/I18n';
import { RootState } from '../../../../state/redux/store';
import { getCurrentProject, getReviews } from '../../../../state/selectors/projects';
import { Col, H2, Row } from '../../../UI/Html';

const mapStateToProps = (state: RootState) => ({
  currentProject: getCurrentProject(state.projects),
  reviews: getReviews(state.projects),
});

const mapDispatchToProps = () => ({});

const MessageReview: React.FC<PageElementProps> = (props) => {
  const { pageElement } = props;
  const currentAnswer = props.currentAnswer as MessageAnswer;
  const options = pageElement.attributes.options as MessageReviewOptions;

  const { classes, type } = options;
  const answerToReview = currentAnswer;

  const match1 = answerToReview.match(/<span>(.*?)<\/span>/);
  const fillAnswer = match1?.[1] ?? '';
  let output: ReactNode[] | string = replace(
    answerToReview,
    /<span>(.*?)<\/span>/g,
    () => '<span>{{dependent}}</span>'
  ) as ReactNode[];
  const translatedString = i18nCText(output.join('').trim());
  output = replace(
    translatedString,
    /\{\{([a-z|A-Z|0-9|.]+)\}\}/g,
    () => fillAnswer
  ) as ReactNode[];
  output = answerToReview;

  if (type && type === 'info') {
    return (
      <div className={`idea-message-container ml-md-3 ${classes || ''}`}>
        <i className={options.prefixIcon} />
        <span
          className="dependent-text intext-blue"
          dangerouslySetInnerHTML={{
            __html: `<span class='prefix-text'>${i18nCText(
              options.prefixText
            )}</span><span>${output}</span>`,
          }}
        />
      </div>
    );
  }

  return (
    <Row>
      <Col className="mx-2">
        <H2 className="font-weight-bolder text-black mt-2 mb-4">
          <span dangerouslySetInnerHTML={{ __html: i18nCText(answerToReview) }} />
        </H2>
      </Col>
    </Row>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageReview);
