import { PrioritizationMatrixAnswer } from '@app/@types/redux/answer';
import { PrioritizationMatrixOptions } from '@app/@types/redux/pageElement';
import { PageElementProps } from '..';
import PrioritizationMatrixView from './HelperComponents/PrioritizationMatrixView';

const PrioritizationMatrix: React.FC<PageElementProps> = (props) => {
  const { currentAnswer: propCurrentAnswer, pageElement } = props;
  const answer = props.answer as (answer: PrioritizationMatrixAnswer) => void;

  const options = pageElement.attributes.options as PrioritizationMatrixOptions;

  const currentAnswer = propCurrentAnswer as PrioritizationMatrixAnswer;

  // useEffect(() => {
  //   answer({ ...currentAnswer });
  // }, [answer, currentAnswer]);

  return (
    <PrioritizationMatrixView
      currentAnswer={currentAnswer}
      answer={answer}
      pageElementOptions={options}
    />
  );
};

export default PrioritizationMatrix;
