import logger from '@libs/log';
import { fetchSimulationEmails } from '@state/redux/slices/project.slice';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import { useEffect, type Dispatch, type SetStateAction } from 'react';
import { Modal } from 'reactstrap';
import EmailMessenger from '.';

interface EmailModalProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
}
function EmailModal({ modal, setModal }: EmailModalProps) {
  const toggle = () => {
    setModal(!modal);
  };

  const userProjectEmails = useAppSelector((state) => state.projects.currentProject.emails);
  const projectId = useAppSelector((state) => state.projects.currentProject.project.id);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchSimulationEmails({ projectId })).catch(() => {
      logger.error('Failed to fetch simulation emails');
    });
  }, []);
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      // external={externalCloseBtn}
      style={{ width: '90vw', maxWidth: '90vw' }}
    >
      <div className="emailModel__container">
        <EmailMessenger onClose={toggle} emails={userProjectEmails} />
      </div>
    </Modal>
  );
}

export default EmailModal;
