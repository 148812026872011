import ProjectPreview from '../../components/Projects/View';
import Layout from '../../components/UI/Layout';

function ProjectPreviewContainer() {
  return (
    <Layout>
      <ProjectPreview />
    </Layout>
  );
}

export default ProjectPreviewContainer;
