import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { AddMediaImage } from '@app/@types/redux/answer';
import { UnsplashResult } from '@app/@types/redux/users';
import {
  ButtonGroup,
  Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
} from '@components/UI/Html';
import CircularProgress from '@components/UI/Html/CircularProgress';
import useUpload, { UseUploadValidations } from '@libs/hooks/useUpload';
import logger from '@libs/log';
import Api from '@state/utils/Api';
import _, { debounce } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { BiCloudUpload, BiImageAdd, BiLogoUnsplash } from 'react-icons/bi';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { IoIosSearch } from 'react-icons/io';
import { RiDragDropLine } from 'react-icons/ri';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Button, Col, Input, Row } from 'reactstrap';
import { translate } from '../../../../../state/utils/helper';
import UnsplashCredits from '../../../../UI/Html/UnsplashCredits';
import './AddMedia.scss';

const useStyles = makeStyles(() => ({
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

interface UnsplashImageProps {
  image: UnsplashResult;
  selected: boolean;
  handleSelect: (image: UnsplashResult) => void;
}

function UnsplashImage(props: UnsplashImageProps) {
  const { image, selected, handleSelect } = props;
  const {
    id,
    image: { regular },
    author: { authorName, authorUrl },
    meta: { description },
  } = image;

  const elementId = `unsplashImage${id}`;
  return (
    <div className="custom-control addMedia__unsplash_customControl">
      <Input
        className="custom-control-input addMedia__unsplash_input"
        id={elementId}
        type="checkbox"
        value={id}
        checked={selected}
        onChange={() => {
          handleSelect(image);
        }}
      />
      <label className="custom-control-label addMedia__unsplash_label" htmlFor={elementId}>
        <img
          src={regular}
          className={classnames('addMedia__unsplash_image', {
            addMedia__unsplash_image_selected: selected,
          })}
          alt={description}
        />
        <UnsplashCredits authorName={authorName} authorUrl={authorUrl} />
      </label>
    </div>
  );
}

const MemoizedUnsplashImage = memo(UnsplashImage);

interface AddMediaProps {
  open: boolean;
  onRequestClose: () => void;
  onSelect: (image: AddMediaImage[] | AddMediaImage) => void;
  multiple?: boolean;
  unsplash?: boolean;
  heading?: string;
  subheading?: string;
  validations?: UseUploadValidations;
  preSearchedQuery?: string;
}

function AddMedia(props: AddMediaProps) {
  const {
    open,
    onRequestClose,
    multiple,
    onSelect,
    unsplash = false,
    heading,
    subheading,
    validations,
    preSearchedQuery,
  } = props;
  const classes = useStyles();
  const [totalUnsplashSearchedImages, setTotalUnsplashSearchedImages] = useState(0);
  const [selectedImages, setSelectedImages] = useState<UnsplashResult[]>([]);
  const [mediaType, setMediaType] = useState(unsplash ? 'unsplash' : 'upload');

  const [inputText, setInputText] = useState(preSearchedQuery ?? '');
  const [results, setResults] = useState<UnsplashResult[]>([]);

  const [params, setParams] = useState({
    perPage: 6,
    page: 1,
  });

  const { upload, progress } = useUpload(validations);
  const [uploadLoading, setUploadLoading] = useState(false);

  const unsplashQuery = (searchText: string, pageNumber: number, perPage: number) => {
    Api.unsplashSearch(searchText, perPage, pageNumber)
      .then((res) => {
        setResults(res.data.results);
        setTotalUnsplashSearchedImages(res.data.meta.total);
      })
      .catch(() => {
        logger.error('Error fetching unsplash images');
      });
  };

  const onClose = useCallback(() => {
    setResults([]);
    setInputText(preSearchedQuery ?? '');
    onRequestClose();
    setSelectedImages([]);
    setParams({ ...params, page: 1 });
    setTotalUnsplashSearchedImages(0);
    setMediaType(unsplash ? 'unsplash' : 'upload');
    setUploadLoading(false);
  }, [setInputText, onRequestClose, params, unsplash]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setUploadLoading(true);
      upload(acceptedFiles[0], (imageUrl: string) => {
        onSelect({
          regular: imageUrl,
          thumb: imageUrl,
          authorName: '',
          authorUrl: '',
        });
        setUploadLoading(false);
        onClose();
      });
    },
    [onClose, onSelect, upload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const { page, perPage } = params;

  const selectedImagesIds = selectedImages.map((image) => image.id);

  const handleImageSelect = (image: UnsplashResult) => {
    if (!selectedImagesIds.includes(image.id)) {
      if (multiple) {
        setSelectedImages([...selectedImages, image]);
      } else {
        setSelectedImages([image]);
      }
    } else {
      if (multiple) {
        setSelectedImages(selectedImages.filter((im) => im.id !== image.id));
      } else {
        setSelectedImages([]);
      }
    }
  };

  useEffect(() => {
    if (unsplash && inputText !== '') {
      unsplashQuery(inputText, page, perPage);
    }
  }, [inputText, unsplash, page, perPage]);

  const handleQueryChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  }, 500);

  const pages = Math.ceil(totalUnsplashSearchedImages / 6);

  function renderPages() {
    const currentPageIndex = page;
    return _.range(1, pages + 1).map((p, index) => {
      const pId = p;
      const element = (
        <PaginationItem key={pId} className={page === pId ? 'active' : ''}>
          <PaginationLink
            onClick={(e: { preventDefault: () => void }) => {
              e.preventDefault();
              setParams({ ...params, page: pId });
            }}
          >
            {index + 1}
          </PaginationLink>
        </PaginationItem>
      );
      const pages = Array.from({ length: totalUnsplashSearchedImages }, (_, i) => i + 1);

      if (
        index === 0 ||
        index === pages.length - 1 ||
        (currentPageIndex - 3 < index && currentPageIndex + 3 > index)
      ) {
        return element;
      }
      if (currentPageIndex - 3 === index || currentPageIndex + 3 === index) {
        return (
          <PaginationItem key={pId} className="disabled">
            <PaginationLink
              onClick={(e: { preventDefault: () => void }) => {
                e.preventDefault();
              }}
            >
              ...
            </PaginationLink>
          </PaginationItem>
        );
      }
      return '';
    });
  }

  return (
    <Modal
      isOpen={open}
      toggle={onClose}
      size="lg"
      style={{
        content: {
          width: '100%',
          bottom: '1em',
        },
      }}
    >
      <div className="addMedia__wrapper">
        <h3 className="addMedia__heading">{heading ?? 'Add an Image'}</h3>
        <p className="addMedia__description">
          {subheading ??
            (unsplash
              ? 'Search for an image on Unsplash or upload your own.'
              : 'Upload your image.')}
        </p>
        {unsplash ? (
          <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
            <Button
              className={classnames({ active: mediaType === 'unsplash' })}
              color="secondary"
              onClick={() => {
                setMediaType('unsplash');
              }}
            >
              <BiLogoUnsplash size={20} />
              <input
                autoComplete="off"
                name="options"
                type="radio"
                checked={mediaType === 'unsplash'}
              />
              Search Unsplash
            </Button>
            <Button
              className={classnames({ active: mediaType === 'upload' })}
              color="secondary"
              onClick={() => {
                setMediaType('upload');
              }}
            >
              <BiCloudUpload size={20} />
              <input
                autoComplete="off"
                name="options"
                type="radio"
                checked={mediaType === 'upload'}
              />
              Upload Image
            </Button>
          </ButtonGroup>
        ) : null}
        {unsplash && mediaType === 'unsplash' ? (
          <>
            <div className="addMedia__form">
              <TextField
                label="Search"
                placeholder="Eg. Nature, Food, etc."
                className={classes.input}
                onChange={handleQueryChange}
                disabled={!unsplash}
                defaultValue={inputText}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IoIosSearch
                        size={20}
                        style={{
                          margin: '0 4px',
                        }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {results.length !== 0 ? (
                        <IconButton
                          disabled={!unsplash}
                          className={classes.iconButton}
                          aria-label="clear"
                          onClick={() => {
                            setInputText('');
                            setResults([]);
                            setParams({ ...params, page: 1 });
                          }}
                        >
                          <FiX />
                        </IconButton>
                      ) : null}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="addMedia__unsplash_container">
              <Row>
                {results.map((unsplashImage) => {
                  return (
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={unsplashImage.id}
                      xl={4}
                      className="addMedia__unsplash_imageContainer"
                    >
                      <MemoizedUnsplashImage
                        image={unsplashImage}
                        key={unsplashImage.id}
                        selected={Boolean(selectedImagesIds.includes(unsplashImage.id))}
                        handleSelect={handleImageSelect}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
            <div className="d-flex justify-content-between w-100 mt-2">
              {results.length > 0 ? (
                <Pagination
                  className={classnames('pagination justify-content-end mb-0')}
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={page > 1 ? '' : 'disabled'}>
                    <PaginationLink
                      onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        if (page - 1 > 0) setParams({ ...params, page: page - 1 });
                      }}
                      tabIndex="-1"
                    >
                      <FaAngleLeft />
                      <span className="sr-only">{translate('takeProject.previous')}</span>
                    </PaginationLink>
                  </PaginationItem>
                  {renderPages()}
                  <PaginationItem>
                    <PaginationLink
                      href="#"
                      onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        setParams({ ...params, page: page + 1 });
                      }}
                    >
                      <FaAngleRight />
                      <span className="sr-only">{translate('takeProject.next')}</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              ) : (
                <div />
              )}
              <Button
                color="primary"
                disabled={selectedImages.length === 0}
                onClick={() => {
                  if (multiple) {
                    const images: AddMediaImage[] = selectedImages.map((image) => ({
                      regular: image.image.regular,
                      thumb: image.image.thumb,
                      authorName: image.author.authorName,
                      authorUrl: image.author.authorUrl,
                    }));
                    onSelect(images);
                  } else {
                    const selectedImage: AddMediaImage = {
                      regular: selectedImages[0].image.regular,
                      thumb: selectedImages[0].image.thumb,
                      authorName: selectedImages[0].author.authorName,
                      authorUrl: selectedImages[0].author.authorUrl,
                    };
                    onSelect(selectedImage);
                  }
                  onClose();
                }}
              >
                Add{' '}
                {selectedImages.length > 1 ? `${String(selectedImages.length)} Images` : 'Image'}
              </Button>
            </div>
          </>
        ) : null}
        {mediaType === 'upload' ? (
          <div
            className={classnames('addMedia__dropzone__wrapper', { isDragActive })}
            {...getRootProps({
              onClick: (event) => {
                event.stopPropagation();
              },
            })}
          >
            {isDragActive || uploadLoading ? (
              <>
                {isDragActive ? (
                  <div className="addMedia__dropzone__isDragActiveText">
                    <RiDragDropLine
                      size={30}
                      style={{
                        marginRight: '8px',
                      }}
                    />
                    <span>Drop the files here...</span>
                  </div>
                ) : null}
                {uploadLoading ? (
                  <div className="addMedia__dropzone__uploadingText">
                    <div className="addMedia__dropzone__uploadingProgress">
                      <CircularProgress progress={progress} />
                    </div>
                    <span style={{ marginLeft: '12px', marginTop: '10px' }}>Uploading...</span>
                  </div>
                ) : null}
              </>
            ) : (
              <div className="text-center">
                <BiImageAdd size={70} />
                <div className="addMedia__dropzone__placeholderWrapper">
                  <label
                    htmlFor="file-upload"
                    className="addMedia__dropzone__fileLabel"
                    style={{
                      marginBottom: '0px',
                    }}
                  >
                    <span>Upload a file</span>
                    <input
                      {...getInputProps()}
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                    />
                  </label>
                  <p
                    style={{
                      marginBottom: '0px',
                    }}
                  >
                    or drag and drop
                  </p>
                </div>
                <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </Modal>
  );
}

export default AddMedia;
