import { Component } from 'react';
import Layout from '../../components/UI/Layout';
import NotificationsAll from '../../components/UI/Layout/NotificationsAll';

class NotificationsAllContainer extends Component {
  override render() {
    return (
      <Layout isFullWidth {...this.props}>
        <NotificationsAll />
      </Layout>
    );
  }
}

export default NotificationsAllContainer;
