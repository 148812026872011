import { FC, ReactNode } from 'react';

interface H4Props {
  className?: string;
  children?: ReactNode | string;
}

const H4: FC<H4Props> = ({ className = '', children = null }) => {
  return <h4 className={className}>{children}</h4>;
};

export default H4;
