import { FC, ReactNode } from 'react';

interface H5Props {
  className?: string;
  children?: ReactNode | string;
}

const H5: FC<H5Props> = ({ className = '', children = null }) => {
  return <h5 className={className}>{children}</h5>;
};

export default H5;
