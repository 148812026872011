import { FC, ReactNode } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Alert } from 'reactstrap';

interface ErrorAlertProps {
  children: ReactNode;
  errors?: boolean;
}

const ErrorAlert: FC<ErrorAlertProps> = ({ children, errors = false }) => {
  if (errors) {
    return (
      <Alert color="danger" fade className="fadeInUp inline-form-error-message">
        <div className="arrow" />
        <span className="alert-inner--icon">
          <FaExclamationTriangle className="alert-triangle mx-1" />
        </span>{' '}
        <span className="alert-inner--text">{children}</span>
      </Alert>
    );
  }
  return null;
};

export default ErrorAlert;
