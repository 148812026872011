import { Project } from '@app/@types/redux/project';
import { SlLock } from 'react-icons/sl';
import { slugify } from '../../../libs/helper';
import I18n, { i18nCText } from '../../../libs/i18n/I18n';
import { Button, Card, Col, Row } from '../../UI/Html';
import './Item.scss';

function ProjectIndexItem(props: {
  data: Project['attributes'];
  locked: boolean;
  workspaceId: string | number;
}) {
  const { data, locked, workspaceId } = props;
  return (
    <Card className="project__item__card">
      <div className="project__item__cardImage">
        <div className="project__item__imageContainer">
          <img
            className={`project__item__image ${
              !data.isActive ? 'project__item__image__inactive' : ''
            }`}
            src={data.options.images[I18n.language] || data.imageData}
            alt={data.name}
          />
          {locked ? (
            <div className="project__item__locked">
              <SlLock />
            </div>
          ) : null}
        </div>
      </div>
      <div className="project__item__cardBody">
        <h3>{i18nCText(data.name)}</h3>
        <div className="project__item__buttonContainer">
          {locked ? null : (
            <Button
              color="link"
              href={`/workspace/${String(workspaceId)}/challenge/${data.id}/${slugify(data.name)}`}
            >
              {I18n.t('projectsIndex.goButton')}
            </Button>
          )}
        </div>
        <Row>
          <Col className="text-right d-flex" />
        </Row>
      </div>
    </Card>
  );
}

export default ProjectIndexItem;
