import { getAccessToken, setRedirectToPath } from '@state/utils/helper';

import { Navigate, Outlet } from 'react-router-dom';

const Protected = () => {
  const token = getAccessToken();
  return token ? <Outlet /> : <Redirect />;
};

const Redirect = () => {
  const pathname = window.location.pathname;
  const search = window.location.search;
  setRedirectToPath(
    JSON.stringify({
      pathname,
      search,
    })
  );
  return <Navigate to="/sign_in" />;
};

export default Protected;
