import { ErrorMessage } from '@hookform/error-message';
import { Input } from 'reactstrap';

import { LocationData } from '@app/@types/redux/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { RootState, useAppSelector } from '@state/redux/store';
import { getRedirectToPath, setRedirectToPath } from '@state/utils/helper';
import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaMagic } from 'react-icons/fa';
import { FiLogIn } from 'react-icons/fi';
import { DEFAULT_PATH } from '../../../libs/constants/constants';
import { URL_REGEX } from '../../../libs/constants/regex';
import i18n from '../../../libs/i18n/I18n';
import { redirectTo } from '../../../routes/helpers';
import {
  fetchMe,
  login,
  requestMagicLoginLink,
  toggleLoginModal,
} from '../../../state/redux/slices/auth.slice';
import { useAppDispatch } from '../../../state/redux/store';
import { translate } from '../../../state/utils/helper';

import {
  Alert,
  Button,
  CardBody,
  Col,
  Form,
  FormGroup,
  H4,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  SubmitButton,
} from '../../UI/Html';

import { successToast } from '@libs/helper';
import { useNavigate } from 'react-router-dom';
import { z, ZodType } from 'zod';
import cartedoLogo from '../../../assets/images/logo/cartedo/dark/cartedo-logo-no-text-400.png';
import ErrorAlert from '../../UI/Html/Form/Error';

export interface LoginForm {
  email: string;
  password: string;
}

const LoginForm = (props: { mode?: string | undefined }) => {
  const { mode = 'page' } = props;
  const dispatch = useAppDispatch();

  const previousReduxLocation: LocationData = useAppSelector(
    (state: RootState) => state.auth.previousLocation
  );
  let previousLocation: LocationData | null = null;
  const redirectToPath: string | null = getRedirectToPath();
  if (redirectToPath) {
    previousLocation = JSON.parse(redirectToPath) as LocationData;
  } else {
    previousLocation = previousReduxLocation;
  }
  const [focusedEmail, setFocusEmail] = useState(false);
  const [focusedPassword, setFocusPassword] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState(false);
  const [doneModal, setDoneModal] = useState(false);
  const [withPassword, setWithPassword] = useState(true);

  const validationSchema: ZodType<LoginForm> = z.object({
    email: z.string().email('Email is not valid'),
    password: z.string(),
  });

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError: setFormError,
  } = useForm<LoginForm>({
    mode: 'onBlur',
    resolver: zodResolver(validationSchema),
  });

  const redirectLocation = previousLocation.pathname
    ? `${previousLocation.pathname.slice(1)}${previousLocation.search}`
    : DEFAULT_PATH;

  function trySignIn(data: { password: string; email: string }) {
    if (withPassword && !data.password) {
      setFormError('password', {
        type: 'required',
        message: i18n.t('Password is not valid').toString(),
      });
      return;
    }
    setSubmitLoading(true);
    if (withPassword) {
      dispatch(
        login({
          email: data.email.toLowerCase(),
          password: data.password,
        })
      )
        .unwrap()
        .then(() => {
          setSubmitLoading(false);
          dispatch(fetchMe())
            .unwrap()
            .then(() => {
              setSubmitLoading(false);
              if (mode === 'page') {
                navigate(`/${redirectLocation}`);
              } else {
                dispatch(toggleLoginModal(false));
                window.location.reload();
              }
            })
            .catch(() => {
              setSubmitLoading(false);
            });
        })
        .catch(() => {
          setError(true);
          setSubmitLoading(false);
        });
    } else {
      dispatch(
        requestMagicLoginLink({
          email: data.email.toLowerCase(),
          redirect: redirectLocation,
        })
      )
        .unwrap()
        .then(() => {
          // setDoneModal(true);
          successToast(translate('auth.login.magicLinkSent'));
          setRedirectToPath('');
          setSubmitLoading(false);
        })
        .catch(() => {
          setSubmitLoading(false);
        });
    }
  }
  const isRTL = i18n.dir() === 'rtl';
  const { ref: refEmail, ...registerEmail } = register('email');
  const { ref: refPassword, ...registerPassword } = register('password');
  return (
    <>
      <Alert
        isOpen={doneModal}
        onRequestClose={() => {
          setDoneModal(false);
          if (mode === 'modal') {
            dispatch(toggleLoginModal(false));
          }
        }}
        showCloseButton
        icon="success"
      >
        <Row className="mb-3">
          <Col>
            <div>
              <h1>{translate('auth.login.magicLinkSent')}</h1>
              <p className="px-6">{translate('auth.login.checkMailBox')}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              color="info"
              onClick={() => {
                setDoneModal(false);
                const url = `http://${watch('email').split('@')[1]}`;
                if (URL_REGEX.test(url)) {
                  window.open(url);
                }
                if (mode === 'modal') {
                  return dispatch(toggleLoginModal(false));
                }
                redirectTo('/');
              }}
            >
              {translate('auth.login.checkMailBoxButton')}
            </Button>
          </Col>
        </Row>
      </Alert>
      <Alert
        isOpen={error}
        onRequestClose={() => {
          setError(false);
        }}
        showCloseButton
        icon="error"
      >
        <Row className="mb-3">
          <Col>
            <div>
              <h1>{translate('auth.login.loginFailed')}</h1>
              <p>{translate('auth.login.emailPasswordNotMatch')}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              color="danger"
              onClick={() => {
                setError(false);
              }}
            >
              {i18n.t('auth.login.retryButton')}
            </Button>
          </Col>
        </Row>
      </Alert>
      <CardBody className="px-1 px-lg-3 py-lg-4">
        {mode === 'modal' ? ( // Rachit - Please check this condition
          <>
            <div className="loginForm__cartedoLogoContainer">
              <img src={cartedoLogo} alt="cartedo logo" />
            </div>
            <div>
              <h1 className="loginForm__heading">{translate('auth.signInHeading')}</h1>
            </div>
          </>
        ) : null}
        <H4 className="mb-4 text-sm text-center text-muted">
          {withPassword
            ? i18n.t('auth.login.subTitlePassword').toString()
            : i18n.t('auth.login.subTitleMagicLink').toString()}
        </H4>
        <Form role="form" onSubmit={handleSubmit(trySignIn)}>
          <FormGroup
            className={classNames('mb-3', {
              focused: focusedEmail,
            })}
          >
            <InputGroup className="overflow-hidden input-group-merge input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-email-83" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={i18n.t('auth.signInEmail')}
                id="email"
                onFocus={() => {
                  setFocusEmail(true);
                }}
                required
                autoComplete="email"
                dir={i18n.dir()}
                lang={i18n.language}
                className="text-lowercase"
                innerRef={refEmail}
                {...registerEmail}
              />
            </InputGroup>
          </FormGroup>
          <ErrorAlert errors={Boolean(errors.email)}>
            <ErrorMessage errors={errors} name="email" />
          </ErrorAlert>
          {withPassword && (
            <>
              <FormGroup
                className={classNames({
                  focused: focusedPassword,
                })}
              >
                <InputGroup className="overflow-hidden input-group-merge input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={translate('auth.signInPassword')}
                    type="password"
                    id="password"
                    required={withPassword}
                    onFocus={() => {
                      setFocusPassword(true);
                    }}
                    autoComplete="password"
                    dir={i18n.dir()}
                    lang={i18n.language}
                    innerRef={refPassword}
                    {...registerPassword}
                  />
                </InputGroup>
              </FormGroup>
              <ErrorAlert errors={Boolean(errors.password)}>
                <ErrorMessage errors={errors} name="password" />
              </ErrorAlert>
            </>
          )}
          <div className="text-center">
            <Button
              className="btn-link"
              onClick={() => {
                setWithPassword(!withPassword);
              }}
            >
              <small>
                {withPassword
                  ? translate('auth.login.useMagicLink')
                  : translate('auth.login.usePassword')}
              </small>
            </Button>
          </div>
          <div className="text-center">
            {withPassword ? (
              <SubmitButton
                className={classNames('btn-icon btn-3 mt-3 btn-block btn-round', {
                  rtl: isRTL,
                })}
                color="info"
                type="submit"
                loading={submitLoading}
              >
                {!submitLoading && (
                  <span className="btn-inner--icon">
                    <FiLogIn />
                  </span>
                )}
                <span className="mx-2 btn-inner--text">{translate('auth.signInBtn')}</span>
              </SubmitButton>
            ) : (
              <SubmitButton
                className={classNames('btn-icon btn-3 mt-3 btn-block btn-round', {
                  rtl: isRTL,
                })}
                color="info"
                type="submit"
                loading={submitLoading}
              >
                {!submitLoading && (
                  <span className="btn-inner--icon">
                    <FaMagic />
                  </span>
                )}
                <span className="mx-2 btn-inner--text">{translate('auth.login.magicLinkBtn')}</span>
              </SubmitButton>
            )}
            {!withPassword ? (
              <div className="px-3 pt-2 text-center text-muted" style={{ fontSize: '10px' }}>
                By clicking the button above, you hereby agree to and accept the following{' '}
                <a
                  href="https://www.cartedo.com/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms and conditions
                </a>
                .
              </div>
            ) : null}
          </div>
        </Form>
      </CardBody>
    </>
  );
};

export default LoginForm;
