import classNames from 'classnames';
import { CSSProperties, FC, ReactNode } from 'react';
import Confetti from 'react-confetti';
import Modal from 'react-modal';
import './Alert.scss';
import { errorIcon, successIcon, warningIcon } from './icons';

interface AlertModalProps {
  backgroundGradient?: string;
  icon?: string;
  children?: ReactNode;
  style?: CSSProperties;
  isOpen?: boolean | string;
  onRequestClose?: () => void;
  showCloseButton?: boolean;
  confetti?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  blur?: boolean;
  className?: string;
  id?: string;
}

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    top: '',
    left: '',
    right: '',
    bottom: '',
    border: '',
    borderRadius: '',
    padding: '2em',
  },
};

const AlertModal: FC<AlertModalProps> = ({
  backgroundGradient,
  icon,
  children,
  style,
  isOpen,
  onRequestClose,
  showCloseButton,
  confetti,
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  blur,
  className,
  id,
}) => {
  let styles = {};
  let iconElement;
  switch (icon) {
    case 'success':
      iconElement = successIcon;
      break;
    case 'error':
      iconElement = errorIcon;
      break;
    case 'warning':
      iconElement = warningIcon;
      break;
    default:
      iconElement = '';
      break;
  }
  switch (backgroundGradient) {
    case 'blue':
      styles = {
        ...customStyles,
        content: {
          ...customStyles.content,
          background: 'linear-gradient(87deg, #2caee4 0, #2c61e4 100%)',
        },
      };
      break;
    case 'red':
      styles = {
        ...customStyles,
        content: {
          ...customStyles.content,
          background: 'linear-gradient(87deg,#f5365c,#f56036)',
        },
      };
      break;
    case 'orange':
      styles = {
        ...customStyles,
        content: {
          ...customStyles.content,
          background: 'linear-gradient(87deg,#fb6340,#fbb140)',
        },
      };
      break;
    case 'green':
      styles = {
        ...customStyles,
        content: {
          ...customStyles.content,
          background: 'linear-gradient(87deg,#2dce89,#2dcecc)',
        },
      };
      break;
    case 'purple':
      styles = {
        ...customStyles,
        content: {
          ...customStyles.content,
          background: 'linear-gradient(87deg,#5e72e4,#825ee4)',
        },
      };
      break;
    default:
      styles = customStyles;
      break;
  }

  return (
    <>
      {confetti && Boolean(isOpen) && <Confetti />}
      <Modal
        id={id}
        isOpen={Boolean(isOpen)}
        onRequestClose={onRequestClose}
        style={{
          ...styles,
          ...style,
        }}
        contentLabel="Alert"
        closeTimeoutMS={100}
        ariaHideApp={false}
        shouldCloseOnEsc={shouldCloseOnEsc}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        className={classNames(['custom-alert', className])}
        overlayClassName="custom-alert-overlay"
        bodyOpenClassName={classNames({
          'custom-alert-blur-body-open': blur,
        })}
        role="dialog"
      >
        {showCloseButton && (
          <button
            type="button"
            className="custom-alert-close"
            aria-label="Close this dialog"
            onClick={onRequestClose}
          >
            ×
          </button>
        )}
        {iconElement && (
          <div className="custom-alert-header">
            <div className="custom-alert-icon">{iconElement}</div>
          </div>
        )}
        {children}
      </Modal>
    </>
  );
};

export default AlertModal;
