import logger from '@libs/log';
import { FC, useEffect, useRef, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { TbLock } from 'react-icons/tb';
import { Col, Modal, Row, UncontrolledTooltip } from 'reactstrap';
import { i18nCText } from '../../libs/i18n/I18n';
import { ImagePlaceholder } from '../UI/Html';

interface BadgeProps {
  img: string;
  video: string;
  name: string;
  description: string;
  earned: boolean;
  count: number;
}

interface MyBadgesProps {
  badge: BadgeProps;
}

const MyBadges: FC<MyBadgesProps> = ({ badge }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [explosion, setExplosion] = useState(false);
  return (
    <>
      <div
        className="badge-img"
        style={badge.earned ? { opacity: 1 } : { opacity: 0.5, filter: 'grayscale(100%)' }}
        aria-hidden="true"
        onClick={() => {
          setIsOpen(true);
          setTimeout(() => {
            setExplosion(true);
          }, 2000);
        }}
      >
        <ImagePlaceholder className="" width={150} height={150} src={badge.img} />
      </div>

      <Modal
        className="modal-md badge-modal"
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
          setExplosion(false);
        }}
      >
        {isOpen ? <BadgeModal badge={badge} /> : null}
        {explosion && badge.earned ? (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ConfettiExplosion zIndex={999} duration={3500} />
          </div>
        ) : null}
      </Modal>
    </>
  );
};

const BadgeModal: FC<MyBadgesProps> = ({ badge }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(() => {
        logger.error('Error playing video');
      });
    }
  }, []);
  return (
    <div className="pl-4 pr-4">
      <Row>
        <Col>
          <video
            className="w-100"
            ref={videoRef}
            src={badge.video}
            // type="video/mp4"
          ></video>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="pb-4">
            <h2 className="bold_text h2 font-weight-bold text-center">{badge.name}</h2>
            <h4 className="font-weight-bold h4 text-muted text-center">{badge.description}</h4>
          </div>
          {badge.earned ? (
            <div className="d-flex p-4 pl-5 pr-5 rounded custom-bg-color">
              <h3 className="pl-2">AWARDED</h3>
              <h4 className="ml-auto pr-2">{badge.count} TIMES</h4>
            </div>
          ) : (
            <>
              <div
                data-placement="top"
                id="myBadgesShowLockedTooltip_wqxll"
                className="d-flex rounded custom-bg-color py-3 justify-content-center "
              >
                <TbLock size={25} color="#999fbc" />
              </div>
              <UncontrolledTooltip
                delay={0}
                placement="top"
                target="myBadgesShowLockedTooltip_wqxll"
              >
                {i18nCText('Badges Locked')}
              </UncontrolledTooltip>
            </>
          )}
        </Col>
      </Row>
      <Row className="py-3"></Row>
    </div>
  );
};

export default MyBadges;
