import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import {
  AddMediaImage,
  IdeaCard,
  IdeaCardAnswer,
  ImageRadioStyle3Answer,
} from '@app/@types/redux/answer';
import { ImageRadioOptions } from '@app/@types/redux/pageElement';
import { PageElement, UserAnswerAnswer } from '@app/@types/redux/project';
import { useAppSelector } from '@state/redux/store';
import { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { FiEdit, FiPlus } from 'react-icons/fi';
import { CSSTransition } from 'react-transition-group';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { mode } from '../../../../../libs/helper';
import { i18nCText } from '../../../../../libs/i18n/I18n';
import { translate } from '../../../../../state/utils/helper';
import { ImagePlaceholder } from '../../../../UI/Html';
import UnsplashCredits from '../../../../UI/Html/UnsplashCredits';
import { AddMedia } from '../HelperComponents';
import './Style3.scss';

const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
  },
  textarea: {
    width: '100%',
    '& > div > textarea': {
      lineHeight: '1.5rem',
    },
  },
  editImageButton: {
    position: 'absolute',
    color: 'white',
    top: 15,
    right: 15,
  },
}));

interface ImageCardProps {
  item: IdeaCard;
  index: number;
  pageElementId: string;
  disabled: boolean;
  handleSelect: () => void;
  titleLabel: string;
  descriptionLabel: string;
  selected: boolean;
}

function ImageCard({
  item,
  index,
  pageElementId,
  disabled,
  handleSelect,
  titleLabel,
  descriptionLabel,
  selected,
}: ImageCardProps) {
  const id = pageElementId;

  const { title, description, id: imageId } = item;
  const imageUrl = item.image.regular;
  const imageAuthorName = item.image.authorName;
  const imageAuthorUrl = item.image.authorUrl;
  return (
    <Col xs={12} sm={4} md={4} lg={4} xl={4} key={index}>
      <div className="custom-control custom-radio my-5 m-auto">
        <Input
          className="custom-control-input"
          name={`radio_${id}`}
          id={`radio_${id}_${index.toString()}`}
          type="radio"
          autoComplete="off"
          checked={selected}
          disabled={disabled}
          onClick={handleSelect}
        />
        <label
          className="custom-control-label question-radio-select"
          htmlFor={`radio_${id}_${index.toString()}`}
        >
          <div
            className={classnames(
              'w-100',
              {
                'question-option-not-selected': !selected,
              },
              {
                'question-option-selected': selected,
              }
            )}
          >
            <ImagePlaceholder
              className="inspiration-card-img mb-3"
              alt="Card Image"
              src={imageUrl}
              authorName={imageAuthorName ?? ''}
              authorUrl={imageAuthorUrl ?? ''}
              id={imageId}
              top
            />
            <CardBody className="pt-1 text-align-initial">
              <ListGroup flush>
                <ListGroupItem className="checklist-entry flex-column align-items-start py-2 px-2">
                  <div className="checklist-item checklist-item">
                    <div className="checklist-info">
                      <h5 className="checklist-title mb-0">{titleLabel}</h5>
                      <small>{title}</small>
                    </div>
                  </div>
                </ListGroupItem>
                <ListGroupItem className="checklist-entry flex-column align-items-start py-2 px-2">
                  <div className="checklist-item checklist-item">
                    <div className="checklist-info">
                      <h5 className="checklist-title mb-0">{descriptionLabel}</h5>
                      <small
                        style={{
                          wordBreak: 'break-word',
                        }}
                      >
                        {description}
                      </small>
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </div>
        </label>
      </div>
    </Col>
  );
}
interface Style3Props {
  pageElementAnswer: {
    answer: UserAnswerAnswer;
    type: string;
  };
  answer: (payload: ImageRadioStyle3Answer) => void;
  pageElementId: string;
  currentAnswer: ImageRadioStyle3Answer | undefined;
  questions: string[];
  pageElement: PageElement;
  disabled: boolean;
}
function Style3({
  pageElementAnswer,
  answer,
  pageElementId,
  currentAnswer,
  questions,
  pageElement,
  disabled,
}: Style3Props) {
  const classes = useStyles();

  const initialAnswer = {
    scores: [],
    description: '',
    title: '',
    image: {
      regular: '',
      thumb: '',
      authorName: '',
      authorUrl: '',
    },
    isSatisfied: false,
    index: '',
    id: '',
  };

  const {
    titleLabel = translate('pageElements.ideaCard.title'),
    descriptionLabel = translate('pageElements.ideaCard.description'),
  } = pageElement.attributes.options as ImageRadioOptions;
  const [addMediaOpen, setAddMediaOpen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [buttonDirection, setButtonDirection] = useState('next');
  const [view, setView] = useState('select');
  const activeStageId = useAppSelector((state) => state.projects.activeStageId || '');
  const currentStageId = useAppSelector((state) => state.projects.currentStageId || '');
  useEffect(() => {
    if (parseInt(activeStageId, 10) < parseInt(currentStageId, 10)) {
      setView('selected');
    }
  }, [activeStageId, currentStageId]);
  const [newIdea, setNewIdea] = useState({
    title: '',
    description: '',
  });
  const maxCharacters = 80;
  const scores =
    currentAnswer?.scores && currentAnswer.scores.length > 0
      ? currentAnswer.scores
      : questions.map(() => '');

  const modeT = mode(Object.values(scores));
  const ideaCardAnswer = pageElementAnswer.answer as IdeaCardAnswer;
  const selectedIdea = Object.values(ideaCardAnswer).find((idea) => idea.id === modeT);
  let tempVal = selectedIdea;
  if (currentAnswer?.index === 'custom') {
    tempVal = currentAnswer.image.regular ? currentAnswer : undefined;
  }
  const imageUrlT = tempVal?.image.regular;
  const imageAuthorNameT = tempVal?.image.authorName;
  const imageAuthorUrlT = tempVal?.image.authorUrl;
  if (view === 'select') {
    return (
      <Row>
        <Col>
          <div className="questions-slider-container">
            {questions.map((question, questionIndex) => {
              return (
                <CSSTransition
                  key={questionIndex}
                  in={currentQuestionIndex === questionIndex}
                  timeout={{ enter: 500, exit: 500 }}
                  unmountOnExit
                  classNames={`questions-slide-${buttonDirection}`}
                >
                  <Row>
                    <Col>
                      <h1 className="text-center mb-5">{i18nCText(question)}</h1>
                      <Row className="justify-content-center">
                        {Object.keys(ideaCardAnswer)
                          // ?.sort(() => (questionIndex /
                          //   questions?.length) - 0.5)
                          .map((index) => {
                            const item = ideaCardAnswer[index];
                            return (
                              <ImageCard
                                key={index}
                                item={item}
                                index={Number(index)}
                                pageElementId={pageElementId}
                                selected={currentAnswer?.scores[questionIndex] === item.id}
                                handleSelect={() => {
                                  const tempScores = [...scores];
                                  const cAnswer = currentAnswer ?? initialAnswer;
                                  tempScores[questionIndex] = item.id;
                                  answer({
                                    ...cAnswer,
                                    scores: tempScores,
                                  });
                                  setTimeout(() => {
                                    if (currentQuestionIndex === questions.length - 1) {
                                      setButtonDirection('next');
                                      setCurrentQuestionIndex(questions.length);
                                      if (!disabled) {
                                        const tempAnswer = {
                                          ...cAnswer,
                                          ...selectedIdea,
                                          scores: tempScores,
                                        };
                                        answer(tempAnswer);
                                      }
                                      setTimeout(() => {
                                        setView('selected');
                                      }, 300);
                                    } else {
                                      setButtonDirection('next');
                                      if (currentQuestionIndex === questions.length - 1) {
                                        setCurrentQuestionIndex(currentQuestionIndex);
                                      } else {
                                        setCurrentQuestionIndex(currentQuestionIndex + 1);
                                      }
                                    }
                                  }, 300);
                                }}
                                disabled={disabled}
                                titleLabel={titleLabel}
                                descriptionLabel={descriptionLabel}
                              />
                            );
                          })}
                      </Row>
                    </Col>
                  </Row>
                </CSSTransition>
              );
            })}
          </div>
        </Col>
      </Row>
    );
  }
  if (view === 'selected' && tempVal) {
    const { title, description } = tempVal;

    return (
      <>
        {parseInt(activeStageId, 10) >= parseInt(currentStageId, 10) ? (
          <Row>
            <Col>
              <Button
                size="sm"
                onClick={() => {
                  answer(initialAnswer);
                  setButtonDirection('previous');
                  setView('select');
                  setCurrentQuestionIndex(0);
                }}
              >
                <FaArrowLeft className="mx-2" />
                {translate('pageElements.imageRadioButton.style3.selectAgain')}
              </Button>
            </Col>
          </Row>
        ) : null}
        <Row className="p-5">
          <Col>
            <Row className="justify-content-center">
              <Col md={4}>
                <Card className={classnames('w-100', 'question-option-selected')}>
                  <ImagePlaceholder
                    className="inspiration-card-img mb-3"
                    alt="Card Image"
                    src={imageUrlT}
                    authorName={imageAuthorNameT ?? ''}
                    authorUrl={imageAuthorUrlT ?? ''}
                    top
                  />
                  <CardBody className="pt-1 text-align-initial">
                    <ListGroup flush>
                      <ListGroupItem className="checklist-entry flex-column align-items-start py-2 px-2">
                        <div className="checklist-item checklist-item">
                          <div className="checklist-info">
                            <h5 className="checklist-title mb-0">{titleLabel}</h5>
                            <small>{title}</small>
                          </div>
                        </div>
                      </ListGroupItem>
                      <ListGroupItem className="checklist-entry flex-column align-items-start py-2 px-2">
                        <div className="checklist-item checklist-item">
                          <div className="checklist-info">
                            <h5 className="checklist-title mb-0">{descriptionLabel}</h5>
                            <small
                              style={{
                                wordBreak: 'break-word',
                              }}
                            >
                              {description}
                            </small>
                          </div>
                        </div>
                      </ListGroupItem>
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="text-center mt-4 mb-4">
              {translate('pageElements.imageRadioButton.style3.happyWithSelection')}
            </h2>
            <div className="justify-content-center row feedback-faces d-flex">
              <Button
                color="primary"
                outline
                type="button"
                className="mx-2"
                onClick={() => {
                  setView('new-idea');
                }}
              >
                {translate('pageElements.imageRadioButton.style3.yes')}
              </Button>
              <Button
                color="default"
                outline={!currentAnswer?.isSatisfied}
                type="button"
                className="mx-2"
                onClick={() => {
                  answer({
                    ...(currentAnswer ?? initialAnswer),
                    isSatisfied: true,
                  });
                }}
              >
                {translate('pageElements.imageRadioButton.style3.no')}
              </Button>
            </div>
          </Col>
        </Row>
      </>
    );
  }
  if (view === 'new-idea') {
    <>
      <Row>
        <Col>
          <Button
            size="sm"
            onClick={() => {
              answer(initialAnswer);
              setButtonDirection('previous');
              setView('select');
              setCurrentQuestionIndex(0);
            }}
          >
            <FaArrowLeft className="mr-2" />
            {i18nCText('Go Back')}
          </Button>
        </Col>
      </Row>
      <Row className="my-5">
        <Col>
          <h2 className="text-align-initial">
            {i18nCText(
              "Change your idea or come up with a completely new one if you're not happy with the previous selection.  Add a brand new image, title and description for your new idea. Select wisely, you will be working on this through the remainder of the challenge."
            )}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="justify-content-center">
            <Col xs={12} sm={12} md={6} lg={6} xl={4} className="d-flex">
              <Card className="w-100">
                <AddMedia
                  open={addMediaOpen}
                  onRequestClose={() => {
                    setAddMediaOpen(false);
                  }}
                  onSelect={(image) => {
                    const mImg = image as AddMediaImage;
                    answer({
                      ...(currentAnswer ?? initialAnswer),
                      index: 'custom',
                      image: mImg,
                    });
                  }}
                />
                {imageUrlT ? (
                  <div className="ideaCards__imageCard_imageContainer">
                    <img
                      src={imageUrlT}
                      className={classnames('ideaCards__imageCard_image')}
                      alt={imageAuthorNameT ?? 'Idea Card Image'}
                    />
                    {!disabled && (
                      <>
                        <div className="ideaCards__imageCard_imageOverlay" />
                        <Button
                          color="primary"
                          size="sm"
                          className={classnames(
                            'ideaCards__imageCard_imageOptions',
                            classes.editImageButton
                          )}
                          onClick={() => {
                            setAddMediaOpen(true);
                          }}
                        >
                          <FiEdit style={{ fontSize: '1.5em' }} />
                        </Button>
                      </>
                    )}
                    <UnsplashCredits authorName={imageAuthorNameT} authorUrl={imageAuthorUrlT} />
                  </div>
                ) : (
                  <div
                    className="ideaCards__imageCard_addImage"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      setAddMediaOpen(true);
                    }}
                  >
                    <FiPlus />
                  </div>
                )}
                <CardBody className="text-align-initial">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="idea-card-title">
                      {titleLabel}
                    </label>
                    <InputGroup className="input-group-merge">
                      <Input
                        id="idea-card-title"
                        value={newIdea.title}
                        placeholder={translate('pageElements.ideaCard.titlePlaceholder')}
                        disabled={disabled}
                        onChange={(tTitle: string) => {
                          setNewIdea({
                            ...newIdea,
                            title: tTitle,
                          });
                        }}
                        onBlur={(tTitle: string) => {
                          setNewIdea({
                            ...newIdea,
                            title: tTitle.trim(),
                          });
                          answer({
                            ...(currentAnswer ?? initialAnswer),
                            index: 'custom',
                            ...newIdea,
                          });
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="idea-card-idea">
                      {descriptionLabel}
                    </label>
                    <InputGroup className="input-group-merge">
                      <Input
                        id="idea-card-idea"
                        type="textarea"
                        placeholder={translate('pageElements.ideaCard.descriptionPlaceholder', {
                          maxCharacters,
                        })}
                        value={newIdea.description}
                        rows={4}
                        maxLength={maxCharacters}
                        disabled={disabled}
                        onChange={(tDescription: string) => {
                          setNewIdea({
                            ...newIdea,
                            description: tDescription,
                          });
                        }}
                        onBlur={(tDescription: string) => {
                          setNewIdea({
                            ...newIdea,
                            description: tDescription.trim(),
                          });
                          answer({
                            ...(currentAnswer ?? initialAnswer),
                            index: 'custom',
                            ...newIdea,
                          });
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>;
  }
}

export default Style3;
