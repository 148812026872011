export const checkAnyPermission = (givenRoles: string[], allowedRoles: string[]) => {
  return allowedRoles.some((role: string) => givenRoles.includes(role));
};

export const checkAllPermission = (givenRoles: string[], allowedRoles: string[]) => {
  return allowedRoles.every((role: string) => givenRoles.includes(role));
};

export const checkPermissionForRoute = (givenRoles: string[], allowedRoles: string[]) => {
  return givenRoles.some((role: string) => allowedRoles.includes(role));
};
