import { SubmissionCardAnswer } from '@app/@types/redux/answer';
import { SubmissionCardOptions } from '@app/@types/redux/pageElement';
import { PageElement, UserAnswerAnswer } from '@app/@types/redux/project';
import { store } from '@state/redux/store';
import { getDependeeValues } from '@state/selectors/projects';
import { useEffect } from 'react';
import { Col, Row } from '../../../UI/Html';
import SubmissionType from './HelperComponents/SubmissionType';
import ReviewSubmissionCard from './ReviewSubmissionCard';

interface FinalCardProps {
  answer: (payload: UserAnswerAnswer) => void;
  currentAnswer: SubmissionCardAnswer;
  pageElement: PageElement;
}

const FinalCard: React.FC<FinalCardProps> = (props) => {
  const { answer, pageElement } = props;

  const { dependees } = pageElement.attributes;

  const { allowAttachment } = pageElement.attributes.options as SubmissionCardOptions;

  useEffect(() => {
    const submissionCard = getDependeeValues(dependees, [], store.getState().projects);
    if (submissionCard) {
      answer(submissionCard as SubmissionCardAnswer);
    }
  }, [dependees]);

  const currentAnswerSafe = props.currentAnswer;
  const questions = Object.keys(currentAnswerSafe.questions).map(
    (key) => currentAnswerSafe.questions[key].question
  );

  return (
    <>
      <Row>
        <Col className="px-4 py-2 pb-4">
          <SubmissionType
            type={currentAnswerSafe.type}
            images={currentAnswerSafe.images}
            url={currentAnswerSafe.link}
            currentAnswer={currentAnswerSafe}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
          <ReviewSubmissionCard
            data={currentAnswerSafe}
            questions={questions}
            type={currentAnswerSafe.type}
            allowAttachment={allowAttachment}
          />
        </Col>
      </Row>
    </>
  );
};
export default FinalCard;
