import { Component } from 'react';
import Layout from '../../../components/UI/Layout';
import RecommendationsIndex from '../../../components/Users/Recommendations/RecommendationsIndex';

class RecommendationsContainer extends Component {
  override render() {
    return (
      <Layout {...this.props}>
        <RecommendationsIndex />
      </Layout>
    );
  }
}

export default RecommendationsContainer;
