import { FC, ReactNode, useEffect, useRef } from 'react';

interface NoStyleDivProps {
  children?: ReactNode;
  className?: string;
}

const NoStyleDiv: FC<NoStyleDivProps> = ({ children = null }) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const handleIframeLoad = () => {
      const currentIframe = iframeRef.current;
      if (currentIframe?.contentDocument) {
        const iframeDocument = currentIframe.contentDocument;
        let contentHeight = `${String(Math.min(iframeDocument.documentElement.scrollHeight))}px`;
        if (contentHeight === '150px') {
          contentHeight = `${String(Math.min(iframeDocument.body.clientHeight + 30))}px`;
        }
        currentIframe.style.height = '0px';
        currentIframe.style.minHeight = contentHeight;
      }
    };

    if (iframeRef.current) {
      const currentIframe = iframeRef.current;
      currentIframe.addEventListener('load', handleIframeLoad);

      return () => {
        currentIframe.removeEventListener('load', handleIframeLoad);
      };
    }
  }, [children]);

  const embeddedContent = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
    @font-face {
      font-family: Inter var, Helvetica, Arial, sans-serif;
      src: url('https://rsms.me/inter/inter.css');
      /* Add additional font formats as needed */
    }

    body {
      font-family: Inter var, Helvetica, Arial, sans-serif;
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: optimizeLegibility;
      margin: 0px;
      padding: 0px;
    }
  </style>
  </head>
  <body>
    <div style="width: 100%; height: 100%;">
      ${String(children)}
    </div>
  </body>
</html>
`;

  if (!children) {
    return null;
  }
  return (
    <iframe
      ref={iframeRef}
      title="Embedded Content"
      srcDoc={embeddedContent}
      frameBorder="0"
      width="100%"
      scrolling="no"
    />
  );
};

export default NoStyleDiv;
