import { UncontrolledTooltip } from '@components/UI/Html';
import EllipsisText from 'react-ellipsis-text/lib/components/EllipsisText';
import { BiCheck, BiCheckDouble, BiLock } from 'react-icons/bi';
import './ChatMenu.scss';
import type { IChat } from './tempConstants';

interface ChatMenuProps {
  chat: IChat;
  selected: boolean;
  handleOnClick: () => void;
}
const getStatusIcon = (status: string, id: string) => {
  switch (status) {
    case 'sent':
      return <BiCheck size={20} color="#a4a4a4" />;
    case 'delivered':
      return <BiCheckDouble size={20} color="#a4a4a4" />;
    case 'read':
      return <BiCheckDouble size={20} color="#0ea5e9" />;
    case 'locked':
      return (
        <>
          <BiLock size={18} color="#a4a4a4" id={`locked-chat-${id}`} />
          <UncontrolledTooltip delay={0} placement="top" target={`locked-chat-${id}`}>
            This chat is locked
          </UncontrolledTooltip>
        </>
      );
    default:
      return null;
  }
};
const ChatMenu = ({ chat, selected, handleOnClick }: ChatMenuProps) => {
  return (
    <div
      className={`email__menu__container ${selected ? 'email__menu__container__selected' : ''}`}
      onClick={() => {
        if (chat.messageStatus === 'locked') {
          return;
        }
        handleOnClick();
      }}
    >
      <div className="chat__menu__avatar">
        <img alt="..." className="avatar rounded-circle" src={chat.profilePicture} />
        {chat.online && <div className="chat__menu__avatar__online"></div>}
      </div>
      <div style={{ width: '100%' }}>
        <div className="email__menu__content">
          <div className="d-flex" style={{ gap: '4px', alignItems: 'center' }}>
            <h1 className="mb-0 text-base">{chat.name}</h1>
          </div>
          <div className="d-flex align-items-center text-right text-muted" style={{ gap: '8px' }}>
            <small>{chat.lastMessageTime}</small>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-1">
          <span className="email__menu__message">
            <EllipsisText text={chat.lastMessage} length={45} />
          </span>
          <div className="d-flex align-items-center" style={{ gap: '8px' }}>
            <div>{getStatusIcon(chat.messageStatus, chat.id)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChatMenu;
