import { getDependeeValues, getPageElementDisabledState } from '@state/selectors/projects';
import { View } from 'react-native-web';
import { Input, Row } from 'reactstrap';
import { errorToast } from '../../../../libs/helper';

import { PostitCheckboxAnswer } from '@app/@types/redux/answer';
import { PostitCheckboxOptions } from '@app/@types/redux/pageElement';
import { useAppSelector } from '@state/redux/store';
import React from 'react';
import { PageElementProps } from '..';
import { translate } from '../../../../state/utils/helper';
import { Card, CardBody, CardTitle, Col } from '../../../UI/Html';

type PostitCheckboxItem = PostitCheckboxAnswer[string];

const PostItCheckBoxes: React.FC<PageElementProps> = (props) => {
  const { pageElement, state } = props;

  const { id } = pageElement;

  const disabled = useAppSelector((state) => getPageElementDisabledState(state.projects, id));

  const currentAnswer = props.currentAnswer as PostitCheckboxAnswer | undefined;
  const { dependees } = pageElement.attributes;
  const { min, max } = pageElement.attributes.options as PostitCheckboxOptions;

  const generatePostIts = (idea: string, index: number, item: PostitCheckboxItem) => {
    const { answer } = props;

    const answers = currentAnswer;

    return (
      <Col xs={12} sm={12} md={6} lg={6} xl={4} key={index} className="card-container">
        <div className="custom-control custom-checkbox mb-3 m-auto postItCheckbox">
          <Input
            className="custom-control-input"
            id={`checkbox_${id}_${String(index)}`}
            type="checkbox"
            value={index}
            checked={!!answers?.[index]}
            disabled={disabled}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { nextValidate } = props;
              let copy = { ...answers };
              if (e.target.checked) {
                if (Object.keys(answers ? answers : {}).length < max) {
                  copy[index] = {
                    index,
                    value: idea,
                    color: item.color || 'yellow',
                  };
                } else {
                  errorToast(translate('pageElements.errorMessages.error11', { max }));
                }
              } else {
                copy = Object.keys(copy).reduce(
                  (result: Record<string, PostitCheckboxItem>, key) => {
                    if (String(key) !== String(index)) {
                      result[key] = copy[key];
                    }
                    return result;
                  },
                  {}
                );
              }
              answer(copy);
              if (Object.keys(copy).length < min || Object.keys(copy).length > max) {
                nextValidate && nextValidate(true);
              } else {
                nextValidate && nextValidate(false);
              }
            }}
          />
          <label
            className="custom-control-label postItCheckbox-label"
            htmlFor={`checkbox_${id}_${String(index)}`}
          >
            <Card className={`postItCard postIt__normal_container_${item.color || 'yellow'}`}>
              <CardBody className="postItCardBody">
                <View
                  style={{
                    backgroundColor: 'rgba(127, 127, 127, 0.1)',
                    width: '100% ',
                    height: '50px',
                  }}
                >
                  <View
                    style={{
                      paddingTop: '11px',
                      paddingLeft: '11px',
                      paddingBottom: '11px',
                      paddingRight: '11px',
                    }}
                  />
                </View>
                <View
                  style={{
                    margin: '30px',
                    overflowY: 'hidden',
                    minHeight: '150px',
                  }}
                >
                  <CardTitle className="h2 mb-0 postItContent">{idea}</CardTitle>
                </View>
              </CardBody>
            </Card>
          </label>
        </div>
      </Col>
    );
  };

  const options = getDependeeValues(dependees, [], state) as PostitCheckboxItem[];
  return (
    <Col>
      <Row className="mt-4 cards-container">
        {options.map((item, index) => generatePostIts(item.value, index, item))}
      </Row>
    </Col>
  );
};

export default React.memo(PostItCheckBoxes);
