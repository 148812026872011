import classnames from 'classnames';

function Title({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <h2 className={classnames(['text-uppercase font-weight-600 mt-1 mb-4', className])}>
      {children}
    </h2>
  );
}

export default Title;
