import { DefaultError } from '@libs/constants/errors';
import { errorToast } from '@libs/helper';
import { logout, useAppDispatch, useAppSelector } from '@state/redux/store';
import classnames from 'classnames';
import { useEffect } from 'react';
import { FaBell } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { CARTEDO_LINKS } from '../../../libs/constants/constants';
import i18n from '../../../libs/i18n/I18n';
import { fetchMe } from '../../../state/redux/slices/auth.slice';
import { RootState } from '../../../state/redux/store';
import { getFullName } from '../../../state/selectors/users';
import { translate } from '../../../state/utils/helper';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ImagePlaceholder,
  Media,
  UncontrolledDropdown,
} from '../Html';
import './UserOptions.scss';

interface IUserOptionsProps {
  mobile?: boolean;
  desktop?: boolean;
}
function UserOptions({ mobile, desktop }: IUserOptionsProps) {
  const dispatch = useAppDispatch();
  const fullName = useAppSelector((state: RootState) => getFullName(state));
  const userId = useAppSelector((state: RootState) => state.users.user.id);
  const avatarUrl: string = useAppSelector((state) => state.users.user.attributes.avatarUrl);
  const signOut = () => {
    logout();
  };

  useEffect(() => {
    if (!userId) {
      return;
    }
    dispatch(fetchMe()).catch(() => {
      errorToast(DefaultError);
    });
  }, [dispatch, userId]);

  const isRTL = i18n.dir() === 'rtl';
  const dropdownMenu = (
    <DropdownMenu
      className={classnames('mt-2 user-options-dropdown userOptions__dropdown', {
        'text-right': isRTL,
      })}
      right
    >
      <DropdownItem header>Extras</DropdownItem>
      <DropdownItem tag={Link} to="/notifications">
        <FaBell />
        <span>{translate('layout.navbar.notifications')}</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem header>Account</DropdownItem>
      <DropdownItem tag={Link} to="/me" className="d-md-none">
        <i className="ni ni-single-02" />
        <span>{translate('layout.navbar.myProfile')}</span>
      </DropdownItem>
      <DropdownItem tag={Link} to="/settings/account">
        <i className="ni ni-align-left-2" />
        <span>{translate('layout.navbar.navlinks.editMyProfile')}</span>
      </DropdownItem>
      <DropdownItem tag={Link} to="/settings/preferences">
        <i className="ni ni-settings-gear-65" />
        <span>{translate('layout.navbar.settings')}</span>
      </DropdownItem>
      <DropdownItem
        tag={Link}
        to="#"
        onClick={() => {
          signOut();
        }}
        className="text-danger"
      >
        <i className="ni ni-user-run" />
        <span>{translate('layout.navbar.logout')}</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem header>Legal</DropdownItem>
      <DropdownItem href={CARTEDO_LINKS.PRIVACY_POLICY} target="_blank">
        <i className="fas fa-file-alt" />
        <span>{translate('layout.navbar.privacy')}</span>
      </DropdownItem>
      <DropdownItem href={CARTEDO_LINKS.TERMS_AND_CONDITIONS} target="_blank">
        <i className="fas fa-file-alt" />
        <span>{translate('layout.navbar.terms')}</span>
      </DropdownItem>
    </DropdownMenu>
  );
  if (mobile) {
    return (
      <UncontrolledDropdown nav inNavbar className="d-md-none my-auto">
        <DropdownToggle nav>
          <Media className="align-items-center text-sm font-weight-bold">
            <ImagePlaceholder
              className="avatar avatar-sm rounded-circle mr-2"
              src={avatarUrl}
              native
            />
          </Media>
        </DropdownToggle>
        {dropdownMenu}
      </UncontrolledDropdown>
    );
  }
  if (desktop) {
    return (
      <UncontrolledDropdown nav inNavbar className="d-none d-md-block my-auto">
        <DropdownToggle nav>
          <Media className="align-items-center text-sm font-weight-bold">
            <ImagePlaceholder className="avatar avatar-sm rounded-circle" src={avatarUrl} native />
            <span className="mx-2">{fullName}</span>
          </Media>
        </DropdownToggle>
        {dropdownMenu}
      </UncontrolledDropdown>
    );
  }
  return <></>;
}

export default UserOptions;
