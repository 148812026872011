import { nanoid } from '@reduxjs/toolkit';
import { CSSProperties, FC } from 'react';
import { View as RNWView } from 'react-native-web';
import { CardBody, CardTitle, Col } from 'reactstrap';
import { i18nCText } from '../../../libs/i18n/I18n';
import Card from './Card';

interface PostitProps {
  color?: string;
  removeFunction?: () => void;
  text: string;
  md?: string | number;
  style?: CSSProperties;
}

const Postit: FC<PostitProps> = ({
  color = 'yellow',
  removeFunction = () => undefined,
  text,
  md = 6,
  style = {},
}) => {
  return (
    <Col md={md} key={nanoid()} className="card-container" style={style}>
      <Card className={`postItCard postit-${color}`}>
        <CardBody className="postItCardBody">
          <RNWView
            style={{
              backgroundColor: 'rgba(127, 127, 127, 0.1)',
              width: '100% ',
              height: '50px',
            }}
          >
            <RNWView
              style={{
                cursor: 'pointer',
                position: 'relative',
                float: 'right',
                textAlign: 'right',
                paddingTop: '13px',
                paddingRight: '20px',
                fontSize: '22px',
              }}
              onClick={() => {
                removeFunction();
              }}
            >
              <i className="fas fa-trash-alt postItTRemove postItIcon" />
            </RNWView>
          </RNWView>
          <RNWView
            style={{
              margin: '30px',
              overflowY: 'hidden',
              minHeight: '150px',
            }}
          >
            <CardTitle className="h2 mb-0 postItContent">{i18nCText(text)}</CardTitle>
          </RNWView>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Postit;
