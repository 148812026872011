/* eslint-disable max-len */

export interface IMbtiData {
  id: string;
  title: string;
  short: string;
  long: string;
  keywords: string[];
  keepDoing: string[];
  couldDoBetter: string[];
  careerPaths: {
    description: string;
    positive: string[];
    negative: string[];
  };
  educationPaths: {
    positive: string[];
    negative: string[];
  };
  footNote: string;
}

const mbtiData: IMbtiData[] = [
  {
    id: 'INTP',
    title: 'Logicians',
    short: 'Innovative brains with an unrelenting desire for knowledge and objectivity.',
    long: 'Logicians are fairly rare and good for them, because their uniqueness is what sets them apart. They are known for their inventiveness and creativity. Logicians take pride in knowing that their perspectives are vigorously intellectual. They’re always looking to develop novel and viable solutions. They can spend hours questioning and brainstorming as their mind encompasses unceasing thought processes and ideas.',
    keywords: ['INNOVATIVE', 'OBJECTIVE', 'LOGICAL', 'ANALYTICAL'],
    keepDoing: [
      'Analysing things and abstract thinking',
      'Encouraging people to be open-minded and creativity',
      'Being enthusiastic and passionate for finding novel solutions',
    ],
    couldDoBetter: [
      'Recognize your emotions, what you feel and why',
      'Being warm and open to other people',
      'Understanding others’ perspectives',
    ],
    careerPaths: {
      description:
        'Logicians bring in qualities such as creativity, a passion for theoretical methods and ideas, and an entrepreneurial, innovative spirit. They may find it difficult to understand emotional exchanges, and often service-oriented positions may prove exhausting for them.',
      positive: [
        'Business Analyst',
        'Data Scientists',
        'Physicists',
        'Mechanical, Electrical, Software Engineers',
        'Technical writers',
        'Journalists',
      ],
      negative: [
        'Sales Manager',
        'Recreation Worker',
        'Preschool Teacher',
        'Licensed Nurse',
        'Retail Salesperson',
      ],
    },
    educationPaths: {
      positive: [
        'Using their imagination to come up with novel ideas',
        'Having a strategic plan for all situations',
        'Being confident',
        'Being independent, hard working and determined',
      ],
      negative: ['Medicine', 'Nursing', 'Education and Teaching'],
    },
    footNote:
      'For Logicians, gaining an insight into one’s emotions and personality will further help them be creative and aid a “human” perspective to their innovations.',
  },
  // INFP
  {
    id: 'INFP',
    title: 'Healers',
    short: 'Kind and altruistic hearts always eager to contribute to the better of the world.',
    long: 'These personalities are pure idealists. They tend to look on the bright side of life and constantly search ways to make things better. Though they may come across as shy, Healers have an inner flame and passion that can truly shine. They are guided by principle rather than logic. They enjoy indulging and fantasy.',
    keywords: ['IDEALIST', 'PASSIONATE', 'FLEXIBLE'],
    keepDoing: [
      'Seeking peace and harmony',
      'Being open-minded and flexible',
      'Being passionate and determined about work',
    ],
    couldDoBetter: [
      'Being less idealistic and impractical',
      'Working with facts such as numbers and data',
      'Being less reserved and more confident',
    ],
    careerPaths: {
      description:
        'Freedom loving Healers, with a love for language are often drawn toward the creative fields. They may not be able to work in highly stressful and high pressure jobs.',
      positive: [
        'Photographer',
        'Fashion Designer',
        'Writer',
        'Psychologist',
        'Librarian',
        'Social Worker',
        'Human Resource Specialist',
      ],
      negative: ['Chemical Engineer', 'Chemist', 'Financial and Sales Manager', 'Systems Analyst'],
    },
    educationPaths: {
      positive: ['Social Sciences', 'Creative Arts', 'Design', 'Psychology'],
      negative: ['Finance', 'Engineering', 'Management'],
    },
    footNote:
      'INFPs often indulge in deep thought, enjoying contemplating the hypothetical and the philosophical more than any other personality type. However, this may lead them to withdraw from the real world. Hence, keeping in touch with practicalities of day-to-day life is important for them.',
  },
  // ISTJ
  {
    id: 'ISTJ',
    title: 'Logisticians',
    short: 'Fact minded individuals high on practicality whose reliability cannot be doubted.',
    long: 'ISTJs are responsible organizers and prefer neat and orderly procedures for everything they do. Reliable and dutiful, ISTJs want to uphold tradition and follow regulations.They contribute greatly to maintaining social order. People with the Logistician personality type enjoy taking responsibility for several things and take pride in committing towards a goal. They are accurate and to the point. They prefer autonomy and self-sufficiency.',
    keywords: ['PRACTICAL', 'LOGICAL', 'DEDICATED', 'RELIABLE'],
    keepDoing: [
      'Being honest and straightforward',
      'Being responsible and strong-willed',
      'Making sure there is social order',
      'Keeping your calm in times of stress',
    ],
    couldDoBetter: [
      'Being more flexible',
      "Being aware of other's emotions",
      "Understanding other's perspectives",
      "Respecting other's opinions",
    ],
    careerPaths: {
      description:
        'Logisticians are often focused on creating long-term and stable careers. Their careers revolve around institutions of respected tradition, authority, security, and established consistency.',
      positive: [
        'Lawyer',
        'Financial Manager',
        'Statistician',
        'Top Executive',
        'Aerospace, Biomedical, Chemical, Civil Engineering',
        'Food Scientist',
        'Athlete',
        'Dentist',
        'Software and Web Developer',
        'Military',
      ],
      negative: [
        'Actor and Musician',
        'Photographer and Journalist',
        'Graphic Designer',
        'Receptionist',
        'Retail Salesperson',
      ],
    },
    educationPaths: {
      positive: [
        'Science and Technology',
        'Engineering',
        'Mathematics and Statistics',
        'Business and Law',
      ],
      negative: [
        'Medicine',
        'Nursing',
        'Education and Teaching',
        'Performing Arts',
        'Hotel Management',
      ],
    },
    footNote:
      'Logisticians may need to be more in touch with their emotions and build stronger and warm relationships with those around them. Being less hard on oneself will work wonderfully.',
  },
  // ESTJ
  {
    id: 'ESTJ',
    title: 'Executive',
    short: 'Excellent leader unsurpassed at managing things or even people.',
    long: 'ESTJs are hardworking individuals who enjoy taking charge in bringing people together. They get things done in a systematic and organized manner. Values of honesty, dedication and dignity, are extremely important for them. They are conventional and believe in facts that are grounded in reality. They are confident, tough-minded individuals who appreciate structure.',
    keywords: ['HONEST', 'DEDICATED', 'SOCIABLE', 'EAGER'],
    keepDoing: [
      'Being loyal, patient and reliable',
      'Being responsible and strong-willed',
      'Making sure there is social order',
      'Organizing several events',
    ],
    couldDoBetter: [
      'Being more flexible',
      'Being more comfortable with unconventional situations',
      'Relaxing and taking breaks',
      "Respecting other's opinions",
    ],
    careerPaths: {
      description:
        'ESTJs career paths are often clear and straightforward. Their hard work and motivation often results in them staying with a single employer as long as possible. Their loyalty and commitment often leads to work with well-respected organizations.',
      positive: [
        'Hotel Manager',
        'Military and Police Officer',
        'School Principal',
        'Dentist',
        'Civil Engineering',
        'Physician',
      ],
      negative: ['Actor and Musician', 'Social Worker', 'Artist', 'Graphic Designer', 'Librarian'],
    },
    educationPaths: {
      positive: ['Medicine', 'Engineering', 'Management', 'Business and Law'],
      negative: [
        'Social Sciences',
        'Design',
        'Creative Arts',
        'Performing Arts',
        'Hotel Management',
      ],
    },
    footNote:
      'A true victory and a great challenge for an Executive would be to recognize the potential of each individual in the team and contributing to their growth.',
  },
  // ISTP
  {
    id: 'ISTP',
    title: 'Virtuoso',
    short: 'Bold and skilful experimenter with a mastery in all kinds of tools.',
    long: 'ISTPs are observant individuals with a desire to explore. They enjoy building different things and learning from the environment. They often have an in-depth understanding of mechanics and an interest in troubleshooting. Their approach is extremely flexible and practical. ISTPs are attentive to details and responsive to the demands of the environment. They enjoy being independent and are often self-directed.',
    keywords: ['RATIONAL', 'CURIOUS', 'SKILLED'],
    keepDoing: [
      'Being optimistic and energetic',
      'Creating practical solutions',
      'Finding solutions in times if crisis',
      'Staying relaxed and prioritizing flexibly',
    ],
    couldDoBetter: [
      'Being more sensitive to other people',
      'Managing boredom',
      'Being more open with people',
      "Respecting other's opinions",
    ],
    careerPaths: {
      description:
        'ISTPs prefer diverse and unpredictable career paths. They enjoy problem solving and finding unique solutions. They prefer jobs wherein their freedom is not restricted.',
      positive: [
        'Mechanic',
        'Systems Analyst',
        'Surveyor',
        'Software Developer',
        'Architect',
        'Photographer',
      ],
      negative: ['Actor and Musician', 'Journalist', 'Social Worker', 'Physician'],
    },
    educationPaths: {
      positive: ['Science and Technology', 'Engineering', 'Management', 'Data Sciences'],
      negative: ['Social Sciences', 'Design', 'Creative Arts', 'Performing Arts', 'Medicine'],
    },
    footNote:
      'Although dealing with emotions is a challenge for ISTPs. Making Finding a few good friends who understand their style and creativity would open tons of doors to happiness.',
  },
  // ESTP
  {
    id: 'ESTP',
    title: 'Entrepreneur',
    short: 'Energetic, spirited and enjoys living like there’s no tomorrow.',
    long: 'ESTPs are dynamic and bring in a lot of energy to their daily interactions. They are great at finding practical solutions and are able to examine quickly. They are active and playful with a good sense of humour. They are often the life of the party and truly enjoy entertaining people. They are spontaneous and often jump into situations without any planning.',
    keywords: ['ENERGETIC', 'THRILL SEEKING', 'SMART', 'DYNAMIC'],
    keepDoing: [
      'Being bold and discovering new ideas',
      'Being original',
      'Seeking lots of friends',
      'Communicating clearly',
    ],
    couldDoBetter: [
      'Being more patient',
      'Being careful with risks',
      'Finding structure and order',
    ],
    careerPaths: {
      description:
        'Entrepreneurs are extremely high on social intelligence and have great energy at work. They don’t shy away from taking risks and are considered to be a huge asset to the working world.',
      positive: [
        'Sales Manager',
        'Fitness Instructor',
        'Surveyor',
        'Television Reporter',
        'Chef',
        'Photographer',
      ],
      negative: ['Writer', 'Chemist', 'Animator', 'Nurse'],
    },
    educationPaths: {
      positive: ['Science and Technology', 'Engineering', 'Management', 'Management', 'Management'],
      negative: ['Social Sciences', 'Design', 'Creative Arts', 'Performing Arts', 'Medicine'],
    },
    footNote:
      'Entrepreneurs might need to be careful of getting too caught in the moment and taking risks, for their own health and safety. Their contagious energy must be redirected to achieve constructive results.',
  },
  // ISFP
  {
    id: 'ISFP',
    title: 'Adventurer',
    short: 'Artists always exploring something new with flexibility and charm.',
    long: 'Adventurer personalities are true artists and enjoy thinking out of the box. Often enough though, they are perfectly capable of this. They use design and aesthetic to defy social convention. They are considered to be unpredictable. ISFPs have a strong aesthetic sense and seek out beauty in their surroundings.',
    keywords: ['FLEXIBLE', 'CREATIVE', 'EXPERIMENTAL'],
    keepDoing: [
      'Being imaginative and creative',
      'Relating well to others’ emotions',
      'Thinking out of the box',
      'Being curious and eager to learn',
    ],
    couldDoBetter: [
      'Dealing with rules and norms',
      'Managing stressful emotions',
      'Being confident of oneself',
    ],
    careerPaths: {
      description:
        'Adventurers prefer jobs that are high on experimentation and compel them to think creatively. They truly enjoy their artistic freedom.',
      positive: ['Fashion Designer', 'Artist', 'Surveyor', 'Nurse', 'Dietician', 'Social Worker'],
      negative: ['Executive', 'Sales Manager', 'Dentist', 'Surgeon'],
    },
    educationPaths: {
      positive: [
        'Science and Technology',
        'Engineering',
        'Social Sciences',
        'Creative Arts',
        'Design',
      ],
      negative: ['Management', 'Business', 'Statistics', 'Medicine'],
    },
    footNote:
      'The biggest challenge facing Adventurers is planning for the future. They might need to find a balance between creating a meaningful sense of identity and creating assets for the future.',
  },
  // ESFP
  {
    id: 'ESFP',
    title: 'Entertainer',
    short: 'Enthusiastic and energetic, never feels life is boring.',
    long: 'ESFPs are vivacious entertainers who charm and engage those around them. They are spontaneous, energetic, and fun-loving, and take pleasure in the things around them. ESFPs are typically warm and talkative and have a contagious enthusiasm for life. They like to be in the middle of the action and the center of attention. They have a playful, open sense of humor, and like to draw out other people and help them have a good time.',
    keywords: ['AESTHETIC', 'CURIOUS', 'PERFORMER'],
    keepDoing: [
      'Being imaginative and bold',
      'Relating well to others’ emotions',
      'Being practical and observant',
      'Having excellent people skills',
    ],
    couldDoBetter: [
      'Being mess sensitive to criticism',
      'Being focused and planning well',
      'Avoiding and not managing conflicts',
    ],
    careerPaths: {
      description:
        'Entertainers prefer an active social environment and enjoy being spontaneous at work. They are pragmatic, realistic, and enjoy being of service to other people.',
      positive: [
        'Elementary Teacher',
        'Nurse',
        'Child Care Provider',
        'Social Worker',
        'Dietician',
        'Interior Designer',
        'Artist',
        'Musician',
        'Actor',
      ],
      negative: ['Systems Analyst', 'Engineer', 'College Professor', 'Psychologist'],
    },
    educationPaths: {
      positive: ['Performing Arts', 'Social Sciences', 'Creative Arts', 'Design'],
      negative: ['Management', 'Business', 'Statistics', 'Medicine', 'Engineering'],
    },
    footNote:
      'Being great at spontaneity, Entertainers might be encouraged to focus a little more on planning and organising certain aspects of their life to enhance their zest for life.',
  },
  // INTJ
  {
    id: 'INTJ',
    title: 'Architects',
    short: 'Imaginative thinkers and dreamers with a plan for everything.',
    long: 'Architects truly enjoy spreading the joy of learning and knowledge. They possess confidence and pride in their knowledge. They tend to steer clear of distractions and are often focused. They always look for new tactics, strategies, and contingency plans and have an independent style of thinking with a preference for rationality.',
    keywords: ['IMAGINATIVE', 'FOCUSED', 'CONFIDENT'],
    keepDoing: [
      'Using their imagination to come up with novel ideas',
      'Having a strategic plan for all situations',
      'Being confident',
      'Being independent, hard working and determined',
    ],
    couldDoBetter: [
      'Being mindful of others’ feelings',
      'Being less critical and analytical',
      'Accepting things as they are',
    ],
    careerPaths: {
      description:
        'Architects often preferring more “lone wolf” jobs. These people value personal resourcefulness, hard work, insight, and commitment in themselves and in others. They believe that everyone should complete their work and meet the highest possible standards.',
      positive: [
        'Computer Programming',
        'Technical Writing',
        'Market Research Analysis',
        'Architecture',
      ],
      negative: [
        'Social Worker',
        'Preschool Teacher',
        'Dental Hygienist',
        'Receptionist',
        'Production Worker',
      ],
    },
    educationPaths: {
      positive: [
        'Medicine',
        'Mathematics',
        'Computers and Information Technology',
        'Arts, Design and Communication',
      ],
      negative: ['Social Work', 'Counselling', 'Literature'],
    },
    footNote:
      'Though INTJs may find it difficult to connect with other people easily, a greater human connection with those around help them enhance their knowledge.',
  },
  // ENTJ
  {
    id: 'ENTJ',
    title: 'Leaders',
    short: 'Bold leaders with a strong-will and the courage to always make things work.',
    long: 'Natural-born leaders who embody confidence, and project authority. They are often successful at getting people together to work toward a common goal. However, they are also characterized by an often ruthless level of rationality, using their drive, determination and sharp minds to achieve whatever end they’ve set for themselves. ENTJs are strategic leaders, motivated to organize change, as they are quick to see inefficiency and conceptualize new solutions.',
    keywords: ['CONFIDENT', 'STRONG-WILLED', 'INFLUENTIAL'],
    keepDoing: [
      'Being extremely efficient at work',
      'Being high on self-confidence and extremely strong-willed',
      'Having an energy of inspiration and change',
      'Planning effectively',
    ],
    couldDoBetter: [
      'Being more sensitive toward other people',
      'Recognizing one’s emotions',
      'Being less dominant',
    ],
    careerPaths: {
      description:
        'Combining their vision, intelligence and determination to push ideas through to completion no matter the obstacles, Leaders are a force to be reckoned with.',
      positive: [
        'Financial Analyst',
        'Logistician',
        'Market Research Analysis',
        'Statistician',
        'Mechanical, Aerospace, Electrical Engineer',
        'Anthropologist',
        'Historian',
        'Psychologist',
        'Sociologist',
        'Lawyer',
      ],
      negative: ['Cosmetologist', 'Preschool Teacher', 'Receptionist', 'Craft Artist'],
    },
    educationPaths: {
      positive: ['Psychology', 'Business', 'Law', 'Engineering'],
      negative: ['Dental Sciences', 'Arts and Crafts', 'Hotel Management'],
    },
    footNote:
      'ENTJs are powerhouses, as they are larger than life. However, they need to remember though, that their strengths come not just from their own actions, but from the contributions and talents of their social network, as well.',
  },
  // ENTP
  {
    id: 'ENTP',
    title: 'Visionaries',
    short: 'Thinkers driven by curiosity and a strong preference for intellectual challenges.',
    long: 'Visionaries enjoy questioning the prevailing mode of thought and finding new directions. They are often energized by challenge and are drawn toward making the impossible “possible”. Their confidence most often guides them to show spontaneity. They will often jump into a new situation and trust themselves to adapt as they go.',
    keywords: ['CURIOUS', 'SPONTANEOUS', 'CHARISMATIC'],
    keepDoing: [
      'Being eager to learn something new',
      'Showing originality in coming up with ideas and solutions',
      'Being charismatic',
      'Showing flexibility in thought',
    ],
    couldDoBetter: [
      'Being less argumentative',
      'Accepting practical day-to-day matters',
      'Being more focused on one path',
    ],
    careerPaths: {
      description:
        'These personalities are focused on developing solutions to interesting and diverse technical and intellectual problems. Routine, structure and formal rules all feel like unnecessary hindrances to Visionaries, and they may find that their best careers yet allow them to engage their intellectual pursuits on their own terms.',
      positive: [
        'Financial Analyst',
        'Software and Web Developer',
        'Actor and Musician',
        'Photographer',
        'Technical Writer',
        'Anthropologist',
        'Historian',
        'Psychologist',
        'Political Scientist',
        'Lawyer',
      ],
      negative: ['Pilot', 'Preschool Teacher', 'Dietician', 'Dentist'],
    },
    educationPaths: {
      positive: ['Social Sciences', 'Business', 'Law', 'Performing Arts'],
      negative: ['Dental Sciences', 'Engineering', 'Hotel Management'],
    },
    footNote:
      'Although questioning is essential to the people with this personality type. They may find that the most complex and rewarding intellectual challenge is to understand a more sentimental perspective, and to argue consideration and compromise alongside logic and progress.',
  },
  // INFJ
  {
    id: 'INFJ',
    title: 'Advocate',
    short: 'Creative nurturers and inspiring idealists.',
    long: "Creative and dedicated, they have a desire for helping others with their personal challenges. They have ability to understand others' emotions and motivations.They tend to be great at reading people.However, they are also reserved and are selective about sharing intimate thoughts and feelings.They have a knack for warm, sensitive language, speaking in human terms, rather than with pure logic and fact, helping them make connections easily.",
    keywords: ['ALTRUISTIC', 'WARM', 'CREATIVE'],
    keepDoing: [
      'Being creative and finding insightful solutions to human problems',
      'Being able to convince and inspire people',
      'Being altruistic',
      'Being determined and passionate',
    ],
    couldDoBetter: [
      'Relax once in a while',
      'Opening up easily',
      'Being less sensitive to criticism',
    ],
    careerPaths: {
      description:
        'Advocate personalities need to find meaning in their work and to know that they are helping and connecting with people. Thus, careers in healthcare can be rewarding',
      positive: [
        'Nutritionist',
        'Psychiatrist',
        'Clinical Psychologist',
        'Counsellor',
        'Teacher',
        'Editor',
        'Writer',
      ],
      negative: ['Surveyor', 'Real Estate Agent', 'Financial Manager', 'Sales Manager'],
    },
    educationPaths: {
      positive: ['Social Sciences', 'Psychology', 'Medicine', 'Creative Arts'],
      negative: ['Finance', 'Engineering', 'Management'],
    },
    footNote:
      'Advocates may forget themselves in the process of changing the world. Hence, it is important for them to take care of themselves to avoid burning out.',
  },
  // ENFJ
  {
    id: 'ENFJ',
    title: 'Protagonist',
    short: 'Inspiring leaders with an ability to mesmerize and motivate.',
    long: 'Protagonists act as catalysts of change guiding others to work together to improve themselves and their community. People are drawn to their optimistic and intuitive personalities. They radiate authenticity, concern and altruism, unafraid to stand up and speak when they feel something needs to be said.',
    keywords: ['OPTIMISTIC', 'ALTRUISTIC', 'CATALYSTS OF CHANGE'],
    keepDoing: [
      'Being extremely reliable',
      'Being warm and selfless to the community',
      'Are visionary leaders',
      'Being tolerant and patient with other people',
    ],
    couldDoBetter: [
      'Tendency to be overly idealistic',
      'Too sensitive about criticism',
      'Have a fluctuating sense of self-confidence',
    ],
    careerPaths: {
      description:
        'Altruistic careers like social work, teaching, counselling are best suited for Protagonists',
      positive: [
        'Health Educator',
        'Family Therapist',
        'Social Worker',
        'Urban Planner',
        'Sociologist',
        'Actor',
        'Interior Designer',
      ],
      negative: [
        'Electrician',
        'Mechanic',
        'Electrical and Mechanical Engineer',
        'Systems Analyst',
      ],
    },
    educationPaths: {
      positive: ['Social Sciences', 'Psychology', 'Interior Design', 'Creative Arts'],
      negative: ['Finance', 'Engineering', 'Management'],
    },
    footNote:
      'Protagonists may get too caught up in solving others’ problems that they fail to distinguish themselves from others. Self-reflection can help them take a step back when things begin to get too overwhelming.',
  },
  // ENFP
  {
    id: 'ENFP',
    title: 'Campaigner',
    short: 'Enthusiastic and sociable spirits with an optimistic mind-set.',
    long: 'ENFPs are people-centered creators with a focus on possibilities and a contagious enthusiasm for new ideas, people and activities. They are energetic, warm and passionate. They are often the life of the party. They are charming, independent and enjoy making social connections with other people. They enjoy their freedom of thought and are able to deal with the complexities of life.',
    keywords: ['ENTHUSIASTIC', 'CHARMING', 'INDEPENDENT'],
    keepDoing: [
      'Being excellent communicators',
      'Being popular and friendly',
      'Easily calming down and relaxing',
      'Being enthusiastic and energetic',
    ],
    couldDoBetter: [
      'Focusing more on important things',
      'Managing emotions to reduce stress',
      'Being more practical',
    ],
    careerPaths: {
      description:
        'Campaigners have a solid foothold in any human science or service. They thrive on the ability to question the status quo and may not be the best at strict regiment systems',
      positive: [
        'Photographer',
        'Reporter',
        'Market Research Analyst',
        'Travel Agent',
        'Librarian',
        'Activist',
        'Sociologist',
      ],
      negative: ['Pathologist', 'Dentist', 'Software and Civil Engineer', 'Systems Analyst'],
    },
    educationPaths: {
      positive: ['Social Sciences', 'Performing Arts', 'Research Sciences'],
      negative: ['Finance', 'Engineering', 'Dental Sciences'],
    },
    footNote:
      'ENFPs spend a lot of time exploring social relationships, feelings and ideas on the basis of their intuitions. However, they may need to be careful to not get mislead.',
  },
  // ESFJ
  {
    id: 'ESFJ',
    title: 'Counsels',
    short: 'Exceptionally caring and social people, always ready to lend a helping hand.',
    long: 'ESFJs are conscientious helpers. They are sensitive to the needs of others and give energy to their responsibilities. They are highly aware to their emotional environment and recognize others’ emotions. They like a sense of harmony and cooperation around them. Counsels value loyalty and tradition, and usually make their family and friends their top priority.',
    keywords: ['HARD WORKING', 'DEPENDABLE', 'EMOTIONAL'],
    keepDoing: [
      'Managing day-to-day routine tasks',
      'Having a strong sense of duty',
      'Being loyal, sensitive and warm',
      'Making good connections with others',
    ],
    couldDoBetter: [
      'Being more flexible and open-minded',
      'Stepping out of your comfort zone',
      'Being more accepting of criticism',
    ],
    careerPaths: {
      description:
        'Consuls enjoy to organizing the environment. Their practical skills combine well with their dependability. They need human interaction and emotional feedback to be truly satisfied in their line of work.',
      positive: [
        'Dietician',
        'Family Physician',
        'Hotel Manager',
        'Social Worker',
        'Nutritionist',
        'HR Manager',
      ],
      negative: [
        'Software Developer',
        'Computer Programming',
        'Social Scientist',
        'Economist',
        'Electrical Engineer',
        'Editor',
        'Actor',
      ],
    },
    educationPaths: {
      positive: ['Medicine', 'Human Resource Management', 'Hotel Management'],
      negative: ['Finance', 'Engineering', 'Social Sciences'],
    },
    footNote:
      'For Consuls the biggest challenge is to understand that people are going to disagree and they’re going to criticize, and while it hurts, it’s just a part of life. The best thing might to shift their focus and continue being a role model.',
  },
  // ISFJ
  {
    id: 'ISFJ',
    title: 'Protectors',
    short: 'Dedicated and warm, motivated to the protect their loved ones',
    long: 'ISFJs are industrious caretakers. They are loyal to tradition and are compassionate and caring. They are steady and committed workers with a deep sense of responsibility to others. They are conscientious and methodical, and persist until the job is done. Being excellent analysts, they are meticulous beyond perfection.',
    keywords: ['WARM', 'LOYAL', 'ANALYTICAL'],
    keepDoing: [
      'Being reliable and patient',
      'Being supportive of your loved ones',
      'Being loyal, hardworking and enthusiastic',
      'Maintaining harmony among others',
    ],
    couldDoBetter: ['Recognizing your contributions', 'Accepting change', 'Being less sensitive'],
    careerPaths: {
      description:
        'They respect tradition and security, and have no problem with the idea of moving along in a structured hierarchy. Protectors are well-aware of others’ emotions. Having a strong sense of practicality, they manage things well.',
      positive: [
        'Dentist',
        'Pharmacist',
        'Surgeon',
        'Social Worker',
        'Librarian',
        'Teacher Assistant',
        'Child Care Worker',
        'Biomedical Engineer',
      ],
      negative: [
        'Executive',
        'Insurance Agent',
        'Journalist',
        'Photographer',
        'Economist',
        'Social Sciences',
        'Actor',
      ],
    },
    educationPaths: {
      positive: ['Medicine', 'Engineering', 'Mathematics'],
      negative: ['Journalism', 'Performing Arts', 'Social Sciences'],
    },
    footNote:
      'ISFJs may never be truly comfortable in the spotlight, and may feel guilty taking due credit for team efforts, but if they can ensure that their efforts are recognized, Defenders are likely to feel a level of satisfaction in what they do that many other personality types can only dream of.',
  },
];

export default function getMBTIData(code: string) {
  return mbtiData.find((item) => item.id === code);
}
