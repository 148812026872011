import { Button, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useEffect } from 'react';

import { InputStyle4Answer } from '@app/@types/redux/answer';
import { InputOptions } from '@app/@types/redux/pageElement';
import i18n, { i18nCText } from '@libs/i18n/I18n';
import { nanoid } from '@reduxjs/toolkit';
import { getPageElementDisabledState } from '@state/selectors/projects';
import _ from 'lodash';
import { Controller } from 'react-hook-form';
import { FiPlus, FiX } from 'react-icons/fi';
import { PageElementProps } from '..';
import { TEXTAREA_MAX_LENGTH } from '../../../../libs/constants/constants';
import { translate } from '../../../../state/utils/helper';
import './Input.scss';
import { useAppSelector } from '@state/redux/store';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
  grid: {
    marginBottom: theme.spacing(2),
    textAlign: 'initial',
  },
  formControl: {
    width: '100%',
  },
  closeButton: {
    minWidth: 'auto',
    fontSize: '1rem',
    color: 'darkgray',
    zIndex: 999,
  },
}));

const Input: React.FC<PageElementProps> = (props) => {
  const {
    pageElement,
    pageElement: { id: pageElementId },
    answer,
    form: { control } = {},
    state,
  } = props;
  const allowCopyPaste = useAppSelector((state) => state.projects.allowCopyPaste);

  const options = pageElement.attributes.options as InputOptions;
  const { placeholder, type, maxLength, min = 3 } = options;
  const currentAnswer = props.currentAnswer as InputStyle4Answer | undefined;

  const disabled = getPageElementDisabledState(state, pageElementId);
  const classes = useStyles();
  const elementId = `input-${pageElementId}`;
  const maxLengthAllowed = maxLength || TEXTAREA_MAX_LENGTH;
  useEffect(() => {
    if (
      type === 'style_4' &&
      (!currentAnswer?.values.length || currentAnswer.values.length < min)
    ) {
      const tempAnswers: InputStyle4Answer['values'] = [];
      _.range(min).map(() => {
        tempAnswers.push({
          value: '',
          id: nanoid(),
        });
        return true;
      });
      answer({ values: tempAnswers });
    }
  }, [answer, currentAnswer?.values, min, props, type]);
  let content = <></>;

  switch (type) {
    case 'style_4': {
      const textValues = currentAnswer?.values;
      content = (
        <>
          <Grid item xs={12} sm={10} className={classes.grid}>
            <Grid item xs={12} className={classes.grid}>
              {textValues?.map((cAnswer, index) => {
                const textFieldElementId = `${elementId}-${cAnswer.id || String(index)}`;
                return (
                  <div className="my-4 d-flex" key={textFieldElementId}>
                    <Controller
                      name={textFieldElementId}
                      control={control}
                      rules={{
                        required: translate('pageElements.errorMessages.required'),
                        maxLength: {
                          value: maxLengthAllowed,
                          message: translate('pageElements.errorMessages.maxLength', {
                            maxLengthAllowed,
                          }),
                        },
                      }}
                      defaultValue={cAnswer.value}
                      render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                        <TextField
                          id={textFieldElementId}
                          helperText={error ? error.message : null}
                          disabled={disabled}
                          error={!!error}
                          onChange={onChange}
                          value={value}
                          fullWidth
                          label={i18nCText(placeholder).toUpperCase()}
                          dir={i18n.dir()}
                          className={classes.input}
                          onBlur={(e) => {
                            onBlur();
                            const tempAnswers = [...(currentAnswer?.values ?? [])];
                            tempAnswers[index] = {
                              ...tempAnswers[index],
                              value: e.target.value.trim(),
                            };
                            answer({ values: tempAnswers });
                          }}
                          onPaste={(e) => {
                            if (!allowCopyPaste) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                    />
                    {/*
                     * We are keeping this to check whether onblur works or not
                    <TextField
                      id={textFieldElementId}
                      name={textFieldElementId}
                      disabled={disabled}
                      dir={i18n.dir()}
                      inputRef={register({
                        required: translate(
                          'pageElements.errorMessages.required'
                        ),
                        maxLength: {
                          value: maxLengthAllowed,
                          message: translate(
                            'pageElements.errorMessages.maxLength',
                            {
                              maxLengthAllowed,
                            }
                          ),
                        },
                      })}
                      defaultValue={cAnswer.value}
                      onBlur={() => {
                        const tempAnswers = currentAnswer.values || [];
                        tempAnswers[index] = {
                          ...tempAnswers[index],
                          value: watch(textFieldElementId).trim(),
                        };
                        answer({ values: tempAnswers });
                      }}
                      className={classes.input}
                      label={i18nCText(placeholder).toUpperCase()}
                      helperText={errors?.[textFieldElementId]?.message}
                      error={Boolean(errors?.[textFieldElementId])}
                    /> */}
                    {textValues.length > min && !disabled && (
                      <Button
                        disabled={disabled}
                        className={classnames(classes.closeButton)}
                        onClick={() => {
                          const tempAnswers = [...(currentAnswer?.values ?? [])].filter(
                            (item) => item.id !== cAnswer.id
                          );
                          answer({ values: tempAnswers });
                        }}
                      >
                        <FiX />
                      </Button>
                    )}
                  </div>
                );
              })}
            </Grid>
          </Grid>
          {!disabled && (
            <Grid item xs={12} className={classes.grid}>
              <Button
                variant="outlined"
                color="primary"
                disabled={disabled}
                startIcon={<FiPlus />}
                onClick={() => {
                  const tempAnswers = [...(currentAnswer?.values ?? [])];
                  tempAnswers.push({
                    value: '',
                    id: nanoid(),
                  });
                  answer({ values: tempAnswers });
                }}
              >
                {translate('pageElements.input.addMore')}
              </Button>
            </Grid>
          )}
        </>
      );
      break;
    }
    default:
      content = <></>;
  }
  return content;
};

export default Input;
