import { ReactNode } from 'react';
import Zoom from 'react-medium-image-zoom';

interface CZoomProps {
  children?: ReactNode;
  zoomAllowed?: boolean;
}

const CZoom = ({ children, zoomAllowed = false }: CZoomProps) => {
  if (zoomAllowed) {
    return <Zoom>{children}</Zoom>;
  }
  return <>{children}</>;
};

export default CZoom;
