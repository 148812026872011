import React from 'react';
import { Col, Container, Row } from 'reactstrap';

interface HeaderProps {
  title: string;
  lead: string;
}

const Header: React.FC<HeaderProps> = ({ title, lead }) => {
  return (
    <div className="header py-5 py-lg-6 pt-lg-5">
      <Container>
        <div className="header-body text-center mb-7">
          <Row className="justify-content-center">
            <Col className="px-5" lg="6" md="8" xl="5">
              {title ? <h1 className="text-white">{title}</h1> : null}
              {lead ? <p className="text-lead text-white">{lead}</p> : null}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Header;
