import { UserProject } from '@app/@types/redux/project';
import { FC } from 'react';
import i18n, { i18nCText } from '../../libs/i18n/I18n';
import { A, Button, Col, Div, H4, ListGroupItem, Progress, Row } from '../UI/Html';

interface UserProjectItemProps {
  userProject: UserProject;
}

const UserProjectItem: FC<UserProjectItemProps> = ({ userProject }) => {
  const {
    state,
    progress,
    project: { id: projectId, name, image },
    workspace: { id: workspaceId },
  } = userProject.attributes;

  return (
    <ListGroupItem className="px-0">
      <Row className="align-items-center">
        <Col className="col-auto">
          <A href={`/workspace/${workspaceId}/challenge/${projectId}/preview`}>
            <img
              className="user-project-item-image rounded"
              width={200}
              height={200}
              alt={name}
              src={image}
            />
          </A>
        </Col>
        <Div className="col ml--2">
          <H4 className="mb-2">
            <A href={`/workspace/${workspaceId}/challenge/${projectId}/preview`}>
              {i18nCText(name)}
            </A>
          </H4>
          <Progress max={100} value={progress * 100} color="success" />
          <Row className="mt-2">
            <Col className="">
              <span className="text-success mr-1">●</span>
              <small>{i18n.t(`userProjects.state.${state}`).toString()}</small>
            </Col>
            <Col className="col-auto">
              {state === 'Finished' ? (
                <Button
                  href={`/workspace/${workspaceId}/challenge/${projectId}/preview`}
                  color="success"
                  size="sm"
                >
                  View
                </Button>
              ) : (
                <Button
                  href={`/workspace/${workspaceId}/challenge/${projectId}/preview`}
                  color="primary"
                  size="sm"
                >
                  {i18n.t('userProjects.state.resume').toString()}
                </Button>
              )}
            </Col>
          </Row>
        </Div>
      </Row>
    </ListGroupItem>
  );
};

export default UserProjectItem;
