import { isLoggedIn } from '@utils/helpers/auth';
import React, { ReactNode } from 'react';
import { View } from 'react-native-web';
import { connect } from 'react-redux';

import i18n from '../../../libs/i18n/I18n';
import NavBar from '../NavigationBar/NavBar';
import LayoutBody from './LayoutBody';
import NotificationModal from './UserNotificationModal';

const mapStateToProps = () => ({
  isSignedIn: isLoggedIn(),
});

interface LayoutProps {
  children: ReactNode;
  isSignedIn?: boolean;
  isFullWidth?: boolean;
  bg?: boolean;
}

const Layout: React.FC<LayoutProps> = (props) => {
  const { isFullWidth, children, bg = false } = props;
  return (
    <>
      {bg ? <div className="bg-default" /> : null}
      <View>
        <NotificationModal />
        <NavBar language={i18n.language} />
        <LayoutBody isFullWidth={isFullWidth}>{children}</LayoutBody>
      </View>
    </>
  );
};

export default connect(mapStateToProps)(Layout);
