// import wallpaperLogin from './wallpaper_login.png'
// // import avatar from './avatar.png'
// import projectSkill from './project_skill.svg'
// import projectCover from './profile-cover.jpg'
// import angular from './angular.jpg'
// import bootstrap from './bootstrap.jpg'
// import react from './react.jpg'
// import sketch from './sketch.jpg'
// import profileTeam from './team-4.jpg'
// import vue from './vue.jpg'
// import iconItem from './logro-ej.png'

// import nike from './nike.jpg'
// import peru from './countries/peru.png'
// import youtube from './projects/pages/youtube.jpeg'
// import mockups from './projects/pages/mockups.jpeg'
// import vision from './projects/pages/vision.jpeg'
// import figma from './figma.svg'
// import balsamiq from './balsamiq.png'
// import biteable from './biteable.png'
// import canva from './canva.png'
// import gslide from './gslide.jpg'
// import marvel from './marvelapp.png'
// import ads from './ads.png'
// import appMockup from './appMockup.png'
// import appMockupCard from './appMockupCard.png'
// import business from './business.png'
// import businessCard from './businessCard.png'
// import elevatorPitch from './elevatorPitch.png'
// import githubCard from './githubCard.png'
// import handDrawn from './handDrawn.png'
// import slide from './slide.png'
// import storyBoard from './storyBoard.png'
// import storyBoardCard from './storyBoardCard.png'
// import visionSlide from './visionSlide.png'
// import visionSlideCard from './visionSlideCard.png'
// // import comingSoonMan from './coming_soon.png'
// import pdf from './pdf.svg'
// import ppt from './ppt.svg'
// import slides from './slides.svg'
// // import vimeo from './vimeo.svg'
// // import bitbucket from './bitbucket.png'
// import websiteMockup from './websiteMockup.jpg'
// import advertisementCard from './advertisementCard.png'
// import threeD from './3D.png'
// import blog from './blog.png'
// import blogCard from './blogCard.jpg'
// import businessCase from './businessCase.png'
// import comic from './comic.png'
// import comicCard from './comicCard.png'
// import howItWorks from './howItWorks.png'
// import social from './social.png'
// import socialCard from './socialCard.png'
// // import blogger from './blogger.svg'
// // import linkedin from './linkedin.svg'
// import medium from './medium.svg'
// import smartsheet from './smartsheet.png'
// import storyboard from './storyboardThat.png'
// import word from './word.png'
// import attachment from './attachment.svg'
// import designThinking from './designThinking.png'
// import entrepreneur from './entrepreneur.png'
// import innovation from './innovation.png'
// import technology from './technology.png'
// import businessCaseCard from './businessCaseCard.png'
// // import facebookIcon from './facebookIcon.png'
// // import googleIcon from './googleIcon.svg'
// import elevatorPitchCard from './elevatorPitchCard.png'

// import b5Large from './assessments/b5/b5_large.jpg'
// import b5 from './assessments/b5/b5.png'
// import lovelngLarge from './assessments/lovelng/lovelng_large.jpg'
// import lovelng from './assessments/lovelng/lovelng.png'
// import lovelngHelp from './assessments/lovelng/help.png'
// import lovelngHigh5 from './assessments/lovelng/high5.png'
// import lovelngShow from './assessments/lovelng/show.png'
// import lovelngTell from './assessments/lovelng/tell.png'
// import lovelngTime from './assessments/lovelng/time.png'

// Error images
import error404 from './error_404.svg';
import error500 from './error_500.svg';

// Modal images
import happyModal from './modals/happy.png';
import embarrassedModal from './modals/embarrassed.svg';
import suspiciousModal from './modals/suspicious.svg';
import warningModal from './modals/warning.png';

// Random images
import random from './random';

// Brand logos
import github from './brand_logos/github.svg';
import google from './brand_logos/google.svg';

// Page images
import interviewingPageImage from './pages/interviewing.png';

// Placeholder images
import underConstructionImage from './placeholders/construction.svg';
import comingSoonImage from './placeholders/coming_soon.png';
import studentProfileBanner from './placeholders/student-profile-banner.png';
import workerProfileBanner from './placeholders/worker-profile-banner.jpg';
import evaluationResults from './placeholders/evaluation_results.svg';
import defaultAvatar from './placeholders/default_avatar.png';
import marvelApp from './placeholders/marvelapp.png';

// Icons
import pdfIcon from './pages/pdf-icon.png';

// Profile cover
import profileCover from './img-1-1000x600.jpg';

// Default grey image
import grey from './grey.png';

// MBTI images and badges
import mbtiAssessment from './assessments/mbti';

// SDG Badges and Icons
import { sdgBadges, sdgIcons } from './sdg';

// Recommendation images
import recommendation1 from './recommendations/1.jpg';
import recommendation2 from './recommendations/2.jpg';
import recommendation3 from './recommendations/3.jpg';
import recommendation4 from './recommendations/4.jpg';
import recommendation5 from './recommendations/5.jpg';
import recommendation6 from './recommendations/6.jpg';
import recommendation7 from './recommendations/7.jpg';

// Cartedo Logos
import cartedoLogo from './logo/cartedo';

// Feedback instruction page images
import acceptIcon from './instructions/accept.png';
import ignoreIcon from './instructions/ignore.png';
import reportIcon from './instructions/report.png';
import feedbackInstructionExample from './instructions/feedback-instruction-example.png';

const recommendations = [
  recommendation1,
  recommendation1,
  recommendation2,
  recommendation3,
  recommendation4,
  recommendation5,
  recommendation6,
  recommendation7,
];

const error = {
  404: error404,
  500: error500,
};

export {
  grey,
  random,
  cartedoLogo,
  acceptIcon,
  ignoreIcon,
  reportIcon,
  feedbackInstructionExample,
  mbtiAssessment,
  profileCover,
  pdfIcon,
  interviewingPageImage,
  underConstructionImage,
  github,
  google,
  recommendations,
  comingSoonImage,
  error,
  happyModal,
  warningModal,
  suspiciousModal,
  embarrassedModal,
  studentProfileBanner,
  workerProfileBanner,
  sdgBadges,
  sdgIcons,
  evaluationResults,
  defaultAvatar,
  marvelApp,
};
