import { TextareaStyle1Answer } from '@app/@types/redux/answer';
import { TextareaOptions } from '@app/@types/redux/pageElement';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppSelector } from '@state/redux/store';
import { getPageElementDisabledState } from '@state/selectors/projects';
import { Controller } from 'react-hook-form';
import { PageElementProps } from '..';
import { TEXTAREA_MAX_LENGTH } from '../../../../libs/constants/constants';
import { translate } from '../../../../state/utils/helper';
import { Row } from '../../../UI/Html';

const useStyles = makeStyles(() => ({
  textarea: {
    width: '100%',
    '& > div': {
      borderRadius: '1rem',
      background: 'white',
    },
    '& > p': {
      marginLeft: '1rem',
      marginTop: '0.5rem',
    },
    '& > div > textarea': {
      lineHeight: '1.5rem',
    },
  },
}));

const TextArea: React.FC<PageElementProps> = (props) => {
  const classes = useStyles();
  const {
    pageElement,
    pageElement: { id: pageElementId },
    answer,
    form: { control } = {},
  } = props;
  const allowCopyPaste = useAppSelector((state) => state.projects.allowCopyPaste);
  const currentAnswer = props.currentAnswer as TextareaStyle1Answer | undefined;
  const {
    placeholder,
    type,
    maxLength,
    rows = 8,
  } = pageElement.attributes.options as TextareaOptions;

  const disabled = useAppSelector((state) =>
    getPageElementDisabledState(state.projects, pageElementId)
  );

  const elementId = `textarea${pageElementId}`;
  const textValue = currentAnswer?.value ?? '';

  let content = <></>;
  const maxLengthAllowed = maxLength || TEXTAREA_MAX_LENGTH;
  switch (type) {
    case 'style_1': {
      content = (
        <Controller
          name={elementId}
          control={control}
          rules={{
            required: translate('pageElements.errorMessages.required'),
            maxLength: {
              value: maxLengthAllowed,
              message: translate('pageElements.errorMessages.maxLength', {
                maxLengthAllowed,
              }),
            },
          }}
          defaultValue={textValue}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
            <TextField
              id={elementId}
              multiline
              minRows={rows}
              helperText={error ? error.message : null}
              disabled={disabled}
              error={!!error}
              onChange={onChange}
              value={value}
              margin="normal"
              placeholder={placeholder}
              fullWidth
              variant="outlined"
              className={classes.textarea}
              onBlur={(e) => {
                onBlur();
                answer({
                  value: e.target.value,
                });
              }}
              onPaste={(e: React.ClipboardEvent) => {
                if (!allowCopyPaste) {
                  e.preventDefault();
                }
              }}
            />
          )}
        />
      );
      break;
    }
    default:
      content = <Row />;
  }
  return content;
};

export default TextArea;
