// import { nanoid } from '@reduxjs/toolkit';
// import React, { useState } from 'react';
// import Skeleton from 'react-loading-skeleton';
// import image2 from '../../../assets/images/redemptions/1.png';
// import image1 from '../../../assets/images/redemptions/11.png';
// import image3 from '../../../assets/images/redemptions/4_1.png';
// import image4 from '../../../assets/images/redemptions/5_1.png';
// import image5 from '../../../assets/images/redemptions/6_1.png';
// import image6 from '../../../assets/images/redemptions/7.png';
// import image7 from '../../../assets/images/redemptions/8.png';
// import image8 from '../../../assets/images/redemptions/9.png';
// import I18n from '../../../libs/i18n/I18n';
// import ProjectsFilters from '../../Projects/Filters';
// import {
//   Button,
//   Card,
//   CardBody,
//   CardTitle,
//   Col,
//   Container,
//   H2,
//   ImagePlaceholder,
//   Modal,
//   ModalBody,
//   ModalFooter,
//   ModalHeader,
//   Row,
// } from '../../UI/Html';
// import Footer from '../../UI/Layout/Footer';

// import descImage1 from '../../../assets/images/redemptions/12.png';
// import descImage2 from '../../../assets/images/redemptions/2_1.png';
// import descImage3 from '../../../assets/images/redemptions/how-to-earn.jpg';
// import descImage4 from '../../../assets/images/redemptions/how-to-redeem.jpg';

// interface Resource {
//   id: number;
//   name: string;
//   company: string;
//   image: string;
//   tedoPoints: number;
//   description: string;
// }

// const loadingSkeleton = () => {
//   return (
//     <>
//       {Array.from(Array(3), () => (
//         <Col xs={12} sm={6} lg={4} className="mb-5" key={nanoid()}>
//           <Skeleton height={300} />
//           <div style={{ height: 20 }} />
//           <Skeleton count={3} height={20} />
//           <div style={{ height: 20 }} />
//           <Skeleton count={1} height={20} />
//         </Col>
//       ))}
//     </>
//   );
// };

// const RecommendationsIndex: React.FC = () => {
//   const [resourceModal, setResourceModal] = useState<boolean>(false);
//   const [loading] = useState<boolean>(false);
//   const [resource, setResource] = useState<Resource | null>(null);
//   const [recommendations] = useState<Resource[]>([
//     {
//       id: 1,
//       name: 'How to redeem Tedo Points',
//       company: 'Cartedo',
//       image: image1,
//       tedoPoints: 0,
//       description: `<img src="${descImage1}" class='w-100'/>`,
//     },
//     {
//       id: 2,
//       name: 'Digital Certificate',
//       company: 'UNICEF',
//       image: image2,
//       tedoPoints: 0,
//       description: `<img src="${descImage2}" class='w-100'/>`,
//     },
//     {
//       id: 3,
//       name: 'Udemy Courses (Nigeria)',
//       company: 'ZLTO',
//       image: image3,
//       description: `<a href="https://app.zlto.co/signup/unicef-nigeria-sign-up/" target="_blank"><img src="${descImage3}" class='w-100'/></a>`,
//       tedoPoints: 2000,
//     },
//     {
//       id: 4,
//       name: 'Udemy Courses (South Africa)',
//       company: 'ZLTO',
//       image: image4,
//       description: `<a href="https://app.zlto.co/signup/rlabs-sign/" target="_blank"><img src="${descImage4}" class='w-100'/></a>`,
//       tedoPoints: 2000,
//     },
//     {
//       id: 5,
//       name: 'Shaw Academy Courses (Nigeria)',
//       company: 'ZLTO',
//       image: image5,
//       description: `<a href="https://app.zlto.co/signup/unicef-nigeria-sign-up/" target="_blank"><img src="${descImage3}" class='w-100'/></a>`,
//       tedoPoints: 2000,
//     },
//     {
//       id: 6,
//       name: 'Shaw Academy Courses (South Africa)',
//       company: 'ZLTO',
//       image: image6,
//       description: `<a href="https://app.zlto.co/signup/rlabs-sign/" target="_blank"><img src="${descImage4}" class='w-100'/></a>`,
//       tedoPoints: 2000,
//     },
//     {
//       id: 7,
//       name: 'Guidance from mentors (Nigeria)',
//       company: 'ZLTO',
//       image: image7,
//       description: `<a href="https://app.zlto.co/signup/unicef-nigeria-sign-up/" target="_blank"><img src="${descImage3}" class='w-100'/></a>`,
//       tedoPoints: 2000,
//     },
//     {
//       id: 8,
//       name: 'Guidance from mentors (South Africa)',
//       company: 'ZLTO',
//       image: image8,
//       description: `<a href="https://app.zlto.co/signup/rlabs-sign/" target="_blank"><img src="${descImage4}" class='w-100'/></a>`,
//       tedoPoints: 2000,
//     },
//   ]);

//   const toggleModal = () => {
//     setResourceModal((prev) => !prev);
//   };

//   const showResourceModal = () => {
//     if (!resource) return null;
//     const { name, description } = resource;
//     return (
//       <Modal
//         className="modal-lg"
//         isOpen={resourceModal}
//         toggle={() => {
//           toggleModal();
//         }}
//       >
//         <ModalHeader
//           toggle={() => {
//             toggleModal();
//           }}
//         >
//           <Row>
//             <Col>{name}</Col>
//           </Row>
//         </ModalHeader>
//         <ModalBody>
//           <Container>
//             <Row>
//               <Col>
//                 <p dangerouslySetInnerHTML={{ __html: description }} />
//               </Col>
//             </Row>
//           </Container>
//         </ModalBody>
//         <ModalFooter>{/* Add additional modal footer content if needed */}</ModalFooter>
//       </Modal>
//     );
//   };

//   const renderResource = (resource: Resource) => {
//     const { name, image, id, company, tedoPoints } = resource;
//     return (
//       <Col xs={12} sm={6} lg={4} className="d-flex" key={id}>
//         <Card className="project-index-card flex-grow-1">
//           <ImagePlaceholder className="card-img-top" width={300} height={300} src={image} />
//           <CardBody className="d-flex flex-column">
//             <CardTitle className="h3 mb-2">{name}</CardTitle>
//             <small className="mb-2 text-muted text-uppercase font-weight-light">{company}</small>
//             <Row>
//               <Col className="text-right">
//                 <Button
//                   className="px-0 text-right"
//                   color="link"
//                   onClick={() => {
//                     setResource(resource);
//                     toggleModal();
//                   }}
//                 >
//                   {`${tedoPoints.toString()} Tedo Points | View Details`}
//                 </Button>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//       </Col>
//     );
//   };

//   // Rachit do we need this code?
//   // renderGame(resource) {
//   //   const { name, image, skills, id } = resource;
//   //   return (
//   //     <Col xs={12} sm={6} lg={4} className="d-flex" key={id}>
//   //       <Card className="project-index-card flex-grow-1">
//   //         <ImagePlaceholder
//   //           className="project-card-img top"
//   //           width={300}
//   //           height={300}
//   //           src={{ original: image }}
//   //         />
//   //         <CardBody className="d-flex flex-column pb-1">
//   //           <CardTitle className="h3 mb-2 ml-1">{name}</CardTitle>
//   //           <Row className="flex-grow-1 align-items-center justify-content-between d-flex flex-column mx-0">
//   //             <Row
//   //               className="mr--4 mt-2 d-block text-right"
//   //               style={{ width: "100%" }}
//   //             >
//   //               <Button
//   //                 className="px-0 text-right"
//   //                 color="link"
//   //                 onClick={() => {
//   //                   this.setState({ resource });
//   //                   this.toggleModal("resourceModal");
//   //                 }}
//   //               >
//   //                 {I18n.t("recommendations.viewButtonGames")}
//   //               </Button>
//   //             </Row>
//   //           </Row>
//   //         </CardBody>
//   //       </Card>
//   //     </Col>
//   //   );
//   // }

//   return (
//     <Container fluid>
//       {showResourceModal()}
//       <Row className="mt-5 main-container">
//         <Col sm={3}>
//           <ProjectsFilters />
//         </Col>
//         <Col sm={9}>
//           <Container fluid>
//             <Row>
//               <Col>
//                 <h2 className="text-uppercase font-weight-600 mt-1 mb-4">Redeem Your Rewards</h2>
//               </Col>
//             </Row>
//             <Row style={recommendations.length === 0 ? {} : { display: 'none' }}>
//               <Col className="text-center">
//                 <H2>{I18n.t('recommendations.emptyString').toString()}</H2>
//               </Col>
//             </Row>
//             <Row style={recommendations.length === 0 ? { display: 'none' } : {}}>
//               {loading ? loadingSkeleton() : recommendations.map(renderResource)}
//             </Row>
//           </Container>
//         </Col>
//       </Row>
//       <Footer />
//     </Container>
//   );
// };

// export default RecommendationsIndex;

export default function Redemptions() {
  return <div>Redemptions</div>;
}
