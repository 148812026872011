import { nanoid } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import { i18nCText } from '../../../../../libs/i18n/I18n';
import { translate } from '../../../../../state/utils/helper';

import { PersonaEntryAnswer } from '@app/@types/redux/answer';
import { ImagePlaceholder } from '../../../../UI/Html';
import './CardPersona.scss';

const CardPersona = (props: { persona: PersonaEntryAnswer }) => {
  const { persona } = props;
  const { id: cardImageId, longTextareaFields, title } = persona;
  const original = persona.image?.regular ?? '';
  return (
    <div className="cardPersona__container">
      <Row className="mb-6">
        <Col md={5}>
          <ImagePlaceholder
            className="cardPersona__personaImage"
            alt={title || 'Image'}
            src={original}
            id={cardImageId}
          />
        </Col>
        <Col
          md={7}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div className="cardPersona__rightContainer">
            <h1>{translate('personaCard.bio')}</h1>
            <div>
              <strong>{translate('personaCard.nameLabel')}</strong>:&nbsp;
              <span>{_.find(persona.inputFields, { fieldName: 'name' })?.fieldValue}</span>
            </div>
            <div>
              <strong>{translate('personaCard.ageLabel')}</strong>:&nbsp;
              <span>{_.find(persona.inputFields, { fieldName: 'age' })?.fieldValue}</span>
            </div>
            <div>
              <strong>{translate('personaCard.locationLabel')}</strong>:&nbsp;
              <span>{_.find(persona.inputFields, { fieldName: 'location' })?.fieldValue}</span>
            </div>
          </div>
          <div className="cardPersona__rightContainer">
            <h1>{translate('personaCard.insights')}</h1>
            {longTextareaFields.map((question) => {
              const { fieldLabel, fieldValue } = question;
              return (
                <div key={nanoid()}>
                  <strong>{i18nCText(fieldLabel)}</strong>
                  <br />
                  {fieldValue}
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CardPersona;
