import env from '@config/env';
import logger from '@libs/log';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import _ from 'lodash';

const openai = axios.create({
  baseURL: 'https://api.openai.com/v1',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${env.OPENAI_API_KEY}`,
    'OpenAI-Organization': env.OPENAI_ORG_ID,
  },
  timeout: 120000,
});

axiosRetry(openai, {
  retries: 3,
  shouldResetTimeout: true,
  retryCondition: () => true,
});

export type OpenAIRole = 'user' | 'assistant' | 'system';

export interface Message {
  role: OpenAIRole;
  content: string;
}

interface OpenAIResponse {
  id: string;
  object: string;
  created: number;
  model: string;
  choices: {
    index: number;
    message: Message;
    logprobs: null;
    finish_reason: string;
  }[];
  usage: {
    prompt_tokens: number;
    completion_tokens: number;
    total_tokens: number;
  };
  system_fingerprint: null;
}

interface OpenAIApiOptions {
  model: string;
  messages: Message[];
  temperature: number;
  response_format?: { type: string };
}

export function getCompletions(
  messages: Message[],
  outputFormat?: unknown,
  model = 'gpt-4o'
): Promise<OpenAIResponse> {
  const options: OpenAIApiOptions = {
    model,
    messages,
    temperature: 0.5,
  };
  if (outputFormat) {
    options.response_format = { type: 'json_object' };
  }
  return openai.post('/chat/completions', options).then((response) => {
    return response.data as OpenAIResponse;
  });
}

export function parseJsonCompletions(output: string) {
  const fIndex = output.indexOf('{');
  const lIndex = output.lastIndexOf('}');
  let ret: Record<string, unknown> = {};
  try {
    ret = JSON.parse(output.substring(fIndex, lIndex + 1)) as Record<string, unknown>;
  } catch (e: unknown) {
    logger.error(`Error parsing JSON: ${String(e)}`);
  }
  return ret;
}

export interface Prompt {
  prompt: string;
  outputFormat?: unknown;
  role?: OpenAIRole;
}

/**
 * Get data from prompt
 * @param promptArray Array of prompts
 * @param setProgress Set progress
 * @returns
 */
export async function getGenerativeDataFromPrompt<T>(
  promptArray: Prompt[],
  setProgress: (progress: number) => void = () => undefined,
  model = 'gpt-4o'
): Promise<T> {
  const messages: Message[] = [];
  let mainData = {};
  const forLoop = async () => {
    for (const { prompt, outputFormat = false, role = 'user' } of promptArray) {
      let promptText: string | undefined = prompt;
      if (outputFormat) {
        promptText = `${prompt}

Give output in imperative mood in the strictly following JSON (no free text, only JSON) format:
${JSON.stringify(outputFormat)}`;
      }
      messages.push({ role, content: promptText });
      const getData = async (
        messages: Message[],
        outputFormat: unknown,
        counter = 0
      ): Promise<OpenAIResponse | null> => {
        if (counter === 5) {
          return null;
        }
        const response = await getCompletions(messages, null, model);
        if (outputFormat) {
          const data = parseJsonCompletions(response.choices[0].message.content);
          if (_.isEqual(Object.keys(data), Object.keys(outputFormat))) {
            return response;
          } else {
            return getData(messages, outputFormat, counter + 1);
          }
        } else {
          return response;
        }
      };
      const response = await getData(messages, outputFormat);
      if (response === null) {
        return null;
      }
      setProgress(promptArray.length);
      messages.push(response.choices[0].message);
      if (outputFormat) {
        const data = parseJsonCompletions(response.choices[0].message.content);
        mainData = { ...mainData, ...data };
      }
    }
  };
  await forLoop();
  logger.debug({ openai_response: mainData });
  return mainData as T;
}
