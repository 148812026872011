import { FillAnswer, PersonaEntryAnswer } from '@app/@types/redux/answer';
import { MessageOptions } from '@app/@types/redux/pageElement';
import { DependeeValue, UserAnswerAnswer } from '@app/@types/redux/project';
import { PAGE_ELEMENT } from '@libs/constants/constants';
import { getDependeeValues, parseDependeeValue } from '@state/selectors/projects';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { FaCheckSquare, FaRegLightbulb } from 'react-icons/fa';
import replace from 'react-string-replace';
import { PageElementProps } from '..';
import { FILL_BLANK_REGEX } from '../../../../libs/constants/regex';
import { i18nCText } from '../../../../libs/i18n/I18n';
import { Card, Col, H2, Row } from '../../../UI/Html';
import NoStyleDiv from '../../../UI/Html/NoStyleDiv';
import './Message.scss';

const reg = FILL_BLANK_REGEX;

const Message: React.FC<PageElementProps> = (props) => {
  const { answer, pageElement, getDependeeAnswerType, state } = props;

  const { dependees } = pageElement.attributes;
  const options = pageElement.attributes.options as MessageOptions;
  const dependentValue =
    dependees.length > 0 &&
    (parseDependeeValue(
      getDependeeAnswerType(dependees[0].dependeeId),
      dependees[0].dependeeKey
    ) as UserAnswerAnswer);

  const dependentValues = getDependeeValues(dependees, [], state) as DependeeValue[];
  let { value } = options;
  const { type, classes } = options;

  let parsedDependentValue = dependentValue;
  const [isHtml, setIsHtml] = useState(false);

  const isFillMessage = (value?.match(reg) ?? []).length > 0;

  const parsedValue = value ?? '';

  const output = [];

  if (isFillMessage) {
    dependentValues.map((dependentValueItem) => {
      if (dependentValueItem.elementType === PAGE_ELEMENT.PERSONA_ENTRY) {
        parsedDependentValue = (dependentValue as PersonaEntryAnswer).title;
        output.push(
          replace(i18nCText(parsedValue), reg, () => parsedDependentValue as string).join()
        );
      } else if (dependentValueItem.elementType === PAGE_ELEMENT.FILL) {
        const tempDependentValues = dependentValueItem.answer as FillAnswer;
        if (tempDependentValues.fillText) {
          value = tempDependentValues.fillText;
        }
        let translatedValue = i18nCText(parsedValue);
        Object.keys(tempDependentValues).map((key) => {
          const tempOutput = replace(translatedValue, `{{${key}}}`, () => {
            return `<span>${tempDependentValues[key]}</span>`;
          });
          translatedValue = tempOutput.join('');
          return translatedValue;
        });
        output.push(translatedValue);
      } else if (dependentValueItem.elementType === PAGE_ELEMENT.RADIO) {
        const tempDependentValue = dependentValueItem.answer as string;
        const translatedValue = i18nCText(parsedValue);
        const tempOutput = replace(translatedValue, reg, () => tempDependentValue);
        output.push(tempOutput.join(''));
      } else {
        const translatedValue = i18nCText(parsedValue);
        const tempDependentValue = dependentValueItem.answer as string;
        const tempOutput = replace(translatedValue, reg, () => tempDependentValue);
        output.push(tempOutput.join(''));
      }
      return '';
    });
  } else {
    output.push(i18nCText(parsedValue));
  }

  const textOutput = output.join('').trim();
  // output = output.join('').trim();

  useEffect(() => {
    answer(textOutput);
    const htmlRegex = /<\/?[a-z][\s\S]*>/i;
    setIsHtml(htmlRegex.test(textOutput));
  }, [textOutput]);

  let content = null;
  switch (type) {
    case 'info':
      content = (
        <div className={`idea-message-container ml-md-3 ${classes || ''}`}>
          <i className={options.prefixIcon} />
          <span
            className="dependent-text intext-blue"
            dangerouslySetInnerHTML={{
              __html: `<span class='prefix-text'>${i18nCText(
                options.prefixText
              )}</span>${textOutput}`,
            }}
          />
        </div>
      );
      break;
    case 'checked-option':
      content = (
        <Row>
          <Col className="ml-3">
            <Card className="selected-option shadow-none px-4 py-3">
              <h3 className="font-weight-600">{options.prefixText}</h3>
              <h4>
                <FaCheckSquare className="text-primary mr-2" style={{ fontSize: '1.2em' }} />
                <span
                  className="dependent-text intext-blue "
                  dangerouslySetInnerHTML={{
                    __html: textOutput,
                  }}
                />
              </h4>
            </Card>
          </Col>
        </Row>
      );
      break;
    case 'filled-statement':
      content = (
        <Row>
          <Col className="ml-3">
            <Card className="selected-option shadow-none px-4 py-3">
              <h3 className="font-weight-600">{options.prefixText}</h3>
              <h4>
                <h4
                  className="dependent-text intext-blue intext-underline"
                  dangerouslySetInnerHTML={{
                    __html: textOutput,
                  }}
                />
              </h4>
            </Card>
          </Col>
        </Row>
      );
      break;
    case 'style_info_with_label':
      content = (
        <Row>
          <Col>
            <Card className="styleInfoWithLabel__card shadow-none">
              <h3 className="font-weight-600">
                <FaRegLightbulb />
                <span>{options.label}</span>
              </h3>
              <h4
                className="dependent-text intext-blue intext-underline"
                dangerouslySetInnerHTML={{
                  __html: textOutput,
                }}
              />
            </Card>
          </Col>
        </Row>
      );
      break;
    default:
      if (textOutput) {
        if (isHtml) {
          content = (
            <Row>
              <Col>
                <H2 className={classnames(classes)}>
                  <NoStyleDiv className={classnames(classes)}>{textOutput}</NoStyleDiv>
                </H2>
              </Col>
            </Row>
          );
        } else {
          content = (
            <Row>
              <Col>
                <H2 className={classnames(classes)}>{textOutput}</H2>
              </Col>
            </Row>
          );
        }
      }
  }
  return content;
};

export default Message;
