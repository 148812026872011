// import { initialState } from '../reducers/usersReducer'

import { UserEvaluation } from '@app/@types/redux/project';
import { User } from '@app/@types/redux/users';
import { RootState } from '../redux/store';

export const getCurrentUser = (state: RootState): User => state.users.user;

export const getUserGrowths = (state: RootState) => state.users.growths;

export const getPreferences = (state: RootState) => state.users.user.attributes.preferences;

export const getFullName = (state: RootState) => state.users.user.attributes.name;

export function getEvaluationResult(evaluationId: string, myEvaluations: UserEvaluation[]): string {
  const evaluationResult: UserEvaluation | undefined = myEvaluations.find(
    (x: UserEvaluation) => x.attributes.evaluationId === evaluationId
  );

  if (!evaluationResult) {
    return '';
  }
  return evaluationResult.attributes.results?.resultHuman ?? '';
}
