import classnames from 'classnames';

import { isLoggedIn } from '@utils/helpers/auth';
import { createBrowserHistory } from 'history';
import { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import cartedoLogoSVG from '../../../assets/images/logo/cartedo/dark/cartedo-logo-720.png';
import { toggleLoginModal } from '../../../state/redux/slices/auth.slice';
import * as actions from '../../../state/redux/slices/user.slice';
import { AppDispatch, RootState, useAppDispatch, useAppSelector } from '../../../state/redux/store';
import { translate } from '../../../state/utils/helper';
import LoginForm from '../../Users/Auth/LoginForm';
import {
  Alert,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from '../Html';
import LanguageOptions from './LanguageOptions';
import './NavBar.scss';
import Notifications from './Notifications';
import TedoPoints from './TedoPoints';
import UserOptions from './UserOptions';

const mapStateToProps = (state: RootState) => ({
  currentUser: state.users.user,
  isSignedIn: isLoggedIn(),
  preferences: state.users.preferences,
});
const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      ...actions,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface INavBarProps extends PropsFromRedux {
  language: string;
}
function NavBar(props: INavBarProps) {
  const [isOpen, setIsOpen] = useState(false);
  const loginModal = useAppSelector((state: RootState) => state.auth.flags.loginModal);
  const dispatch = useAppDispatch();
  function toggleNavbar() {
    setIsOpen(!isOpen);
  }
  const history = createBrowserHistory();
  const onSignInPage = history.location.pathname === '/sign_in';
  const { isSignedIn } = props;
  return (
    <>
      <Navbar
        className={classnames(
          'navbar-top',
          'border-bottom',
          'navbar-light',
          'bg-white',
          'navbar-main',
          'navBarShadow'
        )}
        sticky="top"
        style={{ zIndex: 1049 }}
        expand="lg"
        id="rachit-navbar"
      >
        <Container fluid className="navbar-container-fluid">
          <NavbarToggler
            onClick={() => {
              toggleNavbar();
            }}
          />
          <NavbarBrand to="/" tag={Link}>
            <img alt="..." src={cartedoLogoSVG} />
          </NavbarBrand>
          {isSignedIn ? <UserOptions mobile /> : <LanguageOptions mobile />}
          <Collapse isOpen={isOpen} navbar>
            <div className="navBar__container">
              <Nav navbar>
                {isSignedIn && (
                  <>
                    <NavItem className="d-none d-md-block">
                      <NavLink to="/me" tag={Link} className="text-uppercase my-sm-2">
                        {translate('layout.navbar.navlinks.myProfile')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/workspaces" tag={Link} className="text-uppercase my-sm-2">
                        {translate('layout.navbar.navlinks.workspaces')}
                      </NavLink>
                    </NavItem>
                  </>
                )}
              </Nav>
              <Nav navbar style={{ placeItems: 'center' }}>
                <LanguageOptions desktop />
                {isSignedIn ? (
                  <>
                    <TedoPoints />
                    <Notifications />
                    <UserOptions desktop />
                  </>
                ) : (
                  <>
                    <NavItem>
                      <NavLink
                        to={!onSignInPage ? '/sign_in' : '/'}
                        tag={Link}
                        onClick={(e: React.MouseEvent) => {
                          if (onSignInPage) return;
                          e.preventDefault();
                          dispatch(toggleLoginModal(true));
                        }}
                        className="text-uppercase text-primary"
                      >
                        {translate('auth.signInBtn')}
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        to='/sign_up'
                        tag={Link}
                        className='text-uppercase text-primary'
                      >
                        {translate('auth.registerBtn')}
                      </NavLink>
                    </NavItem> */}
                  </>
                )}
              </Nav>
            </div>
          </Collapse>
        </Container>
      </Navbar>
      <Alert
        isOpen={loginModal}
        onRequestClose={() => {
          dispatch(toggleLoginModal(false));
        }}
        showCloseButton
        className="bg-secondary login-form-modal"
        style={{ width: '80%', zIndex: 1050 }}
      >
        <LoginForm mode="modal" />
      </Alert>
    </>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
