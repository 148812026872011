import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { errorToast, strengthIndicator } from '../../../libs/helper';
import { redirectTo } from '../../../routes/helpers';
import {
  resetPassword as resetPasswordFn,
  resetPasswordTokenValid,
} from '../../../state/redux/slices/auth.slice';
import { translate } from '../../../state/utils/helper';

import { DefaultError } from '@libs/constants/errors';
import i18n from '@libs/i18n/I18n';
import { useAppDispatch } from '@state/redux/store';
import { Input } from 'reactstrap';
import {
  A,
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  H4,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from '../../UI/Html';
import Footer from '../../UI/Layout/Footer';
import Header from './Header';

interface SignInProp {
  token: string;
}

const SignIn = ({ token }: SignInProp) => {
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [focusedPassword, setFocusedPassword] = useState(false);
  const [focusedPasswordConfirmation, setFocusedPasswordConfirmation] = useState(false);
  const [doneModal, setDoneModal] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    document.body.classList.add('bg-default-color');
    dispatch(resetPasswordTokenValid({ token }))
      .then(() => {
        setIsTokenValid(true);
      })
      .catch(() => {
        setIsTokenValid(false);
      });

    return () => {
      document.body.classList.remove('bg-default-color');
    };
  }, [token, dispatch]);

  const trySignIn = () => {
    if (password !== passwordConfirmation) {
      errorToast(translate('auth.resetPassword.passwordConfirmNoMatch'));
      return false;
    }
    if (passwordStrength < 2) {
      errorToast(translate('auth.resetPassword.passwordNotStrong'));
      return false;
    }
    dispatch(resetPasswordFn({ token, password, passwordConfirmation }))
      .then(() => {
        setDoneModal(true);
      })
      .catch(() => {
        errorToast(DefaultError);
        return false;
      });
    return false;
  };

  return (
    <div className="main-container">
      {isTokenValid ? (
        <Header
          title={translate('auth.resetPassword.heading')}
          lead={translate('auth.resetPassword.description')}
        />
      ) : (
        <Header
          title={translate('auth.tokenExpired.heading')}
          lead={translate('auth.tokenExpired.subheading')}
        />
      )}
      <Alert
        isOpen={doneModal}
        onRequestClose={() => {
          setDoneModal(false);
        }}
        showCloseButton
        icon="success"
      >
        <Row className="mb-3">
          <Col>
            <div>
              <h1>Password reset successful!</h1>
              <p>You may now sign in with your new password</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              color="info"
              onClick={() => {
                setDoneModal(false);
                redirectTo('/sign_in');
              }}
            >
              Sign In
            </Button>
          </Col>
        </Row>
      </Alert>
      <Container className="mt--8 pb-5 main-container-sign-out">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                {isTokenValid ? (
                  <Form role="form" onSubmit={trySignIn}>
                    <FormGroup
                      className={classnames({
                        focused: focusedPassword,
                        'mb-1': true,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          value={password}
                          placeholder={translate('auth.resetPassword.newPassword')}
                          type="password"
                          name="password"
                          id="password"
                          onFocus={() => {
                            setFocusedPassword(true);
                          }}
                          onBlur={() => {
                            setFocusedPassword(false);
                          }}
                          autoComplete="new-password"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(e.target.value);
                            setPasswordStrength(strengthIndicator(e.target.value));
                          }}
                          required
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-muted font-italic mb-3 pl-1">
                      <small>
                        Password Strength:{' '}
                        <span
                          className={classnames({
                            'font-weight-700': true,
                            'text-danger': passwordStrength <= 1,
                            'text-warning': passwordStrength > 1 && passwordStrength < 4,
                            'text-success': passwordStrength > 3,
                          })}
                        >
                          {passwordStrength <= 1 && 'weak'}
                          {passwordStrength > 1 && passwordStrength < 4 && 'medium'}
                          {passwordStrength > 3 && 'strong'}
                        </span>
                      </small>
                    </div>
                    <FormGroup
                      className={classnames({
                        focused: focusedPasswordConfirmation,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          value={passwordConfirmation}
                          placeholder={translate('auth.resetPassword.newConfirmPassword')}
                          type="password"
                          name="confirm_new_password"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setPasswordConfirmation(e.target.value);
                          }}
                          onFocus={() => {
                            setFocusedPasswordConfirmation(true);
                          }}
                          onBlur={() => {
                            setFocusedPasswordConfirmation(false);
                          }}
                          autoComplete="confirm-new-password"
                          required
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button className="my-4" color="info" type="submit">
                        {translate('auth.resetPassword.heading').toString()}
                      </Button>
                    </div>
                  </Form>
                ) : (
                  <div className="text-center">
                    <H4>{i18n.t('auth.tokenExpired.description').toString()}</H4>
                    <Button
                      className="my-4"
                      color="info"
                      onClick={() => {
                        redirectTo('/forgot_password');
                      }}
                    >
                      {i18n.t('auth.forgotPassword.button').toString()}
                    </Button>
                  </div>
                )}
              </CardBody>
            </Card>
            <Row className="mt-3 justify-content-center">
              <Col xs="6" className="text-center">
                <A className="text-white" href="/sign_in">
                  <small>{i18n.t('auth.signInBtn').toString()}</small>
                </A>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container>
        <Footer />
      </Container>
    </div>
  );
};

export default SignIn;
