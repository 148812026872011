import React from 'react';

interface MarvelAppProps {
  url?: string;
}

const MarvelApp: React.FC<MarvelAppProps> = ({ url = 'https://marvelapp.com/6a18b1d' }) => {
  const result = url;
  // const result = url.match(MARVEL_APP_REGEX)
  return result ? (
    <iframe
      src={`${url}?emb=1&iosapp=false&frameless=false`}
      width="100%"
      title="title"
      height="500"
      frameBorder="0"
      className="mb-4"
    />
  ) : (
    <p>{result}</p>
  );
};

export default MarvelApp;
