import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import {
  AddMediaImage,
  AttachmentAnswer,
  SubmissionCardAdAnswer,
  SubmissionCardAnswer,
  SubmissionCardEmbedAnswer,
  SubmissionCardGalleryAnswer,
  SubmissionCardLinkAnswer,
} from '@app/@types/redux/answer';
import { SubmissionCardOptions } from '@app/@types/redux/pageElement';
import { UserAnswerAnswer } from '@app/@types/redux/project';
import { PAGE_ELEMENT } from '@libs/constants/constants';
import { getPageElementDisabledState, parseDependeeValue } from '@state/selectors/projects';
import { FiPlus, FiTrash } from 'react-icons/fi';
import ReactPlayer from 'react-player';
import { PageElementProps } from '..';
import { SUBMISSION_TYPES } from '../../../../libs/constants';
import { errorToast } from '../../../../libs/helper';
import { i18nCText } from '../../../../libs/i18n/I18n';
import { translate } from '../../../../state/utils/helper';
import { Animated, Button, Col, Div, H4, Input, Modal, ModalBody, Row } from '../../../UI/Html';
import UnsplashCredits from '../../../UI/Html/UnsplashCredits';
import { AddMedia, CarouselGallery } from './HelperComponents';
import MarvelApp from './HelperComponents/MarvelApp';
import AdBuilder from './HelperComponents/PrototypingMethods/AdBuilder';
import EmbedSubmission from './HelperComponents/PrototypingMethods/EmbedSubmission';
import LinkSubmission from './HelperComponents/PrototypingMethods/LinkSubmission';
import SubmissionAttachment from './HelperComponents/PrototypingMethods/SubmissionAttachment';
import { useAppSelector } from '@state/redux/store';

const MAX_IMAGES = 4;

const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
  },
  textarea: {
    width: '100%',
    '& > div > textarea': {
      lineHeight: '1.5rem',
    },
  },
  editImageButton: {
    position: 'absolute',
    color: 'white',
    top: 15,
    right: 15,
  },
  formControl: {
    width: '100%',
  },
}));

const SubmissionCard: React.FC<PageElementProps> = (props) => {
  const {
    pageElement,
    pageElement: {
      attributes: { dependees },
      id,
    },
    currentAnswer,
    state,
  } = props;
  const options = pageElement.attributes.options as SubmissionCardOptions;

  const getDependeeAnswerType = props.getDependeeAnswerType as (peId: string) => {
    answer: UserAnswerAnswer;
    type: PAGE_ELEMENT;
  };

  // const { questions, types } = options;
  const { questions, types, allowAttachment = true } = options;
  const allowCopyPaste = useAppSelector((state) => state.projects.allowCopyPaste);

  // const allowAttachment = true

  const answer = props.answer as (answer: SubmissionCardAnswer) => void;

  const [addMediaOpen, setAddMediaOpen] = useState(false);
  const [embedTips, setEmbedTips] = useState(false);
  const isSingleType = types.length === 1;
  const classes = useStyles();

  const disabled = getPageElementDisabledState(state, id);

  const emptyAnswer: SubmissionCardAnswer = useMemo(
    () => ({
      type: '',
      title: '',
      link: '',
      images: [],
      embedCode: '',
      description: '',
      headline: '',
      displayLink: '',
      callToAction: '',
      attachments: [],
      questions: {},
      image: {
        regular: '',
      },
      profileImage: {
        regular: '',
      },
      url: '',
      linkMetaData: {
        title: '',
        description: '',
        image: '',
        url: '',
        favicon: '',
        siteName: '',
      },
    }),
    []
  );

  const currentAnswerSafe = (currentAnswer ?? emptyAnswer) as SubmissionCardAnswer;

  useEffect(() => {
    const answers = currentAnswer as SubmissionCardAnswer | undefined;
    const dependeeValues: UserAnswerAnswer[] = [];
    dependees.forEach((dependee) => {
      const dependeeValue = parseDependeeValue(
        getDependeeAnswerType(dependee.dependeeId),
        dependee.dependeeKey
      ) as UserAnswerAnswer[];
      dependeeValue.forEach((item) => {
        dependeeValues.push(item);
      });
    });
    if (isSingleType && !answers?.type) {
      answer({
        ...(answers ?? emptyAnswer),
        type: types[0],
      });
    }
  }, [answer, currentAnswer, dependees, getDependeeAnswerType, isSingleType, types, emptyAnswer]);

  function renderQuestion(question: string, index: number) {
    const answerQuestions = currentAnswerSafe.questions;
    const thisAnswer = answerQuestions[index] as
      | {
          question: string;
          value: string;
        }
      | undefined;
    const maxCharacters = 800;
    return (
      <Row className="my-4 text-align-initial" key={index}>
        <Col>
          <H4 className="side-border-title font-weight-500 font-size-1em">
            <span dangerouslySetInnerHTML={{ __html: i18nCText(question) }} />
          </H4>
          <Input
            type="textarea"
            placeholder={translate('pageElements.submissionCard.textareaPlaceholder', {
              maxCharacters,
            })}
            value={thisAnswer?.value}
            onChange={(value) => {
              answer({
                ...currentAnswerSafe,
                questions: {
                  ...answerQuestions,
                  [index]: {
                    question,
                    value,
                  },
                },
              });
            }}
            onBlur={(value) => {
              answer({
                ...currentAnswerSafe,
                questions: {
                  ...answerQuestions,
                  [index]: {
                    question,
                    value: value.trim(),
                  },
                },
              });
            }}
            onPaste={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!allowCopyPaste) {
                e.preventDefault();
              }
            }}
            className=""
            rows={3}
            maxLength={maxCharacters}
            disabled={disabled}
          />
        </Col>
      </Row>
    );
  }

  const { title, link, type: submissionType } = currentAnswerSafe;

  const currentSubmissionType = SUBMISSION_TYPES.find((st) => st.type === submissionType);

  return (
    <>
      <Animated>
        {['ad'].includes(submissionType) && (
          <Row>
            <Col className="px-4 pb-5">
              <h3>
                <strong>Instructions: </strong>Add your content in the provided fields on the left
                to render your ad in the ad-builder template shown on the right. Please hover over
                the image within the ad-builder to change it.
              </h3>
            </Col>
          </Row>
        )}
        {['model', 'gallery'].includes(submissionType) && (
          <Row>
            <Col className="px-4 py-2 pb-4">
              <Div className="submission-carousel-gallery">
                <CarouselGallery
                  slides={(currentAnswerSafe as SubmissionCardGalleryAnswer).images}
                />
              </Div>
            </Col>
          </Row>
        )}
        {['embed'].includes(submissionType) && (
          <EmbedSubmission currentAnswer={currentAnswer as SubmissionCardEmbedAnswer} />
        )}
        {['link'].includes(submissionType) && (
          <LinkSubmission
            currentAnswer={currentAnswer as SubmissionCardLinkAnswer}
            answer={answer as (payload: SubmissionCardLinkAnswer) => void}
          />
        )}
        <Row className="ml-md-3">
          <Col xs={12} sm={['short-answer'].includes(submissionType) ? 12 : 6}>
            {['embed'].includes(submissionType) && (
              <>
                <div className="mx-0 pb-1 my-4">
                  <H4 className="side-border-title font-weight-500 font-size-1em">
                    {currentSubmissionType?.title.label}
                  </H4>
                  <Input
                    value={title}
                    onChange={(newTitle) => {
                      answer({
                        ...currentAnswerSafe,
                        title: newTitle,
                      });
                    }}
                    onBlur={(newTitle) => {
                      answer({
                        ...currentAnswerSafe,
                        title: newTitle.trim(),
                      });
                    }}
                    onPaste={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (!allowCopyPaste) {
                        e.preventDefault();
                      }
                    }}
                    className=""
                    rows={1}
                    placeholder={currentSubmissionType?.title.placeholder}
                    disabled={disabled}
                  />
                </div>
                <div className="mx-0 pb-1 my-4">
                  <H4 className="side-border-title font-weight-500 font-size-1em">
                    Embed Code
                    <Button
                      color="link"
                      href="#"
                      onClick={() => {
                        setEmbedTips(!embedTips);
                      }}
                      className="ml-2"
                    >
                      How to embed?
                    </Button>
                    <Modal
                      className="modal-lg"
                      isOpen={embedTips}
                      toggle={() => {
                        setEmbedTips(!embedTips);
                      }}
                    >
                      <ModalBody>
                        <Row>
                          <Col>
                            <iframe
                              src="https://scribehow.com/embed/Embed_google_slides_in_4_steps__BuYACbHQR7-8ZrD7m-Heeg"
                              width="100%"
                              height="640"
                              allowFullScreen
                              frameBorder="0"
                              style={{
                                border: 'none',
                                overflow: 'hidden',
                              }}
                              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                              title="embedTips"
                            ></iframe>
                          </Col>
                        </Row>
                      </ModalBody>
                    </Modal>
                  </H4>
                  <Input
                    type="textarea"
                    value={currentAnswerSafe.embedCode}
                    onChange={(newEmbedCode) => {
                      answer({
                        ...currentAnswerSafe,
                        embedCode: newEmbedCode,
                      });
                    }}
                    onBlur={(newEmbedCode) => {
                      answer({
                        ...currentAnswerSafe,
                        embedCode: newEmbedCode.trim(),
                      });
                    }}
                    className=""
                    rows={3}
                    placeholder='Eg. <iframe src="https://www.youtube.com/embed/4aQwT3n2c1o" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>'
                    disabled={disabled}
                  />
                </div>
              </>
            )}
            {['ad'].includes(submissionType) && (
              <>
                <div className="mx-0 pb-1 my-4">
                  <H4 className="side-border-title font-weight-500 font-size-1em">
                    {currentSubmissionType?.title.label}
                  </H4>
                  <Input
                    value={title}
                    onChange={(newTitle) => {
                      answer({
                        ...currentAnswerSafe,
                        title: newTitle,
                      });
                    }}
                    onBlur={(newTitle) => {
                      answer({
                        ...currentAnswerSafe,
                        title: newTitle.trim(),
                      });
                    }}
                    onPaste={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (!allowCopyPaste) {
                        e.preventDefault();
                      }
                    }}
                    className=""
                    rows={1}
                    placeholder={currentSubmissionType?.title.placeholder}
                    disabled={disabled}
                  />
                </div>
                <div className="mx-0 pb-1 my-4">
                  <H4 className="side-border-title font-weight-500 font-size-1em">
                    Ad Description
                  </H4>
                  <Input
                    type="textarea"
                    value={currentAnswerSafe.description}
                    maxLength={90}
                    onChange={(newTitle) => {
                      answer({
                        ...currentAnswerSafe,
                        description: newTitle,
                      });
                    }}
                    onBlur={(newTitle) => {
                      answer({
                        ...currentAnswerSafe,
                        description: newTitle.trim(),
                      });
                    }}
                    onPaste={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (!allowCopyPaste) {
                        e.preventDefault();
                      }
                    }}
                    className=""
                    rows={3}
                    placeholder="Description"
                    disabled={disabled}
                  />
                </div>
                <div className="mx-0 pb-1 my-4">
                  <H4 className="side-border-title font-weight-500 font-size-1em">Ad Headline</H4>
                  <Input
                    value={currentAnswerSafe.headline}
                    maxLength={25}
                    onChange={(newTitle) => {
                      answer({
                        ...currentAnswerSafe,
                        headline: newTitle,
                      });
                    }}
                    onBlur={(newTitle) => {
                      answer({
                        ...currentAnswerSafe,
                        headline: newTitle.trim(),
                      });
                    }}
                    onPaste={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (!allowCopyPaste) {
                        e.preventDefault();
                      }
                    }}
                    className=""
                    rows={1}
                    placeholder="Ad Headline"
                    disabled={disabled}
                  />
                </div>
                <div className="mx-0 pb-1 my-4">
                  <H4 className="side-border-title font-weight-500 font-size-1em">Display Link</H4>
                  <Input
                    value={currentAnswerSafe.displayLink}
                    onChange={(newTitle) => {
                      answer({
                        ...currentAnswerSafe,
                        displayLink: newTitle,
                      });
                    }}
                    onBlur={(newTitle) => {
                      answer({
                        ...currentAnswerSafe,
                        displayLink: newTitle.trim(),
                      });
                    }}
                    className=""
                    rows={1}
                    placeholder="Display Link"
                    disabled={disabled}
                  />
                </div>
                <div className="mx-0 pb-1 my-4">
                  <H4 className="side-border-title font-weight-500 font-size-1em">
                    Call to action
                  </H4>
                  <Input
                    value={currentAnswerSafe.callToAction}
                    maxLength={25}
                    onChange={(newTitle) => {
                      answer({
                        ...currentAnswerSafe,
                        callToAction: newTitle,
                      });
                    }}
                    onBlur={(newTitle) => {
                      answer({
                        ...currentAnswerSafe,
                        callToAction: newTitle.trim(),
                      });
                    }}
                    rows={1}
                    placeholder="Call to action"
                    disabled={disabled}
                  />
                </div>
              </>
            )}
            {['video', 'app', 'link'].includes(submissionType) &&
              currentSubmissionType?.link?.label && (
                <Row className="mx-0 pb-1">
                  <H4 className="side-border-title font-weight-500 font-size-1em">
                    {currentSubmissionType.link.label}
                  </H4>
                  <Input
                    value={link}
                    placeholder={currentSubmissionType.link.placeholder}
                    onChange={(newLink) => {
                      answer({
                        ...currentAnswerSafe,
                        link: newLink,
                      });
                    }}
                    onBlur={(newLink) => {
                      answer({
                        ...currentAnswerSafe,
                        link: newLink.trim(),
                      });
                    }}
                    className=""
                    rows={1}
                    disabled={disabled}
                  />
                </Row>
              )}
            {questions.map((question, index) => renderQuestion(question, index))}
            {allowAttachment ? (
              <SubmissionAttachment
                currentAnswer={currentAnswer as AttachmentAnswer}
                answer={answer as (payload: AttachmentAnswer) => void}
              />
            ) : null}
          </Col>
          <Col xs={12} sm={6}>
            {['model', 'gallery'].includes(submissionType) && (
              <Row>
                <Col>
                  <Row>
                    {currentAnswerSafe.images.map((image) => {
                      return (
                        <Col md={6} className="my-4" key={image.regular}>
                          <div
                            className="ideaCards__imageCard_imageContainer"
                            style={{ borderRadius: '1em' }}
                          >
                            <img
                              src={image.regular}
                              className={classnames('ideaCards__imageCard_image')}
                              alt={image.authorName ?? 'Card Image'}
                            />
                            {!disabled && (
                              <>
                                <div className="ideaCards__imageCard_imageOverlay" />
                                <Button
                                  color="primary"
                                  size="sm"
                                  className={classnames(
                                    'ideaCards__imageCard_imageOptions',
                                    classes.editImageButton
                                  )}
                                  onClick={() => {
                                    const tempImages = currentAnswerSafe.images.filter(
                                      (tImage) => tImage.regular !== image.regular
                                    );
                                    answer({
                                      ...currentAnswerSafe,
                                      images: tempImages,
                                    });
                                  }}
                                >
                                  <FiTrash />
                                </Button>
                              </>
                            )}
                            <UnsplashCredits
                              authorName={image.authorName}
                              authorUrl={image.authorUrl}
                            />
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                  <AddMedia
                    open={addMediaOpen}
                    onRequestClose={() => {
                      setAddMediaOpen(false);
                    }}
                    multiple
                    subheading="You can upload up to 4 images."
                    heading="Upload Images"
                    onSelect={(images) => {
                      const tempImages = currentAnswerSafe.images.length
                        ? [...currentAnswerSafe.images]
                        : [];
                      if ((images as AddMediaImage[]).length > 0) {
                        (images as AddMediaImage[]).map((tImage) => {
                          if (tempImages.length < MAX_IMAGES) {
                            tempImages.push(tImage);
                          } else {
                            errorToast(
                              translate('pageElements.submissionCard.maxImages', {
                                count: MAX_IMAGES,
                              })
                            );
                          }
                          return true;
                        });
                      } else {
                        if (tempImages.length < MAX_IMAGES) {
                          tempImages.push(images as AddMediaImage);
                        } else {
                          errorToast(
                            translate('pageElements.submissionCard.maxImages', {
                              count: MAX_IMAGES,
                            })
                          );
                        }
                      }
                      answer({
                        ...currentAnswerSafe,
                        images: tempImages,
                      });
                    }}
                  />
                  {currentAnswerSafe.images.length < MAX_IMAGES ? (
                    <div
                      className="ideaCards__imageCard_addImage"
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        if (disabled) return;
                        setAddMediaOpen(true);
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: 'inline-grid',
                            placeItems: 'center',
                          }}
                        >
                          <FiPlus />
                        </div>
                        <span>{translate('pageElements.submissionCard.addImages')}</span>
                      </div>
                    </div>
                  ) : null}
                </Col>
              </Row>
            )}
            {['video'].includes(submissionType) && (
              <ReactPlayer url={currentAnswerSafe.link} width="100%" />
            )}
            {['app'].includes(submissionType) && <MarvelApp url={currentAnswerSafe.link} />}
            {['ad'].includes(submissionType) && (
              <AdBuilder
                currentAnswer={currentAnswer as SubmissionCardAdAnswer}
                answer={answer as (arg0: SubmissionCardAdAnswer) => void}
                edit={!disabled}
              />
            )}
          </Col>
        </Row>
      </Animated>
    </>
  );
};

export default SubmissionCard;
