import { Component } from 'react';
import { Image, View } from 'react-native-web';
import { cartedoLogo } from '../assets/images';
import Indicator from '../components/UI/Html/Indicator';

class Loading extends Component {
  override render() {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          height: '100vh',
          backgroundColor: '#f9f9f9',
        }}
      >
        <View>
          <Image
            source={cartedoLogo.dark.large}
            style={{
              width: 200,
              height: 120,
            }}
            resizeMode="contain"
          />
          <Indicator />
        </View>
      </div>
    );
  }
}

export default Loading;
