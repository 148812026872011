import ReactTimeAgo from 'react-timeago';

function TimeAgo({ date }: { date: string }) {
  // const formatter = buildFormatter({
  //   day: translate('timeAgo.day'),
  //   days: translate('timeAgo.days'),
  //   hour: translate('timeAgo.hour'),
  //   hours: translate('timeAgo.hours'),
  //   minute: translate('timeAgo.minute'),
  //   minutes: translate('timeAgo.minutes'),
  //   month: translate('timeAgo.month'),
  //   months: translate('timeAgo.months'),
  //   prefixAgo: translate('timeAgo.prefixAgo'),
  //   prefixFromNow: translate('timeAgo.prefixFromNow'),
  //   seconds: translate('timeAgo.seconds'),
  //   year: translate('timeAgo.year'),
  //   years: translate('timeAgo.years'),
  //   suffixAgo: translate('timeAgo.suffixAgo'),
  //   suffixFromNow: translate('timeAgo.suffixFromNow'),
  //   wordSeparator: translate('timeAgo.wordSeparator'),
  // });
  return <ReactTimeAgo date={date} />;
}

export default TimeAgo;
