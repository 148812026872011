import { CustomerJourneyMapAnswer } from '@app/@types/redux/answer';
import { Col, H3, Modal, ModalBody, Row } from '@components/UI/Html';
import { i18nCText } from '@libs/i18n/I18n';
import { useAppSelector } from '@state/redux/store';
import { useState } from 'react';
import {
  HiOutlineCalendar,
  HiOutlineInformationCircle,
  HiOutlineLocationMarker,
} from 'react-icons/hi';
import { Button, Input } from 'reactstrap';
import './CustomerJourneyMapView.scss';

const MAP_COLUMNS = ['Awareness', 'Consideration', 'Purchase', 'Post Purchase'];

const MAP_ROWS = ['User Actions', 'Pain Points', 'Touch Points & Solutions'];

interface CustomerJourneyMapMatrixProps {
  currentAnswer: CustomerJourneyMapAnswer | undefined;
  answer?: (value: CustomerJourneyMapAnswer) => void;
  submissionQuestion: string;
  disabled: boolean;
}

const CustomerJourneyMapView: React.FC<CustomerJourneyMapMatrixProps> = (props) => {
  const { currentAnswer, answer, submissionQuestion, disabled } = props;
  const allowCopyPaste = useAppSelector((state) => state.projects.allowCopyPaste);

  const persona = currentAnswer?.persona;

  const [modal, setModal] = useState(false);
  const [mapData, setMapData] = useState(
    currentAnswer?.map && currentAnswer.map.length > 0
      ? currentAnswer.map
      : Array.from({ length: MAP_ROWS.length || 0 }, () =>
          Array.from({ length: MAP_COLUMNS.length || 0 }, () => '')
        )
  );

  return (
    <>
      <div className="mt-4 mb-2 w-100">
        <div className="prioritization-matrix__instructionButtonWrapper mb-4">
          <Button
            type="button"
            color="link"
            className="prioritization-matrix__instructionButton"
            onClick={() => {
              setModal(true);
            }}
          >
            <HiOutlineInformationCircle style={{ fontSize: '18px' }} />
            <span>Understand Journey Map</span>
          </Button>
        </div>
        {persona?.title ? (
          <div className="customJourneyMap__personaContainer">
            <div>
              <img
                src={persona.imageUrl}
                alt="Customer Journey Map"
                className="customJourneyMap__personaImage"
              />
            </div>
            <div className="customJourneyMap__personaInfo">
              <h1>{persona.title}</h1>
              <h3>
                <span>
                  <HiOutlineCalendar />
                  <span>{i18nCText(persona.age)}</span>
                </span>
                <span>
                  <HiOutlineLocationMarker />
                  <span>{i18nCText(persona.location)}</span>
                </span>
              </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: i18nCText(persona.description),
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="mb-6 w-100">
        <Row className="customJourneyMap__table">
          <Col>
            {/* Taking the row and column approach here. First traversing through rows and then columns */}
            <Row>
              <Col sm={12} md={2}>
                &nbsp;
              </Col>
              {MAP_COLUMNS.map((column, index) => {
                return (
                  <Col
                    // style={{ maxWidth: '25%' }}
                    className="customJourneyMap__columnHeadingTd"
                    key={`zjhhb${String(index)}`}
                  >
                    <div
                      className={`customJourneyMap__columnHeading customJourneyMap__columnHeadingIndex_${String(
                        index + 1
                      )} d-none d-md-block`}
                    >
                      {column}
                    </div>
                  </Col>
                );
              })}
            </Row>
            {MAP_ROWS.map((row, rowIndex) => {
              return (
                <Row key={`bboyc${String(rowIndex)}`} className="mb-4 customJourneyMap__tdRow">
                  <Col
                    sm={12}
                    md={2}
                    className="customJourneyMap__rowHeadingTd d-flex align-items-center justify-content-start d-none d-md-block"
                  >
                    <div className="customJourneyMap__rowHeading d-none d-md-block">{row}</div>
                  </Col>
                  {MAP_COLUMNS.map((column, columnIndex) => {
                    return (
                      <Col
                        // style={{ maxWidth: `${100 / MAP_COLUMNS.length}%` }}
                        key={`lbjza${String(columnIndex)}`}
                        className="customJourneyMap__td"
                      >
                        {disabled ? (
                          <div>
                            <h1 className="customJourneyMap__previewText">
                              {mapData[rowIndex][columnIndex]}
                            </h1>
                          </div>
                        ) : (
                          <>
                            <div
                              className={`customJourneyMap__columnHeading customJourneyMap__columnHeadingIndex_${String(
                                columnIndex + 1
                              )} d-sm-block d-md-none`}
                            >
                              {column}
                            </div>
                            <div className="customJourneyMap__rowHeading d-sm-block d-md-none">
                              {row}
                            </div>
                            <textarea
                              value={mapData[rowIndex][columnIndex]}
                              className="customJourneyMap__textarea"
                              disabled={disabled}
                              rows={10}
                              maxLength={270}
                              onChange={(e) => {
                                const copy = [...mapData];
                                const row = [...copy[rowIndex]];
                                row[columnIndex] = e.target.value;
                                copy[rowIndex] = row;
                                setMapData(copy);
                                answer && answer({ ...currentAnswer, map: copy });
                              }}
                              onPaste={(e) => {
                                if (!allowCopyPaste) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </>
                        )}
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </Col>
        </Row>
        {submissionQuestion ? (
          <>
            <Row>
              <Col>
                <H3 className="my-3 side-border-title">{submissionQuestion}</H3>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type="textarea"
                  placeholder="Type your answer here"
                  value={currentAnswer?.text}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    answer && answer({ ...currentAnswer, text: e.target.value });
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    answer && answer({ ...currentAnswer, text: e.target.value.trim() });
                  }}
                  onPaste={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (!allowCopyPaste) {
                      e.preventDefault();
                    }
                  }}
                  className="textarea-border"
                  rows={5}
                  disabled={disabled}
                  maxLength="200"
                />
              </Col>
            </Row>
          </>
        ) : null}
      </div>
      <Modal
        className="modal-lg"
        isOpen={modal}
        toggle={() => {
          setModal(false);
        }}
      >
        <ModalBody>
          <Row>
            <Col>
              <p>
                <span style={{ fontWeight: '500' }}>User Actions:</span> Details what a customer
                does in each stage of the buying process.
              </p>
              <p>
                <span style={{ fontWeight: '500' }}>Pain Points:</span> Challenges faced by the
                customer at different stages of the customer journey that triggers negative emotions
                such as frustration, delays etc.{' '}
              </p>
              <p>
                <span style={{ fontWeight: '500' }}>Touch Points and Solutions:</span> Potential
                ways to improve the buying process so that your customer&apos;s experience improves.
              </p>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CustomerJourneyMapView;
