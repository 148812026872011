import classnames from 'classnames';
import { FC } from 'react';
import Image from 'react-graceful-image';
import 'react-medium-image-zoom/dist/styles.css';
import { grey } from '../../../assets/images';
import { translate } from '../../../state/utils/helper';
import A from './A';
import Zoom from './Zoom';

interface ImagePlaceholderProps {
  id?: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  style?: object;
  imageStyle?: object;
  className?: string;
  imageClassName?: string;
  src?: string;
  native?: boolean;
  zoom?: boolean;
  top?: boolean;
  bottom?: boolean;
  rounded?: boolean;
  authorUrl?: string;
  authorName?: string;
  onClick?: () => void;
}

const ImagePlaceholder: FC<ImagePlaceholderProps> = ({
  id = '',
  style = {},
  src = grey,
  imageStyle = {},
  width = 'auto',
  height = 'auto',
  className = '',
  imageClassName = '',
  alt = translate('layout.image'),
  zoom = false,
  native = false,
  top = false,
  bottom = false,
  rounded = false,
  authorUrl = '',
  authorName = '',
  onClick = () => undefined,
}) => {
  const authorCredits = (authorName: string, authorUrl: string) => (
    <>
      {translate('pageElements.ideaCard.authorPre')}{' '}
      <A rel="noopener noreferrer" href={authorUrl} target="_blank">
        {authorName}
      </A>{' '}
      {translate('pageElements.ideaCard.authorPost')}{' '}
      <A rel="noopener noreferrer" href="https://unsplash.com" target="_blank">
        Unsplash
      </A>
    </>
  );

  if (native) {
    return (
      <img
        className={classnames([
          {
            'card-img-top': top,
            'card-img-bottom': bottom,
            rounded,
          },
          className || imageClassName,
        ])}
        style={Object.keys(imageStyle).length !== 0 ? imageStyle : style}
        width={width}
        height={height}
        src={typeof src === 'string' ? src : ''}
        id={id.toString()}
        alt={alt}
      />
    );
  }
  return (
    <div
      style={{
        overflow: typeof src === 'object' ? 'hidden' : '',
        ...style,
      }}
      className={classnames('unsplash-div', 'position-relative', className)}
    >
      <Zoom zoomAllowed={zoom}>
        <Image
          onClick={onClick}
          className={classnames([
            {
              'card-img-top': top,
              'card-img-bottom': bottom,
              rounded,
            },
            imageClassName || className,
          ])}
          style={imageStyle}
          width={width}
          height={height}
          src={typeof src === 'string' ? src : ''}
          id={id.toString()}
          alt={alt}
        />
      </Zoom>
      {authorUrl && authorName && id ? (
        <>
          <h6 className="unsplash-author" data-placement="top" id={`unsplash-tooltip-${id}`}>
            <i className="fas fa-info-circle mr-1" />
            {authorCredits(authorName, authorUrl)}
          </h6>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default ImagePlaceholder;
