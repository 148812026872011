import { UserWorkspace, Workspace, WorkspaceResponseListItem } from '@app/@types/redux/workspace';
import { ROLES } from '@libs/constants/constants';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '@state/utils/Api';
import { PURGE } from 'redux-persist';

interface WorkspaceReducer {
  workspaces: WorkspaceResponseListItem[];
  totalWorkspaces: number;
  currentWorkspace: Workspace;
  userWorkspace: UserWorkspace;
}

const initialState: WorkspaceReducer = {
  workspaces: [],
  totalWorkspaces: 0,
  currentWorkspace: {
    id: '',
    type: '',
    attributes: {
      id: 0,
      name: '',
      image: '',
      workspaceType: '',
      userId: 0,
      createdAt: '',
      updatedAt: '',
      options: {
        description: '',
        cost: 0,
        overview: '',
        resources: [],
      },
      startDate: '',
      endDate: '',
      projectsCount: 0,
      owner: '',
      inTrash: false,
    },
  },
  userWorkspace: {
    id: 0,
    type: '',
    attributes: {
      id: 0,
      userId: 0,
      workspaceId: 0,
      userWorkspaceType: '',
      invoiceUrl: '',
      userWorkspaceRoles: [],
      report: '',
      certificateUrl: '',
      favorite: false,
    },
  },
};

export const fetchMyWorkspaces = createAsyncThunk(
  'workspace/fetchMyWorkspaces',
  async ({
    currentPage,
    sortOption,
    perPage,
    sortDirection,
  }: {
    currentPage: number;
    sortOption: string;
    perPage: number;
    sortDirection: string;
  }) => {
    const response = await Api.getMyWorkspaces(currentPage, perPage, sortDirection, sortOption);
    return response.data;
  }
);

export const fetchCurrentWorkspace = createAsyncThunk(
  'workspace/fetchCurrentWorkspace',
  async (workspaceId: string, { dispatch }) => {
    const response = await Api.getCurrentWorkspace(workspaceId);
    await dispatch(fetchUserWorkspace({ workspaceId }));
    return response.data.data;
  }
);

export const fetchUserWorkspace = createAsyncThunk(
  'workspace/fetchUserWorkspace',
  async ({ workspaceId }: { workspaceId: string }) => {
    const response = await Api.getUserWorkspace(workspaceId);
    return response.data.data;
  }
);

export function verifyUserWorkspace(workspaceId: string) {
  return Api.getUserWorkspace(workspaceId);
}

export const verifyWorkspacePayment = createAsyncThunk(
  'workspace/verifyWorkspacePayment',
  async ({
    workspaceId,
    successFn,
    errorFn,
  }: {
    workspaceId: string;
    successFn: () => void;
    errorFn: () => void;
  }) => {
    const response = await Api.postPaymentVerify(workspaceId);
    if (response.data.data.attributes.userWorkspaceRoles.includes(ROLES.ACCESS_APPROVED)) {
      successFn();
    } else {
      errorFn();
    }
    return response.data;
  }
);

export const verifyWorkspaceVoucher = createAsyncThunk(
  'workspace/verifyWorkspaceVoucher',
  async ({
    workspaceId,
    voucherCode,
    successCb,
  }: {
    workspaceId: string;
    voucherCode: string;
    successCb: () => void;
    errorCb: () => void;
  }) => {
    const response = await Api.postWorkspaceVoucherVerify(workspaceId, voucherCode);
    successCb();
    return response.data;
  }
);

const resetUserWorkspaceCase = (state: WorkspaceReducer) => {
  state.userWorkspace = initialState.userWorkspace;
};

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
    builder.addCase(fetchMyWorkspaces.fulfilled, (state, action) => {
      state.workspaces = action.payload.workspaces;
      state.totalWorkspaces = action.payload.totalWorkspaces;
    });
    builder.addCase(fetchCurrentWorkspace.fulfilled, (state, action) => {
      state.currentWorkspace = action.payload;
    });
    builder.addCase(fetchUserWorkspace.fulfilled, (state, action) => {
      state.userWorkspace = action.payload;
    });
    builder.addCase(fetchUserWorkspace.rejected, resetUserWorkspaceCase);
    builder.addCase(verifyWorkspacePayment.fulfilled, (state, action) => {
      state.userWorkspace = action.payload.data;
    });
    builder.addCase(verifyWorkspacePayment.rejected, resetUserWorkspaceCase);
    builder.addCase(verifyWorkspaceVoucher.fulfilled, (state, action) => {
      state.userWorkspace = action.payload.data;
    });
    builder.addCase(verifyWorkspaceVoucher.rejected, (state, action) => {
      state.userWorkspace = initialState.userWorkspace;
      action.meta.arg.errorCb();
    });
  },
});

export default workspaceSlice.reducer;
