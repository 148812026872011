/* eslint-disable no-restricted-syntax */
import { CustomerJourneyMapAnswer, PersonaEntryAnswer } from '@app/@types/redux/answer';
import { CustomerJourneyMapOptions } from '@app/@types/redux/pageElement';
import { nanoid } from '@reduxjs/toolkit';
import { getPageElementDisabledState, parseDependeeValue } from '@state/selectors/projects';
import { useEffect } from 'react';
import { PageElementProps } from '..';
import CustomerJourneyMapMatrix from './HelperComponents/CustomerJourneyMapView';

const CustomerJourneyMap: React.FC<PageElementProps> = (props) => {
  const { currentAnswer: propCurrentAnswer, getDependeeAnswerType, state } = props;
  const pageElement = props.pageElement;
  const answer = props.answer as (value: CustomerJourneyMapAnswer) => void;
  const { id: pageElementId, dependees } = pageElement.attributes;
  const options = pageElement.attributes.options as CustomerJourneyMapOptions;
  const disabled = getPageElementDisabledState(state, pageElementId);
  const currentAnswer = propCurrentAnswer as CustomerJourneyMapAnswer;

  const submissionQuestion = options.submissionQuestion;

  const dependentValue =
    dependees.length > 0 &&
    dependees[0].dependeeId &&
    (parseDependeeValue(
      getDependeeAnswerType(dependees[0].dependeeId),
      dependees[0].dependeeKey
    ) as PersonaEntryAnswer | (PersonaEntryAnswer & { elementType: string }));

  useEffect(() => {
    if (options.persona.title) {
      answer({ ...currentAnswer, persona: options.persona });
    } else if (
      dependentValue &&
      ((dependentValue as PersonaEntryAnswer & { elementType: string }).elementType ===
        'persona_entry' ||
        (typeof dependentValue === 'object' &&
          Object.keys(dependentValue).length !== 0 &&
          currentAnswer.persona?.id !== (dependentValue as PersonaEntryAnswer).id))
    ) {
      const title = String(
        dependentValue.inputFields.find((field) => field.fieldName === 'name')?.fieldValue
      );
      const what3 = String(
        dependentValue.longTextareaFields.find((field) => field.fieldName === 'what3')?.fieldValue
      );
      const age = String(
        dependentValue.inputFields.find((field) => field.fieldName === 'age')?.fieldValue
      );
      const location = String(
        dependentValue.inputFields.find((field) => field.fieldName === 'location')?.fieldValue
      );
      answer({
        ...currentAnswer,
        persona: {
          id: nanoid(),
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          imageUrl: dependentValue.image?.regular ?? '', // we have optional image here to cater legacy challenges till May 2024
          title,
          description: what3,
          age,
          location,
        },
      });
    }
  }, [options.persona.title]);

  return (
    <CustomerJourneyMapMatrix
      currentAnswer={currentAnswer}
      answer={answer}
      submissionQuestion={submissionQuestion}
      disabled={disabled}
    />
  );
};

export default CustomerJourneyMap;
