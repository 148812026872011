import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../components/UI/Layout';
import ResetPassword from '../../components/Users/Auth/ResetPassword';
import { redirectTo } from '../../routes/helpers';

const ResetPasswordContainer: FC = () => {
  const { token } = useParams();

  useEffect(() => {
    if (!token) {
      redirectTo('/');
    }
  }, [token]);

  if (!token) return null;
  return (
    <Layout isFullWidth bg>
      <ResetPassword token={token} />
    </Layout>
  );
};

export default ResetPasswordContainer;
