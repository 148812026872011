/* eslint-disable no-nested-ternary */
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { nanoid } from '@reduxjs/toolkit';
import {
  fetchDegree,
  fetchEvaluations,
  fetchUserMyBadges,
  fetchUserMyProjects,
} from '@state/redux/slices/user.slice';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import { getFullName } from '@state/selectors/users';
import { range } from 'lodash';
import { Helmet } from 'react-helmet';
import { AiOutlineLink } from 'react-icons/ai';
import { BiGridHorizontal, BiListUl, BiSort } from 'react-icons/bi';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { HiDotsHorizontal } from 'react-icons/hi';
import {
  ImSortAlphaAsc,
  ImSortAlphaDesc,
  ImSortNumbericDesc,
  ImSortNumericAsc,
} from 'react-icons/im';
import { IoMdClose } from 'react-icons/io';
import Skeleton from 'react-loading-skeleton';
import {
  Button,
  ButtonGroup,
  CardImg,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { profileCover } from '../../assets/images';
import badge1 from '../../assets/img/badges/1.png';
import badge2 from '../../assets/img/badges/2.png';
import badge3 from '../../assets/img/badges/3.png';
import badge4 from '../../assets/img/badges/4.png';
import badge5 from '../../assets/img/badges/5.png';
import mbtiImagePlaceholder from '../../assets/img/mbti/mbti_placeholder.mp4';
import badgeVideo1 from '../../assets/videos/badges/1.mp4';
import badgeVideo2 from '../../assets/videos/badges/2.mp4';
import badgeVideo3 from '../../assets/videos/badges/3.mp4';
import badgeVideo4 from '../../assets/videos/badges/4.mp4';
import badgeVideo5 from '../../assets/videos/badges/5.mp4';
import { TITLE } from '../../libs/constants/constants';
import { calculateAge } from '../../libs/helper';
import { i18nCText } from '../../libs/i18n/I18n';
import { redirectTo } from '../../routes/helpers';
import { fetchMe } from '../../state/redux/slices/auth.slice';
import { translate } from '../../state/utils/helper';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  ImagePlaceholder,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from '../UI/Html';
import Layout from '../UI/Layout';
import Footer from '../UI/Layout/Footer';
import MBTIReport from './MBTIReport';
import './MainProfile.scss';
import MyBadges from './MyBadges';
import ProfileHeader from './ProfileHeader';
import UserProjectItem from './UserProjectItem';

const badgesArray = [
  {
    name: 'Time Management',
    uid: 'time_management',
    description: 'Earned by the top 1% who quickly complete activities ahead of peers',
    img: badge1,
    video: badgeVideo1,
    earned: true,
  },
  {
    name: 'Karma Power',
    uid: 'karma_power',
    img: badge2,
    description: 'Awarded for providing constructive feedback beyond the required number of peers',
    video: badgeVideo2,
    earned: true,
  },
  {
    name: 'Pro Feedback',
    uid: 'pro_feedback',
    img: badge3,
    description: 'Awarded for providing detailed, actionable, and helpful peer feedback',
    video: badgeVideo3,
    earned: false,
  },
  {
    name: 'Idea Fluency',
    img: badge4,
    uid: 'idea_fluency',
    description: 'Recognizes generating more ideas than average during brainstorming',
    video: badgeVideo4,
    earned: false,
  },
  {
    name: 'Top Achiever',
    uid: 'top_achiever',
    img: badge5,
    description: 'Awarded for scoring 80% or higher in simulation challenges',
    video: badgeVideo5,
    earned: false,
  },
];

const sortInitialValue = {
  value: '',
  label: 'Sort',
  sortBy: '',
  sortDirection: '',
  icon: BiSort,
};

const SORT_BY = {
  WORKSPACE_NAME: 'project_name',
  PROGRESS: 'progress',
  LAST_ACTIVE: 'last_active',
};

const SORT_DIR = {
  ASC: 'asc',
  DESC: 'desc',
};

interface Badge {
  name: string;
  description: string;
  uid: string;
  img: string;
  video: string;
  earned: boolean;
  id: string;
  count: number;
}

interface Option {
  value: string;
  label: string;
  sortBy: string;
  sortDirection: string;
}
function MainProfile() {
  const [view, setView] = useState('grid');
  const [loadingUserBadges, setLoadingUserBadges] = useState(true);
  const [loadingUserProject, setLoadingUserProject] = useState(true);
  const [badges, setBadges] = useState<Badge[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedValue, setSelectedValue] = useState<Option>({
    value: 'sort-name-asc',
    label: '',
    sortBy: '',
    sortDirection: '',
  });
  const [sortValue, setSortValue] = useState(sortInitialValue.sortBy);
  const [sortDirection, setSortDirection] = useState(sortInitialValue.sortDirection);
  const [tab, setTab] = useState('workspaces');
  const [params, setParams] = useState({
    page: 1,
    perPage: 8,
  });

  const dispatch = useAppDispatch();

  const { userEducation } = useAppSelector((state) => state.users.user.attributes);

  useEffect(() => {
    dispatch(fetchMe())
      .then(() => {
        dispatch(fetchEvaluations())
          .then(() => {
            dispatch(
              fetchUserMyProjects({
                currentPage: currentPage,
                sortOption: sortValue,
                sortDirection: sortDirection,
              })
            )
              .then(() => {
                setLoadingUserProject(false);
              })
              .catch(() => {
                setLoadingUserProject(false);
                setLoadingUserBadges(false);
              });
            dispatch(fetchUserMyBadges())
              .unwrap()
              .then((res) => {
                const resultArray: Badge[] = [];
                badgesArray.forEach((item1) => {
                  const matchingItem = res.find((item2) => item1.uid === item2.badgeCode);
                  let badgeEarned = false;
                  if (matchingItem && matchingItem.count > 0) {
                    badgeEarned = true;
                  }
                  if (matchingItem) {
                    resultArray.push({
                      name: item1.name,
                      description: item1.description,
                      uid: matchingItem.badgeCode,
                      img: item1.img,
                      video: item1.video,
                      earned: badgeEarned,
                      id: matchingItem.badgeCode,
                      count: matchingItem.count,
                    });
                  }
                });
                setLoadingUserBadges(false);
                setBadges(resultArray);
              })
              .catch(() => {
                setLoadingUserProject(false);
                setLoadingUserBadges(false);
              });
          })
          .catch(() => {
            setLoadingUserProject(false);
            setLoadingUserBadges(false);
          });
      })
      .catch(() => {
        setLoadingUserProject(false);
        setLoadingUserBadges(false);
      });

    if (userEducation.degree) {
      void dispatch(fetchDegree(userEducation.degree));
    }
  }, []);

  function fetchProjects() {
    dispatch(
      fetchUserMyProjects({
        currentPage: currentPage,
        sortOption: sortValue,
        sortDirection: sortDirection,
      })
    )
      .then(() => {
        setLoadingUserProject(false);
      })
      .catch(() => {
        setLoadingUserProject(false);
      });
  }

  function handlePageChange(value: number) {
    setCurrentPage(value);
    setLoadingUserProject(true);
  }

  const sortFunction = (sortValue: string, sortDirection: string) => {
    setSortValue(sortValue);
    setSortDirection(sortDirection);
    setLoadingUserProject(true);
    fetchProjects();
  };

  const handleChange = (selectedOption: Option | undefined) => {
    if (selectedOption) {
      if (selectedOption.value === 'clear') {
        setSelectedValue({
          value: '',
          label: 'Sort',
          sortBy: '',
          sortDirection: '',
        });
        sortFunction('', '');
        return;
      }
      setSelectedValue(selectedOption);
      sortFunction(selectedOption.sortBy, selectedOption.sortDirection);
    }
  };

  const sortOptions = [
    {
      value: 'sort-name-asc',
      label: 'Name (A to Z)',
      sortBy: SORT_BY.WORKSPACE_NAME,
      sortDirection: SORT_DIR.ASC,
      icon: ImSortAlphaAsc,
    },
    {
      value: 'sort-name-desc',
      label: 'Name (Z to A)',
      sortBy: SORT_BY.WORKSPACE_NAME,
      sortDirection: SORT_DIR.DESC,
      icon: ImSortAlphaDesc,
    },
    {
      value: 'progress-asc',
      label: 'Progress (0 to 100)',
      sortBy: SORT_BY.PROGRESS,
      sortDirection: SORT_DIR.ASC,
      icon: ImSortNumericAsc,
    },
    {
      value: 'progress-desc',
      label: 'Progress (100 to 0)',
      sortBy: SORT_BY.PROGRESS,
      sortDirection: SORT_DIR.DESC,
      icon: ImSortNumbericDesc,
    },
    {
      value: 'last-active-asc',
      label: 'Last Active',
      sortBy: SORT_BY.LAST_ACTIVE,
      sortDirection: SORT_DIR.ASC,
      icon: ImSortNumericAsc,
    },
    {
      value: '',
      label: 'Clear',
      sortBy: '',
      sortDirection: '',
      icon: IoMdClose,
    },
  ];

  const {
    user: profileData,
    user: {
      attributes: { avatarUrl, birthdate, about, userExperience, socialMediaLinks },
    },
    projects,
    totalPages,
    myEvaluations,
  } = useAppSelector((state) => state.users);

  const fullName = useAppSelector((state) => getFullName(state));

  function loadingSkeleton() {
    return (
      <>
        {Array.from(Array(4), () => (
          <Col xs={12} sm={12} md={6} lg={3} xl={3} className="mb-5" key={nanoid()}>
            <Skeleton height={200} />
            <div style={{ height: 20 }} />
            <Skeleton count={1} height={20} />
            <div style={{ height: 20 }} />
            <Skeleton count={3} height={20} />
          </Col>
        ))}
      </>
    );
  }
  const { page } = params;
  const pages = totalPages;
  const previousPage = params.page - 1;
  const nextPage = params.page + 1;

  function renderPages(): JSX.Element[] {
    const currentPageIndex = page;
    return range(1, pages + 1).map((p, index) => {
      const pId: number = p;
      const element: JSX.Element = (
        <PaginationItem key={pId} className={page === pId ? 'active' : ''}>
          <PaginationLink
            onClick={(e: { preventDefault: () => void }) => {
              e.preventDefault();
              switchPage(pId);
            }}
          >
            {index + 1}
          </PaginationLink>
        </PaginationItem>
      );
      if (
        index === 0 ||
        pages - 1 === index ||
        (currentPageIndex - 3 < index && currentPageIndex + 3 > index)
      ) {
        return element;
      }
      if (currentPageIndex - 3 === index || currentPageIndex + 3 === index) {
        return (
          <PaginationItem key={pId} className="disabled">
            <PaginationLink
              onClick={(e: { preventDefault: () => void }) => {
                e.preventDefault();
              }}
            >
              ...
            </PaginationLink>
          </PaginationItem>
        );
      }
      return <></>; // Return an empty JSX element instead of an empty string
    });
  }

  const switchPage = (pageNumber: number) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setParams({
        perPage: 8,
        page: pageNumber,
      });
      handlePageChange(pageNumber);
    }
  };

  const mbtiEvaluationResult = myEvaluations?.find(
    (ele) => ele.attributes.evaluationCode === 'mbti'
  );

  const mbtiResultHuman = mbtiEvaluationResult?.attributes.results?.resultHuman;

  return (
    <>
      <Helmet>
        <title>{`${translate('layout.navbar.navlinks.myProfile')} | ${TITLE}`}</title>
      </Helmet>
      <Layout isFullWidth>
        <>
          <ProfileHeader profileData={profileData} />
          <Container className="mt--6" fluid>
            <Row>
              {/* Profile Card on left side start */}
              <Col className="order-xl-1 mt--9" xs={12} sm={12} md={4} lg={4} xl={3}>
                <Card className="card-profile">
                  <ImagePlaceholder
                    alt="..."
                    src={profileCover}
                    top
                    height={200}
                    style={{
                      objectFit: 'cover',
                    }}
                  />
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <ImagePlaceholder
                            className="rounded-circle"
                            width={140}
                            height={140}
                            src={avatarUrl}
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <CardHeader className="pt-8 pb-0 text-center border-0 pt-md-4 pb-md-4">
                    <div className="d-flex justify-content-between" />
                  </CardHeader>
                  <CardBody className="pt-0">
                    <div className="mt-5 mb-3 text-center">
                      <h5 className="mb-0 h2 bold_text">
                        {fullName}
                        <span className="font-weight-light">
                          {birthdate ? `, ${String(calculateAge(new Date(birthdate)))}` : null}
                        </span>
                      </h5>
                      <small className="h4 font-weight-light text-muted">
                        {userExperience.occupation}
                      </small>
                    </div>
                    <div className="mb-2 text-center">
                      {Array.isArray(socialMediaLinks) &&
                        socialMediaLinks.map((ele, key) => {
                          return (
                            <Button
                              key={key}
                              className="m-2 btn-icon-only rounded-circle"
                              color={ele.type}
                              onClick={() => window.open(ele.value, '_blank')}
                            >
                              {ele.type === 'LinkedIn' ? <i className="fab fa-linkedin"></i> : null}
                              {ele.type === 'Dribble' ? <i className="fab fa-dribbble"></i> : null}
                              {ele.type === 'Github' ? <i className="fab fa-github"></i> : null}
                              {ele.type === 'Behance' ? <i className="fab fa-behance"></i> : null}
                              {ele.type === 'Custom1' || ele.type === 'Custom2' ? (
                                <AiOutlineLink size={30} style={{ padding: '7px' }} />
                              ) : null}
                            </Button>
                          );
                        })}
                    </div>
                    <div className="pt-5 text-left">
                      <h5 className="about">About</h5>
                      <h4>{about ?? '-'} </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {/* Profile Card on left side end */}

              {/* CHallenge container on right */}
              <Col className="order-xl-2 mt-7" xs={12} sm={12} md={8} lg={8} xl={9}>
                <Row>
                  <Col xl="12" className="pr-4">
                    <Card>
                      <CardHeader>
                        <div className="position-relative justify-content-start d-flex">
                          <Nav
                            className="nav-fill overviewPageNav1 overviewPageNav"
                            id="tabs-icons-text"
                            role="tablist"
                          >
                            <NavItem>
                              <NavLink
                                aria-selected={tab === 'workspaces'}
                                className={classnames(
                                  'border-bottom navpill mb-sm-3 mb-md-0 font-weight-700',
                                  {
                                    active: tab === 'workspaces',
                                  }
                                )}
                                style={{
                                  fontSize: '14px',
                                }}
                                onClick={() => {
                                  setTab('workspaces');
                                }}
                                href="#"
                                role="tab"
                              >
                                {translate('profile.myProjects')}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                aria-selected={tab === 'myBadges'}
                                className={classnames(
                                  'border-bottom navpill mb-sm-3 mb-md-0 font-weight-700',
                                  {
                                    active: tab === 'myBadges',
                                  }
                                )}
                                style={{
                                  fontSize: '14px',
                                }}
                                onClick={() => {
                                  setTab('myBadges');
                                }}
                                href="#"
                                role="tab"
                              >
                                {translate('My Badges')}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                aria-selected={tab === 'mbtiTab'}
                                className={classnames(
                                  'border-bottom navpill mb-sm-3 mb-md-0 font-weight-700',
                                  {
                                    active: tab === 'mbtiTab',
                                  }
                                )}
                                style={{
                                  fontSize: '14px',
                                }}
                                onClick={() => {
                                  setTab('mbtiTab');
                                }}
                                href="#"
                                role="tab"
                              >
                                {translate('My MBTI')}
                              </NavLink>
                            </NavItem>
                          </Nav>

                          {/* Filter */}
                          {tab === 'workspaces' ? (
                            <div className="ml-auto d-flex ">
                              <div style={{ width: '220px', zIndex: 100000 }} className="mr-4">
                                <select
                                  className="custom-select custom-select-sm"
                                  value={selectedValue.value}
                                  onChange={(e) => {
                                    const selectedOption: Option | undefined = sortOptions.find(
                                      (option: { value: string }) => option.value === e.target.value
                                    );
                                    handleChange(selectedOption);
                                  }}
                                >
                                  {sortOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <ButtonGroup
                                style={{ height: '38px' }}
                                className="mr-1 btn-group-toggle"
                                data-toggle="buttons"
                              >
                                <Button
                                  data-placement="top"
                                  id="mainProfileMyProjectsGridViewTooltip_mujtf"
                                  className={classnames({
                                    active: view === 'grid',
                                  })}
                                  color="secondary"
                                  size="sm"
                                  onClick={() => {
                                    setView('grid');
                                  }}
                                >
                                  <input
                                    autoComplete="off"
                                    name="options"
                                    type="radio"
                                    defaultChecked={view === 'grid'}
                                  />
                                  <BiGridHorizontal size={25} className="ml-0" />
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  placement="top"
                                  target="mainProfileMyProjectsGridViewTooltip_mujtf"
                                >
                                  {i18nCText('Grid View')}
                                </UncontrolledTooltip>
                                <Button
                                  data-placement="top"
                                  id="mainProfileMyProjectsListViewTooltip_nxogd"
                                  className={classnames({
                                    active: view === 'list',
                                  })}
                                  size="sm"
                                  color="secondary"
                                  onClick={() => {
                                    setView('list');
                                  }}
                                >
                                  <input
                                    autoComplete="off"
                                    name="options"
                                    type="radio"
                                    defaultChecked={view === 'list'}
                                  />
                                  <BiListUl size={25} className="ml-0" />
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  placement="top"
                                  target="mainProfileMyProjectsListViewTooltip_nxogd"
                                >
                                  {i18nCText('List View')}
                                </UncontrolledTooltip>
                              </ButtonGroup>
                            </div>
                          ) : null}
                          {/* Filter end */}
                        </div>
                      </CardHeader>

                      {/* Workspace tab */}
                      {tab === 'workspaces' ? (
                        <>
                          {view === 'grid' ? (
                            <CardBody>
                              <Row>
                                {
                                  // eslint-disable-next-line no-nested-ternary
                                  loadingUserProject ? (
                                    loadingSkeleton()
                                  ) : projects.length > 0 ? (
                                    projects.map((item, index) => (
                                      <Col
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={3}
                                        xl={3}
                                        className="d-flex"
                                        key={item.id}
                                        style={{
                                          animationDelay: `${(0.1 + index * 0.1).toString()}s`,
                                          cursor: 'pointer',
                                          // zIndex: 1,
                                        }}
                                      >
                                        <Card className="w-100">
                                          <div className="project__item__imageContainer">
                                            <CardImg
                                              alt="..."
                                              src={item.attributes.project.image}
                                              className="project__item__image"
                                              top
                                              onClick={() => {
                                                redirectTo(
                                                  `/workspace/${String(
                                                    item.attributes.workspace.id
                                                  )}/challenge/${String(
                                                    item.attributes.project.id
                                                  )}/preview`
                                                );
                                              }}
                                            />
                                          </div>
                                          <CardBody className="pt-2 pb-3 pl-3 pr-2">
                                            <div className="d-flex">
                                              <div className="text_overlap">
                                                <small className="text-muted">
                                                  {item.attributes.workspace.name}
                                                </small>
                                              </div>
                                              <div className="ml-auto ">
                                                <UncontrolledDropdown>
                                                  <DropdownToggle
                                                    className="btn btn-sm"
                                                    style={{
                                                      backgroundColor: 'transparent',
                                                      margin: 0,
                                                      boxShadow: 'none',
                                                    }}
                                                  >
                                                    <HiDotsHorizontal />
                                                  </DropdownToggle>
                                                  <DropdownMenu>
                                                    {item.attributes.state === 'Finished' ? (
                                                      <DropdownItem
                                                        color="link"
                                                        href={`/workspace/${item.attributes.workspace.id.toString()}/challenge/${item.attributes.project.id.toString()}/report`}
                                                        style={{
                                                          zIndex: 9999,
                                                        }}
                                                      >
                                                        {translate(' View Report')}
                                                      </DropdownItem>
                                                    ) : (
                                                      <DropdownItem
                                                        color="link"
                                                        href={`/workspace/${item.attributes.workspace.id.toString()}/challenge/${item.attributes.project.id.toString()}/preview`}
                                                      >
                                                        {translate('userProjects.state.resume')}
                                                      </DropdownItem>
                                                    )}
                                                  </DropdownMenu>
                                                </UncontrolledDropdown>
                                              </div>
                                            </div>
                                            <h3 className="mb-0 h3 text-wrapping">
                                              {i18nCText(item.attributes.project.name)}
                                            </h3>
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    ))
                                  ) : (
                                    <p className="ml-3">There are no challenges</p>
                                  )
                                }
                              </Row>
                            </CardBody>
                          ) : (
                            <CardBody>
                              {loadingUserProject ? (
                                Array.from(Array(3), () => (
                                  <Row className="mb-5" key={nanoid()}>
                                    <Col className="col-auto">
                                      <Skeleton height={120} width={200} />
                                    </Col>
                                    <div className="mt-4 col ml--2">
                                      <h4 className="mb-1">
                                        <Skeleton height={20} />
                                      </h4>
                                      <h4 className="mb-1">
                                        <Skeleton height={20} />
                                      </h4>
                                    </div>
                                  </Row>
                                ))
                              ) : (
                                // <CardList
                                //   emptyMessage={translate('profile.emptyProjects')}
                                //   flush
                                //   className="list my--3"
                                //   listWrapper={ListGroup as React.ElementType}
                                //   itemComponent={UserProjectItem}
                                //   items={projects}
                                // />
                                <ListGroup className="list my--3">
                                  {projects.map((project) => (
                                    <UserProjectItem key={project.id} userProject={project} />
                                  ))}
                                </ListGroup>
                              )}
                            </CardBody>
                          )}
                          <CardFooter>
                            {/* <ProjectPagination className='pagination-style' /> */}
                            <div className="pagination-style">
                              <Pagination
                                className={classnames('pagination justify-content-end mb-0')}
                                listClassName="justify-content-end mb-0"
                              >
                                <PaginationItem className={previousPage ? '' : 'disabled'}>
                                  <PaginationLink
                                    onClick={(e: React.MouseEvent) => {
                                      e.preventDefault();
                                      switchPage(previousPage);
                                    }}
                                    tabIndex="-1"
                                  >
                                    <FaAngleLeft />
                                    <span className="sr-only">
                                      {translate('takeProject.previous')}
                                    </span>
                                  </PaginationLink>
                                </PaginationItem>
                                {renderPages()}
                                <PaginationItem className={nextPage ? '' : 'disabled'}>
                                  <PaginationLink
                                    href="#"
                                    onClick={(e: { preventDefault: () => void }) => {
                                      e.preventDefault();
                                      switchPage(nextPage);
                                    }}
                                  >
                                    <FaAngleRight />
                                    <span className="sr-only">{translate('takeProject.next')}</span>
                                  </PaginationLink>
                                </PaginationItem>
                              </Pagination>
                            </div>
                          </CardFooter>
                        </>
                      ) : tab === 'myBadges' ? (
                        <CardBody className="">
                          <Row>
                            {badges.map((ele, index) => {
                              return (
                                <Col
                                  xs={6}
                                  sm={6}
                                  md={4}
                                  lg={4}
                                  xl={2}
                                  className="p-0 fadeInUp"
                                  key={ele.id}
                                  style={{
                                    animationDelay: `${String(0.1 + index * 0.1)}s`,
                                    zIndex: 1,
                                  }}
                                >
                                  {loadingUserBadges ? null : <MyBadges badge={ele} />}
                                </Col>
                              );
                            })}
                          </Row>
                        </CardBody>
                      ) : (
                        <CardBody>
                          <Row>
                            <Col xl="12">
                              {mbtiResultHuman ? (
                                <MBTIReport myEvaluation={mbtiResultHuman} />
                              ) : (
                                <MbtiPlaceholderContent />
                              )}
                            </Col>
                          </Row>
                        </CardBody>
                      )}
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      </Layout>
      <Container fluid>
        <Container fluid>
          <Footer />
        </Container>
      </Container>
    </>
  );
}

function MbtiPlaceholderContent() {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (videoRef.current) {
      void videoRef.current.play();
    }
  }, []);

  return (
    <Row>
      <Col>
        <div
          style={{ cursor: 'pointer' }}
          role="presentation"
          onClick={(e) => {
            e.preventDefault();
            redirectTo('/evaluations/code/mbti');
          }}
        >
          <video className="w-100" ref={videoRef} src={mbtiImagePlaceholder}></video>
        </div>
      </Col>
    </Row>
  );
}

export default MainProfile;
