import { Evaluation } from '@app/@types/redux/project';
import * as actions from '@state/redux/slices/project.slice';
import classnames from 'classnames';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch, useAppSelector } from '../../../state/redux/store';
import {
  getCurrentEvaluationPage,
  pageBelongsToEvaluation,
} from '../../../state/selectors/projects';
import { Pagination, PaginationItem, PaginationLink } from '../../UI/Html';

interface EvaluationPaginationProps {
  className?: string;
  evaluation: Evaluation;
}

const EvaluationPagination: React.FC<EvaluationPaginationProps> = ({ className, evaluation }) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => getCurrentEvaluationPage(state.projects));
  const currentPageId = useSelector((state: RootState) => state.projects.currentEvaluationPageId);

  const switchEvaluationPage = (id: string) => {
    dispatch(actions.switchEvaluationPage(id));
  };

  useEffect(() => {
    updatePage();
  }, [evaluation, currentPageId]);

  const updatePage = () => {
    const { pages = [] } = evaluation.attributes;
    if (pages.length !== 0) {
      if (currentPageId && pageBelongsToEvaluation(currentPageId, evaluation)) {
        switchEvaluationPage(currentPageId);
      } else {
        switchEvaluationPage(pages[0].id);
      }
    }
  };

  const currentPageIdFn = () => {
    const { pages = [] } = evaluation.attributes;
    if (currentPage) {
      return parseInt(currentPage.id, 10);
    }
    if (pages[0]) {
      return parseInt(pages[0].id, 10);
    }
    return null;
  };

  const pages = () => {
    const { pages = [] } = evaluation.attributes;
    const cpId = currentPageIdFn();
    const currentPageIndex = pages.findIndex((page) => parseInt(page.id, 10) === cpId);
    return pages.map((p, index) => {
      const pId = parseInt(p.id, 10);
      const element = (
        <PaginationItem key={pId} className={cpId === pId ? 'active' : ''}>
          <PaginationLink
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              switchEvaluationPage(p.id);
            }}
          >
            {index + 1}
          </PaginationLink>
        </PaginationItem>
      );
      if (
        index === 0 ||
        pages.length - 1 === index ||
        (currentPageIndex - 3 < index && currentPageIndex + 3 > index)
      ) {
        return element;
      }
      if (currentPageIndex - 3 === index || currentPageIndex + 3 === index) {
        return (
          <PaginationItem key={pId} className="disabled">
            <PaginationLink
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
              }}
            >
              ...
            </PaginationLink>
          </PaginationItem>
        );
      }
      return '';
    });
  };

  return (
    <Pagination
      className={classnames('pagination justify-content-end mb-0', className)}
      listClassName="justify-content-end mb-0"
    >
      {pages()}
    </Pagination>
  );
};

export default EvaluationPagination;
