import email from '../../assets/images/icons/email.svg';
import user from '../../assets/images/icons/user.svg';
import { translate } from '../../state/utils/helper';

const errors = {
  ACTIVATION: {
    severity: 1,
    label: translate('errors.activation.label'),
    message: translate('errors.activation.message'),
    button: translate('errors.activation.button'),
    title: translate('errors.activation.title'),
    image: email,
  },
  PROFILE_INCOMPLETE: {
    severity: 1,
    label: translate('errors.profileIncomplete.label'),
    message: translate('errors.profileIncomplete.message'),
    button: translate('errors.profileIncomplete.button'),
    title: translate('errors.profileIncomplete.title'),
    image: user,
  },
};

export const DefaultError = 'Something Went Wrong!';

export default errors;
