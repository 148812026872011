import { useEffect, useState } from 'react';
import { Badge, Col, ListGroup, ListGroupItem, Row, UncontrolledCollapse } from 'reactstrap';
import ENFJ_IMG from '../../assets/img/mbti/ENFJ.png';
import ENFP_IMG from '../../assets/img/mbti/ENFP.png';
import ENTJ_IMG from '../../assets/img/mbti/ENTJ.png';
import ENTP_IMG from '../../assets/img/mbti/ENTP.png';
import ESFJ_IMG from '../../assets/img/mbti/ESFJ.png';
import ESFP_IMG from '../../assets/img/mbti/ESFP.png';
import ESTJ_IMG from '../../assets/img/mbti/ESTJ.png';
import ESTP_IMG from '../../assets/img/mbti/ESTP.png';
import INFJ_IMG from '../../assets/img/mbti/INFJ.png';
import INFP_IMG from '../../assets/img/mbti/INFP.png';
import INTJ_IMG from '../../assets/img/mbti/INTJ.png';
import INTP_IMG from '../../assets/img/mbti/INTP.png';
import ISFJ_IMG from '../../assets/img/mbti/ISFJ.png';
import ISFP_IMG from '../../assets/img/mbti/ISFP.png';
import ISTJ_IMG from '../../assets/img/mbti/ISTJ.png';
import ISTP_IMG from '../../assets/img/mbti/ISTP.png';

import { FiUserCheck, FiUserMinus, FiUserPlus } from 'react-icons/fi';
import { i18nCText } from '../../libs/i18n/I18n';
import getMBTIData from '../../libs/mbti/mbti-data';
import { ImagePlaceholder } from '../UI/Html';

interface MBTIReportProps {
  myEvaluation: string;
}

const MBTIReport: React.FC<MBTIReportProps> = ({ myEvaluation }) => {
  const mbtiData = getMBTIData(myEvaluation);

  const [mbtiImage, setMbtiImage] = useState<string | null>(null);
  const [colorBadge, setColorBadge] = useState('#7fced0');
  const [colorText, setColorText] = useState('#436882');

  const { id } = mbtiData ?? {};

  useEffect(() => {
    if (id && id === 'ENFJ') {
      setMbtiImage(ENFJ_IMG);
      setColorBadge('#c0d693');
      setColorText('#707057');
    } else if (id && id === 'ENFP') {
      setMbtiImage(ENFP_IMG);
      setColorBadge('#c0d693');
      setColorText('#707057');
    } else if (id && id === 'ENTJ') {
      setMbtiImage(ENTJ_IMG);
      setColorBadge('#868acd');
      setColorText('#4d4f80');
    } else if (id && id === 'ENTP') {
      setMbtiImage(ENTP_IMG);
      setColorBadge('#868acd');
      setColorText('#4d4f80');
    } else if (id && id === 'ESFJ') {
      setMbtiImage(ESFJ_IMG);
      setColorBadge('#7fced0');
      setColorText('#436882');
    } else if (id && id === 'ESFP') {
      setMbtiImage(ESFP_IMG);
      setColorBadge('#e8896b');
      setColorText('#965541');
    } else if (id && id === 'ESTJ') {
      setMbtiImage(ESTJ_IMG);
      setColorBadge('#7fced0');
      setColorText('#487189');
    } else if (id && id === 'ESTP') {
      setMbtiImage(ESTP_IMG);
      setColorBadge('#e8896b');
      setColorText('#965541');
    } else if (id && id === 'INFJ') {
      setMbtiImage(INFJ_IMG);
      setColorBadge('#c0d693');
      setColorText('#707057');
    } else if (id && id === 'INFP') {
      setMbtiImage(INFP_IMG);
      setColorBadge('#c0d693');
      setColorText('#707057');
    } else if (id && id === 'INTJ') {
      setMbtiImage(INTJ_IMG);
      setColorBadge('#868acd');
      setColorText('#4d4f80');
    } else if (id && id === 'INTP') {
      setMbtiImage(INTP_IMG);
      setColorBadge('#868acd');
      setColorText('#4d4f80');
    } else if (id && id === 'ISFJ') {
      setMbtiImage(ISFJ_IMG);
      setColorBadge('#7fced0');
      setColorText('#436882');
    } else if (id && id === 'ISTJ') {
      setMbtiImage(ISTJ_IMG);
      setColorBadge('#7fced0');
      setColorText('#436882');
    } else if (id && id === 'ISTP') {
      setMbtiImage(ISTP_IMG);
      setColorBadge('#e8896b');
      setColorText('#965541');
    } else {
      setMbtiImage(ISFP_IMG);
      setColorBadge('#e8896b');
      setColorText('#965541');
    }
  }, [id]);

  if (mbtiData === undefined) return null;

  const { long, keywords, keepDoing, couldDoBetter, careerPaths, educationPaths, footNote } =
    mbtiData;

  return (
    <div className="mbti-report fadeInUp">
      <Row>
        <Col>
          <header>
            {mbtiImage && (
              <ImagePlaceholder
                alt="..."
                src={mbtiImage}
                top
                height={300}
                style={{
                  objectFit: 'cover',
                }}
              />
            )}
          </header>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <p>{i18nCText(long)}</p>
          <div className="tags mt-4">
            {keywords.map((keyword: string) => {
              return (
                <span
                  key={keyword}
                  className="badge-tags"
                  color="warning"
                  style={{
                    color: colorBadge,
                    border: `${colorBadge} 2px solid`,
                  }}
                >
                  {i18nCText(keyword)}
                </span>
              );
            })}
          </div>
        </Col>
      </Row>
      <Row className="mb-4 mt-4">
        <Col>
          <ListGroup className="report-table">
            <ListGroupItem
              style={{ cursor: 'pointer', background: colorBadge }}
              className="d-flex align-items-center report-table-header"
              id="keepDoingSection"
            >
              <FiUserCheck color={colorText} />
              <h3 style={{ color: colorText }}>
                {i18nCText('Keep doing this, you are great at it')}
              </h3>
            </ListGroupItem>
            <UncontrolledCollapse toggler="#keepDoingSection">
              {keepDoing.map((keyword: string, index: number) => {
                return (
                  <ListGroupItem key={keyword} className="d-flex align-items-center">
                    <Badge style={{ background: colorBadge }} pill>
                      {index + 1}
                    </Badge>
                    <span className="ml-3">{i18nCText(keyword)}</span>
                  </ListGroupItem>
                );
              })}
            </UncontrolledCollapse>
          </ListGroup>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <ListGroup className="report-table">
            <ListGroupItem
              style={{ cursor: 'pointer', background: colorBadge }}
              className="d-flex align-items-center report-table-header"
              id="couldDoBetterSection"
            >
              <FiUserPlus color={colorText} />
              <h3 style={{ color: colorText }}>{i18nCText('Things you could do better')}</h3>
            </ListGroupItem>
            <UncontrolledCollapse toggler="#couldDoBetterSection">
              {couldDoBetter.map((keyword: string, index: number) => {
                return (
                  <ListGroupItem key={keyword} className="d-flex align-items-center">
                    <Badge style={{ background: colorBadge }} pill>
                      {index + 1}
                    </Badge>
                    <span className="ml-3">{i18nCText(keyword)}</span>
                  </ListGroupItem>
                );
              })}
            </UncontrolledCollapse>
          </ListGroup>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h1>{i18nCText('Career & Education Paths')}</h1>
          <p>{i18nCText(careerPaths.description)}</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <ListGroup className="report-table">
            <ListGroupItem
              style={{ cursor: 'pointer', background: colorBadge }}
              className="d-flex align-items-center report-table-header"
              id="careerPath"
            >
              <FiUserPlus color={colorText} />
              <h3 style={{ color: colorText }}>{i18nCText('Career Paths to Explore')}</h3>
            </ListGroupItem>
            <UncontrolledCollapse toggler="#careerPath">
              {careerPaths.positive.map((keyword: string, index: number) => {
                return (
                  <ListGroupItem key={keyword} className="d-flex align-items-center">
                    <Badge style={{ background: colorBadge }} pill>
                      {index + 1}
                    </Badge>
                    <span className="ml-3">{i18nCText(keyword)}</span>
                  </ListGroupItem>
                );
              })}
            </UncontrolledCollapse>
          </ListGroup>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <ListGroup className="report-table">
            <ListGroupItem
              style={{ cursor: 'pointer', background: colorBadge }}
              className="d-flex align-items-center report-table-header"
              id="careerPathAvoid"
            >
              <FiUserMinus color={colorText} />
              <h3 style={{ color: colorText }}>{i18nCText('Career Paths to Avoid')}</h3>
            </ListGroupItem>
            <UncontrolledCollapse toggler="#careerPathAvoid">
              {careerPaths.negative.map((keyword: string, index: number) => {
                return (
                  <ListGroupItem key={keyword} className="d-flex align-items-center">
                    <Badge style={{ background: colorBadge }} pill>
                      {index + 1}
                    </Badge>
                    <span className="ml-3">{i18nCText(keyword)}</span>
                  </ListGroupItem>
                );
              })}
            </UncontrolledCollapse>
          </ListGroup>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <ListGroup className="report-table">
            <ListGroupItem
              style={{ cursor: 'pointer', background: colorBadge }}
              className="d-flex align-items-center report-table-header"
              id="enjoyLearning"
            >
              <FiUserPlus color={colorText} />
              <h3 style={{ color: colorText }}>{i18nCText('What you’ll enjoy learning!')}</h3>
            </ListGroupItem>
            <UncontrolledCollapse toggler="#enjoyLearning">
              {educationPaths.positive.map((keyword: string, index: number) => {
                return (
                  <ListGroupItem key={keyword} className="d-flex align-items-center">
                    <Badge style={{ background: colorBadge }} pill>
                      {index + 1}
                    </Badge>
                    <span className="ml-3">{i18nCText(keyword)}</span>
                  </ListGroupItem>
                );
              })}{' '}
            </UncontrolledCollapse>
          </ListGroup>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <ListGroup className="report-table">
            <ListGroupItem
              style={{ cursor: 'pointer', background: colorBadge }}
              className="d-flex align-items-center report-table-header"
              id="notFun"
            >
              <FiUserPlus color={colorText} />
              <h3 style={{ color: colorText }}>
                {i18nCText('What might not be as much fun for you!')}
              </h3>
            </ListGroupItem>
            <UncontrolledCollapse toggler="#notFun">
              {educationPaths.negative.map((keyword: string, index: number) => {
                return (
                  <ListGroupItem key={keyword} className="d-flex align-items-center">
                    <Badge style={{ background: colorBadge }} pill>
                      {index + 1}
                    </Badge>
                    <span className="ml-3">{i18nCText(keyword)}</span>
                  </ListGroupItem>
                );
              })}{' '}
            </UncontrolledCollapse>
          </ListGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <h1>{i18nCText('Summary')}</h1>
          <p>{i18nCText(footNote)}</p>
        </Col>
      </Row>
    </div>
  );
};

export default MBTIReport;
