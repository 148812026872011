import ReactPlayer from 'react-player';
import { ConnectedProps, connect } from 'react-redux';
import quizCompletedImage from '../../assets/images/placeholders/quiz-complete.png';
import quizIncompleteImage from '../../assets/images/placeholders/quiz-incomplete.svg';
import { errorToast, redirectToPrevious } from '../../libs/helper';
import { i18nCText } from '../../libs/i18n/I18n';
import { redirectTo } from '../../routes/helpers';
import { updateLocation as updateLocationFn } from '../../state/redux/slices/auth.slice';
import { AppDispatch, RootState } from '../../state/redux/store';
import { translate } from '../../state/utils/helper';

import { LocationData } from '@app/@types/redux/auth';
import { Evaluation, UserEvaluation } from '@app/@types/redux/project';
import { DefaultError } from '@libs/constants/errors';
import { resetUserEvaluation } from '@state/redux/slices/project.slice';
import { Button, Card, CardBody, CardHeader, Col, Container, Div, H4, Row } from '../UI/Html';
import Footer from '../UI/Layout/Footer';
import ProjectHeader from './ProjectHeader';
import MBTIResult from './Report/MBTIResult';

const mapStateToProps = (state: RootState) => ({
  previousLocation: state.auth.previousLocation,
  userId: state.users.user.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateLocation: (previousLocation: LocationData) => dispatch(updateLocationFn(previousLocation)),
  resetQuiz: (id: string) => dispatch(resetUserEvaluation({ id })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type EvaluationResultsProps = PropsFromRedux & {
  results: UserEvaluation['attributes']['results'];
  evaluation: Evaluation;
  userId: string;
  previousLocation: LocationData;
  updateLocation: (previousLocation: LocationData) => void;
  resetQuiz: (id: string) => void;
};

function EvaluationResults(props: EvaluationResultsProps) {
  let content = null;
  if (props.evaluation.attributes.code === 'mbti') {
    content = <MBTIResult {...props} />;
  }
  const { evaluation, previousLocation, userId, resetQuiz } = props;

  const result = props.results?.result;
  const { name, id, pages, options } = evaluation.attributes;

  const { afterQuiz, creatorId } = options;
  if (!result) return null;

  let comment = '';
  let video = null;
  let retakeQuiz = false;
  let passed = false;
  if (Object.keys(afterQuiz).includes('bad') && Object.keys(afterQuiz).includes('threshold')) {
    const { bad, threshold } = afterQuiz;
    if (Number(result) / pages.length < threshold / 100) {
      passed = false;
      if (bad.selected.includes('retake')) {
        comment = 'Please retake the quiz';
        retakeQuiz = true;
      }
      if (bad.selected.includes('goBack')) {
        comment = 'Please continue.';
      }
      if (bad.selected.includes('video')) {
        if (bad.selected.includes('retake'))
          comment = 'Please watch the video and retake the quiz.';
        if (bad.selected.includes('goBack')) comment = 'Please watch the video and continue.';
        video = (
          <ReactPlayer
            url={bad.videoUrl}
            height="400px"
            volume={0.8}
            muted={false}
            playing={false}
            controls
          />
        );
      }
      comment = bad.message || comment;
    } else {
      passed = true;
      if (Object.keys(afterQuiz).includes('good')) {
        const { good } = afterQuiz;
        comment = 'Congratulations!';
        comment = good.message || comment;
      }
    }
  }
  return (
    <Container fluid className="px-0">
      <Row className="mx-0">
        <Col className="px-0 d-flex flex-grow flex-column">
          <ProjectHeader
            subtitle={i18nCText(name)}
            pageType="evaluationPage"
            evaluationId={id}
            resetQuizHidden={parseInt(String(creatorId), 10) !== parseInt(userId, 10)}
          />
          <Row className="justify-content-center mt--3 mx-0 pb-5">
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <Card>
                <CardHeader>
                  {/* This should be the current stage + page name */}
                  <div className="float-left">
                    <H4 className="d-inline text-uppercase font-weight-light">
                      {translate('takeEvaluation.results')}
                    </H4>
                  </div>
                </CardHeader>
                <CardBody>
                  {content ?? (
                    <div className="text-center">
                      <img
                        src={passed ? quizCompletedImage : quizIncompleteImage}
                        alt="Completed quiz"
                        style={{ height: '300px' }}
                      />
                      <div
                        className="text-center my-2"
                        style={{ fontSize: '2em', fontWeight: 600 }}
                      >
                        {passed ? 'Quiz completed' : 'You almost had it!'}
                      </div>
                      <span dangerouslySetInnerHTML={{ __html: comment }} />
                      <br />
                      {video ?? null}
                    </div>
                  )}
                </CardBody>
              </Card>
              {!retakeQuiz ? (
                <Button
                  className="float-right"
                  onClick={() => {
                    redirectToPrevious(previousLocation);
                  }}
                >
                  Continue
                </Button>
              ) : null}
              {retakeQuiz ? (
                <Button
                  className="float-right"
                  onClick={() => {
                    resetQuiz(id).catch(() => {
                      errorToast(DefaultError);
                    });
                  }}
                >
                  Retake Quiz
                </Button>
              ) : null}
              <Div className="my-6" />
              <Footer />
              <Div className="my-4 d-md-none" />
              <Div className="bottom-take-project-menu d-sm-block d-md-none">
                <Row>
                  {[
                    {
                      id: 1,
                      name: translate('layout.navbar.navlinks.evaluations'),
                      icon: 'fas fa-arrow-left',
                      onClick: () => {
                        redirectTo('/assessments');
                      },
                    },
                    {
                      id: 2,
                      name: translate('buttonLabels.backToHome'),
                      icon: 'fas fa-home',
                      onClick: () => {
                        redirectTo('/assessments');
                      },
                    },
                  ].map((item) => (
                    <Col key={`${item.id.toString()}-bottom-menu`} onClick={item.onClick}>
                      <i className={`${item.icon} mr-2`} />
                      {item.name}
                    </Col>
                  ))}
                </Row>
              </Div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationResults);
