import { AttachmentAnswer, EssayAnswer } from '@app/@types/redux/answer';
import { EssayOptions } from '@app/@types/redux/pageElement';
import { Button, Grid, TextField, makeStyles } from '@material-ui/core';
import { nanoid } from '@reduxjs/toolkit';
import { useAppSelector } from '@state/redux/store';
import { getPageElementDisabledState } from '@state/selectors/projects';
import { useEffect, useState } from 'react';
import { FiPlus, FiX } from 'react-icons/fi';
import { Col, Row } from 'reactstrap';
import { PageElementProps } from '..';
import i18n from '../../../../libs/i18n/I18n';
import WYSIWYGEditor from '../../../UI/Html/WYSIWYG';
import SubmissionAttachment from './HelperComponents/PrototypingMethods/SubmissionAttachment';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
  grid: {
    marginBottom: theme.spacing(2),
    textAlign: 'initial',
  },
  formControl: {
    width: '100%',
  },
  closeButton: {
    minWidth: 'auto',
    fontSize: '1rem',
    color: 'darkgray',
    zIndex: 999,
  },
}));

const Essay: React.FC<PageElementProps> = (props) => {
  const {
    answer,
    pageElement,
    pageElement: { id: pageElementId },
  } = props;
  const currentAnswer = props.currentAnswer as EssayAnswer;
  const { allowAttachment } = pageElement.attributes.options as EssayOptions;

  const classes = useStyles();
  const [quillValue, setQuillValue] = useState(currentAnswer.essay || '');
  const [references, setReferences] = useState(currentAnswer.references ?? []);
  useEffect(() => {
    answer({
      ...currentAnswer,
      essay: quillValue,
    });
  }, [answer, currentAnswer, quillValue]);

  useEffect(() => {
    answer({
      ...currentAnswer,
      references,
    });
  }, [answer, currentAnswer, references]);

  const disabled = useAppSelector((state) =>
    getPageElementDisabledState(state.projects, pageElementId)
  );
  const allowCopyPaste = useAppSelector((state) => state.projects.allowCopyPaste);

  return (
    <>
      <div className="pointer-events-none mt-4 cursor-not-allowed pt-3 opacity-50">
        <div className="mb-2 h-[600px]">
          <WYSIWYGEditor
            value={quillValue}
            onBlur={setQuillValue}
            placeholder="Eg. Share your thoughts here..."
            height="600px"
          />
        </div>
      </div>
      <div className="mb-4">
        <Grid item xs={12} sm={10} className={classes.grid}>
          <Grid item xs={12} className={classes.grid}>
            {references.map((reference, index) => {
              const textFieldElementId = `${pageElementId}-${String(index)}`;
              return (
                <div className="my-4 d-flex" key={textFieldElementId}>
                  <TextField
                    id={textFieldElementId}
                    name={textFieldElementId}
                    disabled={disabled}
                    dir={i18n.dir()}
                    className={classes.input}
                    label={`Reference ${String(index + 1)}`}
                    value={reference.value}
                    onChange={(event) => {
                      const tempAnswers = [...references];
                      tempAnswers[index].value = event.target.value;
                      setReferences(tempAnswers);
                    }}
                    onPaste={(e) => {
                      if (!allowCopyPaste) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <Button
                    disabled={disabled}
                    className={classes.closeButton}
                    onClick={() => {
                      const tempAnswers = [...references];
                      tempAnswers.splice(index, 1);
                      setReferences(tempAnswers);
                    }}
                  >
                    <FiX />
                  </Button>
                </div>
              );
            })}
          </Grid>
        </Grid>
        {!disabled && (
          <Grid item xs={12} className={classes.grid}>
            <Button
              variant="outlined"
              color="primary"
              disabled={disabled}
              startIcon={<FiPlus />}
              onClick={() => {
                const tempAnswers = [...references];
                tempAnswers.push({
                  value: '',
                  id: nanoid(),
                });
                setReferences(tempAnswers);
              }}
            >
              Add Reference
            </Button>
          </Grid>
        )}
      </div>
      <Row>
        <Col md={6}>
          {allowAttachment ? (
            <SubmissionAttachment
              currentAnswer={currentAnswer as AttachmentAnswer}
              answer={answer}
            />
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default Essay;
