import { FC, ReactNode } from 'react';
import { Row } from 'reactstrap';

interface CRowProps {
  className?: string;
  noGutters?: boolean;
  form?: boolean;
  children?: ReactNode;
  style?: React.CSSProperties;
}

const CRow: FC<CRowProps> = ({ className, noGutters, form, children, style }) => {
  return (
    <Row className={className} noGutters={noGutters} form={form} style={style}>
      {children}
    </Row>
  );
};

export default CRow;
