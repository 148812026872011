/* eslint-disable max-len */
import { getPageElementDisabledState, parseDependeeValue } from '@state/selectors/projects';
import { FaInfoCircle } from 'react-icons/fa';
import { Input, Row } from 'reactstrap';
import personaImage from '../../../../assets/images/placeholders/persona-image.png';
import { i18nCText } from '../../../../libs/i18n/I18n';

import { AddMediaImage, PersonaEntryAnswer } from '@app/@types/redux/answer';
import { PersonaEntryOptions } from '@app/@types/redux/pageElement';
import { useAppSelector } from '@state/redux/store';
import React, { useEffect, useState } from 'react';
import {
  LiaUserAltSolid,
  LiaUserAstronautSolid,
  LiaUserInjuredSolid,
  LiaUserNinjaSolid,
  LiaUserNurseSolid,
  LiaUserSecretSolid,
  LiaUserTieSolid,
} from 'react-icons/lia';
import { PageElementProps } from '..';
import { Col, H3, Label, UncontrolledTooltip } from '../../../UI/Html';
import { AddMedia } from './HelperComponents';

const PersonaEntry: React.FC<PageElementProps> = (props) => {
  const [addMediaModal, setAddMediaModal] = useState(false);
  const {
    pageElement,
    pageElement: {
      id,
      attributes: { dependees },
    },
    getDependeeAnswerType,
    currentAnswer,
    state,
  } = props;

  const answer = props.answer as (answer: PersonaEntryAnswer) => void;
  const allowCopyPaste = useAppSelector((state) => state.projects.allowCopyPaste);

  const { dependentData, inputFieldsVisible, textareaFieldsVisible, longTextareaFieldsVisible } =
    pageElement.attributes.options as PersonaEntryOptions;

  useEffect(() => {
    const dependentValue =
      dependees.length > 0 &&
      (parseDependeeValue(
        getDependeeAnswerType(dependees[0].dependeeId),
        dependees[0].dependeeKey
      ) as PersonaEntryAnswer);

    const tempAnswers = currentAnswer as PersonaEntryAnswer | undefined;

    const {
      index,
      title,
      image,
      description,
      inputFields,
      textareaFields,
      longTextareaFields,
      insight,
    } = (dependentValue || tempAnswers) ?? {};

    const entry = {
      index,
      title: title ?? '',
      image: image?.regular ? image : { regular: personaImage, thumbnail: personaImage },
      description: description ?? '',
      disabled: (dependentValue && dependentValue.disabled) || false,
      inputFields: inputFields?.length ? inputFields : [],
      textareaFields: textareaFields?.length ? textareaFields : [],
      longTextareaFields: longTextareaFields?.length ? longTextareaFields : [],
      insight: insight?.fieldValue
        ? insight
        : {
            fieldName: 'insight',
            fieldLabel: 'Insight',
            fieldValue: '',
          },
    };

    if (dependentData.id && !tempAnswers?.image?.regular) {
      answer(dependentData); // commentated for now for state persistance
    } else if (
      !(
        tempAnswers?.image?.regular &&
        tempAnswers.index === (dependentValue && dependentValue.index)
      )
    ) {
      answer(entry);
    }
  }, []);

  const answers = currentAnswer as PersonaEntryAnswer | undefined;

  const options = answers;

  const disabled = getPageElementDisabledState(state, id);

  return (
    <Row className="ml-0">
      <Col xs={12} sm={12} md={12} lg={4} xl={4}>
        <Row>
          <Col>
            <Row>
              <Col>
                <button
                  className="dropImageContainer mb-4 rounded mt-2"
                  type="button"
                  onClick={() => {
                    setAddMediaModal(true);
                  }}
                >
                  {options?.image?.regular ? (
                    <div className="persona_entry_image">
                      <img src={options.image.regular} className="object-fit addEntryImage" />
                      <div className="overlay">
                        <div className="text">Change Image</div>
                      </div>
                    </div>
                  ) : (
                    <div className="personaEntry__addImageContainer">
                      <IconRotator
                        icons={[
                          LiaUserAltSolid,
                          LiaUserNurseSolid,
                          LiaUserTieSolid,
                          LiaUserInjuredSolid,
                          LiaUserSecretSolid,
                          LiaUserNinjaSolid,
                          LiaUserAstronautSolid,
                        ]}
                      />
                      <h1>Click here to add a persona image</h1>
                    </div>
                  )}
                </button>
                <AddMedia
                  open={addMediaModal}
                  unsplash
                  onRequestClose={() => {
                    setAddMediaModal(false);
                  }}
                  preSearchedQuery="persona"
                  onSelect={(image) => {
                    const temp = getDependeeAnswerType(id).answer as PersonaEntryAnswer;
                    answer({
                      ...temp,
                      image: image as AddMediaImage,
                    });
                  }}
                  heading="Add Persona Image"
                  subheading="Search for a persona image from Unsplash or upload your own"
                />
              </Col>
            </Row>
            {options?.disabled ? (
              <>
                <H3 className="mb-2">{i18nCText(options.title || '')}</H3>
                <p className="text-sm mb-2">
                  {i18nCText(
                    options.inputFields.find((item) => item.fieldName === 'ageGender')
                      ?.fieldValue ?? ''
                  )}
                </p>
              </>
            ) : null}
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={8} xl={8}>
        {inputFieldsVisible &&
          options?.inputFields.map((item, index) => (
            <Row key={item.fieldName}>
              <Col xs={12} sm={12} md={12} lg={4} xl={4} className="pt-3">
                <Label className="form-control-label side-border-title" for={item.fieldName}>
                  {i18nCText(item.fieldLabel)}
                </Label>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                <Input
                  type="text"
                  placeholder={i18nCText(item.fieldLabel)}
                  value={item.fieldValue}
                  className="my-2"
                  id={item.fieldName}
                  name={item.fieldName}
                  disabled={disabled || options.disabled}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { inputFields } = options;
                    const temp = { ...inputFields[index] };
                    temp.fieldValue = e.target.value;
                    const tempInputFields = [...inputFields];
                    tempInputFields[index] = temp;
                    answer({
                      ...options,
                      inputFields: tempInputFields,
                    });
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { inputFields } = options;
                    const temp = { ...inputFields[index] };
                    temp.fieldValue = e.target.value.trim();
                    const tempInputFields = [...inputFields];
                    tempInputFields[index] = temp;
                    answer({
                      ...options,
                      inputFields: tempInputFields,
                    });
                  }}
                  onPaste={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (!allowCopyPaste) {
                      e.preventDefault();
                    }
                  }}
                />
              </Col>
            </Row>
          ))}
        <Row>
          {textareaFieldsVisible &&
            options?.textareaFields.map((item, index) => (
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className="my-3" key={item.fieldName}>
                <H3 className="text-info">{i18nCText(item.fieldLabel)}</H3>
                <Input
                  type="textarea"
                  placeholder={i18nCText(item.fieldLabel)}
                  value={item.fieldValue}
                  disabled={disabled || options.disabled}
                  className="my-2 textarea-border"
                  name={item.fieldName}
                  rows={5}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { textareaFields } = options;
                    const temp = { ...textareaFields[index] };
                    temp.fieldValue = e.target.value;
                    const tempTextareaFields = [...textareaFields];
                    tempTextareaFields[index] = temp;
                    answer({
                      ...options,
                      textareaFields: tempTextareaFields,
                    });
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { textareaFields } = options;
                    const temp = { ...textareaFields[index] };
                    temp.fieldValue = e.target.value.trim();
                    const tempTextareaFields = [...textareaFields];
                    tempTextareaFields[index] = temp;
                    answer({
                      ...options,
                      textareaFields: tempTextareaFields,
                    });
                  }}
                  onPaste={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (!allowCopyPaste) {
                      e.preventDefault();
                    }
                  }}
                />
              </Col>
            ))}
        </Row>
        <Row className="mt-4">
          {longTextareaFieldsVisible &&
            options?.longTextareaFields.map((item, index) => (
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3" key={item.fieldName}>
                <Label className="form-control-label side-border-title" for={item.fieldName}>
                  {i18nCText(item.fieldLabel)}
                  {item.fieldTooltip ? (
                    <>
                      <span
                        data-placement="right"
                        id={`persona-question-tooltip-${item.fieldName}`}
                        className="mx-2"
                        style={{
                          fontSize: '1rem',
                        }}
                      >
                        <FaInfoCircle />
                      </span>
                      <UncontrolledTooltip
                        delay={0}
                        placement="right"
                        target={`persona-question-tooltip-${item.fieldName}`}
                      >
                        {i18nCText(item.fieldTooltip)}
                      </UncontrolledTooltip>
                    </>
                  ) : null}
                </Label>
                <Input
                  type="textarea"
                  placeholder={i18nCText(item.fieldPlaceholder)}
                  value={item.fieldValue}
                  className="my-2 textarea-border"
                  id={item.fieldName}
                  name={item.fieldName}
                  disabled={disabled}
                  rows={3}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { longTextareaFields } = options;
                    const temp = { ...longTextareaFields[index] };
                    temp.fieldValue = e.target.value;
                    const tempLongTextareaFields = [...longTextareaFields];
                    tempLongTextareaFields[index] = temp;
                    answer({
                      ...options,
                      longTextareaFields: tempLongTextareaFields,
                    });
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { longTextareaFields } = options;
                    const temp = { ...longTextareaFields[index] };
                    temp.fieldValue = e.target.value.trim();
                    const tempLongTextareaFields = [...longTextareaFields];
                    tempLongTextareaFields[index] = temp;
                    answer({
                      ...options,
                      longTextareaFields: tempLongTextareaFields,
                    });
                  }}
                  onPaste={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (!allowCopyPaste) {
                      e.preventDefault();
                    }
                  }}
                />
              </Col>
            ))}
        </Row>
      </Col>
    </Row>
  );
};

export default PersonaEntry;

// Write a component that is given a list of icons, rotate through each icon after each 2 seconds among the given icons randomly

function IconRotator({ icons }: { icons: React.FC[] }) {
  const [currentIcon, setCurrentIcon] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIcon((prev) => (prev + 1) % icons.length);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <>{React.createElement(icons[currentIcon])}</>;
}
