export enum COOKIE_NAMES {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  REPORT_ABUSE = 'report_abuse',
  REDIRECT_TO_PATH = 'redirect_to',
}

export enum POPUP_FLAG {
  EXAMPLE = 'examplePopup',
  VIDEO = 'videoPopup',
  INSTRUCTION = 'instructionPopup',
  RESOURCES = 'resourcesPopup',
  NOTE = 'noteSidePane',
}

export enum ROLES {
  ADMIN = 'admin',
  USER = 'user',
  SUPER_ADMIN = 'super_admin',
  GUEST = 'guest',
  LEARNER = 'learner',
  OWNER = 'owner',
  INSTRUCTOR = 'instructor',
  ACCESS_APPROVED = 'access_approved',
  TESTER = 'tester',
}

export enum PAGE_ELEMENT {
  MESSAGE = 'message',
  MESSAGE_REVIEW = 'message_review',
  INPUT = 'input',
  TEXTAREA = 'textarea',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  FILL = 'fill',
  IMAGE = 'image',
  IMAGE_RADIO = 'image_radio',
  VIDEO = 'video',
  POSTIT = 'postit',
  POSTIT_CHECKBOX = 'postit_checkbox',
  CARD = 'card',
  CARD_FEEDBACK = 'card_feedback',
  IDEA_CARD = 'idea_card',
  SUBMISSION_CARD = 'submission_card',
  PERSONA_ENTRY = 'persona_entry',
  OTHERS = 'others',
  BUTTON = 'button',
  CANVAS = 'canvas',
  CUSTOMER_JOURNEY_MAP = 'customer_journey_map',
  PRIORITIZATION_MATRIX = 'prioritization_matrix',
  ESSAY = 'essay',
  AI_RESPONSE = 'ai_response',
  FINAL_CARD = 'final_card',
  FINAL_FEEDBACK = 'final_feedback',
  FINAL_REVIEW = 'final_review',
  HMW_RANDOM = 'hmw_random',
  MARVEL_IFRAME = 'marvel_iframe',
  BUSINESS_MODEL_CANVAS = 'business_model_canvas',
  INPUT_LIST = 'input_list',
  FIVE_WHYS = 'five_whys',
  SPACING = 'spacing',
  COLUMN_TEXTAREA = 'column_textarea',
  IMAGE_URL = 'image_url',
  USER_SELECT = 'user_select',
  DOWNLOAD_FILE = 'download_file',
  UPLOAD_FILE = 'upload_file',
  UPLOAD_IMAGE = 'upload_image',
  IMAGE_CHECKBOX = 'image_checkbox',
  AUTO_DRAW = 'autodraw',
  EXAMPLE = 'example',
  DROPDOWN = 'dropdown',
  IMAGE_MESSAGE = 'image_message',
  ADD_ENTRY = 'add_entry',
  CARD_REVIEW = 'card_review',
  CARD_FINAL_REVIEW = 'card_final_review',
  CARD_FINAL_FEEDBACK = 'card_final_feedback',
  EMAIL_ACTIVITY = 'email_activity',
  CHAT_ACTIVITY = 'chat_activity',
  NULL = '',
}

const ROOT_PATH = '/workspaces';

export const DEFAULT_PATH = ROOT_PATH;

export const PROJECTS_PATH = '/';

export const TITLE = 'Cartedo';

export const TEXTAREA_MAX_LENGTH = 100;

export const EMAIL_SUFFIX = '@tamu.edu';

export const CARTEDO_LINKS = {
  PRIVACY_POLICY: 'https://www.cartedo.com/privacy-policy',
  TERMS_AND_CONDITIONS: 'https://www.cartedo.com/terms-condition',
  CONTACT_US: 'https://cartedo.com/contact-us',
  ABOUT_US: 'https://cartedo.com/about-us',
  FAQ: 'https://cartedo.com/faq',
  BLOG: 'https://cartedo.com/blog',
  HELP: 'https://cartedo.com/help',
  SUPPORT: 'https://cartedo.com/support',
  CAREERS: 'https://cartedo.com/careers',
  PRESS: 'https://cartedo.com/press',
  PARTNERS: 'https://cartedo.com/partners',
  WEBSITE: 'https://cartedo.com',
};
