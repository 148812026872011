import env from '@config/env';
import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';
import { Editor as TinyMCEEditor } from 'tinymce';

const WYSIWYGEditor = (props: {
  value: string;
  height?: string | number;
  onBlur?: (text: string) => void;
  placeholder?: string;
}): JSX.Element => {
  const { value, height = 250, onBlur, placeholder = 'Eg. Share your thoughts here...' } = props;
  const editorRef = useRef<TinyMCEEditor | null>(null);

  return (
    <div
      style={{
        border: `1px solid #d1d5db`,
        borderRadius: '10px',
      }}
    >
      <Editor
        apiKey={env.TINY_MCE_API_KEY}
        onInit={(_evt, editor) => (editorRef.current = editor)}
        onBlur={() => {
          if (onBlur && editorRef.current) {
            onBlur(editorRef.current.getContent());
          }
        }}
        initialValue={typeof value === 'string' ? value : ''}
        init={{
          placeholder,
          menubar: false,
          height,
          statusbar: false,
          plugins:
            'preview searchreplace autolink save directionality visualchars image link media codesample table anchor insertdatetime advlist lists wordcount charmap quickbars emoticons fullscreen',
          toolbar:
            'fullscreen undo redo | fontfamily font_size_formats formatselect fontsize bold italic underline strikethrough | image | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | forecolor backcolor permanentpen formatpainter removeformat | pagebreak | charmap emoticons | preview print | media link anchor codesample | a11ycheck ltr rtl',
          content_css: '/assets/styles/WYSIWYGEditor.css',
          quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote',
          font_size_formats:
            '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt',
          body_class: 'WYSIWYGEditor',
          autosave_restore_when_empty: true,
          spellchecker_active: true,
          browser_spellcheck: true,
        }}
      />
    </div>
  );
};

export default WYSIWYGEditor;
