import { CanvasAnswer } from '@app/@types/redux/answer';
import { CanvasOptions } from '@app/@types/redux/pageElement';
import React from 'react';
import BMCanvas from './BMCanvas';
import CustomCanvas from './CustomCanvas';
import SWOTCanvas from './SWOTCanvas';

interface CanvasViewProps {
  options: CanvasOptions;
  elements: CanvasAnswer;
}

const CanvasView: React.FC<CanvasViewProps> = (props) => {
  const { options, elements } = props;
  const { canvasType, questions = [] } = options;

  switch (canvasType) {
    case 'custom_canvas_creator':
      return <CustomCanvas elements={elements} questions={questions} options={options} />;
    case 'bmc_canvas_creator':
      return <BMCanvas elements={elements} />;
    case 'swot_canvas_creator':
      return <SWOTCanvas elements={elements} />;

    /**
     * The following cases are commented out because they are not used in the project currently, we will add them back when needed
     *
    case "personal_canvas":
      return <PersonalCanvas elements={elements} />;
    case "job_fit_compass":
      return <JobFitCompass elements={elements} />;
    case "life_craft_canvas":
      return <LifeCraftCanvas elements={elements} />;
    case "dream_track_resume":
      return (
        <DreamTrackResume elements={elements} pageElement={props.pageElement} />
      );
    case "network_insight":
      return (
        <NetworkInsight elements={elements} pageElement={props.pageElement} />
      );
    case "action_plan_life_craft":
      return <ActionPlan elements={elements} pageElement={props.pageElement} />;
    case "gap_finder_masters":
      return <GapFinder elements={elements} pageElement={props.pageElement} />;
    */

    default:
      return '';
  }
};

export default CanvasView;
