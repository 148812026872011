import {
  AddMediaImage,
  SubmissionCardAdAnswer,
  SubmissionCardEmbedAnswer,
} from '@app/@types/redux/answer';
import { UserAnswerAnswer } from '@app/@types/redux/project';
import ReactPlayer from 'react-player';
import { Div } from '../../../../UI/Html';
import CarouselGallery from './CarouselGallery';
import MarvelApp from './MarvelApp';
import AdBuilder from './PrototypingMethods/AdBuilder';
import EmbedSubmission from './PrototypingMethods/EmbedSubmission';

interface SubmissionTypeProps {
  type: string;
  images: AddMediaImage[];
  url: string;
  currentAnswer: UserAnswerAnswer;
}
function SubmissionType({ type, images, url, currentAnswer }: SubmissionTypeProps) {
  if (['gallery', 'model'].includes(type)) {
    return (
      <Div className="submission-carousel-gallery">
        <CarouselGallery slides={images} />
      </Div>
    );
  }
  if (type === 'video') {
    if (ReactPlayer.canPlay(url)) {
      return <ReactPlayer url={url} width="100%" />;
    }
  }
  if (type === 'app') {
    return <MarvelApp url={url} />;
  }
  if (type === 'ad') {
    return <AdBuilder currentAnswer={currentAnswer as SubmissionCardAdAnswer} />;
  }
  if (type === 'embed') {
    return <EmbedSubmission currentAnswer={currentAnswer as SubmissionCardEmbedAnswer} />;
  }
  return '';
}

export default SubmissionType;
