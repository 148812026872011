import type { EmailActivityAnswer } from '@app/@types/redux/answer';
import type { SimulationEmail } from '@app/@types/redux/pageElement';
import { Alert, Col, H1, ImagePlaceholder, Row } from '@components/UI/Html';
import NoStyleDiv from '@components/UI/Html/NoStyleDiv';
import env from '@config/env';
import { getParsedEmailBody, successToast } from '@libs/helper';
import { useAppSelector } from '@state/redux/store';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import EllipsisText from 'react-ellipsis-text/lib/components/EllipsisText';
import {
  FaAsterisk,
  FaCalendarAlt,
  FaExclamationTriangle,
  FaImage,
  FaLink,
  FaLock,
  FaPaperclip,
  FaPen,
  FaSmile,
  FaTextHeight,
} from 'react-icons/fa';
import { HiDownload } from 'react-icons/hi';
import { LuFileSpreadsheet } from 'react-icons/lu';
import { Button } from 'reactstrap';
import { Editor as TinyMCEEditor } from 'tinymce';
import smile from '../../assets/images/icons/smile.svg';
import './EmailBox.scss';

interface EmailBoxProps {
  email: SimulationEmail;
  sendFn: () => void;
  setEmailAnswer: (value: EmailActivityAnswer) => void;
}
const EmailBox = ({ email, sendFn, setEmailAnswer }: EmailBoxProps) => {
  const [message, setMessage] = useState<string>('');
  const [sendModal, setSendModal] = useState(false);

  const name = useAppSelector((state) => state.users.user.attributes.name);
  const senderEmail = email.attributes.simulationEmail.attributes.senderEmail;
  const senderImage = email.attributes.simulationEmail.attributes.senderImage;
  const attachments = email.attributes.simulationEmail.attributes.attachments;
  const emailSentAt = email.attributes.sentAt;
  const subject = email.attributes.simulationEmail.attributes.subject;
  const body = getParsedEmailBody(name, email.attributes.simulationEmail.attributes.body);
  const editorRef = useRef<TinyMCEEditor | null>(null);

  function onBlur(value: string) {
    setMessage(value);
  }

  useEffect(() => {
    setEmailAnswer({
      body: message,
      to: senderEmail,
      subject: `RE: ${subject}`,
      simulationEmailId: email.attributes.simulationEmail.id,
      sentAt: emailSentAt ?? '',
    });
  }, [message]);

  return (
    <div className="email-chatbox-container">
      {/* <div className="email-chatbox-header">
        <h3>New Email</h3>
        <RxCross2 size={24} onClick={setIsSlideOverOpen} />
      </div> */}
      <div className="email-chatbox-body">
        <div className="email-form">
          <InputWithPills label="To" defaultPills={[senderEmail]} avatar={senderImage} />
          <EmailInput label="Re" value={subject} />

          <div className="email-field">
            {/* <textarea
              id="message"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              className="text-area-message-input"
              rows={12}
            /> */}
            <Editor
              apiKey={env.TINY_MCE_API_KEY}
              onInit={(_evt, editor) => (editorRef.current = editor)}
              onBlur={() => {
                if (editorRef.current) {
                  onBlur(editorRef.current.getContent());
                }
              }}
              initialValue={typeof message === 'string' ? message : ''}
              init={{
                placeholder: 'Eg. Share your thoughts here...',
                menubar: false,
                height: '600px',
                statusbar: false,
                plugins:
                  'preview searchreplace autolink save directionality visualchars image link media codesample table anchor insertdatetime advlist lists wordcount charmap quickbars emoticons fullscreen',
                toolbar:
                  'fullscreen undo redo | fontfamily font_size_formats formatselect fontsize bold italic underline strikethrough | image | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | forecolor backcolor permanentpen formatpainter removeformat | pagebreak | charmap emoticons | preview print | media link anchor codesample | a11ycheck ltr rtl',
                content_css: '/assets/styles/WYSIWYGEditor.css',
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote',
                font_size_formats:
                  '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt',
                body_class: 'WYSIWYGEditor',
                autosave_restore_when_empty: true,
                spellchecker_active: true,
                browser_spellcheck: true,
              }}
            />

            <div className="fixed-text">
              On {moment(emailSentAt).format('MMMM Do YYYY, h:mm:ss a')},
              <span style={{ margin: '0 6px', fontWeight: 700 }}>{senderEmail}</span>
              wrote:
              <br />
              <div className="pl-4 ml-2" style={{ borderLeft: 'solid 1px #d8d8d8' }}>
                <NoStyleDiv>{body}</NoStyleDiv>
              </div>
              {attachments.length && attachments.length > 0 ? (
                <div className="email__attachments">
                  <h5 className="email__attachments__title">Attachments ({attachments.length})</h5>
                  <div className="emailPage__attachments__container">
                    {attachments.map((attachment, index) => {
                      return (
                        <div key={index} className="email__attachments__item">
                          <div rel="noreferrer" className="email__attachments__link">
                            <div className="email__attachments__content">
                              <LuFileSpreadsheet className="email__attachments__icon" size={32} />
                              <EllipsisText
                                className="email_text"
                                text={attachment.name}
                                length={12}
                              />
                            </div>
                            <HiDownload
                              onClick={() => window.open(attachment.attachment, '_blank')}
                              className="email__attachments__icon--end"
                              size={26}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="email-toolbar">
        <Button
          color="primary"
          onClick={() => {
            setSendModal(true);
          }}
        >
          Send
        </Button>
        <div className="icon-group">
          <FaAsterisk className="icon" size={12} />
          <FaTextHeight className="icon" />
          <FaPaperclip className="icon" />
          <FaLink className="icon" />
          <FaSmile className="icon" />
          <FaExclamationTriangle className="icon" />
          <FaImage className="icon" />
          <FaLock className="icon" />
          <FaPen className="icon" />
          <FaCalendarAlt className="icon" />
        </div>
      </div>

      <Alert
        isOpen={sendModal}
        onRequestClose={() => {
          setSendModal(false);
        }}
        showCloseButton
      >
        <Row className="py-3">
          <Col>
            <ImagePlaceholder src={smile} height="60" width="60" native />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <H1 className="font-weight-500 text-dark">Are you sure you want to send this email?</H1>
          </Col>
        </Row>
        <Row className="pt-2 pb-3">
          <Col>
            <p className="px-3 font-weight-400 text-dark">
              Please review the email before sending it. Once you send it, you cannot undo it.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="px-1 mx-0 mb-2 text-center text-sm-right">
            <Button
              color="grey"
              onClick={() => {
                setSendModal(false);
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col className="px-1 mx-0 mb-2 text-center text-sm-left">
            <Button
              color="info"
              onClick={() => {
                setSendModal(false);
                successToast('Email Sent Successfully');
                sendFn();
              }}
            >
              Send
            </Button>
          </Col>
        </Row>
      </Alert>
    </div>
  );
};
export default EmailBox;
interface InputWithPillsProps {
  label: string;
  defaultPills: string[];
  avatar: string;
}
const InputWithPills = ({ label, defaultPills, avatar }: InputWithPillsProps) => {
  // const [inputValue, setInputValue] = useState('');
  // const [pills, setPills] = useState<string[]>(defaultPills ?? ['rohan.saroah@cartedo.com']);

  // const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === 'Enter' && inputValue.trim()) {
  //     setPills([...pills, inputValue.trim()]);
  //     setInputValue('');
  //   }
  // };

  return (
    <div className="input-with-pills">
      <div className="input-with-pills-container">
        <label className="fixed-label">{label}: </label>
        <div className="input-wrapper">
          {defaultPills.map((pill, index) => (
            <div className="email-input" key={index}>
              <img className="email-icon" alt="..." src={avatar} />
              <span className="email-text">{pill}</span>
            </div>
          ))}
          {/* <input
            type="text"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onKeyDown={handleKeyDown}
            placeholder=""
          /> */}
        </div>
      </div>
    </div>
  );
};

interface InputProps {
  label: string;
  value?: string;
}

const EmailInput: React.FC<InputProps> = ({ label, value }) => {
  const [inputValue, setInputValue] = useState(value);

  return (
    <div className="input-container">
      <label className="fixed-input-label">{label}: </label>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        className="simple-input"
        readOnly
      />
    </div>
  );
};
