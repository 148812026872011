import { AiOutlineLink } from 'react-icons/ai';
import { HiOutlineDocumentText, HiOutlineHome, HiPencil } from 'react-icons/hi';
import { IoArrowBack, IoArrowForward, IoRocketOutline } from 'react-icons/io5';
import { IconType } from 'react-icons/lib';
import { RiAddFill } from 'react-icons/ri';
import { Col, Row } from 'reactstrap';
import { PageElementProps } from '..';
import LinkSubmission from './HelperComponents/PrototypingMethods/LinkSubmission';

interface IconMapItem {
  id: string;
  icon: IconType;
}
const ICON_MAP: IconMapItem[] = [
  {
    id: 'RiAddFill',
    icon: RiAddFill,
  },
  {
    id: 'AiOutlineLink',
    icon: AiOutlineLink,
  },
  {
    id: 'HiOutlineHome',
    icon: HiOutlineHome,
  },
  {
    id: 'HiPencil',
    icon: HiPencil,
  },
  {
    id: 'IoRocketOutline',
    icon: IoRocketOutline,
  },
  {
    id: 'IoArrowBack',
    icon: IoArrowBack,
  },
  {
    id: 'IoArrowForward',
    icon: IoArrowForward,
  },
  {
    id: 'HiOutlineDocumentText',
    icon: HiOutlineDocumentText,
  },
];
interface ButtonOptions {
  buttonText: string;
  buttonLink: string;
  buttonIcon: string;
}
const ButtonE: React.FC<PageElementProps> = (props) => {
  const { pageElement } = props;
  const { buttonText, buttonLink, buttonIcon } = pageElement.attributes.options as ButtonOptions;

  function findIcon(id: string): IconType | undefined {
    return ICON_MAP.find((item) => item.id === id)?.icon;
  }
  const ButtonIcon = findIcon(buttonIcon) ?? RiAddFill;
  return (
    <>
      <Row>
        <Col className="mb-4"></Col>
      </Row>
      <Row>
        <Col>
          <LinkSubmission
            preview
            link={buttonLink}
            buttonIcon={<ButtonIcon style={{ fontSize: '1.5em' }} />}
            buttonText={buttonText}
          />
        </Col>
      </Row>
    </>
  );
};

export default ButtonE;
