import { Component } from 'react';
import TakeProject from '../../components/Projects/TakeProject';
import Layout from '../../components/UI/Layout';

class TakeProjectContainer extends Component {
  // shouldComponentUpdate(nextProps, nextState) {
  shouldComponentUpdate() {
    return false;
  }

  override render() {
    return (
      <Layout isFullWidth {...this.props}>
        <TakeProject />
      </Layout>
    );
  }
}

export default TakeProjectContainer;
