import { useState } from 'react';
import { Carousel, CarouselIndicators, CarouselItem } from 'reactstrap';
import Zoom from '../../../../UI/Html/Zoom';

import { AddMediaImage } from '@app/@types/redux/answer';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import UnsplashCredits from '../../../../UI/Html/UnsplashCredits';

interface CarouselGalleryProps {
  slides: AddMediaImage[];
  autoplay?: boolean;
  height: number;
}
function CarouselGallery({ slides, autoplay = true }: CarouselGalleryProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const items = slides.map((item) => {
    const { authorName, authorUrl, regular } = item;
    return (
      <CarouselItem
        onExiting={() => {
          setAnimating(true);
        }}
        onExited={() => {
          setAnimating(false);
        }}
        key={item.regular}
      >
        <div className="carouselGallery__item">
          <Zoom zoomAllowed>
            <img
              alt={authorName}
              src={regular}
              className="rounded"
              style={{
                width: '100%',
                objectFit: 'contain',
              }}
            />
          </Zoom>
          <UnsplashCredits authorName={authorName ?? ''} authorUrl={authorUrl ?? ''} />
        </div>
      </CarouselItem>
    );
  });
  if (slides.length) {
    return (
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        enableTouch
        interval={autoplay}
      >
        <CarouselIndicators items={slides} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {items}
        {items.length > 1 ? (
          <>
            <button
              type="button"
              onClick={previous}
              className="carouselGallery__navBtn carouselGallery__navBtn--prev"
            >
              <HiChevronLeft />
            </button>
            <button
              type="button"
              onClick={next}
              className="carouselGallery__navBtn carouselGallery__navBtn--next"
            >
              <HiChevronRight />
            </button>
          </>
        ) : null}
      </Carousel>
    );
  }
}

CarouselGallery.defaultProps = {
  slides: [],
  autoplay: false,
  height: 400,
};

export default CarouselGallery;
