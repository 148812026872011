import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { UserProject } from '@app/@types/redux/project';
import { WorkspaceResponseListItem } from '@app/@types/redux/workspace';
import { nanoid } from '@reduxjs/toolkit';
import { fetchMyWorkspaces } from '@state/redux/slices/workspace.slice';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { BsThreeDots } from 'react-icons/bs';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardImg,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { TITLE } from '../../libs/constants/constants';
import { i18nCText } from '../../libs/i18n/I18n';
import Api from '../../state/utils/Api';
import { translate } from '../../state/utils/helper';
import { Col, Container, Pagination, PaginationItem, PaginationLink, Row } from '../UI/Html';
import Layout from '../UI/Layout';
import Footer from '../UI/Layout/Footer';
import './Workspace.scss';
import WorkspaceCarousel from './WorkspaceCarousel';
import WorkspaceFilters from './WorkspaceFilters';
import WorkspaceItem from './WorkspaceItem';

function loadingSkeleton() {
  return (
    <>
      {Array.from(Array(6), () => (
        <Col xs={12} sm={12} md={6} lg={3} xl={2} className="mb-5" key={nanoid()}>
          <Skeleton height={150} />
          <div style={{ height: 20 }} />
          <Skeleton count={1} height={20} />
          <div style={{ height: 20 }} />
          <Skeleton count={3} height={20} />
        </Col>
      ))}
    </>
  );
}

const ListWorkspaces = () => {
  const [loading, setLoading] = useState(true);
  const [loadingActiveProjects, setLoadingActiveProjects] = useState(true);
  const [loadingFinishedProjects, setLoadingFinishedProjects] = useState(true);
  const [totalPagesActiveChallenge, setTotalPagesActiveChallenge] = useState(0);
  const [totalPageFinishedProjects, setTotalPageFinishedProjects] = useState(0);

  const [params, setParams] = useState({
    page: 1,
    perPage: 20,
  });

  const [paramsActiveProjects, setParamsActiveProjects] = useState({
    page: 1,
    perPage: 20,
  });

  const [paramsFinishedProjects, setParamsFinishedProjects] = useState({
    page: 1,
    perPage: 20,
  });

  const reduxWorkspaces = useAppSelector((state) => state.workspaces.workspaces);
  const sortByRedux = useAppSelector((state) => state.projects.filters.orderBy);
  const sortDirectionRedux = useAppSelector((state) => state.projects.filters.sortDirection);
  const reduxWorkspacesCount = useAppSelector((state) => state.workspaces.totalWorkspaces);
  const totalPages = Math.ceil(reduxWorkspacesCount / params.perPage);

  const dispatch = useAppDispatch();
  const [workspaces, setWorkspaces] = useState(reduxWorkspaces);
  const [activeProjects, setActiveProjects] = useState<UserProject[]>([]);
  const [finishedProjects, setFinishedProjects] = useState<UserProject[]>([]);
  const [tab, setTab] = useState('workspaces');

  useEffect(() => {
    setWorkspaces(reduxWorkspaces);
  }, [reduxWorkspaces]);

  const fetchProjects = useCallback(
    (pageNo?: number, sortBy = sortByRedux, sortDirection = sortDirectionRedux) => {
      setLoadingActiveProjects(true);
      const filter = { project_submission_deadline_lt: new Date() };
      Api.getMyProjects(
        pageNo ?? 1,
        paramsActiveProjects.perPage,
        sortDirection,
        sortBy,
        JSON.stringify(filter)
      )
        .then((res) => {
          setActiveProjects(res.data.data);
          setTotalPagesActiveChallenge(
            Math.round(res.data.meta.total / paramsActiveProjects.perPage)
          );
          setLoadingActiveProjects(false);
        })
        .catch(() => {
          setLoadingActiveProjects(false);
        });
    },
    [paramsActiveProjects.perPage, sortByRedux, sortDirectionRedux]
  );

  const fetchFinishedProjects = useCallback(
    (pageNo?: number, sortBy = sortByRedux, sortDirection = sortDirectionRedux) => {
      setLoadingFinishedProjects(true);
      Api.getMyProjects(
        pageNo ?? 1,
        paramsFinishedProjects.perPage,
        sortDirection,
        sortBy,
        JSON.stringify({ progress_eq: '1' })
      )
        .then((res) => {
          setFinishedProjects(res.data.data);
          setTotalPageFinishedProjects(
            Math.round(res.data.meta.total / paramsFinishedProjects.perPage)
          );
          setLoadingFinishedProjects(false);
        })
        .catch(() => {
          setLoadingFinishedProjects(false);
        });
    },
    [paramsFinishedProjects.perPage, sortByRedux, sortDirectionRedux]
  );

  useEffect(() => {
    if (tab === 'workspaces') {
      setWorkspaces(reduxWorkspaces);
    } else if (tab === 'activeProjects') {
      fetchProjects(1);
    } else if (tab === 'finishedProjects') {
      fetchFinishedProjects();
    }
  }, [fetchFinishedProjects, fetchProjects, reduxWorkspaces, tab]);

  const fetchWorkspace = useCallback(
    (pageNo?: number, sortBy = sortByRedux, sortDirection = sortDirectionRedux) => {
      setLoading(true);
      dispatch(
        fetchMyWorkspaces({
          currentPage: pageNo ?? 1,
          perPage: params.perPage,
          sortOption: sortBy,
          sortDirection,
        })
      )
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [dispatch, params.perPage, sortByRedux, sortDirectionRedux]
  );

  useEffect(() => {
    fetchWorkspace(params.page, sortByRedux, sortDirectionRedux);
  }, [fetchWorkspace, params.page, sortByRedux, sortDirectionRedux]);

  const handlePageChange = (v: number) => {
    fetchWorkspace(v);
  };

  useEffect(() => {
    if (tab === 'activeProjects') {
      fetchProjects(paramsActiveProjects.page, sortByRedux, sortDirectionRedux);
    } else if (tab === 'finishedProjects') {
      fetchFinishedProjects(paramsFinishedProjects.page, sortByRedux, sortDirectionRedux);
    } else {
      fetchWorkspace(params.page, sortByRedux, sortDirectionRedux);
    }
  }, [
    fetchFinishedProjects,
    fetchProjects,
    fetchWorkspace,
    params.page,
    paramsActiveProjects.page,
    paramsFinishedProjects.page,
    sortByRedux,
    sortDirectionRedux,
    tab,
  ]);

  return (
    <Layout>
      <Helmet>
        <title>{`${translate('layout.navbar.navlinks.projects')} | ${TITLE}`}</title>
      </Helmet>
      <div className="hero_banner_block">
        <Container fluid>
          <Row className="px-3">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <WorkspaceCarousel />
              <WorkspaceFilters tab={tab} setTab={setTab} />
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid>
        {tab === 'workspaces' ? (
          <Row className="mt-4 main-container">
            <Col>
              <Row className="m0-2">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Row>
                    {loading ? loadingSkeleton() : <WorkspaceList workspaces={workspaces} />}
                  </Row>
                </Col>
                <Col>
                  {totalPages > 0 && (
                    <FooterPagination
                      params={params}
                      setParams={setParams}
                      handlePageChange={handlePageChange}
                      totalPages={totalPages}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
        {tab === 'activeProjects' ? (
          <ProjectList
            projects={activeProjects}
            loading={loadingActiveProjects}
            totalPages={totalPagesActiveChallenge}
            params={paramsActiveProjects}
            setParams={setParamsActiveProjects}
            handlePageChange={fetchProjects}
          />
        ) : null}
        {tab === 'finishedProjects' ? (
          <ProjectList
            projects={finishedProjects}
            loading={loadingFinishedProjects}
            totalPages={totalPageFinishedProjects}
            params={paramsFinishedProjects}
            setParams={setParamsFinishedProjects}
            handlePageChange={fetchFinishedProjects}
          />
        ) : null}
        <Footer />
      </Container>
    </Layout>
  );
};

interface FooterProps {
  params: {
    page: number;
    perPage: number;
  };
  setParams: (params: { page: number; perPage: number }) => void;
  handlePageChange: (page: number) => void;
  totalPages: number;
}

const FooterPagination = (props: FooterProps) => {
  const { params, setParams, handlePageChange, totalPages } = props;
  const { page } = params;

  const pages = totalPages;

  function switchPage(pageNumber: number) {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setParams({
        ...params,
        page: pageNumber,
      });
      handlePageChange(pageNumber);
    }
  }

  const previousPage = params.page - 1;
  const nextPage = params.page + 1;

  function renderPages() {
    const currentPageIndex = page;
    return _.range(1, pages + 1).map((p, index) => {
      const pId = p;
      const element = (
        <PaginationItem key={pId} className={page === pId ? 'active' : ''}>
          <PaginationLink
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              switchPage(pId);
            }}
          >
            {index + 1}
          </PaginationLink>
        </PaginationItem>
      );
      if (
        index === 0 ||
        pages - 1 === index ||
        (currentPageIndex - 3 < index && currentPageIndex + 3 > index)
      ) {
        return element;
      }
      if (currentPageIndex - 3 === index || currentPageIndex + 3 === index) {
        return (
          <PaginationItem key={pId} className="disabled">
            <PaginationLink
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
              }}
            >
              ...
            </PaginationLink>
          </PaginationItem>
        );
      }
      return '';
    });
  }

  return (
    <div className="float-right pagination-style">
      <Pagination
        className={classnames('pagination justify-content-end mb-0')}
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem className={previousPage ? '' : 'disabled'}>
          <PaginationLink
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              switchPage(previousPage);
            }}
            tabIndex="-1"
          >
            <FaAngleLeft />
            <span className="sr-only">{translate('takeProject.previous')}</span>
          </PaginationLink>
        </PaginationItem>
        {renderPages()}
        <PaginationItem className={nextPage ? '' : 'disabled'}>
          <PaginationLink
            href="#"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              switchPage(nextPage);
            }}
          >
            <FaAngleRight />
            <span className="sr-only">{translate('takeProject.next')}</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>
  );
};

function WorkspaceList(props: { workspaces: WorkspaceResponseListItem[] }) {
  const { workspaces } = props;
  if (workspaces.length === 0) {
    return <p className="ml-3">There are no default workspaces</p>;
  }
  return workspaces.map((item, index) => (
    <Col
      xs={12}
      sm={12}
      md={6}
      lg={3}
      xl={2}
      className="d-flex "
      key={item.workspace.id}
      style={{
        cursor: 'pointer',
        animationDelay: `${(0.1 + Number(index) * 0.1).toString()}s`,
      }}
    >
      <WorkspaceItem data={item.workspace} ownerName={item.owner} />
    </Col>
  ));
}

function ProjectList(props: {
  projects: UserProject[];
  loading: boolean;
  totalPages: number;
  params: {
    page: number;
    perPage: number;
  };
  setParams: (params: { page: number; perPage: number }) => void;
  handlePageChange: (page: number) => void;
}) {
  const navigate = useNavigate();
  const { projects, loading, totalPages, params, setParams, handlePageChange } = props;
  if (loading) {
    return loadingSkeleton();
  }
  if (projects.length === 0) {
    return <p className="ml-3">There are no projects right now</p>;
  }
  return (
    <Row className="mt-4 main-container">
      <Col>
        <Row className="m0-2">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row>
              <Col>
                <div className="d-flex align-items-center justify-content-between"></div>
              </Col>
            </Row>
            <Row>
              {projects.map((item, index) => (
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  xl={2}
                  className="d-flex "
                  key={item.id}
                  style={{
                    cursor: 'pointer',
                    animationDelay: `${(0.1 + Number(index) * 0.1).toString()}s`,
                  }}
                >
                  <Card className="w-100">
                    <div className="project__item__imageContainer">
                      <CardImg
                        alt="..."
                        src={item.attributes.project.image}
                        className="project__item__image"
                        top
                        onClick={() => {
                          navigate(
                            `/workspace/${
                              item.attributes.workspace.id
                            }/challenge/${item.attributes.project.id.toString()}/preview`
                          );
                        }}
                      />
                    </div>
                    <CardBody className="pt-2 pb-3 pl-3 pr-2">
                      <div className="d-flex">
                        <div className="text_overlap">
                          {' '}
                          <small className="text-muted">{item.attributes.workspace.name}</small>
                        </div>
                        <div className="ml-auto ">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn btn-sm"
                              style={{
                                backgroundColor: 'transparent',
                                margin: 0,
                                padding: '0px 8px',
                                boxShadow: 'none',
                                border: 'none',
                              }}
                            >
                              <BsThreeDots size={15} />
                            </DropdownToggle>
                            <DropdownMenu>
                              {item.attributes.state === 'Finished' ? (
                                <DropdownItem
                                  color="link"
                                  href={`/workspace/${
                                    item.attributes.workspace.id
                                  }/challenge/${item.attributes.project.id.toString()}/report`}
                                >
                                  {translate(' View Report')}
                                </DropdownItem>
                              ) : (
                                <DropdownItem
                                  color="link"
                                  href={`/workspace/${
                                    item.attributes.workspace.id
                                  }/challenge/${item.attributes.project.id.toString()}/preview`}
                                >
                                  {translate('userProjects.state.resume')}
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </div>
                      <h3 className="mb-0 h3 text-wrapping">
                        {i18nCText(item.attributes.project.name)}
                      </h3>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
          <Col>
            {totalPages > 0 && (
              <FooterPagination
                params={params}
                setParams={setParams}
                handlePageChange={handlePageChange}
                totalPages={totalPages}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ListWorkspaces;
