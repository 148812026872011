import classnames from 'classnames';

import { getPageElementDisabledState, parseDependeeValue } from '@state/selectors/projects';
import replace from 'react-string-replace';
import { Input, Row } from 'reactstrap';
import { FILL_BLANK_REGEX } from '../../../../libs/constants/regex';
import { i18nCText } from '../../../../libs/i18n/I18n';

import { FillAnswer } from '@app/@types/redux/answer';
import { FillOptions } from '@app/@types/redux/pageElement';
import { useAppSelector } from '@state/redux/store';
import { ReactNode, useState } from 'react';
import { PageElementProps } from '..';
import { Col } from '../../../UI/Html';
import './FillTheBlank.scss';

const reg = FILL_BLANK_REGEX;

const FillTheBlank: React.FC<PageElementProps> = (props) => {
  const {
    getDependeeAnswerType,
    pageElement,
    pageElement: {
      id,
      attributes: { dependees },
    },
    answer,
  } = props;

  const currentAnswer = props.currentAnswer as FillAnswer;
  const { value: text, keys } = pageElement.attributes.options as FillOptions;

  const [textValues, setTextValues] = useState<Record<string, string>>(
    typeof currentAnswer === 'object' ? currentAnswer : {}
  );
  const disabled = useAppSelector((state) => getPageElementDisabledState(state.projects, id));

  let dependentValue = parseDependeeValue(
    getDependeeAnswerType(dependees[0].dependeeId),
    dependees[0].dependeeKey
  ) as FillAnswer | { title: string } | string;

  const fillText = text;
  if (!!Object.keys(currentAnswer).length && typeof currentAnswer === 'object') {
    dependentValue = (dependentValue as { title: string }).title;
  }
  const matches: string[] = [];
  replace(fillText, reg, (match) => {
    matches.push(match);
    return null;
  });
  let i = 0;
  textValues.fillText = fillText;
  const output = replace(i18nCText(fillText), reg, (matchT) => {
    const match = matches[i];
    i += 1;
    return (
      <span key={`${id}${match}`}>
        {match === 'dependent' ? (
          String(textValues[match]) || String(dependentValue)
        ) : (
          <Input
            autosize
            className={classnames(['fillTheBlank__input'])}
            value={match === 'dependent' ? textValues[match] || dependentValue : textValues[match]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTextValues((prevTextValues) => ({
                ...prevTextValues,
                [match]: e.target.value,
              }));
              answer(textValues);
            }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTextValues((prevTextValues) => ({
                ...prevTextValues,
                [match]: e.target.value.trim(),
              }));
              answer(textValues);
            }}
            maxLength={200}
            placeholder={keys[matchT].placeholder ? keys[matchT].placeholder : matchT}
            disabled={disabled}
          />
        )}
      </span>
    );
  }) as ReactNode[] | string;
  return (
    <Row className="fillTheBlank__text">
      <Col>{output}</Col>
    </Row>
  );
};

export default FillTheBlank;
