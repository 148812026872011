/* eslint-disable max-len */
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { ImageRadioStyle1Answer } from '@app/@types/redux/answer';
import { ImageRadioOptions } from '@app/@types/redux/pageElement';
import { useState } from 'react';
import { HiOutlineInformationCircle, HiOutlineLocationMarker } from 'react-icons/hi';
import { Input } from 'reactstrap';
import { i18nCText } from '../../../../../libs/i18n/I18n';
import { translate } from '../../../../../state/utils/helper';
import { Alert } from '../../../../UI/Html';
import './Style1.scss';
const useStyles = makeStyles(() => ({
  readMoreButton: {
    minWidth: 'auto',
    position: 'absolute',
    fontSize: '1rem',
    color: 'white',
    right: '1rem',
    fontWeight: 'bold',
    padding: '0.4rem 1rem',
    borderRadius: '0.5rem',
    top: '1rem',
    zIndex: 999,
    textTransform: 'none',
    '& > span > svg': {
      '-webkit-filter': 'drop-shadow(0px 0px 8px rgb(0,0,0))',
      filter: 'drop-shadow(0px 0px 8px rgb(0,0,0))',
      marginRight: '8px',
      fontSize: '1.2rem',
    },
    backgroundColor: 'rgba(0,0,0,0.7)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,1)',
    },
  },
}));

interface PersonaProps {
  persona: ImageRadioOptions['values'][0];
  pageElementId: string;
  answers: ImageRadioStyle1Answer;
  answer: (value: ImageRadioStyle1Answer) => void;
  disabled: boolean;
}

function Persona({ persona, pageElementId, answers, answer, disabled }: PersonaProps) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Alert
        isOpen={openModal}
        onRequestClose={() => {
          setOpenModal(false);
        }}
        showCloseButton
      >
        <div className="imageRadioButtons__personaStyle1_modal">
          <div className="imageRadioButtons__personaStyle1_modal_information">
            <img
              src={persona.imageUrl}
              className="imageRadioButtons__personaStyle1_modal_image"
              alt=""
            />
            <div className="imageRadioButtons__personaStyle1_modal_informationText">
              <h1>{i18nCText(persona.title)}</h1>
              <h3>
                <HiOutlineLocationMarker />
                <span>{i18nCText(persona.location)}</span>
              </h3>
            </div>
          </div>
          {/* {persona?.content?.map?.((item) => { */}
          {/* return ( */}
          {/* <h3 className='imageRadioButtons__personaStyle1_modal_title'>
                  {i18nCText(item?.title)}
                </h3> */}

          <p className="imageRadioButtons__personaStyle1_modal_description">
            <span
              dangerouslySetInnerHTML={{
                __html: i18nCText(persona.description),
              }}
            />
          </p>
          {/* ) */}
          {/* })} */}
        </div>
      </Alert>
      <div className="custom-control custom-checkbox mb-3 imageRadioButtons__personaStyle1">
        <Input
          className="custom-control-input"
          id={`checkbox_${pageElementId}_${persona.id.toString()}`}
          type="radio"
          value={persona.id}
          checked={persona.id}
          disabled={disabled}
          onClick={() => {
            const navButton = document.getElementsByClassName('takeProject__navButtons');
            navButton[0].scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
            if (!disabled) {
              answer({
                ...persona,
                id: persona.id.toString(),
              });
            }
          }}
        />
        <label
          className="custom-control-label imageRadioButtons__personaStyle1_label"
          htmlFor={`checkbox_${pageElementId}_${persona.id.toString()}`}
        >
          <div
            className={classnames('imageRadioButtons__personaStyle1_container', {
              imageRadioButtons__personaStyle1_selected: persona.id === answers.id,
              imageRadioButtons__personaStyle1_notSelected:
                Boolean(answers.id) && persona.id !== answers.id,
            })}
            style={{
              background: `url(${persona.imageUrl}) center center`,
              backgroundSize: 'cover',
            }}
          >
            <div
              className="imageRadioButtons__personaStyle1_overlay"
              style={{
                backgroundImage: `linear-gradient(rgba(${persona.color ?? '0, 0, 0'}, 0), rgba(${
                  persona.color ?? '0, 0, 0'
                }, 1))`,
              }}
            />
            <Button
              className={classnames(classes.readMoreButton)}
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <HiOutlineInformationCircle />
              {translate('buttonLabels.readMore')}
            </Button>
            <div className="imageRadioButtons__personaStyle1_textContainer">
              <h3 className="imageRadioButtons__personaStyle1_title">{i18nCText(persona.title)}</h3>
              <h6 className="imageRadioButtons__personaStyle1_description">
                <HiOutlineLocationMarker />
                <span>{i18nCText(persona.location)}</span>
              </h6>
            </div>
          </div>
        </label>
      </div>
    </>
  );
}

interface Style1Props {
  personas: ImageRadioOptions['values'];
  pageElementId: string;
  answers: ImageRadioStyle1Answer;
  answer: (value: ImageRadioStyle1Answer) => void;
  disabled: boolean;
}

function Style1({ personas, pageElementId, answers, answer, disabled }: Style1Props) {
  return (
    <div className="mt-3 imageRadioButtons__optionsContainer">
      {personas.map((persona) => {
        return (
          <Persona
            key={persona.id}
            persona={persona}
            pageElementId={pageElementId}
            answers={answers}
            answer={answer}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
}

export default Style1;
