import classnames from 'classnames';
import { ChangeEvent, FC, FocusEvent } from 'react';
import AutosizeInput from 'react-input-autosize';
import { Input } from 'reactstrap';
import I18n from '../../../libs/i18n/I18n';

interface CInputProps {
  type?: string;
  placeholder?: string;
  autosize?: boolean;
  onChange?: (value: string) => void;
  rawOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  isEmail?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (value: string) => void;
  value?: string | number;
  rows?: number;
  className?: string;
  maxLength?: number;
  disabled?: boolean;
  onPaste?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CInput: FC<CInputProps> = ({
  type = 'text',
  placeholder = I18n.t('defaults.inputPlaceholder'),
  autosize = false,
  onChange = () => undefined,
  rawOnChange = () => undefined,
  isEmail = false,
  onFocus = () => undefined,
  onBlur = () => undefined,
  value = '',
  rows = 0,
  className = '',
  ...rest
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    rawOnChange(e);
    isEmail ? onChange(e.target.value.replace(/\s/g, '').toLowerCase()) : onChange(e.target.value);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    onBlur(e.target.value);
  };

  if (autosize) {
    return (
      <AutosizeInput
        {...rest}
        className={className}
        placeholder={placeholder}
        type={type}
        onFocus={onFocus}
        onBlur={handleBlur}
        value={value || ''}
        onChange={handleChange}
        onPaste={rest.onPaste}
      />
    );
  }

  return (
    <Input
      {...rest}
      rows={rows}
      className={classnames(className)}
      placeholder={placeholder}
      type={type}
      onFocus={onFocus}
      onBlur={handleBlur}
      value={value || ''}
      onChange={handleChange}
      onPaste={rest.onPaste}
    />
  );
};

export default CInput;
