import { VideoOptions } from '@app/@types/redux/pageElement';
import ReactPlayer from 'react-player';
import { Col } from 'reactstrap';
import { PageElementProps } from '..';
import { Row } from '../../../UI/Html';

const Video: React.FC<PageElementProps> = (props) => {
  const { pageElement } = props;
  const { value } = pageElement.attributes.options as VideoOptions;
  return (
    <Row className="justify-content-center mx-0 m-4">
      <Col>
        <div style={{ width: '100%', height: '100%' }}>
          <ReactPlayer
            url={value}
            volume={0.8}
            muted={false}
            playing={false}
            controls
            width="100%"
            height={window.innerHeight * 0.6}
            config={{
              vimeo: {
                playerOptions: {
                  title: false,
                  byline: false,
                  portrait: false,
                  vimeoLogo: false,
                },
              },
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default Video;
