import * as actions from '@state/redux/slices/project.slice';
import { connect } from 'react-redux';
import {
  getCurrentProject,
  getFeedback,
  getPageElementDisabledState,
} from '../../../../../state/selectors/projects';

import {
  ImageRadioStyle1Answer,
  ImageRadioStyle2Answer,
  ImageRadioStyle3Answer,
} from '@app/@types/redux/answer';
import { ImageRadioOptions } from '@app/@types/redux/pageElement';
import { PageElementProps } from '../..';
import { AppDispatch, RootState } from '../../../../../state/redux/store';
import './ImageRadioButtons.scss';
import Style1 from './Style1';
import Style2 from './Style2';
import Style3 from './Style3';

const mapStateToProps = (state: RootState) => ({
  currentProject: getCurrentProject(state.projects),
  feedbacks: getFeedback(state.projects),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchFeedback: (projectId: string, pageElementId: string) =>
    dispatch(actions.fetchFeedback({ projectId, pageElementId })),
});

const ImageRadioButtons: React.FC<PageElementProps> = (props) => {
  const { pageElement, getDependeeAnswerType } = props;

  const answer = props.answer as (
    value: ImageRadioStyle1Answer | ImageRadioStyle2Answer | ImageRadioStyle3Answer
  ) => void;

  const state = props.state;

  const currentAnswer = props.currentAnswer as
    | ImageRadioStyle1Answer
    | ImageRadioStyle2Answer
    | ImageRadioStyle3Answer;

  const { dependees } = pageElement.attributes;

  const {
    pageElement: { id: pageElementId },
  } = props;
  const { values, type } = pageElement.attributes.options as ImageRadioOptions;
  let content = <></>;
  const disabled = getPageElementDisabledState(state, pageElementId);
  const answers = currentAnswer;
  switch (type) {
    case 'style_3': {
      const pageElementAnswer = getDependeeAnswerType(dependees[0]?.dependeeId);
      const { questions } = pageElement.attributes.options as ImageRadioOptions;
      if (pageElementAnswer.type) {
        content = (
          <Style3
            pageElementAnswer={pageElementAnswer}
            currentAnswer={currentAnswer as ImageRadioStyle3Answer}
            pageElementId={pageElementId}
            answer={props.answer as (value: ImageRadioStyle3Answer) => void}
            questions={questions}
            pageElement={pageElement}
            disabled={disabled}
          />
        );
      }
      break;
    }
    case 'style_1': {
      content = (
        <Style1
          personas={values}
          pageElementId={pageElementId}
          answers={answers as ImageRadioStyle1Answer}
          answer={answer as (value: ImageRadioStyle1Answer) => void}
          disabled={disabled}
        />
      );
      break;
    }
    case 'style_2': {
      content = (
        <Style2
          answer={answer as (value: ImageRadioStyle2Answer) => void}
          currentAnswer={currentAnswer as ImageRadioStyle2Answer}
          pageElementId={pageElementId}
          values={values as { id: string; title: string }[]}
        />
      );
      break;
    }
    default:
      content = <></>;
  }
  return content;
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageRadioButtons);
