import './PrioritizationMatrixView.scss';

import { Button, Col, Modal, ModalBody, Row } from '@components/UI/Html';
import classNames from 'classnames';
import { CiWarning } from 'react-icons/ci';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { Badge, UncontrolledTooltip } from 'reactstrap';

import { PrioritizationMatrixAnswer } from '@app/@types/redux/answer';
import { PrioritizationMatrixOptions } from '@app/@types/redux/pageElement';
import React, { useEffect, useState } from 'react';

interface PrioritizationMatrixViewProps {
  currentAnswer: PrioritizationMatrixAnswer | undefined;
  answer?: (value: PrioritizationMatrixAnswer) => void;
  preview?: boolean;
  pageElementOptions: PrioritizationMatrixOptions;
}

const PrioritizationMatrixView: React.FC<PrioritizationMatrixViewProps> = (props) => {
  const { currentAnswer, answer, preview, pageElementOptions } = props;

  const { feedbackQuestion, matrix, instructions } = pageElementOptions;

  const matrixCols = matrix.col;
  const matrixRows = matrix.row;

  const [mapData, setMapData] = useState(
    currentAnswer && currentAnswer.mapData.length > 0
      ? currentAnswer.mapData
      : Array.from({ length: matrixRows.length || 0 }, () =>
          Array.from({ length: matrixCols.length || 0 }, () => 0)
        )
  );

  const [sumArray, setSumArray] = useState(Array.from({ length: matrixRows.length || 0 }, () => 0));

  const [modal, setModal] = useState(false);

  const sorted = sumArray.slice().sort(function (a, b) {
    return b - a;
  });
  const ranks = sumArray.map(function (v) {
    return sorted.indexOf(v) + 1;
  });

  function isConditionMet(
    array: number[],
    predicate: (num: number) => boolean,
    index: number,
    sumArray: number[]
  ) {
    const count = array.filter((num) => predicate(num)).length;
    return count > 1 && sumArray[index] !== 0;
  }

  useEffect(() => {
    if (mapData.length > 0) {
      const newArray = mapData.map((row) => row.reduce((a, b) => a + b, 0));
      setSumArray(newArray);
    }
  }, [currentAnswer, mapData]);

  return (
    <div>
      <div>
        <div className="d-flex justify-content-end mb-3">
          <Button
            type="button"
            onClick={() => {
              setModal(true);
            }}
            color="primary"
            size="sm"
          >
            <HiOutlineInformationCircle style={{ fontSize: '16px' }} />
            <span>Understand Criteria</span>
          </Button>
        </div>
        <table className="w-full table table-bordered rounded">
          <thead>
            <tr>
              <th scope="col">
                <span>Criteria / Option</span>
              </th>
              {matrixCols.map((i, index) => {
                return (
                  <th
                    key={`${String(i)}-${String(index)}`}
                    scope="col"
                    style={{
                      maxWidth: '120px',
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      textAlign: 'center',
                    }}
                  >
                    {i}
                  </th>
                );
              })}
              <th
                scope="col"
                style={{
                  width: '100px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden', // Hide overflowing content
                }}
              >
                Total
              </th>
              <th scope="col">{feedbackQuestion || 'Justification'}</th>
            </tr>
          </thead>
          <tbody>
            {matrixRows.map((i, rowIndex) => {
              const showRank = mapData[rowIndex].reduce((a, b) => a + b, 0) > 0;
              const showTextarea = !(ranks[rowIndex] > 3 || preview);
              const showError =
                ranks.filter((num) => num === ranks[rowIndex]).length > 1 &&
                sumArray[rowIndex] !== 0;

              return (
                <tr
                  key={`${String(i)}-${String(rowIndex)}`}
                  style={{ background: showError ? '#fdf5f5' : 'inherit' }}
                >
                  <th scope="row">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        {isConditionMet(
                          ranks,
                          (num) => num === ranks[rowIndex],
                          rowIndex,
                          sumArray
                        ) ? (
                          <div id="samePointToolTip">
                            <Badge color="#fdf5f5" className="custom-badge">
                              <CiWarning color="red" style={{ width: '24px', height: '24px' }} />{' '}
                            </Badge>
                            <UncontrolledTooltip
                              delay={0}
                              placement="bottom"
                              target="samePointToolTip"
                            >
                              Modify duplicate total scores to proceed further
                            </UncontrolledTooltip>
                          </div>
                        ) : showRank && ranks[rowIndex] <= 3 ? (
                          <Badge
                            color="success"
                            className="badge-md badge-circle badge-floating border-white "
                          >
                            {ranks[rowIndex]}
                          </Badge>
                        ) : null}
                      </div>
                      <div className="pl-2 prioritization-matrix__badge_info">
                        <div style={{ fontSize: '0.9rem' }}>{i}</div>
                      </div>
                    </div>
                  </th>
                  {matrixCols.map((j) => {
                    const value = mapData[rowIndex][matrixCols.indexOf(j)];
                    return (
                      <td key={`${i}-${j}`} className="prioritization-matrix__select__td">
                        <select
                          className={classNames(
                            'prioritization-matrix__select',
                            value === 0
                              ? 'prioritization-matrix__select--empty'
                              : 'prioritization-matrix__select--filled'
                          )}
                          value={value}
                          disabled={preview}
                          onChange={(e) => {
                            const { value } = e.target;
                            const columnIndex = matrixCols.indexOf(j);
                            const newMapData = mapData.map((row, rIndex) => {
                              return row.map((column, cIndex) => {
                                return rIndex === rowIndex && cIndex === columnIndex
                                  ? parseInt(value, 10)
                                  : column;
                              });
                            });
                            setMapData(newMapData);
                            const newArray = newMapData.map((row) =>
                              row.reduce((a, b) => a + b, 0)
                            );
                            const sorted = newArray.slice().sort(function (a, b) {
                              return b - a;
                            });
                            const ranks = newArray.map(function (v) {
                              return sorted.indexOf(v) + 1;
                            });
                            answer &&
                              answer({
                                ...currentAnswer,
                                mapData: newMapData,
                                ranks,
                                matrixRows,
                                feedbacks: currentAnswer?.feedbacks ?? [],
                              });
                          }}
                        >
                          {[...Array(11).keys()].map((k) => {
                            return (
                              <option key={k} value={k}>
                                {k || '-'}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                    );
                  })}
                  <td className="prioritization-matrix__rank">{sumArray[rowIndex]}</td>
                  <td>
                    {showTextarea &&
                    !isConditionMet(ranks, (num) => num === ranks[rowIndex], rowIndex, sumArray) ? (
                      <textarea
                        className="prioritization-matrix__textarea"
                        value={currentAnswer?.feedbacks[rowIndex] ?? ''}
                        disabled={ranks[rowIndex] > 3 || preview}
                        onChange={(e) => {
                          const { value } = e.target;
                          const newFeedbacks = [...(currentAnswer?.feedbacks ?? [])];
                          newFeedbacks[rowIndex] = value;
                          answer &&
                            currentAnswer &&
                            answer({ ...currentAnswer, feedbacks: newFeedbacks });
                        }}
                        maxLength={300}
                        rows={4}
                        placeholder="Enter justification here"
                      />
                    ) : (
                      <div
                        style={{
                          height: '100px',
                        }}
                      >
                        &nbsp;
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        className="modal-lg"
        isOpen={modal}
        toggle={() => {
          setModal(false);
        }}
      >
        <Row>
          <Col>Criteria Explanation</Col>
        </Row>
        <ModalBody>
          <Row>
            <Col>
              {instructions?.map((i, index) => {
                return (
                  <div key={index} className="mb-3">
                    <h3 className="font-bold">{matrixCols[index]}</h3>
                    <p dangerouslySetInnerHTML={{ __html: i }} />
                  </div>
                );
              })}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PrioritizationMatrixView;
