const AssessmentsContainer = () => {
  return <div>AssessmentsContainer</div>;
};

export default AssessmentsContainer;

// import { Evaluation, UserEvaluation } from '@app/@types/redux/project';
// import { AppDispatch, RootState } from '@state/redux/store';
// import { Component } from 'react';
// import { connect } from 'react-redux';
// import Layout from '../../../components/UI/Layout';
// import AssessmentsIndex from '../../../components/Users/Assessments/AssessmentsIndex';

// const mapStateToProps = (state: RootState) => ({
//   myEvaluations: state.users.myEvaluations,
//   evaluations: state.users.evaluations,
// });

// interface AssessmentsContainerProps {
//   dispatch: AppDispatch;
//   evaluations: Evaluation[];
//   myEvaluations: UserEvaluation[];
// }

// class AssessmentsContainer extends Component<AssessmentsContainerProps> {
//   override render() {
//     // const { evaluations, myEvaluations } = this.props;
//     return (
//       <>
//         <Layout {...this.props}>
//           <AssessmentsIndex />
//         </Layout>
//       </>
//     );
//   }
// }

// export default connect(mapStateToProps)(AssessmentsContainer);
