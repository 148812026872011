import { FC } from 'react';
import I18n from '../../../../libs/i18n/I18n';
import { H2 } from '../../../UI/Html';
import CountDown from '../../CountDown';

interface StageCountDownProps {
  beginDate: Date;
  callback: () => void;
}

const StageCountDown: FC<StageCountDownProps> = ({ beginDate, callback }) => {
  return (
    <>
      <H2 className="text-center">{I18n.t('takeProject.startingIn')}</H2>
      <CountDown endDate={beginDate} callback={callback} />
    </>
  );
};

export default StageCountDown;
