import { Component } from 'react';
import Layout from '../../components/UI/Layout';
import Notes from '../../components/Users/Notes/NotesIndex';

class NotesContainer extends Component {
  override render() {
    return (
      <Layout {...this.props}>
        <Notes />
      </Layout>
    );
  }
}

export default NotesContainer;
