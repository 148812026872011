import Skeleton from 'react-loading-skeleton';
import { ConnectedProps, connect } from 'react-redux';
import { Input, Row } from 'reactstrap';
import { slugify } from '../../../../libs/helper';
import { i18nCText } from '../../../../libs/i18n/I18n';
import { RootState, useAppSelector } from '../../../../state/redux/store';
import {
  getCurrentProject,
  getPageElementDisabledState,
  getReviews,
} from '../../../../state/selectors/projects';

import {
  CanvasAnswer,
  CardReviewAnswer,
  CustomerJourneyMapAnswer,
  PrioritizationMatrixAnswer,
  SubmissionCardAnswer,
  type PersonaEntryAnswer,
} from '@app/@types/redux/answer';
import {
  CanvasOptions,
  CardReviewOptions,
  CustomerJourneyMapOptions,
  PrioritizationMatrixOptions,
  type SubmissionCardOptions,
} from '@app/@types/redux/pageElement';
import { PageElement, UserAnswer, UserAnswerAnswer } from '@app/@types/redux/project';
import { PAGE_ELEMENT } from '@libs/constants/constants';
import { translate } from '../../../../state/utils/helper';
import { Card, CardBody, Col, H3, ListGroup, ListGroupItem, StarRatings } from '../../../UI/Html';
import Title from '../../View/Title';
import CanvasView from '../Pages/ModelCanvas/CanvasView';
import CardPersona from './HelperComponents/CardPersona';
import CustomerJourneyMapView from './HelperComponents/CustomerJourneyMapView';
import PrioritizationMatrixView from './HelperComponents/PrioritizationMatrixView';
import SubmissionType from './HelperComponents/SubmissionType';
import ReviewSubmissionCard from './ReviewSubmissionCard';

const DEFAULT_SCORE = 0;

const mapStateToProps = (state: RootState) => ({
  currentProject: getCurrentProject(state.projects),
  reviews: getReviews(state.projects),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type CardReviewProps = PropsFromRedux & {
  reviewableItem: UserAnswer;
  external: boolean;
  externalDisabled: boolean;
  externalLoading: boolean;
  currentAnswer: CardReviewAnswer;
  answer: (answer: CardReviewAnswer) => void;
  pageElement: PageElement;
};

const CardReview: React.FC<CardReviewProps> = (props) => {
  const {
    currentAnswer: propCA,
    answer,
    external,
    externalDisabled,
    reviewableItem,
    pageElement,
    externalLoading,
  } = props;

  const { id: pageElementId } = pageElement.attributes;
  const options = pageElement.attributes.options as CardReviewOptions;
  const currentAnswer = propCA;

  const state = useAppSelector((state) => state.projects);

  const disabled = external ? externalDisabled : getPageElementDisabledState(state, pageElementId);

  function renderReviewElement(item: UserAnswerAnswer, index: number, answerElement: PageElement) {
    if (answerElement.attributes.elementType === PAGE_ELEMENT.PERSONA_ENTRY) {
      return <CardPersona key={index} persona={item as PersonaEntryAnswer} />;
    }
    if (answerElement.attributes.elementType === PAGE_ELEMENT.SUBMISSION_CARD) {
      const { questions, allowAttachment } = answerElement.attributes
        .options as SubmissionCardOptions;
      return (
        <ReviewSubmissionCard
          key={index}
          data={item as SubmissionCardAnswer}
          questions={questions}
          type={(item as SubmissionCardAnswer).type}
          allowAttachment={allowAttachment}
        />
      );
    }
    return <></>;
  }

  function renderQuestion(
    question: string,
    index: number,
    userAnswerFeedbackId: string,
    feedbackType: 'competencyFeedbacks' | 'feedbacks',
    criteria: string[][] = [[]]
  ) {
    const {
      currentAnswer,
      answer,
      pageElement: { id },
    } = props;
    const currentAnswerSafe = currentAnswer;
    const feedbacks = currentAnswerSafe[feedbackType];
    const thisAnswer = feedbacks?.[index];
    return (
      <ListGroupItem
        className="checklist-entry flex-column align-items-start py-2 px-2"
        key={index}
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <h4 className="checklist-title mb-0">
              <span
                dangerouslySetInnerHTML={{
                  __html: i18nCText(question),
                }}
              />
            </h4>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-lg-right position-relative ">
            <StarRatings
              className=""
              disabled={disabled}
              value={thisAnswer?.score ?? DEFAULT_SCORE}
              small
              onChange={(score) => {
                answer({
                  ...currentAnswerSafe,
                  userAnswerFeedbackId,
                  [feedbackType]: {
                    ...feedbacks,
                    [index]: {
                      ...feedbacks?.[index],
                      score,
                      index,
                    },
                  },
                });
              }}
              id={`tooltip-${String(index)}-${id}-${slugify(question)}`}
              criteria={criteria[index]}
            />
          </Col>
        </Row>
      </ListGroupItem>
    );
  }

  const loading = externalLoading;
  const answerElement = reviewableItem.attributes.pageElement;
  let answerToReview: UserAnswerAnswer | UserAnswerAnswer[] = reviewableItem.attributes.answer;
  const userAnswerFeedbackId = currentAnswer.userAnswerFeedbackId;

  const currentAnswerSafe = currentAnswer;

  if (answerToReview && answerToReview.constructor === Object) {
    // * If it's just 1 review we'll turn it into an array of length 1
    answerToReview = [
      answerToReview as
        | SubmissionCardAnswer
        | CanvasAnswer
        | CustomerJourneyMapAnswer
        | PrioritizationMatrixAnswer,
    ];
  }
  if (
    [
      PAGE_ELEMENT.SUBMISSION_CARD,
      PAGE_ELEMENT.PERSONA_ENTRY,
      PAGE_ELEMENT.CANVAS,
      PAGE_ELEMENT.CUSTOMER_JOURNEY_MAP,
      PAGE_ELEMENT.PRIORITIZATION_MATRIX,
    ].includes(answerElement.attributes.elementType)
  ) {
    return (
      <>
        <Row>
          <Col className="px-4 py-2 pb-4">
            {answerElement.attributes.elementType == PAGE_ELEMENT.SUBMISSION_CARD ? (
              <SubmissionType
                type={(answerToReview as SubmissionCardAnswer[])[0]?.type}
                images={(answerToReview as SubmissionCardAnswer[])[0]?.images}
                url={(answerToReview as SubmissionCardAnswer[])[0]?.link}
                currentAnswer={(answerToReview as SubmissionCardAnswer[])[0]}
              />
            ) : null}
            {answerElement.attributes.elementType === PAGE_ELEMENT.CANVAS ? (
              <CanvasView
                elements={answerToReview as CanvasAnswer}
                options={answerElement.attributes.options as CanvasOptions}
              />
            ) : null}
            {answerElement.attributes.elementType === PAGE_ELEMENT.CUSTOMER_JOURNEY_MAP ? (
              <CustomerJourneyMapView
                currentAnswer={answerToReview as CustomerJourneyMapAnswer}
                submissionQuestion={
                  (answerElement.attributes.options as CustomerJourneyMapOptions).submissionQuestion
                }
                disabled
              />
            ) : null}
            {answerElement.attributes.elementType === PAGE_ELEMENT.PRIORITIZATION_MATRIX ? (
              <PrioritizationMatrixView
                preview
                currentAnswer={answerToReview as PrioritizationMatrixAnswer}
                pageElementOptions={answerElement.attributes.options as PrioritizationMatrixOptions}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {loading ? (
              <Row>
                <Col>
                  <Skeleton height={400} />
                </Col>
              </Row>
            ) : (
              <>
                {(answerToReview as UserAnswerAnswer[]).map((item, index) =>
                  renderReviewElement(item, index, answerElement)
                )}
              </>
            )}
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {loading ? (
              <Row className="mb-4">
                <Col>
                  <Skeleton height={400} />
                </Col>
              </Row>
            ) : (
              <>
                <Card>
                  <CardBody>
                    <Title>{translate('pageElements.cardFeedback.softSkills')}</Title>
                    <ListGroup flush>
                      {options.questions.map((question, index) =>
                        renderQuestion(question, index, userAnswerFeedbackId, 'feedbacks')
                      )}
                    </ListGroup>
                  </CardBody>
                </Card>
                {options.competencyQuestions.length > 0 ? (
                  <Card>
                    <CardBody>
                      <Title>{translate('pageElements.cardFeedback.competency')}</Title>
                      <ListGroup flush>
                        {options.competencyQuestions.map((question, index) =>
                          renderQuestion(
                            question,
                            index,
                            userAnswerFeedbackId,
                            'competencyFeedbacks',
                            options.competencyQuestionsGradingCriteria
                          )
                        )}
                      </ListGroup>
                    </CardBody>
                  </Card>
                ) : null}
              </>
            )}
          </Col>
        </Row>
        {loading ? (
          <Row>
            <Col>
              <Skeleton height={20} />
              <div style={{ height: 10 }} />
              <Skeleton height={100} />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col>
                <H3 className="my-3 side-border-title">
                  {options.feedbackQuestion
                    ? options.feedbackQuestion
                    : translate('pageElements.cardReview.textareaLabel')}
                </H3>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type="textarea"
                  placeholder={translate('pageElements.cardReview.textareaPlaceholder')}
                  value={currentAnswerSafe.text}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    answer({
                      ...currentAnswerSafe,
                      userAnswerFeedbackId,
                      text: e.target.value,
                    });
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    answer({
                      ...currentAnswerSafe,
                      userAnswerFeedbackId,
                      text: e.target.value.trim(),
                    });
                  }}
                  className="textarea-border"
                  rows={5}
                  disabled={disabled}
                  maxLength="1200"
                />
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
  return null;
};

export default connector(CardReview);
