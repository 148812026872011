import logger from '@libs/log';
import { useEffect, useRef, useState } from 'react';
import { CiLogin, CiVideoOn } from 'react-icons/ci';
import ReactPlayer from 'react-player';
import {
  Button,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from 'reactstrap';
import carsouselFirstImg from '../../assets/img/slide1.png';
import { Modal } from '../UI/Html';

const items = [
  {
    src: carsouselFirstImg,
    altText: ' LifeCraft - Your Personal Learning & Career Pathfinder',
    buttonText: 'Watch',
    formText: 'Join Beta',
    caption:
      "Discover your ideal career options and test drive them with LifeCraft's 45-min simulations. Say goodbye to confusion and hello to possibilities!",
  },
];

function WorkspaceCarousel() {
  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   return () => dispatch(resetProjectsFilters());
  // }, [dispatch]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    videoRef.current?.play().catch(() => {
      logger.error('Error playing video');
    });
  }, []);

  return (
    <div>
      <Carousel className="hero-Banner" activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators items={items} activeIndex={activeIndex} />
        {items.map((item) => {
          return (
            <CarouselItem
              onExiting={onExiting}
              onExited={onExited}
              key={item.src}
              className="slider-content"
            >
              <img src={item.src} alt={item.altText} />
              <div className="custom-content">
                <p className="carousel-caption">BETA</p>
                <CarouselCaption captionText={item.caption} captionHeader={item.altText} />
                <div className="flex">
                  <Button
                    size="sm"
                    color="primary"
                    className="flex items-center justify-center"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    <CiVideoOn size={16} className="mr-2" />

                    {item.buttonText}
                  </Button>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => {
                      window.open('https://forms.gle/gXkzwCaa2jPKMX6f9', '_blank');
                    }}
                  >
                    <div className="flex items-center justify-center">
                      <CiLogin size={16} className="mr-2" />

                      {item.formText}
                    </div>
                  </Button>
                </div>
              </div>
            </CarouselItem>
          );
        })}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
      <Modal
        className="carousel-modal"
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
      >
        {showModal ? <VideoModal /> : null}
      </Modal>{' '}
    </div>
  );
}

function VideoModal() {
  return (
    <ReactPlayer
      url="https://player.vimeo.com/video/869963102"
      controls
      width="100%"
      height="500px"
      config={{
        vimeo: {
          playerOptions: {
            autoplay: true,
          },
        },
      }}
    />
  );
}

WorkspaceCarousel.defaultProps = {
  filtersData: {
    skills: [],
  },
  filters: {},
};

export default WorkspaceCarousel;
