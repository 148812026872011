import type { EmailActivityAnswer } from '@app/@types/redux/answer';
import type { EmailActivityOptions, SimulationEmail } from '@app/@types/redux/pageElement';
import type { PageElementProps } from '@components/Projects/TakeProject';
import { getParsedEmailBody } from '@libs/helper';
import logger from '@libs/log';
import { useAppSelector } from '@state/redux/store';
import expEndpoints from '@state/utils/Api';
import { useEffect, useState } from 'react';
import { RiArrowGoBackFill, RiReplyFill } from 'react-icons/ri';
import { Button } from 'reactstrap';
import { EmailView } from '.';
import EmailBox from './EmailBox';

const EmailActivity: React.FC<PageElementProps> = (props) => {
  console.log({ props });
  const simulationEmailId = (props.pageElement.attributes.options as EmailActivityOptions)
    .simulationEmailId;
  const projectId = useAppSelector((state) => state.projects.currentProject.project.id);
  const [simulationEmail, setSimulationEmail] = useState<SimulationEmail>({
    id: '',
    type: '',
    attributes: {
      openedAt: '',
      repliedAt: '',
      sentAt: '',
      createdAt: '',
      updatedAt: '',
      simulationEmail: {
        id: '',
        type: '',
        attributes: {
          subject: '',
          body: '',
          senderName: '',
          senderEmail: '',
          senderImage: '',
          attachments: [],
          recipients: [],
          createdAt: '',
          updatedAt: '',
        },
      },
    },
  });

  const [emailAnswer, setEmailAnswer] = useState(
    (props.currentAnswer as EmailActivityAnswer | undefined) ?? {
      to: '',
      subject: '',
      body: '',
      simulationEmailId: '',
      sentAt: '',
    }
  );

  const [replyMode, setReplyMode] = useState(false);
  const emailSent = emailAnswer.body !== '';

  useEffect(() => {
    expEndpoints
      .getSimulationEmailById(projectId, simulationEmailId)
      .then((res) => {
        setSimulationEmail(res.data.data);
      })
      .catch(() => {
        logger.error('Failed to fetch simulation email');
      });
  }, [simulationEmailId]);

  const email = simulationEmail;

  const toMeEmailAddress = useAppSelector((state) => state.users.user.attributes.email);
  const name = useAppSelector((state) => state.users.user.attributes.name);
  const toEmail = useAppSelector((state) => state.users.user.attributes.email);
  const toImage = useAppSelector((state) => state.users.user.attributes.avatarUrl);
  const senderName = email.attributes.simulationEmail.attributes.senderName;
  const senderEmail = email.attributes.simulationEmail.attributes.senderEmail;
  const senderImage = email.attributes.simulationEmail.attributes.senderImage;
  const attachments = email.attributes.simulationEmail.attributes.attachments;
  const emailSentAt = email.attributes.sentAt;
  const subject = email.attributes.simulationEmail.attributes.subject;
  const body = getParsedEmailBody(name, email.attributes.simulationEmail.attributes.body);

  return (
    <div className="">
      <div className="email__main__header">
        <div className="d-flex align-items-center justify-content-end w-100">
          {!emailSent ? (
            replyMode ? (
              <Button
                color="primary"
                onClick={() => {
                  setReplyMode(false);
                }}
              >
                <RiArrowGoBackFill size={16} style={{ marginRight: '0.5rem' }} />
                Go Back
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={() => {
                  setReplyMode(true);
                }}
              >
                <RiReplyFill size={16} style={{ marginRight: '0.5rem' }} />
                Reply
              </Button>
            )
          ) : null}
        </div>
      </div>
      <div className="emailActivity__container">
        {replyMode ? (
          <div>
            <EmailBox
              email={email}
              setEmailAnswer={setEmailAnswer}
              sendFn={() => {
                setReplyMode(false);
                props.answer({
                  ...emailAnswer,
                  sentAt: new Date().toISOString(),
                });
              }}
            />
          </div>
        ) : emailSent ? (
          <EmailView
            subject={emailAnswer.subject}
            body={emailAnswer.body}
            senderName={name}
            senderEmail={toEmail}
            senderImage={toImage}
            attachments={[]}
            emailSentAt={emailAnswer.sentAt || ''}
            toMeEmailAddress={emailAnswer.to}
            replyToSimulationEmailId={emailAnswer.simulationEmailId}
          />
        ) : (
          <EmailView
            subject={subject}
            body={body}
            senderName={senderName}
            senderEmail={senderEmail}
            senderImage={senderImage}
            attachments={attachments}
            emailSentAt={emailSentAt ?? ''}
            toMeEmailAddress={toMeEmailAddress}
          />
        )}
      </div>
    </div>
  );
};
export default EmailActivity;
