import classnames from 'classnames';
import { useState } from 'react';
import StarRatings from 'react-star-ratings';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';

interface CStarRatingsProps {
  value: number;
  onChange?: (value: number) => void;
  className?: string;
  disabled?: boolean;
  small?: boolean;
  id?: string;
  criteria?: string[];
}

const CStarRatings: React.FC<CStarRatingsProps> = ({
  value,
  onChange,
  className = '',
  disabled = false,
  small = false,
  id = 'starRating',
  criteria = ['Excellent', 'Good', 'Average', 'Poor', 'Very Poor'],
  ...rest
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  let criteriaText = '';
  try {
    criteriaText = criteria[criteria.length - value];
  } catch (error) {
    switch (value) {
      case 1:
        criteriaText = 'Very Poor';
        break;
      case 2:
        criteriaText = 'Poor';
        break;
      case 3:
        criteriaText = 'Average';
        break;
      case 4:
        criteriaText = 'Good';
        break;
      case 5:
        criteriaText = 'Excellent';
        break;
      default:
        break;
    }
  }

  const [hover, setHover] = useState(false);

  return (
    <div
      className={classnames(className, 'py-2', disabled ? 'disabled' : '', small ? 'small' : '')}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <span id={id}>
        <StarRatings
          className={classnames(className, 'py-2')}
          {...rest}
          starDimension={small ? '16px' : '24px'}
          starHoverColor="#2CAEE4"
          starRatedColor="#2CAEE4"
          starSpacing={small ? '2px' : '5px'}
          rating={value}
          changeRating={
            disabled
              ? null
              : (val: number) => {
                  if (onChange) {
                    onChange(val);
                  }
                  setPopoverOpen(!popoverOpen);
                }
          }
        />
      </span>
      {!disabled && value && hover ? (
        <div>
          <Popover
            placement="auto"
            isOpen
            target={id}
            toggle={() => {
              setPopoverOpen(!popoverOpen);
            }}
            trigger="focus"
          >
            <PopoverHeader>{value} stars</PopoverHeader>
            <PopoverBody>{criteriaText}</PopoverBody>
          </Popover>
        </div>
      ) : null}
    </div>
  );
};

export default CStarRatings;
