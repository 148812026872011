import classnames from 'classnames';
import { useEffect, useState } from 'react';

import { ProjectFilters } from '@app/@types/redux/project.js';
import {
  resetProjectsFilters,
  updateFilters as updateFiltersFn,
} from '@state/redux/slices/project.slice';
import { useAppDispatch } from '@state/redux/store';
import { BiSort } from 'react-icons/bi';
import {
  ImSortAlphaAsc,
  ImSortAlphaDesc,
  ImSortNumbericDesc,
  ImSortNumericAsc,
} from 'react-icons/im';
import { IoMdClose } from 'react-icons/io';
import { LuFolderCheck, LuFolderClock, LuFolders } from 'react-icons/lu';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { i18nCText } from '../../libs/i18n/I18n.ts';

const PAGE_TYPE = {
  WORKSPACES: 'workspaces',
  ACTIVE_PROJECTS: 'activeProjects',
  FINISHED_PROJECTS: 'finishedProjects',
};

const PAGE_NAME = {
  WORKSPACES: 'Workspaces',
  ACTIVE_PROJECTS: 'Active Challenges',
  FINISHED_PROJECTS: 'Finished Challenges',
};

const SORT_BY = {
  WORKSPACE_NAME: 'workspace_name',
  WORKSPACE_START_DATE: 'workspace_start_date',
  WORKSPACE_END_DATE: 'workspace_end_date',
  PROJECT_NAME: 'project_name',
  PROJECT_START_DATE: 'project_start_date',
  PROJECT_END_DATE: 'project_end_date',
};

const SORT_DIR = {
  ASC: 'asc',
  DESC: 'desc',
};

export interface IconBaseProps extends React.SVGAttributes<SVGElement> {
  children?: React.ReactNode;
  size?: string | number;
  color?: string;
  title?: string;
}

export type IconType = (props: IconBaseProps) => JSX.Element;

interface Option {
  value: string;
  label: string;
  sortBy: string;
  sortDirection: string;
  icon: IconType;
}

function WorkspaceFilters(props: { tab: string; setTab: (tab: string) => void }) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetProjectsFilters());
    };
  }, [dispatch]);

  const [sortSelectedValue, setSortSelectedValue] = useState<Option>();
  const [sortSelectedValueWorkspace, setSortSelectedValueWorkspace] = useState<Option>();

  const { tab, setTab } = props;
  const updateFilters = (data: ProjectFilters) => {
    dispatch(updateFiltersFn(data));
  };

  const sortOptions: Option[] = [
    {
      value: 'sort-name-asc',
      label: 'Name (A to Z)',
      sortBy: SORT_BY.PROJECT_NAME,
      sortDirection: SORT_DIR.ASC,
      icon: ImSortAlphaAsc,
    },
    {
      value: 'sort-name-desc',
      label: 'Name (Z to A)',
      sortBy: SORT_BY.PROJECT_NAME,
      sortDirection: SORT_DIR.DESC,
      icon: ImSortAlphaDesc,
    },
    {
      value: 'sort-start-date-asc',
      label: 'Start Date (First to last)',
      sortBy: SORT_BY.PROJECT_START_DATE,
      sortDirection: SORT_DIR.ASC,
      icon: ImSortNumericAsc,
    },
    {
      value: 'sort-start-date-desc',
      label: 'Start Date (Last to first)',
      sortBy: SORT_BY.PROJECT_START_DATE,
      sortDirection: SORT_DIR.DESC,
      icon: ImSortNumbericDesc,
    },
    {
      value: 'clear',
      label: 'Clear',
      sortBy: '',
      sortDirection: '',
      icon: IoMdClose,
    },
  ];

  const sortOptionsWorkspace: Option[] = [
    {
      value: 'sort-name-asc',
      label: 'Name (A to Z)',
      sortBy: SORT_BY.WORKSPACE_NAME,
      sortDirection: SORT_DIR.ASC,
      icon: ImSortAlphaAsc,
    },
    {
      value: 'sort-name-desc',
      label: 'Name (Z to A)',
      sortBy: SORT_BY.WORKSPACE_NAME,
      sortDirection: SORT_DIR.DESC,
      icon: ImSortAlphaDesc,
    },
    {
      value: 'sort-start-date-asc',
      label: 'Start Date (First to last)',
      sortBy: SORT_BY.WORKSPACE_START_DATE,
      sortDirection: SORT_DIR.ASC,
      icon: ImSortNumericAsc,
    },
    {
      value: 'sort-start-date-desc',
      label: 'Start Date (Last to first)',
      sortBy: SORT_BY.WORKSPACE_START_DATE,
      sortDirection: SORT_DIR.DESC,
      icon: ImSortNumbericDesc,
    },
    {
      value: 'sort-end-date-asc',
      label: 'End Date (First to last)',
      sortBy: SORT_BY.WORKSPACE_END_DATE,
      sortDirection: SORT_DIR.ASC,
      icon: ImSortNumericAsc,
    },
    {
      value: 'sort-end-date-desc',
      label: 'End Date (Last to first)',
      sortBy: SORT_BY.WORKSPACE_END_DATE,
      sortDirection: SORT_DIR.DESC,
      icon: ImSortNumbericDesc,
    },
    {
      value: 'clear',
      label: 'Clear',
      sortBy: '',
      sortDirection: '',
      icon: IoMdClose,
    },
  ];

  const handleChange = (selectedOption: Option) => {
    if (selectedOption.value === 'clear') {
      setSortSelectedValue({
        value: '',
        label: 'Sort',
        sortBy: '',
        sortDirection: '',
        icon: BiSort,
      });
      updateFilters({
        orderBy: '',
        sortDirection: '',
      });
      return;
    }

    setSortSelectedValue(selectedOption);
    updateFilters({
      orderBy: selectedOption.sortBy,
      sortDirection: selectedOption.sortDirection,
    });
  };

  const handleChangeWorkspace = (selectedOption: Option) => {
    if (selectedOption.value === 'clear') {
      setSortSelectedValueWorkspace({
        value: '',
        label: 'Sort',
        sortBy: '',
        sortDirection: '',
        icon: BiSort,
      });
      updateFilters({
        orderBy: '',
        sortDirection: '',
      });
      return;
    }
    setSortSelectedValueWorkspace(selectedOption);
    updateFilters({
      orderBy: selectedOption.sortBy,
      sortDirection: selectedOption.sortDirection,
    });
  };

  return (
    <div className="">
      <div className="px-1 pb-2 mt-3 d-flex justify-content-start">
        <div className="position-relative justify-content-start d-flex">
          <Nav className=" overviewPageNav1 overviewPageNav" id="tabs-icons-text" role="tablist">
            <NavItem>
              <NavLink
                aria-selected={tab === PAGE_TYPE.WORKSPACES}
                className={classnames('navpill font-weight-500', {
                  active: tab === PAGE_TYPE.WORKSPACES,
                })}
                style={{
                  fontSize: '14px',
                }}
                onClick={() => {
                  updateFilters({
                    orderBy: '',
                    sortDirection: '',
                  });
                  setTab(PAGE_TYPE.WORKSPACES);
                }}
                href="#"
                role="tab"
              >
                <LuFolders className="mr-2" size={18} />
                {i18nCText(PAGE_NAME.WORKSPACES)}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tab === PAGE_TYPE.ACTIVE_PROJECTS}
                className={classnames('navpill mb-sm-3 mb-md-0 font-weight-500', {
                  active: tab === PAGE_TYPE.ACTIVE_PROJECTS,
                })}
                style={{
                  fontSize: '14px',
                }}
                onClick={() => {
                  updateFilters({
                    orderBy: '',
                    sortDirection: '',
                  });
                  setSortSelectedValue({
                    value: '',
                    label: 'Sort',
                    sortBy: '',
                    sortDirection: '',
                    icon: BiSort,
                  });
                  setTab(PAGE_TYPE.ACTIVE_PROJECTS);
                }}
                href="#"
                role="tab"
              >
                <LuFolderClock className="mr-2" size={18} />
                {i18nCText(PAGE_NAME.ACTIVE_PROJECTS)}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tab === PAGE_TYPE.FINISHED_PROJECTS}
                className={classnames('navpill mb-sm-3 mb-md-0 font-weight-500', {
                  active: tab === PAGE_TYPE.FINISHED_PROJECTS,
                })}
                style={{
                  fontSize: '14px',
                }}
                onClick={() => {
                  updateFilters({
                    orderBy: '',
                    sortDirection: '',
                  });
                  setSortSelectedValue({
                    value: '',
                    label: 'Sort',
                    sortBy: '',
                    sortDirection: '',
                    icon: BiSort,
                  });
                  setTab(PAGE_TYPE.FINISHED_PROJECTS);
                }}
                href="#"
                role="tab"
              >
                <LuFolderCheck className="mr-2" size={18} />
                {i18nCText(PAGE_NAME.FINISHED_PROJECTS)}
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        {tab === PAGE_TYPE.WORKSPACES && (
          <Filter
            handleChange={handleChangeWorkspace}
            sortSelectedValue={sortSelectedValueWorkspace ?? sortOptions[0]}
            sortOptions={sortOptionsWorkspace}
          />
        )}
        {tab === PAGE_TYPE.ACTIVE_PROJECTS && (
          <Filter
            handleChange={handleChange}
            sortSelectedValue={sortSelectedValue ?? sortOptions[0]}
            sortOptions={sortOptions}
          />
        )}
        {tab === PAGE_TYPE.FINISHED_PROJECTS && (
          <Filter
            handleChange={handleChange}
            sortSelectedValue={sortSelectedValue ?? sortOptions[0]}
            sortOptions={sortOptions}
          />
        )}
      </div>
    </div>
  );
}

interface IFilterProps {
  handleChange: (selectedOption: Option) => void;
  sortSelectedValue: Option;
  sortOptions: Option[];
}
const Filter = (props: IFilterProps) => {
  const { handleChange, sortSelectedValue, sortOptions } = props;

  return (
    <div
      className="ml-auto align-self-center justify-content-start custom-dropdown-align"
      style={{ width: '220px', zIndex: 1000 }}
    >
      <select
        className="custom-select custom-select-sm"
        value={sortSelectedValue.value}
        onChange={(e) => {
          const selectedOption = sortOptions.find(
            (option: Option) => option.value === e.target.value
          );
          handleChange(selectedOption ? selectedOption : sortOptions[0]);
        }}
      >
        {sortOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {/* <Select
        className="basic-single"
        classNamePrefix="select"
        styles={{
          menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999,
            fontSize: "2px",
            width: "100px",
            minWidth: "100%",
          }),
        }}
        onChange={handleChange}
        value={sortSelectedValue}
        isDisabled={false}
        placeholder={
          <div>
            <BiSort className="mr-2 size-4" />
            Sort
          </div>
        }
        isLoading={false}
        isClearable="true"
        isRtl={false}
        isSearchable={false}
        name="color"
        options={sortOptions}
        components={{ Option: IconOption }}
      /> */}
    </div>
  );
};

export default WorkspaceFilters;
