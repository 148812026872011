import cartedoLogoDark100 from './dark/cartedo-logo-100.png';
import cartedoLogoDark1920 from './dark/cartedo-logo-1920.png';
import cartedoLogoDark512 from './dark/cartedo-logo-512.png';
import cartedoLogoDark720 from './dark/cartedo-logo-720.png';
import cartedoLogoNoTextDark100 from './dark/cartedo-logo-no-text-100.png';
import cartedoLogoNoTextDark400 from './dark/cartedo-logo-no-text-400.png';
import cartedoLogoLight100 from './light/cartedo-logo-100.png';
import cartedoLogoLight1920 from './light/cartedo-logo-1920.png';
import cartedoLogoLight512 from './light/cartedo-logo-512.png';
import cartedoLogoLight720 from './light/cartedo-logo-720.png';
import cartedoLogoNoTextLight100 from './light/cartedo-logo-no-text-100.png';
import cartedoLogoNoTextLight400 from './light/cartedo-logo-no-text-400.png';

interface LogoSizes {
  full: string;
  large: string;
  medium: string;
  small: string;
  icon: {
    large: string;
    small: string;
  };
}
const cartedoLogo: { dark: LogoSizes; light: LogoSizes } = {
  dark: {
    full: cartedoLogoDark1920,
    large: cartedoLogoDark720,
    medium: cartedoLogoDark512,
    small: cartedoLogoDark100,
    icon: {
      large: cartedoLogoNoTextDark400,
      small: cartedoLogoNoTextDark100,
    },
  },
  light: {
    full: cartedoLogoLight1920,
    large: cartedoLogoLight720,
    medium: cartedoLogoLight512,
    small: cartedoLogoLight100,
    icon: {
      large: cartedoLogoNoTextLight400,
      small: cartedoLogoNoTextLight100,
    },
  },
};

export default cartedoLogo;
