import { SubmissionCardEmbedAnswer } from '@app/@types/redux/answer';

const EmbedSubmission = (props: { currentAnswer: SubmissionCardEmbedAnswer }) => {
  const { currentAnswer } = props;
  const embedCode = currentAnswer.embedCode;
  return (
    <div className="d-flex justify-content-center">
      <div className="my-4" dangerouslySetInnerHTML={{ __html: embedCode }}></div>
    </div>
  );
};

export default EmbedSubmission;
