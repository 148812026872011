import { CanvasAnswer } from '@app/@types/redux/answer';
import { TextareaOptions } from '@app/@types/redux/pageElement';
import logger from '@libs/log';
import html2canvas from 'html2canvas';
import { useRef } from 'react';
import { BiDollarCircle, BiLineChart } from 'react-icons/bi';
import { FiPieChart } from 'react-icons/fi';
import { HiPrinter } from 'react-icons/hi';
import {
  HiOutlinePencilSquare,
  HiOutlinePuzzlePiece,
  HiOutlineRocketLaunch,
} from 'react-icons/hi2';
import { LiaEyeSolid } from 'react-icons/lia';
import { PiUserCircleGearLight } from 'react-icons/pi';
import { TbEyeStar } from 'react-icons/tb';
import { Button, Col, Row } from 'reactstrap';
import './BMCanvas.scss';
import './PersonalCanvas.scss';

interface BMCanvasProps {
  elements: CanvasAnswer | undefined;
}
function BMCanvas({ elements }: BMCanvasProps) {
  const captureRef = useRef<HTMLDivElement>(null);

  if (!elements) {
    return null;
  }

  const channels = elements.find(
    (el) => (el.pageElement.options as TextareaOptions).id === 'channels'
  );
  const keyPartners = elements.find(
    (el) => (el.pageElement.options as TextareaOptions).id === 'key_partners'
  );
  const customerSegments = elements.find(
    (el) => (el.pageElement.options as TextareaOptions).id === 'customer_segments'
  );
  const customerRelationships = elements.find(
    (el) => (el.pageElement.options as TextareaOptions).id === 'customer_relationships'
  );
  const valueProposition = elements.find(
    (el) => (el.pageElement.options as TextareaOptions).id === 'key_proposition'
  );
  const costStructure = elements.find(
    (el) => (el.pageElement.options as TextareaOptions).id === 'cost_structure'
  );
  const keyResources = elements.find(
    (el) => (el.pageElement.options as TextareaOptions).id === 'key_resources'
  );
  const keyActivities = elements.find(
    (el) => (el.pageElement.options as TextareaOptions).id === 'key_activities'
  );
  const revenueStreams = elements.find(
    (el) => (el.pageElement.options as TextareaOptions).id === 'revenue_streams'
  );

  const handleCapture = () => {
    const captureElement = captureRef.current;
    if (captureElement) {
      html2canvas(captureElement, {
        allowTaint: true,
        useCORS: true,
      })
        .then((canvas) => {
          const imageData = canvas.toDataURL(); // Convert canvas to base64 image data
          const dataUri = `<img src="${imageData}" />`;
          const printWindow = window.open('');
          const imageElement = new Image();

          imageElement.onload = () => {
            printWindow?.document.write(`
            <html>
              <head>
                <title>Captured Screenshot</title>
                <style>
                  @page {
                    size: landscape;
                  }
                  body {
                    margin: 0;
                    padding: 0;
                  }
                  img {
                    max-width: 100%;
                    height: auto;
                    page-break-inside: avoid;
                  }
                </style>
              </head>
              <body>
                ${dataUri}
              </body>
              <script>
                window.addEventListener('afterprint', () => {
                  window.close();
                });
              </script>
            </html>
          `);

            // Trigger print dialog for the opened window after the image has loaded
            printWindow?.print();
          };

          imageElement.src = imageData;
        })
        .catch(() => {
          logger.error('Error capturing screenshot');
        });
    }
  };

  return (
    <div>
      <div className="printArea" ref={captureRef}>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h1 className="personal_canvas_title">Business Model Canvas</h1>
          <Button
            className="print-button"
            size="sm"
            color="default"
            type="button"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              handleCapture();
            }}
          >
            <HiPrinter className="mr-2" />
            Print
          </Button>
        </div>
        <div className="personal_canvas_container">
          <Row className="m-0">
            <Col className="first_row_first_col">
              <h2 className="personal_canvas_heading text-center">Key Partners</h2>
              <HiOutlinePuzzlePiece className="personal_canvas_icon" />
              <div className="py-2">
                <h3 className="personal_canvas_subheading">
                  {(keyPartners?.pageElement.options as TextareaOptions).label}
                </h3>
                <p className="personal_canvas_text">{String(keyPartners?.answer)}</p>
              </div>
            </Col>
            <Col className="first_row_second_col">
              <Row>
                <Col className="first_row_second_col_first_row">
                  <h2 className="personal_canvas_heading text-center">Key Activities</h2>
                  <LiaEyeSolid className="personal_canvas_icon" />
                  <div className="py-2">
                    <h3 className="personal_canvas_subheading">
                      {(keyPartners?.pageElement.options as TextareaOptions).label}
                    </h3>
                    <p className="personal_canvas_text">{String(keyActivities?.answer)}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="first_row_second_col_second_row">
                  <h2 className="personal_canvas_heading text-center">Key Resources</h2>
                  <HiOutlinePencilSquare className="personal_canvas_icon" />
                  <div className="py-2">
                    <h3 className="personal_canvas_subheading">
                      {(keyResources?.pageElement.options as TextareaOptions).label}
                    </h3>
                    <p className="personal_canvas_text">{String(keyResources?.answer)}</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="first_row_third_col">
              <h2 className="personal_canvas_heading text-center">Key Proposition</h2>
              <TbEyeStar className="personal_canvas_icon" />
              <div className="py-2">
                <h3 className="personal_canvas_subheading">
                  {(valueProposition?.pageElement.options as TextareaOptions).label}
                </h3>
                <p className="personal_canvas_text">{String(valueProposition?.answer)}</p>
              </div>
            </Col>
            <Col className="first_row_fourth_col">
              <Row>
                <Col className="first_row_fourth_col_first_row">
                  <h2 className="personal_canvas_heading text-center">Customer Relationships</h2>
                  <PiUserCircleGearLight className="personal_canvas_icon" />
                  <div className="py-2">
                    <h3 className="personal_canvas_subheading">
                      {(customerRelationships?.pageElement.options as TextareaOptions).label}
                    </h3>
                    <p className="personal_canvas_text">{String(customerRelationships?.answer)}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="first_row_fourth_col_second_row">
                  <h2 className="personal_canvas_heading text-center">Channels</h2>
                  <HiOutlineRocketLaunch className="personal_canvas_icon" />
                  <div className="py-2">
                    <h3 className="personal_canvas_subheading">
                      {(channels?.pageElement.options as TextareaOptions).label}
                    </h3>
                    <p className="personal_canvas_text">{String(channels?.answer)}</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="first_row_fifth_col">
              <h2 className="personal_canvas_heading text-center">Customer Segments</h2>
              <FiPieChart className="personal_canvas_icon" />
              <div className="py-2">
                <h3 className="personal_canvas_subheading">
                  {(customerSegments?.pageElement.options as TextareaOptions).label}
                </h3>
                <p className="personal_canvas_text">{String(customerSegments?.answer)}</p>
              </div>
            </Col>
          </Row>
          <Row className="px-3">
            <Col className="second_row_first_col">
              <h2 className="personal_canvas_heading text-center">Cost Structure</h2>
              <BiDollarCircle className="personal_canvas_icon" />
              <div className="py-2">
                <h3 className="personal_canvas_subheading">
                  {(costStructure?.pageElement.options as TextareaOptions).label}
                </h3>
                <p className="personal_canvas_text">{String(costStructure?.answer)}</p>
              </div>
            </Col>
            <Col className="second_row_second_col">
              <h2 className="personal_canvas_heading text-center">Revenue Streams</h2>
              <BiLineChart className="personal_canvas_icon" />
              <div className="py-2">
                <h3 className="personal_canvas_subheading">
                  {(revenueStreams?.pageElement.options as TextareaOptions).label}
                </h3>
                <p className="personal_canvas_text">{String(revenueStreams?.answer)}</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default BMCanvas;
