import { CanvasAnswer } from '@app/@types/redux/answer';
import { TextareaOptions, type CanvasOptions } from '@app/@types/redux/pageElement';
import i18n, { i18nCText } from '@libs/i18n/I18n';
import { nanoid } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import './CustomCanvas.scss';

interface CustomCanvasProps {
  elements: CanvasAnswer;
  questions: string[];
  options: CanvasOptions;
}
function CustomCanvas({ elements, questions, options }: CustomCanvasProps) {
  const sortedCollection = _.sortBy(elements, (item) => {
    const options = item.pageElement.options as TextareaOptions;
    return questions.indexOf(options.label);
  });

  const pElements = sortedCollection.map((element, index) => {
    const textareaOptions = element.pageElement.options as TextareaOptions;
    return (
      <Row className="m-0 custom_canvas_item" key={nanoid()}>
        <Col className="custom_canvas_col">
          <h2 className="custom_canvas_heading">
            Q{index + 1}. {i18nCText(textareaOptions.label)}
          </h2>
          <div className="py-2 px-1">
            <p
              className="custom_canvas_text"
              dangerouslySetInnerHTML={{ __html: element.answer }}
            />
          </div>
        </Col>
      </Row>
    );
  });

  return (
    <div className="custom_canvas_main_container mb-5">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h1 className="personal_canvas_title">{options.title ?? i18n.t('customCanvas.summary')}</h1>
      </div>
      <div className="custom_canvas_container">{pElements}</div>
    </div>
  );
}

export default CustomCanvas;
