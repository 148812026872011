import { FC, useState } from 'react';
import EllipsisText from 'react-ellipsis-text';
import { translate } from '../../../state/utils/helper';

interface ReadMoreProps {
  maxLength?: number;
  text?: string;
}

const ReadMore: FC<ReadMoreProps> = ({ maxLength = 150, text = '' }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div>
      {isExpanded ? text : <EllipsisText text={text} length={maxLength} />}
      {text.length > maxLength ? (
        <>
          &nbsp;
          <a
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            role="button"
            tabIndex={0}
            className="pointer"
          >
            {isExpanded ? translate('buttonLabels.readLess') : translate('buttonLabels.readMore')}
          </a>
        </>
      ) : null}
    </div>
  );
};

export default ReadMore;
