// import { nanoid } from '@reduxjs/toolkit';
// import { FC, useState } from 'react';
// import Skeleton from 'react-loading-skeleton';
// import { recommendations } from '../../../assets/images';
// import notAllowedImage from '../../../assets/images/placeholders/undraw_book_lover.svg';
// import I18n from '../../../libs/i18n/I18n';
// import ProjectsFilters from '../../Projects/Filters';
// import {
//   Alert,
//   Button,
//   Card,
//   CardBody,
//   CardTitle,
//   Col,
//   Container,
//   H1,
//   H2,
//   ImagePlaceholder,
//   Row,
// } from '../../UI/Html';
// import Footer from '../../UI/Layout/Footer';
// interface Resource {
//   id: number;
//   name: string;
//   description?: string;
//   image: string;
//   skills?: string[];
// }

// const RecommendationsIndex: FC = () => {
//   const [resourceModal, setResourceModal] = useState<boolean>(false);
//   const [loading] = useState<boolean>(false);
//   const [recommendationsResources] = useState<Resource[]>([
//     {
//       id: 1,
//       name: 'Reframing Problems | 2 mins',
//       description: 'Lorem Ipsum',
//       image: recommendations[3],
//       skills: ['POV', 'Convergent'],
//     },
//     {
//       id: 2,
//       name: 'Superhero Technique | 3 mins',
//       description: 'Lorem Ipsum',
//       image: recommendations[5],
//       skills: ['Ideation', 'Divergent'],
//     },
//     {
//       id: 3,
//       name: 'Reverse Brainstorming | 3 mins',
//       description: 'Lorem Ipsum',
//       image: recommendations[2],
//       skills: ['Reframe Problems', 'Time Management'],
//     },
//     {
//       id: 4,
//       name: 'Object Storming Technique | 3 mins',
//       description: 'Lorem Ipsum',
//       image: recommendations[1],
//       skills: ['Ideation', 'Divergent', 'Fluency'],
//     },
//     {
//       id: 5,
//       name: 'Spotting Problems | 3 mins',
//       description: 'Lorem Ipsum',
//       image: recommendations[7],
//       skills: ['Problem Solving', 'Time Management'],
//     },
//     {
//       id: 6,
//       name: 'Empathy Dive | 3 mins',
//       description: 'Lorem Ipsum',
//       image: recommendations[4],
//       skills: ['Empathy', 'Time Management'],
//     },
//   ]);

//   const [games] = useState<Resource[]>([
//     {
//       id: 1,
//       name: 'The Food Truck Game',
//       description: 'Lorem Ipsum',
//       image: recommendations[6],
//       skills: ['', ''],
//     },
//   ]);

//   const toggleModal = (modal: string) => {
//     if (modal === 'resourceModal') {
//       setResourceModal(!resourceModal);
//     }
//   };

//   const renderResource = (resource: Resource) => {
//     return (
//       <Col xs={12} sm={6} lg={4} className="d-flex" key={resource.id}>
//         <Card className="project-index-card flex-grow-1">
//           <ImagePlaceholder
//             className="project-card-img top"
//             width={300}
//             height={300}
//             src={resource.image}
//           />
//           <CardBody className="d-flex flex-column pb-1">
//             <CardTitle className="h3 mb-2 ml-1">{resource.name}</CardTitle>
//             <Row>
//               <Col className="text-right">
//                 <Button
//                   className="px-0 text-right"
//                   color="link"
//                   onClick={() => {
//                     if (resource.id === 3) {
//                       window.open(
//                         'https://staging.cartedo.com/challenge/30/reverse-brainstorming-technique'
//                       );
//                     } else {
//                       toggleModal('resourceModal');
//                     }
//                   }}
//                 >
//                   {I18n.t('recommendations.viewButton').toString()}
//                 </Button>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//       </Col>
//     );
//   };

//   const renderGame = (resource: Resource) => {
//     return (
//       <Col xs={12} sm={6} lg={4} className="d-flex" key={resource.id}>
//         <Card className="project-index-card flex-grow-1">
//           <ImagePlaceholder
//             className="project-card-img top"
//             width={300}
//             height={300}
//             src={resource.image}
//           />
//           <CardBody className="d-flex flex-column pb-1">
//             <CardTitle className="h3 mb-2 ml-1">{resource.name}</CardTitle>
//             <Row className="flex-grow-1 align-items-center justify-content-between d-flex flex-column mx-0">
//               <Row className="mr--4 mt-2 d-block text-right" style={{ width: '100%' }}>
//                 <Button
//                   className="px-0 text-right"
//                   color="link"
//                   onClick={() => {
//                     toggleModal('resourceModal');
//                   }}
//                 >
//                   {I18n.t('recommendations.viewButtonGames').toString()}
//                 </Button>
//               </Row>
//             </Row>
//           </CardBody>
//         </Card>
//       </Col>
//     );
//   };

//   return (
//     <Container fluid>
//       <Alert
//         isOpen={resourceModal}
//         onRequestClose={() => {
//           toggleModal('resourceModal');
//         }}
//         showCloseButton
//       >
//         <Row className="py-3">
//           <Col>
//             <ImagePlaceholder src={notAllowedImage} width="60%" native />
//           </Col>
//         </Row>
//         <Row className="pt-3">
//           <Col>
//             <H1 className="font-weight-500 text-dark">Not available for this challenge</H1>
//           </Col>
//         </Row>
//       </Alert>
//       <Row className="mt-5 main-container">
//         <Col sm={3}>
//           <ProjectsFilters />
//         </Col>
//         <Col sm={9}>
//           <Container fluid>
//             <Row>
//               <Col>
//                 <h2 className="text-uppercase font-weight-600 mt-1 mb-4">
//                   {I18n.t('recommendations.pageTitle').toString()}
//                 </h2>
//               </Col>
//             </Row>
//             {recommendationsResources.length === 0 && (
//               <Row>
//                 <Col className="text-center">
//                   <H2>{I18n.t('recommendations.emptyString').toString()}</H2>
//                 </Col>
//               </Row>
//             )}
//             {recommendationsResources.length != 0 && (
//               <Row>
//                 {loading
//                   ? Array.from(Array(3), () => (
//                       <Col xs={12} sm={6} lg={4} className="mb-5" key={nanoid()}>
//                         <Skeleton height={300} />
//                         <div style={{ height: 20 }} />
//                         <Skeleton count={3} height={20} />
//                         <div style={{ height: 20 }} />
//                         <Skeleton count={1} height={20} />
//                       </Col>
//                     ))
//                   : recommendationsResources.map((item) => renderResource(item))}
//               </Row>
//             )}
//             <Row>
//               <Col>
//                 <h2 className="text-uppercase font-weight-600 mt-1 mb-4">
//                   {I18n.t('recommendations.pageTitleGames').toString()}
//                 </h2>
//               </Col>
//             </Row>
//             {games.length === 0 && (
//               <>
//                 <Row>
//                   <Col className="text-center">
//                     <H2>{I18n.t('recommendations.emptyStringGames').toString()}</H2>
//                   </Col>
//                 </Row>
//                 <Row>{games.map((item) => renderGame(item))}</Row>
//               </>
//             )}
//           </Container>
//         </Col>
//       </Row>
//       <Footer />
//     </Container>
//   );
// };

// export default RecommendationsIndex;

export default function RecommendationsIndex() {
  return <div>RecommendationsIndex</div>;
}
