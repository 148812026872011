import { ReactNode } from 'react';

interface H1Props {
  className?: string;
  children?: ReactNode | string;
}

const H1 = ({ className = '', children = null }: H1Props) => {
  return <h1 className={className}>{children}</h1>;
};

export default H1;
