import { useAppDispatch } from '@state/redux/store';
import { isLoggedIn } from '@utils/helpers/auth';
import { useEffect } from 'react';
import problemSolvingImage from '../../../assets/images/placeholders/undraw_problem_solving_ft81.svg';
import { errorToast } from '../../../libs/helper';
import { redirectTo } from '../../../routes/helpers';
import { confirmAccount, loginAfterSignUp } from '../../../state/redux/slices/auth.slice';

import { useParams } from 'react-router-dom';
import Title from '../../Projects/View/Title';
import { Card, CardBody, Col, Container, ImagePlaceholder, Indicator, Row } from '../../UI/Html';
import Layout from '../../UI/Layout';
import Footer from '../../UI/Layout/Footer';

function ConfirmAccount() {
  const dispatch = useAppDispatch();
  const isSignedIn = isLoggedIn();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      dispatch(
        confirmAccount({
          token,
        })
      )
        .unwrap()
        .then((response) => {
          dispatch(
            loginAfterSignUp({
              refreshToken: response.refreshToken,
            })
          )
            .then(() => {
              redirectTo('/');
            })
            .catch(() => {
              errorToast('Some error occurred');
              return setTimeout(() => {
                redirectTo('/');
              }, 2000);
            });
        })
        .catch(() => {
          errorToast('Some error occurred');
          return setTimeout(() => {
            redirectTo('/');
          }, 2000);
        });
    }
  }, [dispatch, token, isSignedIn]);
  if (!token) {
    redirectTo('/');
  }
  return (
    <Layout isFullWidth>
      <Container className="main-container">
        <Row className="justify-content-center vertical-center">
          <Col lg="5" md="7">
            <Card className="shadow">
              <CardBody className="px-lg-5 py-lg-5 text-center">
                <Title className="">Confirming Account...</Title>
                <div className="text-center text-muted mb-4">
                  <small>Please Wait</small>
                </div>
                <Indicator />
                <ImagePlaceholder
                  src={problemSolvingImage}
                  imageStyle={{
                    width: '80%',
                  }}
                  className="my-4"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <Footer />
      </Container>
    </Layout>
  );
}

export default ConfirmAccount;
