import { Component } from 'react';
import Layout from '../../../components/UI/Layout';
import Redemptions from '../../../components/Users/Redemptions/Redemptions';

class RedemptionsContainer extends Component {
  override render() {
    return (
      <Layout {...this.props}>
        <Redemptions />
      </Layout>
    );
  }
}

export default RedemptionsContainer;
