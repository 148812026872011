import { ReactNode, useLayoutEffect } from 'react';
import { View } from 'react-native-web';
import i18n from '../../../libs/i18n/I18n';
import Container from '../Html/Container';

interface LayoutBodyProps {
  children: ReactNode;
  isFullWidth?: boolean;
}
function LayoutBody(props: LayoutBodyProps) {
  const { isFullWidth, children } = props;
  const isRtl = i18n.dir() === 'rtl';
  useLayoutEffect(() => {
    document.body.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
    if (isRtl) {
      document.body.classList.add('rtls');
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
      document.body.classList.remove('rtls');
    }
  }, [isRtl]);
  if (isFullWidth) {
    return <View>{children}</View>;
  }
  return (
    <Container fluid>
      <View style={{ width: '100%' }}>{children}</View>
    </Container>
  );
}

export default LayoutBody;
