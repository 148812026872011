import classnames from 'classnames';
import _ from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { FiUser } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { redirectTo } from '../../../routes/helpers';
import { translate } from '../../../state/utils/helper';
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from '../../UI/Html';
import Layout from '../../UI/Layout';
import Footer from '../../UI/Layout/Footer';
import EditProfile from './EditProfile';

const Settings: FC = () => {
  const { section } = useParams();
  const navItems = useMemo(
    () => [
      {
        tab: 1,
        section: 'account',
        icon: FiUser,
        label: translate('settings.account'),
        content: <EditProfile />,
      },
    ],
    []
  ); // Add dependencies here if any

  const [tab, setTab] = useState(() => {
    if (section) {
      return navItems.find((navItem) => navItem.section === section)?.tab ?? 1;
    }
    return 1;
  });

  // Rachit need your help here
  useEffect(() => {
    setTab((prevTab) => {
      const prevSection = _.find(navItems, { tab: prevTab });
      const nextSection = _.find(navItems, { section });
      if (prevSection && prevSection.section !== section) {
        window.scrollTo(0, 0);
        if (nextSection) return nextSection.tab;
      }
      return prevSection?.tab ?? 1;
    });
  }, [section, navItems]);

  return (
    <Layout>
      <Container fluid>
        <Row className="mt-4 main-container">
          <Col>
            <Row className="mt-4 justify-content-center">
              <Col md={12} lg={10} xl={8}>
                <>
                  <div className="nav-wrapper">
                    <Nav
                      className="nav-fill flex-column flex-md-row"
                      id="tabs-icons-text"
                      pills
                      role="tablist"
                    >
                      {navItems.map((navItem) => {
                        const IconTag = navItem.icon;
                        return (
                          <NavItem key={navItem.tab}>
                            <NavLink
                              aria-selected={tab === navItem.tab}
                              className={classnames('mb-sm-3 mb-md-0 font-weight-600', {
                                active: tab === navItem.tab,
                              })}
                              onClick={() => {
                                redirectTo(`/settings/${navItem.section}`);
                              }}
                              href="#"
                              role="tab"
                            >
                              <IconTag size={24} className="mr-2" /> {navItem.label}
                            </NavLink>
                          </NavItem>
                        );
                      })}
                    </Nav>
                  </div>
                  <Card className="shadow">
                    <CardBody>
                      <TabContent activeTab={`tabs${String(tab)}`}>
                        {navItems.map((tabPane) => {
                          return (
                            <TabPane
                              key={tabPane.tab}
                              tabId={`tabs${tabPane.tab.toString()}`}
                              className="fadeInUp pt-md-3 px-md-3"
                            >
                              {tab === tabPane.tab && (
                                <div className="fadeInUp">{tabPane.content}</div>
                              )}
                            </TabPane>
                          );
                        })}
                      </TabContent>
                    </CardBody>
                  </Card>
                </>
              </Col>
            </Row>
          </Col>
        </Row>
        <Footer />
      </Container>
    </Layout>
  );
};

export default Settings;
