import Layout from '../../components/UI/Layout';
import ForgotPassword from '../../components/Users/Auth/ForgotPassword';

function SignInContainer() {
  return (
    <Layout isFullWidth bg>
      <ForgotPassword />
    </Layout>
  );
}

export default SignInContainer;
