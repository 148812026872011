import {
  fetchProject,
  fetchReviewable,
  submitReview,
  updateReviewingFrom,
} from '@state/redux/slices/project.slice';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { Form } from 'reactstrap';
import Layout from '../../UI/Layout';

import {
  CanvasAnswer,
  CardReviewAnswer,
  CustomerJourneyMapAnswer,
  PersonaEntryAnswer,
  SubmissionCardAnswer,
} from '@app/@types/redux/answer';
import { CanvasOptions, CustomerJourneyMapOptions } from '@app/@types/redux/pageElement';
import { UserAnswerAnswer } from '@app/@types/redux/project';
import { PAGE_ELEMENT } from '@libs/constants/constants';
import { isLoggedIn } from '@utils/helpers/auth';
import { useLocation, useParams } from 'react-router-dom';
import { TITLE } from '../../../libs/constants/constants';
import { errorToast, slugify } from '../../../libs/helper';
import { i18nCText } from '../../../libs/i18n/I18n';
import { redirectTo } from '../../../routes/helpers';
import { updateLocation } from '../../../state/redux/slices/auth.slice';
import {
  getPageElementAnswerAndType,
  isPageElementAnswered,
} from '../../../state/selectors/projects';
import { translate } from '../../../state/utils/helper';
import { Button, Card, CardBody, Col, Container, Row, SubmitButton } from '../../UI/Html';
import Footer from '../../UI/Layout/Footer';
import { CardReview, FinalCard, MessageReview } from '../TakeProject/Elements';
import CardPersona from '../TakeProject/Elements/HelperComponents/CardPersona';
import CustomerJourneyMapView from '../TakeProject/Elements/HelperComponents/CustomerJourneyMapView';
import CanvasView from '../TakeProject/Pages/ModelCanvas/CanvasView';
import Title from '../View/Title';

function UserAnswerReview() {
  const { projectId, userAnswerId, workspaceId } = useParams();
  const location = useLocation();
  const isSignedIn = isLoggedIn();
  const [projectIsFetching, setProjectIsFetching] = useState(true);
  const [reviewableIsFetching, setReviewableIsFetching] = useState(true);
  // const userProject = useAppSelector((state) =>
  //   state?.projects?.currentProject?.userProject)
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  // Initialize react-hook-form components and methods
  const { handleSubmit } = useForm({
    mode: 'onChange',
  });
  const reviewableFromState = useAppSelector((state) => state.projects.reviewable);
  const reviewing = useAppSelector((state) => state.projects.reviewing);
  const userId = useAppSelector((state) => state.users.user.id);
  const projectFromState = useAppSelector((state) => state.projects.currentProject.project);
  const dispatch = useAppDispatch();
  const [reviewable, setReviewable] = useState(reviewableFromState);
  const [project, setProject] = useState(projectFromState);

  const [answer, setAnswer] = useState<CardReviewAnswer>({
    competencyFeedbacks: {},
    feedbacks: {},
    text: '',
    preCheckFailed: false,
    userAnswerFeedbackId: '',
  });

  const state = useAppSelector((state) => state.projects);

  // Setting answer and reviewable
  useEffect(() => {
    setAnswer(reviewableFromState.review as CardReviewAnswer);
    setReviewable(reviewableFromState);
  }, [reviewableFromState]);

  // Settings project after fetch
  useEffect(() => {
    setProject(projectFromState);
  }, [projectFromState]);

  // Fetching reviewable, project and userProject
  useEffect(() => {
    if (projectId && userAnswerId) {
      setLoading(true);
      dispatch(fetchReviewable({ projectId, userAnswerId, userId }))
        .then(() => {
          setReviewableIsFetching(false);
          dispatch(fetchProject({ id: projectId }))
            .then(() => {
              setProjectIsFetching(false);
              setLoading(false);
            })
            .catch(() => {
              setProjectIsFetching(false);
              setLoading(false);
            });
        })
        .catch(() => {
          setReviewableIsFetching(false);
          setLoading(false);
        });
    }
  }, [projectId, userAnswerId, userId]);
  useEffect(() => {
    return () => {
      dispatch(
        updateReviewingFrom({
          reviewingFrom: 'direct',
          reviewingUserAnswerId: '',
        })
      );
    };
  }, [dispatch]);
  let reviewComingFrom = reviewing.reviewingFrom;
  if (
    userAnswerId &&
    parseInt(reviewing.reviewingUserAnswerId, 10) !== parseInt(userAnswerId, 10)
  ) {
    reviewComingFrom = 'direct';
  }

  // For filtering card or submission card type
  const userAnswer =
    reviewable.items.length > 0
      ? reviewable.items.filter((i) =>
          [
            PAGE_ELEMENT.CARD,
            PAGE_ELEMENT.SUBMISSION_CARD,
            PAGE_ELEMENT.CANVAS,
            PAGE_ELEMENT.CUSTOMER_JOURNEY_MAP,
          ].includes(i.attributes.pageElement.attributes.elementType)
        )[0]
      : undefined;

  // For getting card_review page element
  const reviewableElement = reviewable.reviewPageElements.find(
    (i) => i.attributes.elementType === PAGE_ELEMENT.CARD_REVIEW
  );

  const [reviewElementType, setReviewElementType] = useState<PAGE_ELEMENT>(PAGE_ELEMENT.NULL);
  useEffect(() => {
    userAnswer && setReviewElementType(userAnswer.attributes.pageElement.attributes.elementType);
  }, [userAnswer]);

  type GetAnswerType = (
    peId: string
  ) => { answer: UserAnswerAnswer; type: PAGE_ELEMENT } | undefined;
  const getAnswerType: GetAnswerType = (peId: string) => getPageElementAnswerAndType(state, peId);

  // For overriding helmet meta tags
  let title;
  let description;
  let image;
  if (reviewElementType === PAGE_ELEMENT.SUBMISSION_CARD && userAnswer) {
    const answer = userAnswer.attributes.answer as SubmissionCardAnswer;
    description = '';
    title = answer.title;
    if (['gallery', 'model'].includes(answer.type)) {
      image = answer.images[0]?.regular;
    } else {
      image = '';
    }
  }

  if (!title) {
    title = `Review submission | ${i18nCText(project.attributes.name)} | ${TITLE}`;
  } else {
    title = `${title} | ${i18nCText(project.attributes.name)} | ${TITLE}`;
  }

  const label: Record<string, string> = {
    card: translate('reviewPage.idea'),
    submission_card: translate('reviewPage.submission'),
    canvas: translate('reviewPage.submission'),
  };

  const isFetching = projectIsFetching || reviewableIsFetching;
  const onSubmit = () => {
    if (reviewableElement && userAnswerId && projectId) {
      const res = isPageElementAnswered({
        pageElement: reviewableElement,
        pageElementAnswer: answer,
      });
      if (res === 'answered') {
        setSubmitLoading(true);
        dispatch(
          submitReview({
            userAnswerId,
            userAnswer: answer,
            projectId,
            reviewingFrom: btoa(reviewComingFrom || 'direct'),
            successCallback: () => {
              setSubmitLoading(false);
              if (workspaceId)
                redirectTo(
                  `/workspace/${workspaceId}/challenge/${projectId}/${slugify(
                    project.attributes.name
                  )}?section=overview`
                );
            },
            errorCallback: () => {
              setSubmitLoading(false);
            },
          })
        ).catch(() => {
          setSubmitLoading(false);
        });
      } else {
        errorToast(res);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="title" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="twitter:image" content={image} />
      </Helmet>
      <Layout isFullWidth>
        <div className="header bg-info pb-4">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  {project.attributes.name && (
                    <h1 className="text-white mb-0 text-uppercase headingSubtitle">
                      {i18nCText(project.attributes.name)}
                    </h1>
                  )}
                </Col>
                <Col className="text-right" xs={12} sm={12} md={12} lg={6} xl={6}>
                  &nbsp;
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container fluid>
          <Form role="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Row className="justify-content-center mt--3 mx-0">
              <Col className="p-0 px-md-3">
                <Card>
                  <CardBody className="px-2 px-md-3">
                    {isFetching ? (
                      <Skeleton count={3} />
                    ) : (
                      <>
                        <Title className="px-4 px-md-3">
                          {translate('reviewPage.title', {
                            key: label[reviewElementType],
                          })}
                        </Title>
                        {reviewable.items.map((item, index) => {
                          const keyId = `${item.attributes.id}-idea-review`;
                          const pe = item.attributes.pageElement;
                          const reviewablePageElement = reviewable.reviewPageElements.find(
                            (pe) => pe.attributes.elementType === PAGE_ELEMENT.CARD_REVIEW
                          );
                          if (pe.id) {
                            if (
                              [
                                PAGE_ELEMENT.CARD,
                                PAGE_ELEMENT.SUBMISSION_CARD,
                                PAGE_ELEMENT.PERSONA_ENTRY,
                                PAGE_ELEMENT.CANVAS,
                                PAGE_ELEMENT.CUSTOMER_JOURNEY_MAP,
                                PAGE_ELEMENT.PRIORITIZATION_MATRIX,
                              ].includes(pe.attributes.elementType)
                            ) {
                              if (
                                parseInt(item.attributes.userId, 10) === parseInt(userId, 10) ||
                                !isSignedIn
                              ) {
                                if (pe.attributes.elementType === PAGE_ELEMENT.SUBMISSION_CARD) {
                                  return (
                                    <FinalCard
                                      key={keyId}
                                      pageElement={pe}
                                      answer={(payload: UserAnswerAnswer) => {
                                        setAnswer(payload as CardReviewAnswer);
                                      }}
                                      currentAnswer={item.attributes.answer as SubmissionCardAnswer}
                                    />
                                  );
                                }
                                if (pe.attributes.elementType === PAGE_ELEMENT.CANVAS) {
                                  return (
                                    <div className="m-3" key={keyId}>
                                      <CanvasView
                                        elements={item.attributes.answer as CanvasAnswer}
                                        options={pe.attributes.options as CanvasOptions}
                                      />
                                    </div>
                                  );
                                }
                                if (pe.attributes.elementType === PAGE_ELEMENT.PERSONA_ENTRY) {
                                  return (
                                    <CardPersona
                                      key={keyId}
                                      persona={item.attributes.answer as PersonaEntryAnswer}
                                    />
                                  );
                                }
                                if (
                                  pe.attributes.elementType === PAGE_ELEMENT.CUSTOMER_JOURNEY_MAP
                                ) {
                                  return (
                                    <CustomerJourneyMapView
                                      key={keyId}
                                      currentAnswer={
                                        item.attributes.answer as CustomerJourneyMapAnswer
                                      }
                                      submissionQuestion={
                                        (pe.attributes.options as CustomerJourneyMapOptions)
                                          .submissionQuestion
                                      }
                                      disabled
                                    />
                                  );
                                }
                              }
                              if (
                                reviewablePageElement?.attributes.elementType ===
                                PAGE_ELEMENT.CARD_REVIEW
                              ) {
                                return (
                                  <CardReview
                                    key={keyId}
                                    pageElement={reviewablePageElement}
                                    answer={(payload) => {
                                      setAnswer(payload);
                                    }}
                                    reviewableItem={item}
                                    currentAnswer={answer}
                                    external
                                    externalDisabled={!!answer.userAnswerFeedbackId}
                                    externalLoading={loading}
                                  />
                                );
                              }
                            }
                            if (pe.attributes.elementType === PAGE_ELEMENT.MESSAGE) {
                              return (
                                <MessageReview
                                  key={index}
                                  pageElement={pe}
                                  currentAnswer={item.attributes.answer}
                                  state={state}
                                  getDependeeAnswerType={
                                    getAnswerType as (peId: string) => {
                                      answer: UserAnswerAnswer;
                                      type: PAGE_ELEMENT;
                                    }
                                  }
                                  answer={(payload: UserAnswerAnswer) => {
                                    setAnswer(payload as CardReviewAnswer);
                                  }}
                                />
                              );
                            }
                          }
                          return '';
                        })}
                        <Row>
                          <Col className="text-center mt-5">
                            {isSignedIn ? (
                              !answer.userAnswerFeedbackId &&
                              parseInt(reviewable.items[0]?.attributes.userId, 10) !==
                                parseInt(userId, 10) && (
                                <SubmitButton color="primary" type="submit" loading={submitLoading}>
                                  {translate('reviewPage.submitReview')}
                                </SubmitButton>
                              )
                            ) : (
                              <Button
                                color="info"
                                onClick={() => {
                                  redirectTo('/sign_in');
                                  dispatch(updateLocation(location));
                                }}
                              >
                                {translate('reviewPage.signAndReview')}
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
          <Footer />
        </Container>
      </Layout>
    </>
  );
}

export default UserAnswerReview;
