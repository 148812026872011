import { ButtonHTMLAttributes, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

interface CButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
  children: ReactNode;
  href?: string;
  onClick?: (e: React.MouseEvent) => void;
  loading?: boolean;
  className?: string;
  size?: 'sm' | 'lg';
}

function CButton(props: CButtonProps) {
  const {
    color = 'primary',
    children,
    href = null,
    onClick = null,
    loading = false,
    className = '',
    ...rest
  } = props;
  if (loading) {
    return (
      <Button color={color} className={className} disabled {...rest}>
        ...
      </Button>
    );
  }
  if (href) {
    return (
      <Button color={color} className={className} tag={Link} to={href} onClick={onClick} {...rest}>
        {children}
      </Button>
    );
  }
  return (
    <Button color={color} className={className} onClick={onClick} href={href} {...rest}>
      {children}
    </Button>
  );
}

CButton.displayName = 'CButton';

export default CButton;
