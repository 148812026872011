import i18n from '@libs/i18n/I18n';
import { useAppDispatch } from '@state/redux/store';
import { isLoggedIn } from '@utils/helpers/auth';
import { useEffect } from 'react';
import { redirectTo } from '../../../routes/helpers';
import { toggleLoginModal } from '../../../state/redux/slices/auth.slice';
import { translate } from '../../../state/utils/helper';
import { A, Card, Col, Container, Row } from '../../UI/Html';
import Layout from '../../UI/Layout';
import Footer from '../../UI/Layout/Footer';
import Header from './Header';
import LoginForm from './LoginForm';
import './SignIn.scss';

function SignIn() {
  const isSignedIn = isLoggedIn();
  if (isSignedIn) {
    redirectTo('/me');
  }
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(toggleLoginModal(false));
  }, [dispatch]);

  useEffect(() => {
    document.body.classList.add('bg-default-color');
    return () => {
      document.body.classList.remove('bg-default-color');
    };
  }, []);

  return (
    <Layout isFullWidth bg>
      <Header title={translate('auth.signInHeading')} lead={translate('auth.signInSubHeading')} />
      <Container className="mt--8 pb-5 main-container-sign-out">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <div className="p-4">
                <LoginForm />
              </div>
            </Card>
            <div className="signIn__footerLinks">
              <div />
              <A className="text-white" href="/forgot_password">
                {i18n.t('auth.forgotPassword.heading').toString()}
              </A>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Footer />
      </Container>
    </Layout>
  );
}

export default SignIn;
