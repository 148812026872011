import { CanvasAnswer, InputStyle4Answer } from '@app/@types/redux/answer';
import { TextareaOptions } from '@app/@types/redux/pageElement';
import logger from '@libs/log';
import html2canvas from 'html2canvas';
import _ from 'lodash';
import { useRef } from 'react';
import { HiPrinter } from 'react-icons/hi';
import { Button } from 'reactstrap';
import { slugify } from '../../../../../libs/helper';
import { i18nCText } from '../../../../../libs/i18n/I18n';
import './SWOTCanvas.scss';

interface SWOTCanvasProps {
  elements: CanvasAnswer;
}
function SWOTCanvas({ elements }: SWOTCanvasProps) {
  const sortedCollection = _.sortBy(elements, (item) => {
    return ['Strengths', 'Weaknesses', 'Opportunities', 'Threats'].indexOf(
      (item.pageElement.options as TextareaOptions).label
    );
  }).map((item) => {
    return {
      id: slugify((item.pageElement.options as TextareaOptions).label || ''),
      label: (item.pageElement.options as TextareaOptions).label,
      answers: (item.answer as InputStyle4Answer).values,
    };
  });
  const strengths = sortedCollection.find((item) => item.label === 'Strengths');
  const weaknesses = sortedCollection.find((item) => item.label === 'Weaknesses');
  const opportunities = sortedCollection.find((item) => item.label === 'Opportunities');
  const threats = sortedCollection.find((item) => item.label === 'Threats');

  const captureRef = useRef<HTMLDivElement>(null);

  const handleCapture = () => {
    const captureElement = captureRef.current;
    if (captureElement) {
      html2canvas(captureElement, {
        allowTaint: true,
        useCORS: true,
        ignoreElements: (el) => {
          if (
            el.classList.contains('print-button') ||
            el.classList.contains('swotCanvas__cornerImage') ||
            el.classList.contains('pointer')
          ) {
            return true;
          }
          return false; // Explicitly return false when conditions are not met
        },
      })
        .then((canvas) => {
          const imageData = canvas.toDataURL(); // Convert canvas to base64 image data
          const dataUri = `<img src="${imageData}" />`;
          const printWindow = window.open('');
          const imageElement = new Image();

          imageElement.onload = () => {
            printWindow?.document.write(`
            <html>
              <head>
                <title>Print SWOT Canvas</title>
                <style>
                  @page {
                    size: landscape;
                  }
                  body {
                    margin: 0;
                    padding: 0;
                  }
                  img {
                    max-width: calc(100% - 40px);
                    height: auto;
                    page-break-inside: avoid;
                    padding: 20px;
                  }
                </style>
              </head>
              <body>
                ${dataUri}
              </body>
              <script>
                window.addEventListener('afterprint', () => {
                  window.close();
                });
              </script>
            </html>
          `);

            // Trigger print dialog for the opened window after the image has loaded
            printWindow?.print();
          };

          imageElement.src = imageData;
        })
        .catch(() => {
          logger.warn('Error capturing screenshot');
        });
    }
  };

  return (
    <div className="printArea" ref={captureRef}>
      <div className="swotCanvas__header">
        <h1 className="swotCanvas__title">{i18nCText('SWOT Canvas')}</h1>
        <div>
          <Button
            className="print-button"
            size="sm"
            color="default"
            type="button"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              handleCapture();
            }}
          >
            <HiPrinter className="mr-2" />
            Print
          </Button>
        </div>
      </div>
      <div className="swotCanvas__wrapper">
        <div className="swotCanvas__row">
          <div className="swotCanvas__item swotCanvas__strengths">
            <h2 className="swotCanvas__title">{i18nCText('Strengths')}</h2>
            <ul className="swotCanvas__answers">
              {strengths?.answers.map((item, index) => {
                return <li key={index}>{item.value}</li>;
              })}
            </ul>
            <div className="swotCanvas__cornerImage"></div>
          </div>
          <div className="swotCanvas__item swotCanvas__weaknesses">
            <h2 className="swotCanvas__title">{i18nCText('Weaknesses')}</h2>
            <div className="">
              <ul className="swotCanvas__answers">
                {weaknesses?.answers.map((item, index) => {
                  return <li key={index}>{item.value}</li>;
                })}
              </ul>
              <div className="swotCanvas__cornerImage"></div>
            </div>
          </div>
        </div>
        <div className="swotCanvas__row">
          <div className="swotCanvas__item swotCanvas__opportunities">
            <h2 className="swotCanvas__title">{i18nCText('Opportunities')}</h2>
            <ul className="swotCanvas__answers">
              {opportunities?.answers.map((item, index) => {
                return <li key={index}>{item.value}</li>;
              })}
            </ul>
            <div className="swotCanvas__cornerImage"></div>
          </div>
          <div className="swotCanvas__item swotCanvas__threats">
            <h2 className="swotCanvas__title">{i18nCText('Threats')}</h2>
            <div className="">
              <ul className="swotCanvas__answers">
                {threats?.answers.map((item, index) => {
                  return <li key={index}>{item.value}</li>;
                })}
              </ul>
              <div className="swotCanvas__cornerImage"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SWOTCanvas;
