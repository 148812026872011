import { useState } from 'react';
import EllipsisText from 'react-ellipsis-text';
import { AiOutlineLike } from 'react-icons/ai';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { FaGlobeAmericas, FaPencilAlt, FaRegCommentAlt } from 'react-icons/fa';
import { RiShareForwardLine } from 'react-icons/ri';
import { Col, Row } from '../../../../../UI/Html';
import './AdBuilder.scss';

import { AddMediaImage, SubmissionCardAdAnswer } from '@app/@types/redux/answer';
import AddMedia from '../AddMedia';

const AdBuilder = (props: {
  currentAnswer: SubmissionCardAdAnswer;
  answer?: (arg0: SubmissionCardAdAnswer) => void;
  edit?: boolean;
}) => {
  const { currentAnswer, answer, edit = false } = props;
  const title = currentAnswer.title || 'John Doe';
  const description =
    currentAnswer.description ||
    'Donec rutrum congue leo eget malesuada. Nulla porttitor accumsan tincidunt. Pellentesque in ipsum id orci porta dapibus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Nulla porttitor accumsan tincidunt.';
  const headline = currentAnswer.headline || 'Digital Marketing Event';
  const url = currentAnswer.url || 'https://www.example.com';
  const image = currentAnswer.image;
  const profileImage =
    currentAnswer.profileImage.regular || 'https://www.w3schools.com/howto/img_avatar.png';
  const displayLink = currentAnswer.displayLink || 'www.example.com';
  const callToAction = currentAnswer.callToAction || 'Learn More';
  const [addMediaOpen, setAddMediaOpen] = useState(false);
  const [addProfilePhotoOpen, setAddProfilePhotoOpen] = useState(false);
  return (
    <Row>
      <Col style={{ textAlign: 'initial' }}>
        <div className="adBuilder__wrapper">
          <div className="adBuilder__header">
            <div className="adBuilder__headerRight">
              {edit ? (
                <>
                  <button
                    className="adBuilder__profileImageWrapper"
                    type="button"
                    onClick={() => {
                      setAddProfilePhotoOpen(true);
                    }}
                  >
                    <div className="adBuilder__profileImageOverlay">
                      <FaPencilAlt />
                    </div>
                    <img src={profileImage} alt="ad" />
                  </button>
                  <AddMedia
                    open={addProfilePhotoOpen}
                    onRequestClose={() => {
                      setAddProfilePhotoOpen(false);
                    }}
                    onSelect={(imageObject) => {
                      answer &&
                        answer({
                          ...currentAnswer,
                          profileImage: imageObject as AddMediaImage,
                        });
                    }}
                  />
                </>
              ) : (
                <div className="adBuilder__profileImage">
                  <img src={profileImage} alt="profile" />
                </div>
              )}

              <div className="adBuilder__nameWrapper">
                <h1>{title}</h1>
                <h2>
                  <span>Sponsored &#8226; </span>
                  <FaGlobeAmericas />
                </h2>
              </div>
            </div>
            <div className="adBuilder__headerLeft">
              <BiDotsHorizontalRounded />
            </div>
          </div>
          <div className="adBuilder__description">
            <p>{description}</p>
          </div>
          {edit ? (
            <>
              <div className="adBuilder__addMediaWrapperDiv">
                <button
                  className="adBuilder__addMediaWrapper"
                  type="button"
                  onClick={() => {
                    setAddMediaOpen(true);
                  }}
                >
                  <div className="adBuilder__addMediaOverlay">Click here to edit the image</div>
                  <img src={image.regular} />
                </button>
              </div>
              <AddMedia
                open={addMediaOpen}
                onRequestClose={() => {
                  setAddMediaOpen(false);
                }}
                onSelect={(imageObject) => {
                  answer &&
                    answer({
                      ...currentAnswer,
                      image: imageObject as AddMediaImage,
                    });
                }}
              />
            </>
          ) : (
            <div className="adBuilder__image">
              <img src={image.regular} alt="ad" />
            </div>
          )}
          <div className="adBuilder__urlWrapper">
            <div className="adBuilder__urlWrapperRight">
              <EllipsisText text={displayLink || ''} length={40} />
              <h2>{headline}</h2>
            </div>
            <div className="adBuilder__urlWrapperLeft">
              <button
                type="button"
                onClick={() => {
                  window.open(url, '_blank');
                }}
              >
                {callToAction}
              </button>
            </div>
          </div>
          <div className="adBuilder__actionWrapper">
            <div>
              <AiOutlineLike />
              <span>Like</span>
            </div>
            <div>
              <FaRegCommentAlt />
              <span>Comment</span>
            </div>
            <div>
              <RiShareForwardLine />
              <span>Share</span>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AdBuilder;
