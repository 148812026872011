import CircularProgress from '@components/UI/Html/CircularProgress';
import Dropzone from '@components/UI/Html/Dropzone';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { successToast } from '@libs/helper';
import useUpload from '@libs/hooks/useUpload';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import { translate } from '@state/utils/helper';
import classnames from 'classnames';
import 'flag-icon-css/css/flag-icon.min.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineLink } from 'react-icons/ai';
import {
  BsBehance,
  BsBriefcase,
  BsDribbble,
  BsGithub,
  BsInfoCircle,
  BsLinkedin,
} from 'react-icons/bs';
import { CgMail } from 'react-icons/cg';
import { FaSave } from 'react-icons/fa';
import { FiCamera as Camera, FiUser as User } from 'react-icons/fi';
import { Input } from 'reactstrap';
import { ZodType, z } from 'zod';
import { grey } from '../../../assets/images';
import { fetchMe } from '../../../state/redux/slices/auth.slice';
import { updateAccountSettings } from '../../../state/redux/slices/user.slice';
import {
  Animated,
  Button,
  Col,
  Form,
  FormGroup,
  H5,
  ImagePlaceholder,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  SubmitButton,
} from '../../UI/Html';
import ErrorAlert from '../../UI/Html/Form/Error';

interface EditProfileForm {
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl: string;
  about?: string;
  occupation?: string;
  linkedIn?: string;
  behance?: string;
  dribble?: string;
  github?: string;
  customLink1?: string;
  customLink2?: string;
}

function EditProfile() {
  // dispatch initialize from redux
  const dispatch = useAppDispatch();
  // Focus in puts and styling the input groups
  const [focusName, setFocusName] = useState(false);
  const [focusAbout, setFocusAbout] = useState(false);
  const [focusOccupation, setFocusOccupation] = useState(false);
  const [focusLinkedIn, setFocusLinkedIn] = useState(false);
  const [focusBehance, setFocusBehance] = useState(false);
  const [focusDribble, setFocusDribble] = useState(false);
  const [focusGithub, setFocusGithub] = useState(false);
  const [focusCustomLink1, setFocusCustomLink1] = useState(false);
  const [focusCustomLink2, setFocusCustomLink2] = useState(false);

  const userId = useAppSelector((state) => state.users.user.id);
  useEffect(() => {
    dispatch(fetchMe()).catch(() => {
      // redirectTo(DEFAULT_PATH)
    });
  }, [dispatch, userId]);
  const userDataFromState = useAppSelector((state) => state.users.user.attributes);

  const [image, setImage] = useState(grey);

  // Submit button loading state
  const [submitLoading, setSubmitLoading] = useState(false);

  const validationSchema: ZodType<EditProfileForm> = z.object({
    firstName: z.string().min(2, 'First name should be at least 2 characters'),
    lastName: z.string(),
    email: z.string().email('Invalid email address'),
    avatarUrl: z.string(),
    about: z.string().optional(),
    occupation: z.string().optional(),
    linkedIn: z
      .string()
      .url("LinkedIn URL should be in the format 'https://www.linkedin.com/in/username'")
      .optional()
      .or(z.literal('')),
    behance: z
      .string()
      .url("Behance URL should be in the format 'https://www.behance.net/username'")
      .optional()
      .or(z.literal('')),
    dribble: z
      .string()
      .url("Dribble URL should be in the format 'https://www.dribble.com/username'")
      .optional()
      .or(z.literal('')),
    github: z
      .string()
      .url("Github URL should be in the format 'https://www.github.com/username'")
      .optional()
      .or(z.literal('')),
    customLink1: z
      .string()
      .url("Custom link URL should be in the format 'https://www.yourwebsite.com'")
      .optional()
      .or(z.literal('')),
    customLink2: z
      .string()
      .url("Custom link URL should be in the format 'https://www.yourwebsite.com'")
      .optional()
      .or(z.literal('')),
  });

  useEffect(() => {
    setImage(userDataFromState.avatarUrl);
  }, [userDataFromState.avatarUrl]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<EditProfileForm>({
    mode: 'onChange',
    resolver: zodResolver(validationSchema),
    defaultValues: {
      firstName: userDataFromState.firstName,
      lastName: userDataFromState.lastName,
      email: userDataFromState.email,
      avatarUrl: userDataFromState.avatarUrl,
      about: userDataFromState.about ?? '',
      occupation: userDataFromState.userExperience.occupation,
      linkedIn: userDataFromState.socialMediaLinks?.find((o) => o.type === 'LinkedIn')?.value ?? '',
      behance: userDataFromState.socialMediaLinks?.find((o) => o.type === 'Behance')?.value ?? '',
      dribble: userDataFromState.socialMediaLinks?.find((o) => o.type === 'Dribble')?.value ?? '',
      github: userDataFromState.socialMediaLinks?.find((o) => o.type === 'Github')?.value ?? '',
      customLink1:
        userDataFromState.socialMediaLinks?.find((o) => o.type === 'Custom1')?.value ?? '',
      customLink2:
        userDataFromState.socialMediaLinks?.find((o) => o.type === 'Custom2')?.value ?? '',
    },
  });

  const { ref: refFirstName, ...registerFirstName } = register('firstName');
  const { ref: refLastName, ...registerLastName } = register('lastName');
  const { ref: refEmail, ...registerEmail } = register('email');
  const { ref: refAbout, ...registerAbout } = register('about');
  const { ref: refOccupation, ...registerOccupation } = register('occupation');
  const { ref: refLinkedIn, ...registerLinkedIn } = register('linkedIn');
  const { ref: refBehance, ...registerBehance } = register('behance');
  const { ref: refDribble, ...registerDribble } = register('dribble');
  const { ref: refGithub, ...registerGithub } = register('github');
  const { ref: refCustomLink1, ...registerCustomLink1 } = register('customLink1');
  const { ref: refCustomLink2, ...registerCustomLink2 } = register('customLink2');

  // Submit form handle
  const onSubmit = (data: EditProfileForm) => {
    const socialMediaHandles = [];
    if (data.linkedIn) socialMediaHandles.push({ type: 'LinkedIn', value: data.linkedIn });
    if (data.behance) socialMediaHandles.push({ type: 'Behance', value: data.behance });
    if (data.github) socialMediaHandles.push({ type: 'Github', value: data.github });
    if (data.dribble) socialMediaHandles.push({ type: 'Dribble', value: data.dribble });
    if (data.customLink1) socialMediaHandles.push({ type: 'Custom1', value: data.customLink1 });
    if (data.customLink2) socialMediaHandles.push({ type: 'Custom2', value: data.customLink2 });
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      avatarUrl: data.avatarUrl,
      about: data.about,
      socialMediaLinks: socialMediaHandles,
      userExperience: { occupation: data.occupation },
    };
    setSubmitLoading(true);
    dispatch(updateAccountSettings(payload))
      .then(() => {
        successToast(translate('editProfile.success'));
        setSubmitLoading(false);
      })
      .catch(() => {
        setSubmitLoading(false);
      });
  };

  const [imageUploading, setImageUploading] = useState(false);
  const { upload, progress } = useUpload();
  const onImageDrop = (acceptedFiles: File[]) => {
    setImageUploading(true);
    upload(acceptedFiles[0], (imageUrl: string) => {
      setImage(imageUrl);
      setValue('avatarUrl', imageUrl);

      setImageUploading(false);
    });
  };

  return (
    <>
      <Row className="edit-profile">
        <Col>
          <Dropzone onDrop={onImageDrop} className="editProfile__dropzone">
            <ImagePlaceholder
              width={200}
              height={200}
              src={image}
              className="avatar-round text-center object-fit"
            />
            <Animated isVisible={imageUploading}>
              <div className="editProfile__imageUploading">
                <div style={{ width: 50, height: 50 }}>
                  <CircularProgress progress={progress} />
                </div>
              </div>
            </Animated>
            <Button className="avatar-change">
              <Camera size={24} />
            </Button>
          </Dropzone>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={12} lg={12} xl={8}>
          <Form
            role="form"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
            className="edit-profile"
          >
            <Row className="mt-5">
              <Col>
                <FormGroup className={errors.firstName ? 'mb-3' : 'mb-4'}>
                  <Label className="form-control-label" for="firstName">
                    {translate('editProfile.label.fullName')}
                  </Label>
                  <InputGroup
                    className={classnames('input-group-merge', {
                      focused: focusName,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <User size={20} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Eg. John"
                      type="text"
                      id="firstName"
                      required
                      autoComplete="firstName"
                      onFocus={() => {
                        setFocusName(true);
                      }}
                      innerRef={refFirstName}
                      {...registerFirstName}
                      onBlur={() => {
                        setFocusName(false);
                      }}
                    />
                    <Input
                      placeholder="Eg. Doe"
                      type="text"
                      id="lastName"
                      autoComplete="lastName"
                      onFocus={() => {
                        setFocusName(true);
                      }}
                      innerRef={refLastName}
                      {...registerLastName}
                      onBlur={() => {
                        setFocusName(false);
                      }}
                    />
                  </InputGroup>
                </FormGroup>
                <ErrorAlert errors={Boolean(errors.firstName)}>
                  <ErrorMessage errors={errors} name="firstName" />
                </ErrorAlert>

                <H5 className="heading-small text-muted mb-4">
                  {translate('editProfile.heading.contactInformation')}
                </H5>
                <Row>
                  <Col>
                    <FormGroup className={errors.email ? 'mb-3' : 'mb-4'}>
                      <Label className="form-control-label" for="email">
                        {translate('editProfile.label.emailAddress')}
                      </Label>
                      <InputGroup className="input-group-merge">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <CgMail size={20} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={translate('editProfile.label.emailAddress')}
                          type="email"
                          id="email"
                          disabled
                          required
                          autoComplete="email"
                          className="email-disabled"
                          innerRef={refEmail}
                          {...registerEmail}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <H5 className="heading-small text-muted mb-4">Experience</H5>
                <Row>
                  <Col>
                    {/* Occupation start */}
                    <FormGroup className={errors.occupation ? 'mb-3' : 'mb-4'}>
                      <Label className="form-control-label" for="email">
                        Occupation
                      </Label>
                      <InputGroup
                        className={classnames('input-group-merge', {
                          focused: focusOccupation,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <BsBriefcase size={20} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="occupation"
                          type="text"
                          placeholder="Eg. Graphic Designer, Developer"
                          onFocus={() => {
                            setFocusOccupation(true);
                          }}
                          autoComplete="occupation"
                          innerRef={refOccupation}
                          {...registerOccupation}
                          onBlur={() => {
                            setFocusOccupation(false);
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    {/* Occupation end */}

                    {/* BIO  START */}
                    <FormGroup className={errors.about ? 'mb-3' : 'mb-4'}>
                      <Label className="form-control-label" for="email">
                        {translate('editProfile.label.about')}
                      </Label>
                      <InputGroup
                        className={classnames('input-group-merge', {
                          focused: focusAbout,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <BsInfoCircle size={20} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="about"
                          type="text"
                          placeholder="Eg. Experienced graphic designer with a passion for minimalist design."
                          onFocus={() => {
                            setFocusAbout(true);
                          }}
                          maxLength={250}
                          autoComplete="about"
                          innerRef={refAbout}
                          {...registerAbout}
                          onBlur={() => {
                            setFocusAbout(false);
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    {/* BIO END */}
                  </Col>
                </Row>

                <H5 className="heading-small text-muted mb-4">Social Media</H5>
                <Row>
                  <Col>
                    {/* Social media handles */}
                    {/* Linkedin Start */}
                    <FormGroup className={errors.linkedIn ? 'mb-3' : 'mb-4'}>
                      <InputGroup
                        className={classnames('input-group-merge', {
                          focused: focusLinkedIn,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <BsLinkedin size={20} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="linkedIn"
                          placeholder="Eg. linkedin.com/in/john.doe"
                          type="linkedIn"
                          onFocus={() => {
                            setFocusLinkedIn(true);
                          }}
                          autoComplete="linkedIn"
                          innerRef={refLinkedIn}
                          {...registerLinkedIn}
                          onBlur={() => {
                            setFocusLinkedIn(false);
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <ErrorAlert errors={Boolean(errors.linkedIn)}>
                      {errors.linkedIn?.message}
                    </ErrorAlert>
                    {/* Linkedin End */}

                    {/* Behance Start */}
                    <FormGroup className={errors.behance ? 'mb-3' : 'mb-4'}>
                      <InputGroup
                        className={classnames('input-group-merge', {
                          focused: focusBehance,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <BsBehance size={20} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="behance"
                          placeholder="Eg. behance.net/john.doe"
                          type="behance"
                          onFocus={() => {
                            setFocusBehance(true);
                          }}
                          autoComplete="behance"
                          innerRef={refBehance}
                          {...registerBehance}
                          onBlur={() => {
                            setFocusBehance(false);
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <ErrorAlert errors={Boolean(errors.behance)}>
                      {errors.behance?.message}
                    </ErrorAlert>
                    {/* Behance End */}

                    {/* Dribble start */}
                    <FormGroup className={errors.dribble ? 'mb-3' : 'mb-4'}>
                      <InputGroup
                        className={classnames('input-group-merge', {
                          focused: focusDribble,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <BsDribbble size={20} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="dribble"
                          placeholder="Eg. dribbble.com/john.doe"
                          type="dribble"
                          onFocus={() => {
                            setFocusDribble(true);
                          }}
                          autoComplete="dribble"
                          innerRef={refDribble}
                          {...registerDribble}
                          onBlur={() => {
                            setFocusDribble(false);
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <ErrorAlert errors={Boolean(errors.dribble)}>
                      {errors.dribble?.message}
                    </ErrorAlert>
                    {/* Dribble End */}

                    {/* GitHub Start */}
                    <FormGroup className={errors.github ? 'mb-3' : 'mb-4'}>
                      <InputGroup
                        className={classnames('input-group-merge', {
                          focused: focusGithub,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <BsGithub size={20} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="github"
                          placeholder="Eg. github.com/john.doe"
                          type="github"
                          onFocus={() => {
                            setFocusGithub(true);
                          }}
                          autoComplete="github"
                          innerRef={refGithub}
                          {...registerGithub}
                          onBlur={() => {
                            setFocusGithub(false);
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <ErrorAlert errors={Boolean(errors.github)}>
                      {errors.github?.message}
                    </ErrorAlert>
                    {/* GitHub End */}
                  </Col>
                </Row>
                <H5 className="heading-small text-muted mb-4">Custom links</H5>
                <Row>
                  <Col>
                    {/* Custom Site 1 start */}
                    <FormGroup className={errors.customLink1 ? 'mb-3' : 'mb-4'}>
                      <InputGroup
                        className={classnames('input-group-merge', {
                          focused: focusCustomLink1,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <AiOutlineLink size={20} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="customLink1"
                          placeholder="Eg. personalwebsite.com/john.doe"
                          type="customLink1"
                          onFocus={() => {
                            setFocusCustomLink1(true);
                          }}
                          autoComplete="customLink1"
                          innerRef={refCustomLink1}
                          {...registerCustomLink1}
                          onBlur={() => {
                            setFocusCustomLink1(false);
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <ErrorAlert errors={Boolean(errors.customLink1)}>
                      {errors.customLink1?.message}
                    </ErrorAlert>
                    {/* Custom Site 1 End */}

                    {/* Custom Site 2 Start */}
                    <FormGroup className={errors.customLink2 ? 'mb-3' : 'mb-4'}>
                      <InputGroup
                        className={classnames('input-group-merge', {
                          focused: focusCustomLink2,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <AiOutlineLink size={20} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="customLink2"
                          placeholder="Eg. portfolio.johndoe.com"
                          type="customLink2"
                          onFocus={() => {
                            setFocusCustomLink2(true);
                          }}
                          autoComplete="customLink2"
                          innerRef={refCustomLink2}
                          {...registerCustomLink2}
                          onBlur={() => {
                            setFocusCustomLink2(false);
                          }}
                        />
                      </InputGroup>
                      {/* Custom Site 2 End */}
                    </FormGroup>
                    <ErrorAlert errors={Boolean(errors.customLink2)}>
                      {errors.customLink2?.message}
                    </ErrorAlert>
                    {/* Social media handles end */}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="text-center">
                  <SubmitButton
                    className="my-4 btn-icon btn-3"
                    color="primary"
                    type="submit"
                    loading={submitLoading}
                  >
                    <span className="btn-inner--icon">
                      {!submitLoading && <FaSave size={20} />}
                    </span>
                    <span className="btn-inner--text">{translate('editProfile.saveBtn')}</span>
                  </SubmitButton>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default EditProfile;
