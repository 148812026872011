import i18n from '@libs/i18n/I18n';
import { logout, useAppDispatch } from '@state/redux/store';
import { isLoggedIn } from '@utils/helpers/auth';
import { useCallback, useEffect } from 'react';
import problemSolvingImage from '../../../assets/images/placeholders/undraw_problem_solving_ft81.svg';
import { errorToast } from '../../../libs/helper';

import logger from '@libs/log';
import { useParams } from 'react-router-dom';
import { redirectTo } from '../../../routes/helpers';
import { confirmAccount, fetchMe, loginAfterSignUp } from '../../../state/redux/slices/auth.slice';
import { translate } from '../../../state/utils/helper';
import Title from '../../Projects/View/Title';
import { Card, CardBody, Col, Container, ImagePlaceholder, Indicator, Row } from '../../UI/Html';
import Layout from '../../UI/Layout';
import Footer from '../../UI/Layout/Footer';

function MagicLinkLogin() {
  const { token } = useParams();

  const dispatch = useAppDispatch();
  const isSignedIn = isLoggedIn();
  const redirectToLink = useCallback((forwardUrl: string) => {
    if (forwardUrl && forwardUrl !== '/') {
      redirectTo(`/${forwardUrl.replace(/([^:])(\/\/+)/g, '$1/')}`);
      return;
    }
    redirectTo('/');
  }, []);

  useEffect(() => {
    document.body.classList.add('bg-default-color');
    return () => {
      document.body.classList.remove('bg-default-color');
    };
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(
        confirmAccount({
          token,
        })
      )
        .unwrap()
        .then((response) => {
          logout();
          const redirect = response.redirect;
          dispatch(
            loginAfterSignUp({
              refreshToken: response.refreshToken,
            })
          )
            .then(() => {
              dispatch(fetchMe())
                .then(() => {
                  redirect && redirectToLink(redirect);
                })
                .catch(() => {
                  logger.error('Error fetching user data');
                });
            })
            .catch(() => {
              errorToast(i18n.t('messages.error.default'));
              redirectToLink('/'); // need to check this - if it should be redirectToLink(redirect) or redirectToLink()
            });
        })
        .catch(() => {
          errorToast(i18n.t('messages.error.default'));
          redirectToLink('/');
        });
    }
  }, [dispatch, token, isSignedIn, redirectToLink]);
  if (!token) {
    redirectToLink('/');
  }
  return (
    <Layout isFullWidth bg>
      <Container className="main-container">
        <Row className="justify-content-center vertical-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary">
              <CardBody className="px-lg-5 py-lg-5 text-center">
                <Title>{translate('auth.magicLinkSignIn.signingIn') as React.ReactNode}</Title>
                <div className="text-center text-muted mb-4">
                  <small>{translate('auth.magicLinkSignIn.pleaseWait') as React.ReactNode}</small>
                </div>
                <Indicator />
                <ImagePlaceholder
                  src={problemSolvingImage}
                  imageStyle={{
                    width: '80%',
                  }}
                  className="my-4"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <Footer />
      </Container>
    </Layout>
  );
}

export default MagicLinkLogin;
