import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import {
  IdeaCard,
  IdeaCardAnswer,
  PostitCheckboxAnswer,
  PrioritizationMatrixAnswer,
} from '@app/@types/redux/answer';
import { IdeaCardOptions } from '@app/@types/redux/pageElement';
import { FormUserAnswer } from '@app/@types/redux/project';
import i18n from '@libs/i18n/I18n';
import { nanoid } from '@reduxjs/toolkit';
import { getPageElementDisabledState, parseDependeeValue } from '@state/selectors/projects';
import { Control, Controller } from 'react-hook-form';
import { FiEdit, FiPlus } from 'react-icons/fi';
import { PageElementProps } from '..';
import { TEXTAREA_MAX_LENGTH } from '../../../../libs/constants/constants';
import { translate } from '../../../../state/utils/helper';
import { Button, Col, Row } from '../../../UI/Html';
import UnsplashCredits from '../../../UI/Html/UnsplashCredits';
import { AddMedia } from './HelperComponents';
import './IdeaCards.scss';
import { useAppSelector } from '@state/redux/store';

const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
  },
  textarea: {
    width: '100%',
    '& > div > textarea': {
      lineHeight: '1.5rem',
    },
  },
  editImageButton: {
    position: 'absolute',
    color: 'white',
    top: 15,
    right: 15,
  },
  formControl: {
    width: '100%',
  },
}));

function ImageCard(props: {
  pageElementId: string;
  index: number;
  disabled: boolean;
  updateAnswer: (args: { index: number; key: string; answer: unknown }) => void;
  answer: IdeaCard | undefined;
  control?: Control<FormUserAnswer>;
  titleLabel: string;
  descriptionLabel: string;
}) {
  const allowCopyPaste = useAppSelector((state) => state.projects.allowCopyPaste);

  const {
    pageElementId,
    index,
    disabled,
    updateAnswer,
    answer,
    control,
    titleLabel,
    descriptionLabel,
  } = props;
  const classes = useStyles();
  const titleInputId = `ideaCards${pageElementId}imageCard${String(index)}title`;
  const descInputId = `ideaCards${pageElementId}imageCard${String(index)}description`;
  const titleMaxLengthAllowed = 40;
  const descriptionMaxLengthAllowed = TEXTAREA_MAX_LENGTH;
  const [addMediaOpen, setAddMediaOpen] = useState(false);
  if (!answer) {
    return null;
  }
  const imageUrl = answer.image.regular;
  const imageAuthorName = answer.image.authorName;
  const imageAuthorUrl = answer.image.authorUrl;

  return (
    <Col xs={12} sm={12} md={6} lg={6} xl={4}>
      <div className="ideaCards__imageCard_container">
        <AddMedia
          open={addMediaOpen}
          onRequestClose={() => {
            setAddMediaOpen(false);
          }}
          onSelect={(image) => {
            updateAnswer({ index, key: 'image', answer: image });
          }}
          unsplash
          subheading="Give your idea a visual representation"
        />
        {answer.image.regular ? (
          <div className="ideaCards__imageCard_imageContainer">
            <img
              src={imageUrl}
              className={classnames('ideaCards__imageCard_image')}
              alt={imageAuthorName ?? 'Idea Card Image'}
            />
            {!disabled && (
              <>
                <div className="ideaCards__imageCard_imageOverlay" />
                <Button
                  color="primary"
                  size="sm"
                  className={classnames(
                    'ideaCards__imageCard_imageOptions',
                    classes.editImageButton
                  )}
                  onClick={() => {
                    setAddMediaOpen(true);
                  }}
                >
                  <FiEdit style={{ fontSize: '1.5em' }} />
                </Button>
              </>
            )}
            <UnsplashCredits authorName={imageAuthorName} authorUrl={imageAuthorUrl} />
          </div>
        ) : (
          <div
            className="ideaCards__imageCard_addImage"
            role="button"
            tabIndex={0}
            onClick={() => {
              setAddMediaOpen(true);
            }}
          >
            <div>
              <div
                style={{
                  display: 'inline-grid',
                  placeItems: 'center',
                }}
              >
                <FiPlus />
              </div>
              <span>{translate('pageElements.ideaCard.addImage')}</span>
            </div>
          </div>
        )}
        <div className="ideaCards__imageCard_inputContainer">
          <div className="ideaCards__imageCard_input">
            <Controller
              name={titleInputId}
              control={control}
              rules={{
                required: translate('pageElements.errorMessages.required'),
                maxLength: {
                  value: titleMaxLengthAllowed,
                  message: translate('pageElements.errorMessages.maxLength', {
                    maxLengthAllowed: titleMaxLengthAllowed,
                  }),
                },
              }}
              defaultValue={answer.title}
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <TextField
                  id={titleInputId}
                  helperText={error ? error.message : null}
                  disabled={disabled}
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={titleLabel}
                  dir={i18n.dir()}
                  onBlur={() => {
                    onBlur();
                    updateAnswer({
                      index,
                      key: 'title',
                      answer: value,
                    });
                  }}
                  onPaste={(e) => {
                    if (!allowCopyPaste) {
                      e.preventDefault();
                    }
                  }}
                  className={classnames(classes.input)}
                />
              )}
            />
          </div>
          <div className="ideaCards__imageCard_input">
            <Controller
              name={descInputId}
              control={control}
              rules={{
                required: translate('pageElements.errorMessages.required'),
                maxLength: {
                  value: descriptionMaxLengthAllowed,
                  message: translate('pageElements.errorMessages.maxLength', {
                    maxLengthAllowed: descriptionMaxLengthAllowed,
                  }),
                },
              }}
              defaultValue={answer.description}
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <TextField
                  multiline
                  rows={3}
                  id={titleInputId}
                  helperText={error ? error.message : null}
                  disabled={disabled}
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={descriptionLabel}
                  dir={i18n.dir()}
                  onBlur={() => {
                    onBlur();
                    updateAnswer({
                      index,
                      key: 'description',
                      answer: value,
                    });
                    if (!answer.id) {
                      updateAnswer({
                        index,
                        key: 'id',
                        answer: nanoid(),
                      });
                    }
                  }}
                  onPaste={(e) => {
                    if (!allowCopyPaste) {
                      e.preventDefault();
                    }
                  }}
                  className={classnames(classes.textarea)}
                />
              )}
            />
          </div>
        </div>
      </div>
    </Col>
  );
}

const IdeaCards: React.FC<PageElementProps> = (props) => {
  const initialAnswer = {
    id: '',
    description: '',
    title: '',
    image: {
      regular: '',
    },
  };
  const [answers, setAnswers] = useState<IdeaCardAnswer>({ 0: initialAnswer });
  const {
    pageElement,
    pageElement: {
      attributes: { id: pageElementId },
    },
    answer,
    form: { control } = {},
    getDependeeAnswerType,
    state,
  } = props;
  const options = pageElement.attributes.options as IdeaCardOptions;
  const { dependees } = pageElement.attributes;
  const {
    titleLabel = translate('pageElements.ideaCard.title'),
    descriptionLabel = translate('pageElements.ideaCard.description'),
  } = options;

  const currentAnswer = props.currentAnswer as IdeaCardAnswer | undefined;

  useEffect(() => {
    let tempAnswers = currentAnswer;
    const dependeeValues: IdeaCard[] = [];
    dependees.forEach((dependee) => {
      const dependeeValue =
        !!dependee.dependeeId &&
        (parseDependeeValue(getDependeeAnswerType(dependee.dependeeId), dependee.dependeeKey) as
          | PostitCheckboxAnswer
          | PrioritizationMatrixAnswer);
      if (dependeeValue && Object.keys(dependeeValue as PostitCheckboxAnswer).length > 0) {
        Object.values(dependeeValue as PostitCheckboxAnswer).forEach((item) => {
          dependeeValues.push({
            id: '',
            description: '',
            title: item.value,
            image: {
              regular: '',
            },
          });
        });
      } else if (
        (dependeeValue as PrioritizationMatrixAnswer).matrixRows.length > 0 ||
        (dependeeValue as PrioritizationMatrixAnswer).ranks.length > 0
      ) {
        [1, 2, 3].forEach((i) => {
          const indexOfI = (dependeeValue as PrioritizationMatrixAnswer).ranks.findIndex(
            (rank) => rank === i
          );
          dependeeValues.push({
            id: '',
            description: (dependeeValue as PrioritizationMatrixAnswer).feedbacks[indexOfI] || '',
            title: (dependeeValue as PrioritizationMatrixAnswer).matrixRows[indexOfI] || '',
            image: {
              regular: '',
            },
          });
        });
      }
    });

    dependeeValues.forEach((dependeeValue: IdeaCard, index) => {
      const title = dependeeValue.title;
      const description = dependeeValue.description || '';
      const image = dependeeValue.image.regular ? dependeeValue.image : { regular: '' };

      if (!tempAnswers?.[index]) {
        tempAnswers = tempAnswers ?? {};
        tempAnswers = {
          ...tempAnswers,
          [index]: {
            ...initialAnswer,
            title,
            description,
            image,
          },
        };
      }
      tempAnswers = {
        ...tempAnswers,
        [index]: {
          ...tempAnswers[index],
          title: tempAnswers[index].title ? tempAnswers[index].title : title,
          description: tempAnswers[index].description
            ? tempAnswers[index].description
            : description,
          image: tempAnswers[index].image.regular ? tempAnswers[index].image : image,
        },
      };
    });
    tempAnswers && answer(tempAnswers);
    tempAnswers && setAnswers(tempAnswers);
  }, []);

  const disabled = getPageElementDisabledState(state, pageElementId);
  const updateAnswer = useCallback(
    ({ index, key, answer: answerCallback }: { index: number; key: string; answer: unknown }) => {
      setAnswers((oldAnswers) => {
        if (!disabled) {
          const tempAnswer = {
            ...oldAnswers,
            [index]: {
              ...oldAnswers[index],
              [key]: answerCallback,
            },
          };
          answer(tempAnswer);
          return tempAnswer;
        }
        return oldAnswers;
      });
    },
    [answer, disabled]
  );
  return (
    <Col>
      <Row className="justify-content-center">
        {Object.keys(answers).map((key) => {
          const answerCard = currentAnswer?.[key];
          if (!answerCard) null;
          return (
            <ImageCard
              key={key}
              index={Number(key)}
              pageElementId={pageElementId}
              disabled={disabled}
              updateAnswer={updateAnswer}
              answer={answerCard}
              control={control}
              titleLabel={titleLabel}
              descriptionLabel={descriptionLabel}
            />
          );
        })}
      </Row>
    </Col>
  );
};

export default IdeaCards;
