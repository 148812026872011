import { FC, ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';

interface AnimatedProps {
  children: ReactNode | ReactNode[];
  isVisible?: boolean;
}

const Animated: FC<AnimatedProps> = ({ children, isVisible = true }) => {
  if (isVisible) {
    return (
      <CSSTransition
        in={isVisible}
        timeout={{ enter: 500, exit: 500 }}
        unmountOnExit
        mountOnEnter
        classNames="animated"
      >
        <>{children}</>
      </CSSTransition>
    );
  }
  return null;
};

export default Animated;
