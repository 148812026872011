import { AttachmentAnswer } from '@app/@types/redux/answer';
import Dropzone from '@components/UI/Html/Dropzone';
import useUpload from '@libs/hooks/useUpload';
import { SetStateAction, useEffect, useState } from 'react';
import { HiLink, HiOutlineUpload, HiPlus } from 'react-icons/hi';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  ListGroupItem,
  Progress,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { H4, Modal, Text } from '../../../../../UI/Html';
import './SubmissionAttachment.scss';

type Attachment = AttachmentAnswer['attachments'][number];

interface SubmissionAttachmentProps {
  currentAnswer: AttachmentAnswer | undefined;
  answer: (payload: AttachmentAnswer) => void;
}

const SubmissionAttachment: React.FC<SubmissionAttachmentProps> = (props) => {
  const { currentAnswer, answer } = props;
  const [attachments, setAttachments] = useState<Attachment[]>(currentAnswer?.attachments ?? []);
  const [linkUpload, setLinkUpload] = useState(false);
  const [linkInput, setLinkInput] = useState('');

  useEffect(() => {
    answer({
      ...currentAnswer,
      attachments,
    });
  }, [attachments]);

  const { upload, progress } = useUpload();

  const onDrop = (acceptedFiles: File[]) => {
    upload(acceptedFiles[0], (attachmentUrl: string) => {
      setAttachments([...attachments, { type: 'upload', url: attachmentUrl }]);
    });
  };

  return (
    <>
      <div className="d-flex  w-100 justify-content-between align-items-center">
        <div>
          <H4 className="side-border-title font-weight-500 font-size-1em">Attachments</H4>
        </div>
        <UncontrolledDropdown>
          <DropdownToggle color="primary" size="sm">
            <HiPlus className="mr-2" />
            Add Attachment
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              href="#"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
              }}
            >
              <Dropzone onDrop={onDrop} className="editProfile__dropzone">
                <HiOutlineUpload className="mr-2" />
                Upload File
              </Dropzone>
            </DropdownItem>
            <DropdownItem
              href="#"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                setLinkUpload(true);
              }}
            >
              <HiLink className="mr-2" />
              Add Link
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <Row className="px-1 py-2">
        {attachments.map((attachment: Attachment, index: number) => (
          <Col sm={12} key={`attachment-${index.toString()}`} className="my-2">
            <ListGroupItem className="p-2">
              <Row className="align-items-center">
                <Col className="col">
                  <Text className="text-muted">
                    {/* {String(attachment.url)} */}
                    {attachment.type === 'upload'
                      ? String(decodeURI(attachment.url.split('/').pop() ?? ''))
                      : String(attachment.url)}
                  </Text>
                </Col>
                <Col className="col-auto">
                  <Button
                    color="primary"
                    size="sm"
                    type="button"
                    onClick={() => {
                      window.open(attachment.url);
                    }}
                  >
                    <i className="fas fa-eye" />
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    type="button"
                    onClick={() => {
                      setAttachments(attachments.filter((_: Attachment, i: number) => i !== index));
                    }}
                  >
                    <i className="fas fa-trash" />
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          </Col>
        ))}
        {progress && progress > 0 && progress < 100 ? (
          <Col sm={12} className="my-2">
            <ListGroupItem className="py-4">
              <Progress max="100" value={progress} color="info" className="upload-progress" />
            </ListGroupItem>
          </Col>
        ) : null}
      </Row>
      <Modal
        isOpen={linkUpload}
        toggle={() => {
          setLinkUpload(false);
        }}
        size="md"
        showCloseButton={false}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setAttachments([...attachments, { type: 'link', url: linkInput }]);
            setLinkInput('');
            setLinkUpload(false);
          }}
        >
          <label className="form-control-label" htmlFor="basic-url">
            Add Link
          </label>
          <InputGroup className="mb-3">
            <Input
              placeholder="https://example.com"
              type="url"
              value={linkInput}
              onChange={(e: { target: { value: SetStateAction<string> } }) => {
                setLinkInput(e.target.value);
              }}
              required
            />
            <InputGroupAddon addonType="append">
              <Button color="primary" type="submit">
                Add
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </form>
      </Modal>
    </>
  );
};

export default SubmissionAttachment;
