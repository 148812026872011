import {
  resetProjectsFilters,
  updateFilters as updateFiltersFn,
} from '@state/redux/slices/project.slice';
import { RootState, useAppDispatch } from '@state/redux/store';
import { useEffect } from 'react';
import { useAppSelector } from '../../state/redux/store';
import { translate } from '../../state/utils/helper';

import { Card, CardBody, CardHeader, H5 } from '../UI/Html';

const orderByDataOptions = [
  {
    id: 'begin_date asc',
    name: translate('projectsIndex.filters.order.byDate'),
  },
  {
    id: 'begin_date desc',
    name: translate('projectsIndex.filters.order.byDateDesc'),
  },
];
const hasSpotsLeftOptions = [
  { id: 'all', name: translate('projectsIndex.filters.availableSpots.all') },
  {
    id: 'active',
    name: translate('projectsIndex.filters.availableSpots.active'),
  },
  {
    id: 'inactive',
    name: translate('projectsIndex.filters.availableSpots.inactive'),
  },
];

function ProjectsFilters() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetProjectsFilters());
    };
  }, [dispatch]);

  interface FiltersData {
    isActive: string;
    orderBy: string;
    sortDirection: string;
  }

  const updateFilters = (data: FiltersData): void => {
    dispatch(updateFiltersFn(data));
  };

  const filters = useAppSelector((state: RootState) => state.projects.filters);

  return (
    <Card className="p-0 d-none d-sm-none d-md-block">
      <CardHeader className="h2 text-uppercase mb-0 d-flex">
        {translate('projectsIndex.filters.title')}
      </CardHeader>
      <CardBody>
        <H5 className="mt-0 text-uppercase font-weight-normal d-flex">
          {translate('projectsIndex.filters.active')}
        </H5>

        <select
          className="custom-select custom-select-md"
          value={filters.isActive}
          onChange={(e) => {
            updateFilters({
              isActive: e.target.value,
              orderBy: filters.orderBy,
              sortDirection: '',
            });
          }}
        >
          {hasSpotsLeftOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        <H5 className="mt-3 text-uppercase font-weight-normal d-flex">
          {translate('projectsIndex.filters.orderBy')}
        </H5>
        <select
          className="custom-select custom-select-md"
          value={filters.orderBy}
          onChange={(e) => {
            updateFilters({
              orderBy: e.target.value,
              isActive: filters.isActive ? filters.isActive : '',
              sortDirection: '',
            });
          }}
        >
          {orderByDataOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </CardBody>
    </Card>
  );
}

export default ProjectsFilters;
