import { FC, ReactNode } from 'react';

interface H3Props {
  className?: string;
  children?: ReactNode | string;
}

const H3: FC<H3Props> = ({ className = '', children = null }) => {
  return <h3 className={className}>{children}</h3>;
};

export default H3;
