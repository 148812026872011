import { Workspace } from '@app/@types/redux/workspace';
import { BsThreeDots } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { i18nCText } from '../../libs/i18n/I18n';
import { translate } from '../../state/utils/helper';
import {
  Card,
  CardBody,
  CardImg,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from '../UI/Html';

interface WorkspaceItemProps {
  data: Workspace;
  ownerName: string;
}

const WorkspaceItem = ({ data, ownerName = '' }: WorkspaceItemProps) => {
  const navigate = useNavigate();
  return (
    <Card className="w-100">
      <div className="project__item__imageContainer">
        <CardImg
          alt="..."
          src={data.attributes.image || '/assets/img/placeholder.jpg'}
          className="project__item__image"
          top
          onClick={() => {
            navigate(`/workspace/${data.id}`);
          }}
        />
      </div>
      <CardBody className="pt-2 pb-3 pl-3 pr-2">
        <div className="d-flex">
          <div className="text_overlap">
            <small className="text-muted">By {ownerName}</small>
          </div>
          <div className="ml-auto ">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn btn-sm"
                style={{
                  backgroundColor: 'transparent',
                  margin: 0,
                  padding: '0px 8px',
                  boxShadow: 'none',
                  border: 'none',
                }}
              >
                <BsThreeDots size={15} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem color="link" href={`/workspace/${data.id}`}>
                  {translate('View')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        <h3 className="mb-0 h3 text-wrapping">{i18nCText(data.attributes.name)}</h3>
      </CardBody>
    </Card>
  );
};

export default WorkspaceItem;
