import { useState } from 'react';
import EllipsisText from 'react-ellipsis-text';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import { translate } from '../../../state/utils/helper';
import Alert from './Alert';
import Button from './Button';
import Row from './Row';

interface CAProps {
  href?: string;
  target?: string;
  className?: string;
  external?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const CA = ({
  target = '',
  external = false,
  className = '',
  style = {},
  href = '#',
  children = [],
}: CAProps & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const [externalLink, setExternalLink] = useState(false);

  if (target === '_blank' || external) {
    return (
      <>
        <a
          className={className}
          style={style}
          role="button"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setExternalLink(true);
          }}
        >
          {children}
        </a>
        <Alert
          isOpen={externalLink}
          onRequestClose={() => {
            setExternalLink(false);
          }}
          showCloseButton
          icon="warning"
        >
          <Row className="mb-3">
            <Col>
              <div>
                <h1>{String(translate('messages.attention'))}</h1>
                <p className="m-3">{String(translate('messages.externalWebsiteWarning'))}</p>
                <p className="alert alert-info-external-link" role="alert">
                  <span>{href}</span>
                  <EllipsisText text={href || ''} length={60} />
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                color="grey"
                onClick={() => {
                  setExternalLink(false);
                }}
              >
                {translate('buttonLabels.goBack')}
              </Button>
              <Button
                color="info"
                onClick={() => {
                  setExternalLink(false);
                  window.open(href);
                }}
              >
                {translate('buttonLabels.continue')}
              </Button>
            </Col>
          </Row>
        </Alert>
      </>
    );
  }
  return (
    <Link style={style} className={className} to={href || ''}>
      {children}
    </Link>
  );
};

export default CA;
