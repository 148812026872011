import { COOKIE_NAMES } from '@libs/constants/constants';
import i18n from '@libs/i18n/I18n';
import { TOptions } from 'i18next';
import { deleteCookie, getCookie, setCookie } from './cookie';

/**
 * Get access_token from cookies
 * @returns access_token
 */
export const getAccessToken = (): string | null => getCookie(COOKIE_NAMES.ACCESS_TOKEN);

/**
 * Set access_token to cookies
 * @param accessToken access_token
 * @returns void
 */
export const setAccessToken = (accessToken: string): void => {
  setCookie({ key: COOKIE_NAMES.ACCESS_TOKEN, value: accessToken, path: '/' });
};

/**
 * Remove access_token from cookies
 * @returns void
 */
export const removeAccessToken = (): void => {
  deleteCookie(COOKIE_NAMES.ACCESS_TOKEN);
};

/**
 * Set refresh_token to cookies
 * @param refreshToken refresh_token
 * @returns void
 */
export const setRefreshToken = (refreshToken: string): void => {
  setCookie({ key: COOKIE_NAMES.REFRESH_TOKEN, value: refreshToken, path: '/' });
};

/**
 * Get refresh_token from cookies
 * @returns refresh_token
 */
export const getRefreshToken = (): string | null => getCookie(COOKIE_NAMES.REFRESH_TOKEN);

/**
 * Remove refresh_token from cookies
 * @returns void
 */
export const removeRefreshToken = (): void => {
  deleteCookie(COOKIE_NAMES.REFRESH_TOKEN);
};

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*d)[A-Za-zd]{8,}$/i;

export const hasNumber = (value: string): boolean => {
  return new RegExp(/[0-9]/).test(value);
};

export const hasMixed = (value: string): boolean => {
  return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value);
};

export const hasSpecial = (value: string): boolean => {
  return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
};
export const strengthIndicator = (value: string): number => {
  let strengths = 0;
  if (value.length > 5) strengths += 1;
  if (value.length > 7) strengths += 1;
  if (hasNumber(value)) strengths += 1;
  if (hasSpecial(value)) strengths += 1;
  if (hasMixed(value)) strengths += 1;
  return strengths;
};

export const getRandomColor = (): string => {
  const colors = ['#233FD1', '#FFF4A8', '#9CD8C8', '#7087FF', '#8285D4'];
  return colors[Math.floor(Math.random() * colors.length)];
};

export const getRedirectToPath = (): string | null => getCookie(COOKIE_NAMES.REDIRECT_TO_PATH);

export const setRedirectToPath = (accessToken: string): void => {
  setCookie({ key: COOKIE_NAMES.REDIRECT_TO_PATH, value: accessToken, path: '/' });
};

export const removeRedirectToPath = (): void => {
  deleteCookie(COOKIE_NAMES.REDIRECT_TO_PATH);
};

export function translate(key: string, options?: TOptions): string {
  return i18n.t(key, options);
}
