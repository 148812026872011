import { CSSProperties, FC, ReactNode } from 'react';
import { Container } from 'reactstrap';

interface CContainerProps {
  style?: CSSProperties;
  children?: ReactNode;
  className?: string;
  fullWidth?: boolean;
  fluid?: boolean;
}

const CContainer: FC<CContainerProps> = ({
  style = {},
  children = null,
  className = '',
  fullWidth = false,
  fluid = false,
}) => {
  return (
    <Container
      style={{
        ...(fullWidth ? { width: '100%' } : {}),
        ...style,
      }}
      className={className}
      fluid={fluid}
    >
      {children}
    </Container>
  );
};

export default CContainer;
