import { useDropzone } from 'react-dropzone';

function Dropzone({
  onDrop,
  children,
  className,
  ...rest
}: {
  onDrop: (acceptedFiles: File[]) => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
}): JSX.Element {
  const { getRootProps, getInputProps } = useDropzone({ onDrop, ...rest });
  return (
    <div className={className} {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
}

export default Dropzone;
