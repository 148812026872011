import i18n from '@libs/i18n/I18n';
import Image from 'react-graceful-image';
import Skeleton from 'react-loading-skeleton';
import { Card, CardBody, Col, Row } from 'reactstrap';

export default function WorkspaceOwnerCard(props: {
  loading: boolean;
  name: string;
  image: string | undefined;
}) {
  const { loading, name, image } = props;
  return (
    <Card>
      <CardBody>
        {loading ? (
          <Skeleton count={3} />
        ) : (
          <Row className="align-items-center">
            <Col className="col-auto">
              {image ? (
                <Image
                  className="avatar avatar-xl rounded-circle object-fit-cover"
                  alt={name}
                  src={image}
                />
              ) : (
                <div>
                  <div className="avatar avatar-xl rounded-circle object-fit-cover font-size-2em">
                    {name[0].toUpperCase()}
                  </div>
                </div>
              )}
            </Col>
            <div className="col ml--2">
              <p className="text-sm text-muted mb-0">{i18n.t('workspace.createdBy')}</p>
              <h4 className="mb-0 display-4">{name}</h4>
            </div>
          </Row>
        )}
      </CardBody>
    </Card>
  );
}
