export const successIcon = (
  <div className="swal2-icon swal2-success swal2-icon-show" style={{ display: 'flex' }}>
    <div
      className="swal2-success-circular-line-left"
      style={{ backgroundColor: 'rgb(255, 255, 255)' }}
    />
    <span className="swal2-success-line-tip" />
    <span className="swal2-success-line-long" />
    <div className="swal2-success-ring" />
    <div className="swal2-success-fix" style={{ backgroundColor: 'rgb(255, 255, 255)' }} />
    <div
      className="swal2-success-circular-line-right"
      style={{ backgroundColor: 'rgb(255, 255, 255)' }}
    />
  </div>
);

export const errorIcon = (
  <div className="swal2-icon swal2-error swal2-animate-error-icon" style={{ display: 'flex' }}>
    <span className="swal2-x-mark">
      <span className="swal2-x-mark-line-left" />
      <span className="swal2-x-mark-line-right" />
    </span>
  </div>
);

export const warningIcon = (
  <div
    style={{
      width: 80,
      height: 80,
      borderWidth: 4,
      borderStyle: 'solid',
      borderRadius: '50%',
      margin: '20px auto',
      position: 'relative',
      boxSizing: 'content-box',
      borderColor: '#eea236',
      animation: 'pulseWarning 0.75s infinite alternate',
    }}
  >
    <span
      style={{
        position: 'absolute',
        width: 5,
        height: 47,
        left: '50%',
        top: 10,
        borderRadius: 2,
        marginLeft: -2,
        backgroundColor: '#f0ad4e',
        animation: 'pulseWarningIns 0.75s infinite alternate',
      }}
    />
    <span
      style={{
        position: 'absolute',
        width: 7,
        height: 7,
        borderRadius: '50%',
        marginLeft: -3,
        left: '50%',
        bottom: 10,
        backgroundColor: '#f0ad4e',
        animation: 'pulseWarningIns 0.75s infinite alternate',
      }}
    />
  </div>
);
