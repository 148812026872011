import { User } from '@app/@types/redux/users';
import { FC } from 'react';
import { studentProfileBanner } from '../../assets/images';
import I18n from '../../libs/i18n/I18n';
import { Button, Col, Container, Row } from '../UI/Html';

interface ProfileHeaderProps {
  profileData: User;
}

const ProfileHeader: FC<ProfileHeaderProps> = ({ profileData }) => {
  const { firstName } = profileData.attributes;
  const banner = {
    minHeight: '300px',
    backgroundImage: `url("${studentProfileBanner}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
  };

  return (
    <div className="header pb-0 d-flex align-items-center" style={banner}>
      <span className="mask bg-gradient-info opacity-8" />

      <Container className="d-flex justify-content-end align-items-center" fluid>
        <Row className="justify-content-end text-right">
          <Col lg="7" md="10">
            <h1 className="display-2 text-white d-none d-lg-block">
              {I18n.t('profile.welcome', { name: firstName }).toString()}
            </h1>
            <p className="text-white mt-0 mb-5 d-none d-lg-block">
              {I18n.t('profile.headerMessage').toString()}
            </p>
            <Button className="btn-neutral" color="default" href="/settings/account">
              {I18n.t('profile.editProfile').toString()}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfileHeader;
