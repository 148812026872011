import Skeleton from 'react-loading-skeleton';
import { View } from 'react-native-web';
import { ConnectedProps, connect } from 'react-redux';
import { i18nCText } from '../../libs/i18n/I18n';
import { redirectTo } from '../../routes/helpers';
import {
  getCurrentEvaluation,
  getCurrentEvaluationPage,
  getEvaluationPageElementAnswerAndType,
  isLastEvaluationPage as isLastPageFn,
} from '../../state/selectors/projects';
import { getCurrentUser, getUserGrowths } from '../../state/selectors/users';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Div,
  Form,
  H2,
  H4,
  Row,
  SubmitButton,
} from '../UI/Html';

import { Page, PageElement, UserAnswerAnswer } from '@app/@types/redux/project.js';
import { PAGE_ELEMENT } from '@libs/constants/constants.ts';
import logger from '@libs/log.ts';
import {
  answerEvaluation,
  fetchEvaluation,
  fetchEvaluationByCode,
  fetchUserEvaluation,
  hideNotAnsweredModal,
  nextValidate,
  resetEvaluationOrProject,
  submitEvaluationAnswers,
  switchEvaluationPage,
  updateRestrictions,
} from '@state/redux/slices/project.slice.ts';
import { AppDispatch, RootState, store, useAppDispatch } from '@state/redux/store.ts';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { translate } from '../../state/utils/helper.ts';
import Footer from '../UI/Layout/Footer';
import EvaluationResults from './EvaluationResults';
import ProjectHeader from './ProjectHeader.tsx';
import ImageRadioButtons from './TakeProject/Elements/ImageRadioButtons';
import Input from './TakeProject/Elements/Input';
import Message from './TakeProject/Elements/Message';
import RadioButtons from './TakeProject/Elements/RadioButtons';
import EvaluationPagination from './TakeProject/EvaluationPagination';
import { PageElementProps } from './TakeProject/index.tsx';

const inputsElements: Record<string, React.FC<PageElementProps>> = {
  message: Message,
  input: Input,
  radio: RadioButtons,
  image_radio: ImageRadioButtons,
};

const mapStateToProps = (state: RootState) => ({
  getAnswerType: (peId: string) => getEvaluationPageElementAnswerAndType(state.projects, peId),
  evaluation: getCurrentEvaluation(state.projects),
  currentUser: getCurrentUser(state),
  currentPage: getCurrentEvaluationPage(state.projects),
  growths: getUserGrowths(state),
  isLastPage: isLastPageFn(state.projects),
  nextButtonValidate: state.projects.nextButtonValidate,
  userAnswers: state.projects.evaluationAnswers,
  currentPageId: state.projects.currentEvaluationPageId,
  notAnsweredModal: state.projects.notAnsweredModal,
  userEvaluation: state.projects.currentEvaluation.userEvaluation,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  resetEvaluationOrProject: (entityType: string) => dispatch(resetEvaluationOrProject(entityType)),
  submit: () => dispatch(submitEvaluationAnswers()),
  fetchEvaluation: (id: string) => dispatch(fetchEvaluation({ id })),
  fetchEvaluationByCode: (code: string) => dispatch(fetchEvaluationByCode({ code })),
  fetchUserEvaluation: (id: string) => dispatch(fetchUserEvaluation({ id })),
  switchEvaluationPage: (id: string) => {
    dispatch(switchEvaluationPage(id));
  },
  answerEvaluation: (peId: string, type: PAGE_ELEMENT, payload: UserAnswerAnswer) =>
    dispatch(
      answerEvaluation({
        peId,
        type,
        payload,
      })
    ),
  nextValidate: (nextButtonValidate: boolean) => dispatch(nextValidate(nextButtonValidate)),
  updateRestrictions: () => dispatch(updateRestrictions()),
  hideNotAnsweredModal: () => {
    dispatch(hideNotAnsweredModal());
  },
});

function loadingSkeleton() {
  return (
    <Col xs={12} className="m5-5">
      <Skeleton count={5} height={20} />
    </Col>
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type TakeEvaluationProps = PropsFromRedux;

const TakeEvaluation: React.FC<TakeEvaluationProps> = (props) => {
  const { id: paramsId, code: paramsCode } = useParams();

  const [loading, setLoading] = useState(false);

  const {
    updateRestrictions,
    fetchUserEvaluation,
    fetchEvaluation,
    nextValidate,
    resetEvaluationOrProject,
    evaluation,
    getAnswerType,
    answerEvaluation,
    submit,
    currentPage,
    isLastPage,
    switchEvaluationPage,
    nextButtonValidate,
    userEvaluation,
  } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    updateRestrictions();
    setLoading(true);

    const code = paramsId;
    if (paramsId) {
      fetchEvaluation(paramsId)
        .then(() => {
          resetEvaluationOrProject('evaluation');
          if (code)
            return fetchUserEvaluation(code).then(() => {
              setLoading(false);
            });
        })
        .catch((e: unknown) => {
          logger.error(['Error fetching evaluation by id', e]);
        });
    } else if (paramsCode) {
      dispatch(fetchEvaluationByCode({ code: paramsCode }))
        .unwrap()
        .then((res) => {
          const evaluationId = res.attributes.id;
          resetEvaluationOrProject('evaluation');
          return fetchUserEvaluation(evaluationId).then(() => {
            setLoading(false);
          });
        })
        .catch((e: unknown) => {
          logger.error(['Error fetching evaluation by code', e]);
        });
    }
    nextValidate(false);
  }, [paramsCode, paramsId]);

  const processPageElements = (pageElements: PageElement[]) => {
    if (!currentPage) return null;
    const page = currentPage.attributes;
    let nextPage: Page | null = null;
    const cpId = parseInt(page.id, 10);
    const pages = evaluation.attributes.pages;
    pages.forEach((p, index) => {
      const pId = parseInt(p.id, 10);
      if (cpId === pId) {
        if (!isInLastPage()) {
          nextPage = pages[index + 1];
        }
      }
    });
    return (
      <Form
        onSubmit={(e: React.MouseEvent) => {
          e.preventDefault();
          if (nextPage) {
            switchEvaluationPage(nextPage.id);
          } else {
            submit().catch(() => undefined);
          }
        }}
      >
        {pageElements.map((pe, index) => {
          const IElement = inputsElements[pe.attributes.elementType];
          return (
            <IElement
              key={pe.id}
              position={index}
              pageElement={pe}
              currentAnswer={getAnswerType(pe.id)?.answer as string}
              answer={(payload) => answerEvaluation(pe.id, pe.attributes.elementType, payload)}
              getDependeeAnswerType={
                getAnswerType as (peId: string) => { answer: UserAnswerAnswer; type: PAGE_ELEMENT }
              }
              nextValidate={(nextButtonValidate) => nextValidate(nextButtonValidate)}
              state={store.getState().projects}
            />
          );
        })}
      </Form>
    );
  };

  const isInLastPage = () => {
    return isLastPage;
  };

  const renderEvaluation = () => {
    const page = currentPage;
    return (
      <Card>
        <CardHeader>
          {/* This should be the current stage + page name */}
          <div className="float-left">
            <H4 className="d-inline text-uppercase font-weight-light">{page?.attributes.title}</H4>
          </div>
          <EvaluationPagination evaluation={evaluation} className="float-right" />
        </CardHeader>
        <CardBody>{loading ? loadingSkeleton() : renderPage()}</CardBody>
      </Card>
    );
  };

  const renderPage = () => {
    const page = currentPage;
    if (!page) {
      return null;
    }
    const pageElements = page.attributes.pageElements;
    let defaultPageContent;
    if (evaluation.attributes.pages.length === 0) {
      return (
        <View className="text-center px-3">
          <i className="far fa-frown-open emptyPageIcon py-2" />
          <H2>{translate('takeProject.noPageError')}</H2>
        </View>
      );
    }
    return (
      <div>
        {defaultPageContent}
        {processPageElements(pageElements)}
      </div>
    );
  };

  const page = currentPage;
  let nextPage: Page | null = null;
  let previousPage: Page | null = null;
  if (!page) {
    return null;
  }

  const cpId = parseInt(page.id, 10);
  const pages = evaluation.attributes.pages;
  if (userEvaluation.attributes.results?.result) {
    return (
      <EvaluationResults evaluation={evaluation} results={userEvaluation.attributes.results} />
    );
  }

  pages.forEach((p, index) => {
    const pId = parseInt(p.id, 10);
    if (cpId === pId) {
      if (!isInLastPage()) {
        nextPage = pages[index + 1];
      }
      if (index >= 1) {
        previousPage = pages[index - 1];
      }
    }
  });

  return (
    <Container fluid className="px-0">
      <Row className="mx-0">
        <Col className="px-0 d-flex flex-grow flex-column">
          <ProjectHeader
            subtitle={i18nCText(evaluation.attributes.name)}
            pageType="evaluationPage"
          />
          <Row className="justify-content-center mt--3 mx-0">
            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
              <div
                className="main-container"
                style={{
                  minHeight: 'calc( 100vh - 340px )',
                }}
              >
                {renderEvaluation()}
                <Button
                  color="secondary"
                  className={classNames({
                    disabled: !previousPage,
                    btnPrevious: true,
                  })}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    if (previousPage) {
                      switchEvaluationPage(previousPage.id);
                    }
                  }}
                >
                  {translate('takeProject.previous')}
                </Button>
                {Object.keys(currentPage).length !== 0 ? (
                  <>
                    {isLastPage ? (
                      <SubmitButton
                        color="primary"
                        loading={loading}
                        onClick={(e: React.MouseEvent) => {
                          setLoading(true);
                          e.preventDefault();
                          submit()
                            .then(() => {
                              setLoading(false);
                            })
                            .catch(() => {
                              setLoading(false);
                            });
                        }}
                      >
                        {translate('takeEvaluation.finishEvaluation')}
                      </SubmitButton>
                    ) : (
                      <Button
                        disabled={nextButtonValidate}
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault();
                          if (nextPage) {
                            switchEvaluationPage(nextPage.id);
                          } else {
                            submit().catch(() => undefined);
                          }
                        }}
                      >
                        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                        {nextPage ? translate('takeProject.next') : translate('takeProject.submit')}
                      </Button>
                    )}
                  </>
                ) : (
                  <br />
                )}
              </div>
              <Footer />
              <Div className="my-4 d-md-none" />
              <Div className="bottom-take-project-menu d-sm-block d-md-none">
                <Row>
                  {[
                    {
                      id: 1,
                      name: translate('layout.navbar.navlinks.evaluations'),
                      icon: 'fas fa-arrow-left',
                      onClick: () => {
                        redirectTo('/assessments');
                      },
                    },
                    {
                      id: 2,
                      name: translate('buttonLabels.backToHome'),
                      icon: 'fas fa-home',
                      onClick: () => {
                        redirectTo('/assessments');
                      },
                    },
                  ].map((item) => (
                    <Col key={`${String(item.id)}-bottom-menu`} onClick={item.onClick}>
                      <i className={`${item.icon} mr-2`} />
                      {item.name}
                    </Col>
                  ))}
                </Row>
              </Div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default connector(TakeEvaluation);
