import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { PostitStyle1Answer } from '@app/@types/redux/answer';
import { PostitOptions } from '@app/@types/redux/pageElement';
import { FormUserAnswer } from '@app/@types/redux/project';
import { nanoid } from '@reduxjs/toolkit';
import { useAppSelector } from '@state/redux/store';
import { getPageElementDisabledState, parseDependeeValue } from '@state/selectors/projects';
import { memo, useCallback, useEffect, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { Control, Controller } from 'react-hook-form';
import { FaTrashAlt } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import { PageElementProps } from '..';
import { TEXTAREA_MAX_LENGTH } from '../../../../libs/constants/constants';
import { isArrayEqual } from '../../../../libs/helper';
import { translate } from '../../../../state/utils/helper';
import { Col, Row, UncontrolledTooltip } from '../../../UI/Html';
import PostItFn from '../../../UI/Html/Postit';
import './PostIt.scss';

const useStyles = makeStyles(() => ({
  deleteButton: {
    minWidth: 'auto',
    marginLeft: '10px',
  },
  flipButton: {
    minWidth: 'auto',
  },
  postItTextarea: {},
}));

type PostItItem = PostitStyle1Answer[number];

interface PostItProps {
  pageElementId: string;
  postItItem: PostItItem;
  updatePostIt: (item: PostItItem, answer: PostItItem) => void;
  deletePostIt: (item: PostItItem) => void;
  type: string;
  perRow: number;
  length: number;
  color: string;
  control?: Control<FormUserAnswer>;
}

function PostIt(props: PostItProps) {
  const {
    pageElementId,
    postItItem,
    updatePostIt,
    deletePostIt,
    type,
    perRow,
    length,
    color = 'yellow',
    control,
  } = props;
  const isDisabled = useAppSelector((state) =>
    getPageElementDisabledState(state.projects, pageElementId)
  );
  const [isFlipped, setIsFlipped] = useState(Boolean(isDisabled));
  const item = postItItem;
  const classes = useStyles();
  const allowCopyPaste = useAppSelector((state) => state.projects.allowCopyPaste);
  const elementId = `postIt${pageElementId}p${item.id}`;
  const elementIdFront = `postIt${pageElementId}p${item.id}Front`;
  const elementIdBack = `postIt${pageElementId}p${item.id}Back`;
  switch (type) {
    case 'flip_example':
      return (
        <>
          <PostItFn color="red" text="Lorem ipsum" />
          <PostItFn color="green" text="Lorem ipsum" />
        </>
      );
    case 'flip':
      return (
        <div className="postIt__container">
          <ReactCardFlip isFlipped={isFlipped}>
            <div key="front">
              <div className={classnames('postIt__flip_container')}>
                <div className="postIt__flip_actionBar">
                  <Button
                    className={classnames('postIt__flip_actionBar_button', classes.flipButton)}
                    onClick={() => {
                      setIsFlipped(!isFlipped);
                    }}
                    data-placement="top"
                    id={`flipPostItFrontTooltip${item.id}`}
                  >
                    {translate('pageElements.postit.flip')}
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target={`flipPostItFrontTooltip${item.id}`}
                  >
                    Flip to back
                  </UncontrolledTooltip>
                  {!isDisabled && (
                    <>
                      <Button
                        className={classnames(
                          'postIt__flip_actionBar_button',
                          classes.deleteButton
                        )}
                        onClick={() => {
                          deletePostIt(item);
                        }}
                        data-placement="top"
                        id={`deletePostItFrontTooltip${item.id}`}
                      >
                        <FaTrashAlt />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target={`deletePostItFrontTooltip${item.id}`}
                      >
                        {translate('buttonLabels.delete')}
                      </UncontrolledTooltip>
                    </>
                  )}
                </div>
                <div className="postIt__flip_body">
                  <Controller
                    name={elementIdFront}
                    control={control}
                    rules={{
                      required: translate('pageElements.postit.errors.front'),
                      maxLength: {
                        value: TEXTAREA_MAX_LENGTH,
                        message: translate('pageElements.errorMessages.maxLength', {
                          maxLengthAllowed: TEXTAREA_MAX_LENGTH,
                        }),
                      },
                    }}
                    defaultValue={item.flipped ?? ''}
                    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                      <TextField
                        autoFocus
                        id={elementIdFront}
                        multiline
                        rows={8}
                        variant="outlined"
                        className={classnames('postIt__flip_textarea', classes.postItTextarea)}
                        fullWidth
                        helperText={error ? error.message : null}
                        disabled={isDisabled}
                        error={!!error}
                        onChange={onChange}
                        onPaste={(e: React.ClipboardEvent) => {
                          if (!allowCopyPaste) {
                            e.preventDefault();
                          }
                        }}
                        value={value}
                        onBlur={(e) => {
                          onBlur();
                          updatePostIt(item, {
                            ...item,
                            flipped: e.target.value,
                          });
                        }}
                      />
                    )}
                  />
                  {/* <TextField
                    autoFocus
                    id={elementIdFront}
                    name={elementIdFront}
                    multiline
                    rows={8}
                    inputProps={{
                      maxLength: TEXTAREA_MAX_LENGTH,
                    }}
                    variant="outlined"
                    className={classnames(
                      "postIt__flip_textarea",
                      classes.postItTextarea,
                    )}
                    fullWidth
                    inputRef={register({
                      required: translate("pageElements.postit.errors.front"),
                    })}
                    defaultValue={item.flipped}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                      updatePostIt(item, { flipped: e.target.value });
                    }}
                  /> */}
                </div>
              </div>
            </div>
            <div key="back">
              <div
                className={classnames('postIt__flip_container', 'postIt__flip_container_flipped')}
              >
                <div className="postIt__flip_actionBar">
                  <Button
                    className={classnames('postIt__flip_actionBar_button', classes.flipButton)}
                    onClick={() => {
                      setIsFlipped(!isFlipped);
                    }}
                    data-placement="top"
                    id={`flipPostItBackTooltip${item.id}`}
                  >
                    Flip
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target={`flipPostItBackTooltip${item.id}`}
                  >
                    Flip to front
                  </UncontrolledTooltip>
                  {!isDisabled && (
                    <>
                      <Button
                        className={classnames(
                          'postIt__flip_actionBar_button',
                          classes.deleteButton
                        )}
                        onClick={() => {
                          deletePostIt(item);
                        }}
                        data-placement="top"
                        id={`deletePostItBackTooltip${item.id}`}
                      >
                        <FaTrashAlt />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target={`deletePostItBackTooltip${item.id}`}
                      >
                        Delete
                      </UncontrolledTooltip>
                    </>
                  )}
                </div>
                <div className="postIt__flip_body">
                  <Controller
                    name={elementIdBack}
                    control={control}
                    rules={{
                      required: translate('pageElements.postit.errors.back'),
                      maxLength: {
                        value: TEXTAREA_MAX_LENGTH,
                        message: translate('pageElements.errorMessages.maxLength', {
                          maxLengthAllowed: TEXTAREA_MAX_LENGTH,
                        }),
                      },
                    }}
                    defaultValue={item.value}
                    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                      <TextField
                        autoFocus
                        id={elementIdBack}
                        multiline
                        rows={8}
                        variant="outlined"
                        className={classnames('postIt__flip_textarea', classes.postItTextarea)}
                        fullWidth
                        helperText={error ? error.message : null}
                        disabled={isDisabled}
                        error={!!error}
                        onChange={onChange}
                        value={value}
                        onPaste={(e: React.ClipboardEvent) => {
                          if (!allowCopyPaste) {
                            e.preventDefault();
                          }
                        }}
                        onBlur={(e) => {
                          onBlur();
                          updatePostIt(item, {
                            ...item,
                            value: e.target.value,
                          });
                        }}
                      />
                    )}
                  />

                  {/* <TextField
                    id={elementIdBack}
                    name={elementIdBack}
                    multiline
                    rows={8}
                    inputProps={{
                      maxLength: TEXTAREA_MAX_LENGTH,
                    }}
                    variant="outlined"
                    className={classnames(
                      "postIt__flip_textarea",
                      classes.postItTextarea,
                    )}
                    fullWidth
                    inputRef={register({
                      required: translate("pageElements.postit.errors.back"),
                    })}
                    defaultValue={item.value}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                      updatePostIt(item, { value: e.target.value });
                    }}
                  /> */}
                </div>
              </div>
            </div>
          </ReactCardFlip>
          {/* <ErrorAlert
            errors={
              Boolean(errors[elementIdFront]) || Boolean(errors[elementIdBack])
            }
          >
            {errors[elementIdFront].message || errors[elementIdBack].message}
          </ErrorAlert> */}
        </div>
      );
    default:
      return (
        <div
          key={nanoid()}
          className={`postIt__container ${perRow === 2 ? 'postIt__container_2' : ''}`}
        >
          <div className={`postIt__normal_container postIt__normal_container_${color || 'yellow'}`}>
            <div className="postIt__normal_actionBar">
              {isDisabled || length <= 1 ? (
                ''
              ) : (
                <>
                  <Button
                    className={classnames('postIt__normal_actionBar_button', classes.deleteButton)}
                    onClick={() => {
                      deletePostIt(item);
                    }}
                    data-placement="top"
                    id={`deletePostItTooltip${item.id}`}
                  >
                    <FaTrashAlt />
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target={`deletePostItTooltip${item.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </>
              )}
            </div>
            <div className="postIt__normal_body">
              <Controller
                name={elementId}
                control={control}
                rules={{
                  required: translate('pageElements.postit.errors.complete'),
                  maxLength: {
                    value: TEXTAREA_MAX_LENGTH,
                    message: translate('pageElements.errorMessages.maxLength', {
                      maxLengthAllowed: TEXTAREA_MAX_LENGTH,
                    }),
                  },
                }}
                defaultValue={item.value}
                render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                  <TextField
                    autoFocus
                    id={elementId}
                    multiline
                    rows={8}
                    variant="outlined"
                    className={classnames('postIt__flip_textarea', classes.postItTextarea)}
                    fullWidth
                    onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                      e.target.setSelectionRange(item.value.length, item.value.length);
                    }}
                    helperText={error ? error.message : null}
                    disabled={isDisabled}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    onPaste={(e: React.ClipboardEvent) => {
                      if (!allowCopyPaste) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={(e) => {
                      onBlur();
                      updatePostIt(item, {
                        ...item,
                        value: e.target.value,
                      });
                    }}
                  />
                )}
              />

              {/* <TextField
                autoFocus
                id={elementId}
                name={elementId}
                multiline
                rows={8}
                inputProps={{
                  maxLength: TEXTAREA_MAX_LENGTH,
                }}
                variant="outlined"
                className={classnames(
                  "postIt__normal_textarea",
                  classes.postItTextarea,
                )}
                fullWidth
                inputRef={register({
                  required: translate("pageElements.postit.errors.complete"),
                })}
                defaultValue={item.value}
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                  e.target.setSelectionRange(
                    item.value.length,
                    item.value.length,
                  );
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  updatePostIt({ ...item, color }, { value: e.target.value });
                }}
              /> */}
            </div>
          </div>
          {/* <ErrorAlert
            errors={Boolean(errors[elementId]) || Boolean(errors[elementId])}
          >
            {errors[elementId].message || errors[elementId].message}
          </ErrorAlert> */}
        </div>
      );
  }
}

const MemoizedPostIt = memo(PostIt);

const PostItPageElement: React.FC<PageElementProps> = (props) => {
  const {
    pageElement,
    pageElement: {
      id: pageElementId,
      attributes: { dependees = [] },
    },
    answer,
    form: { control } = {},
  } = props;

  const currentAnswer = props.currentAnswer as PostitStyle1Answer | undefined;

  const {
    type,
    prefix,
    perRow,
    color = 'yellow',
  } = pageElement.attributes.options as PostitOptions;

  const isDisabled = useAppSelector((state) =>
    getPageElementDisabledState(state.projects, pageElementId)
  );

  const getDependeeAnswerType = props.getDependeeAnswerType;
  const dependentValue =
    dependees.length > 0 &&
    !!dependees[0].dependentId &&
    (parseDependeeValue(
      getDependeeAnswerType(dependees[0].dependeeId),
      dependees[0].dependeeKey
    ) as string[]);

  const [answers, setAnswers] = useState<PostitStyle1Answer>([]);

  useEffect(() => {
    let currentAnswers = [];
    if (!currentAnswer?.length && dependentValue && dependentValue.length > 0) {
      currentAnswers = dependentValue.map((value) => {
        return {
          value: prefix ? `${prefix} ${value}` : value,
          id: nanoid(),
          color,
        };
      });
    } else {
      currentAnswers = currentAnswer?.length
        ? currentAnswer
        : [
            {
              value: prefix ? `${prefix} ` : '',
              id: nanoid(),
              color,
            },
          ];
    }
    setAnswers(currentAnswers);
  }, [currentAnswer, dependentValue, prefix, color]);

  const updatePostItFn = useCallback(
    (itemCallback: PostItItem, answerCallback: PostItItem) => {
      setAnswers((oldAnswers) => {
        if (!isDisabled) {
          const tempAnswers = oldAnswers.map((a) => {
            return a.id === itemCallback.id ? { ...itemCallback, ...answerCallback } : a;
          });
          if (!isArrayEqual(tempAnswers, oldAnswers)) {
            answer(tempAnswers);
            return tempAnswers;
          }
        }
        return oldAnswers;
      });
    },
    [answer, isDisabled]
  );

  const deletePostItFn = useCallback(
    (itemCallback: PostItItem) => {
      setAnswers((oldAnswers) => {
        if (!isDisabled) {
          const tempAnswers = oldAnswers.filter((ans) => ans.id !== itemCallback.id);
          answer(tempAnswers);
          return tempAnswers;
        }
        return oldAnswers;
      });
    },
    [answer, isDisabled]
  );

  const options = answers;
  return (
    <Col>
      <Row className="mt-5 postIt__wrapper">
        {options.map((item) => {
          return (
            <MemoizedPostIt
              key={item.id}
              pageElementId={pageElementId}
              postItItem={item}
              updatePostIt={updatePostItFn}
              deletePostIt={deletePostItFn}
              type={type}
              perRow={perRow}
              length={options.length || 1}
              color={color || 'yellow'}
              control={control}
            />
          );
        })}
        {!isDisabled && type !== 'flip_example' && (
          <div
            className={`postIt__addPostItContainer ${perRow === 2 ? 'postIt__container_2' : ''}`}
          >
            <div
              className="postIt__addPostIt"
              role="button"
              tabIndex={0}
              onClick={() => {
                const copy = [...answers];
                copy.push({
                  value: prefix ? `${prefix} ` : '',
                  id: nanoid(),
                  color,
                });
                answer(copy);
              }}
            >
              <FiPlus />
            </div>
          </div>
        )}
      </Row>
    </Col>
  );
};

export default PostItPageElement;
