import { FC } from 'react';
import EllipsisText from 'react-ellipsis-text';
import { FaInfoCircle } from 'react-icons/fa';
import { A } from '.';
import { translate } from '../../../state/utils/helper';
import './UnsplashCredits.scss';

interface UnsplashCreditsProps {
  authorName?: string;
  authorUrl?: string;
}

const UnsplashCredits: FC<UnsplashCreditsProps> = ({ authorName, authorUrl }) => {
  if (authorName && authorUrl) {
    return (
      <h6 className="unsplashCredits__authorContainer">
        <FaInfoCircle className="mx-1" />
        {`${translate('pageElements.ideaCard.authorPre')} `}
        <A rel="noopener noreferrer" href={authorUrl} target="_blank">
          <EllipsisText text={authorName} length={15} />
        </A>
        {` ${translate('pageElements.ideaCard.authorPost')} `}
        <A rel="noopener noreferrer" href="https://unsplash.com" target="_blank">
          Unsplash
        </A>
      </h6>
    );
  }
  return null;
};

export default UnsplashCredits;
