import { CardAnswer } from '@app/@types/redux/answer';
import { CardOptions } from '@app/@types/redux/pageElement';
import { getDependeeValues } from '@state/selectors/projects';
import { useEffect, useState } from 'react';
import { PageElementProps } from '..';
import { CardBody, CardText, CardTitle, Col, Row } from '../../../UI/Html';
import UnsplashCredits from '../../../UI/Html/UnsplashCredits';
import './Cards.scss';

const Cards: React.FC<PageElementProps> = (props) => {
  const [cards, setCards] = useState<CardAnswer>();
  const { pageElement, answer, state } = props;
  const { dependees } = pageElement.attributes;

  const { values = [] } = pageElement.attributes.options as CardOptions;

  useEffect(() => {
    const tempCards = getDependeeValues(dependees, values, state) as CardAnswer;
    setCards(tempCards);
    answer(tempCards);
  }, []);

  // constructor(props) {
  //   super(props);
  //   const {
  //     getDependeeAnswerType,
  //     answer,
  //     pageElement,
  //     external,
  //     currentAnswer,
  //   } = this.props;
  //   const {
  //     options: { values },
  //     dependees,
  //   } = pageElement;
  //   if (external) {
  //     this.state = {
  //       cards: currentAnswer,
  //       pageElement,
  //     };
  //   } else {
  //     const cards = getDependeeValues(getDependeeAnswerType, dependees, values);
  //     this.state = {
  //       cards,
  //       pageElement,
  //     };
  //     answer(cards);
  //   }
  // }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   const {
  //     pageElement: {
  //       options: { values },
  //       dependees: oldDependees,
  //     },
  //   } = prevState;
  //   const {
  //     getDependeeAnswerType,
  //     answer,
  //     pageElement: { dependees: newDependees },
  //     external,
  //     currentAnswer,
  //   } = nextProps;
  //   if (external) {
  //     return { cards: currentAnswer };
  //   }
  //   if (oldDependees !== newDependees) {
  //     const cards = getDependeeValues(
  //       getDependeeAnswerType,
  //       newDependees,
  //       values,
  //     );
  //     answer(cards);
  //     return { cards };
  //   }
  //   return {};
  // }

  const imageCard = (item: CardAnswer[0], key: number) => {
    const imageUrl = item.image.regular;
    const imageAuthorName = item.image.authorName;
    const imageAuthorUrl = item.image.authorUrl;
    return (
      <Col xs={12} sm={12} md={6} lg={6} xl={4} key={key}>
        <div className="cards__imageCard_container">
          <div className="cards__imageCard_imageContainer">
            <img
              src={imageUrl}
              className="cards__imageCard_image"
              alt={imageAuthorName ?? 'Card Image'}
            />
            <UnsplashCredits authorName={imageAuthorName} authorUrl={imageAuthorUrl} />
          </div>
          <CardBody>
            <CardTitle className="font-weight-600">{item.title}</CardTitle>
            <CardText>{item.description}</CardText>
          </CardBody>
        </div>
      </Col>
    );
  };

  return (
    <Col className="mt-5">
      <Row className="justify-content-center">
        {cards?.map((cardInfo, key) => imageCard(cardInfo, key))}
      </Row>
    </Col>
  );
};

export default Cards;
