import { CSSProperties, FC, ReactNode } from 'react';

interface DivProps {
  children?: ReactNode;
  style?: CSSProperties;
  className?: string;
}

const Div: FC<DivProps> = ({ children = null, style = {}, className = '' }) => {
  return !children ? (
    <div className={className} style={style} />
  ) : (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export default Div;
