import { ROLES } from '@libs/constants/constants';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import { isLoggedIn } from '@utils/helpers/auth';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import { CSSTransition } from 'react-transition-group';

import { fetchProject, fetchUserProject } from '@state/redux/slices/project.slice';
import { verifyUserWorkspace } from '@state/redux/slices/workspace.slice';
import { FiFileText } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { TITLE } from '../../../libs/constants/constants';
import { slugify } from '../../../libs/helper';
import { i18nCText } from '../../../libs/i18n/I18n';
import { redirectTo } from '../../../routes/helpers';
import { getFullName } from '../../../state/selectors/users';
import { checkAnyPermission } from '../../../state/selectors/workspace';
import { translate } from '../../../state/utils/helper';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Container, Row } from '../../UI/Html';
import Layout from '../../UI/Layout';
import Footer from '../../UI/Layout/Footer';
import Title from '../View/Title';
import './Report.scss';
import { ChallengeReport } from './Sections';

function Report() {
  const { projectId, workspaceId } = useParams();
  useEffect(() => {
    if (workspaceId) {
      verifyUserWorkspace(workspaceId)
        .then((res) => {
          if (
            !checkAnyPermission(res.data.data.attributes.userWorkspaceRoles, [
              ROLES.TESTER,
              ROLES.ACCESS_APPROVED,
            ])
          ) {
            redirectTo(`/workspace/${workspaceId}`);
          }
        })
        .catch(() => {
          redirectTo(`/workspace/${workspaceId}`);
        });
    }
  }, [workspaceId]);
  const isSignedIn = isLoggedIn();
  const fullName = useAppSelector((state) => getFullName(state));
  const [showConfetti, setShowConfetti] = useState(true);
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowConfetti(false);
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);
  const [openedCollapses, setOpenCollapses] = useState([4]);
  function collapsesToggle(collapseId: number) {
    const tempOpenedCollapses = openedCollapses;
    if (tempOpenedCollapses.includes(collapseId)) {
      setOpenCollapses([]);
    } else {
      setOpenCollapses([collapseId]);
    }
  }
  const [isFetching, setIsFetching] = useState(false);
  const userId = useAppSelector((state) => state.users.user.id);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!projectId || !workspaceId) return;
    setIsFetching(true);
    dispatch(fetchProject({ id: projectId }))
      .then(() =>
        dispatch(fetchUserProject({ id: projectId })).then(() => {
          setIsFetching(false);
        })
      )
      .catch(() => {
        redirectTo(`/workspace/${workspaceId}`);
      });
  }, [projectId, dispatch, isSignedIn, userId, workspaceId]);
  const project = useAppSelector((state) => state.projects.previewProject);
  const userProject = useAppSelector((state) => state.projects.currentProject.userProject);
  if (!projectId || !workspaceId) return null;
  if (userProject.attributes.state && userProject.attributes.state !== 'Finished') {
    redirectTo(
      `/workspace/${workspaceId}/challenge/${projectId}/${slugify(project.attributes.name)}`
    );
  }
  const title = `${translate('report.title', {
    name: fullName,
    challengeName: i18nCText(project.attributes.name),
  })} | ${TITLE}`;
  const description = project.attributes.description;
  const image = project.attributes.imageData;
  const SECTIONS: {
    id: number;
    icon: React.ElementType;
    label: string;
    section: string;
    content: React.ElementType;
  }[] = [
    {
      id: 4,
      icon: FiFileText,
      label: translate('report.challengeReport.label'),
      section: 'challenge-report',
      content: ChallengeReport,
    },
  ];
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="title" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="twitter:image" content={image} />
      </Helmet>
      <Layout isFullWidth>
        {showConfetti ? <Confetti /> : null}
        <div className="header bg-info pb-4">
          <Container>
            <div className="header-body">
              <div className="align-items-center py-4 px-4 d-flex justify-content-between">
                {project.id && (
                  <h1 className="text-white mb-0 text-uppercase headingSubtitle">
                    {i18nCText(project.attributes.name)}
                  </h1>
                )}
                <Button
                  color="default"
                  className="btn-neutral my-1"
                  size="sm"
                  onClick={() => {
                    redirectTo(
                      `/workspace/${workspaceId}/challenge/${projectId}/${slugify(
                        project.attributes.name
                      )}`
                    );
                  }}
                >
                  {translate('formEdit.back')}
                </Button>
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <Row className="justify-content-center mt--3 mx-0 challenge-report">
            <Col className="px-0 px-md-3">
              <Card>
                <CardBody>
                  <Title className="text-align-initial">{translate('report.heading')}</Title>
                  {isFetching ? (
                    <Skeleton count={3} height={20} />
                  ) : (
                    <div className="accordion">
                      {_.orderBy(SECTIONS, ['id'], ['asc']).map((section) => {
                        const SectionIcon = section.icon;
                        const SectionBody = section.content;
                        return (
                          <Card className="shadow" key={section.id}>
                            <CardHeader
                              role="tab"
                              onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                collapsesToggle(section.id);
                              }}
                              aria-expanded={openedCollapses.includes(section.id)}
                            >
                              <div className="tab-header">
                                <SectionIcon />
                                <h3 className="mb-0 mx-2">{section.label}</h3>
                              </div>
                            </CardHeader>
                            <Collapse
                              role="tabpanel"
                              isOpen={openedCollapses.includes(section.id)}
                              className="report-collapse"
                            >
                              <CardBody>
                                <CSSTransition
                                  in={openedCollapses.includes(section.id)}
                                  unmountOnExit
                                  mountOnEnter
                                  timeout={100}
                                >
                                  <SectionBody reportId={userProject.attributes.reportLink} />
                                </CSSTransition>
                              </CardBody>
                            </Collapse>
                          </Card>
                        );
                      })}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Footer />
        </Container>
      </Layout>
    </>
  );
}

export default Report;
