import moment from 'moment';
import { FC } from 'react';
import { FaRegCopyright } from 'react-icons/fa';
import { CARTEDO_LINKS } from '../../../libs/constants/constants';
import { translate } from '../../../state/utils/helper';
import { Nav, NavItem, NavLink } from '../Html';
import './Footer.scss';

const Footer: FC = () => {
  return (
    <footer className="py-5 footer__container" id="footer-main">
      <div>
        <div className="footer__copyright text-muted">
          <FaRegCopyright className="footer__link" />
          <span className="mx-1 footer__link">{moment().format('YYYY')}</span>
          <a
            className="font-weight-bold"
            href={CARTEDO_LINKS.WEBSITE}
            target="_blank"
            rel="noreferrer"
          >
            {translate('appName')}
          </a>
        </div>
      </div>
      <div>
        <Nav className="nav-footer justify-content-center justify-content-xl-end footer__navBar">
          <NavItem>
            <NavLink target="_blank" className="footer__link" href={CARTEDO_LINKS.PRIVACY_POLICY}>
              {translate('layout.navbar.privacy')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              target="_blank"
              href={CARTEDO_LINKS.TERMS_AND_CONDITIONS}
              className="footer__link"
            >
              {translate('layout.navbar.terms')}
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </footer>
  );
};

export default Footer;
