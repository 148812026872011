import { ButtonHTMLAttributes, FC, MouseEvent, ReactNode } from 'react';
import Button from 'reactstrap-button-loader';

interface CButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  children: ReactNode;
  onClick?: (e: MouseEvent) => void;
  color?: string;
}

const CButton: FC<CButtonProps> = ({
  loading = false,
  children,
  onClick = () => undefined,
  color = 'info',
  ...props
}) => {
  return (
    <Button loading={loading} onClick={onClick} color={color} {...props}>
      {children}
    </Button>
  );
};

export default CButton;
