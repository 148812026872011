import { FC, ReactNode } from 'react';

interface H2Props {
  className?: string;
  children?: ReactNode | string;
}

const H2: FC<H2Props> = ({ className = '', children = null }) => {
  return <h2 className={className}>{children}</h2>;
};

export default H2;
